import PsychologistTagCollection from 'HealthBright/components/PsychologistTagCollection/PsychologistTagCollection';
import styles from './PsychologistWelcomeNote.module.scss';

import { PractitionerDetailsInterface } from 'interfaces/PublicProfile/Practitioner/practitioner';

interface PractitionerWelcomeNoteProps {
  practitionerDetails: PractitionerDetailsInterface;
}

const PsychologistWelcomeNote = ({ practitionerDetails }: PractitionerWelcomeNoteProps) => (
  <>
    <div className={styles.greet}>Hi, I'm {practitionerDetails.name}</div>
    <div className={styles.descWrapper}>
      {!!practitionerDetails.helmControl?.deliveryModes?.length && (
        <div className={styles.desc}>
          <div className={styles.label}>I OFFER</div>
          <PsychologistTagCollection practitionerDetails={practitionerDetails} />
        </div>
      )}
      {practitionerDetails.helmControl?.shortDescription && (
        <div className={styles.desc}>
          <div className={styles.label}>INTRO</div>
          <div
            className={styles.content}
            dangerouslySetInnerHTML={{
              __html: practitionerDetails.helmControl.shortDescription
            }}
          />
        </div>
      )}
      {practitionerDetails.helmControl?.credentials && (
        <div className={styles.desc}>
          <div className={styles.label}>EXPERIENCE & CREDENTIALS</div>
          <div
            className={styles.content}
            dangerouslySetInnerHTML={{
              __html: practitionerDetails.helmControl.credentials
            }}
          />
        </div>
      )}
    </div>
  </>
);

export default PsychologistWelcomeNote;
