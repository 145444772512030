import classnames from 'classnames';
import 'react-phone-input-2/lib/style.css';
import PhoneInput from 'react-phone-input-2';

import styles from './MaterialPhoneInput.module.scss';
import LoadingCircle from '../LoadingCircle/LoadingCircle';
import { useState } from 'react';
import classNames from 'classnames';
import { isAUProduction, isDevelopmentENV } from '../../utils/featureToggle/DevelopmentToggle';

export interface MaterialPhoneInputProps {
  id?: string;
  label: string;
  value: string;
  onChange: any;
  isError: boolean;
  isLoading?: boolean;
  placeholder?: string;
}

const GAE_REGION = process.env.REACT_APP_GAE_REGION;
const PREFERRED_COUNTRY = GAE_REGION === 'au' ? 'au' : GAE_REGION === 'gb' ? 'gb' : undefined;

export const contactMaskNumber = isDevelopmentENV()
  ? '0482 555 444'
  : isAUProduction
  ? '0482 666 666'
  : '07712 555 444';
export const phonePrefix = isDevelopmentENV() ? '04' : isAUProduction ? '04' : '07';

const MaterialPhoneInput = ({
  id,
  label,
  value,
  onChange,
  isError,
  isLoading,
  placeholder
}: MaterialPhoneInputProps) => {
  const [isFocusing, setIsFocusing] = useState(false);
  return (
    <>
      <div className={classNames(styles.fieldLabel, isFocusing && styles.fieldLabelFocus)}>{label}</div>
      <div className={styles.phoneInputWrapper}>
        <PhoneInput
          inputClass={classnames(styles.mobileNumberInput, isError && styles.redBorderBottom)}
          buttonClass={classnames(styles.countrySelect, isError && styles.redBorderBottom)}
          dropdownClass={classnames(styles.countryDropdown)}
          value={value}
          onChange={(value, data, event, formattedValue) => {
            onChange(formattedValue);
          }}
          inputProps={{
            id
          }}
          {...(PREFERRED_COUNTRY && {
            country: PREFERRED_COUNTRY,
            preferredCountries: [PREFERRED_COUNTRY]
          })}
          enableLongNumbers
          onFocus={() => setIsFocusing(true)}
          onBlur={() => {
            setIsFocusing(false);
          }}
          placeholder={placeholder}
        />
        {isLoading && (
          <div className={styles.loadingWrapper}>
            <div className={styles.loadingBox}>
              <LoadingCircle />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default MaterialPhoneInput;
