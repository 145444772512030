export const someoneHealthEnvironment = () => ({
  SomeoneHealthURL: 'https://someone.health/',
  ScheduleAppointmentURL: 'https://someone.health/schedule/',
  PsychologistsURL: 'https://someone.health/psychologists/',
  PricingURL: 'https://someone.health/pricing/',
  FAQURL: 'https://support.someone.health/?_ga=2.143979125.1798702250.1675649702-698124791.1675649702',
  ContactURL: 'https://support.someone.health/',
  AnxietyCounsellingURL: 'https://someone.health/anxiety-counselling/',
  DepressionCounsellingURL: 'https://someone.health/depression-mood-counselling/',
  RelationshipCounsellingURL: 'https://someone.health/relationship-counselling/',
  StressCounsellingURL: 'https://someone.health/stress-management-relaxation-counselling/',
  TraumaCounsellingURL: 'https://someone.health/trauma-ptsd-counselling/',
  GPURL: 'https://someone.health/gp',
  MHTPSurveyURL: 'https://forms.tacklit.com/someone-health-gp-new',
  MHTPSurveyURLDev: 'https://dev-s1h-gp-survey-real.paperform.co',
  VTPHNAEmail: 'vtphna@someone.health',
  someoneHealthEmail: 'team@someone.health'
});

export const DefaultClientTimeZone = 'Australia/Melbourne';
