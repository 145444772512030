import { PractitionerDetailsInterface } from 'interfaces/PublicProfile/Practitioner/practitioner';
import styles from './PsychologistTagCollection.module.scss';
import classNames from 'classnames';
import { FC } from 'react';
import { FitDeliveryList } from 'HealthBright/interfaces/fitFilter';

interface PsychologistTagCollectionProps {
  practitionerDetails: PractitionerDetailsInterface;
  selectedValue?: string[];
}

const PsychologistTagCollection: FC<PsychologistTagCollectionProps> = ({ practitionerDetails, selectedValue }) => {
  return (
    <div className={styles.container}>
      {practitionerDetails.helmControl?.deliveryModes?.includes(FitDeliveryList.video) && (
        <div className={classNames(selectedValue?.includes(FitDeliveryList.video) ? styles.selectedTag : styles.tag)}>
          <i className={`material-icons ${styles.callIcon}`}>videocam</i>
          <div className={styles.label}>Video Consults</div>
        </div>
      )}
      {practitionerDetails.helmControl?.deliveryModes?.includes(FitDeliveryList.phone) && (
        <div className={classNames(selectedValue?.includes(FitDeliveryList.phone) ? styles.selectedTag : styles.tag)}>
          <i className={`material-icons ${styles.callIcon}`}>call</i>
          <div className={styles.label}>Phone Consults</div>
        </div>
      )}
    </div>
  );
};

export default PsychologistTagCollection;
