import momentTz from 'moment-timezone';

export interface TimeZone {
  id: string;
  desc: string;
  label: string;
  timezones: string[];
}

const AU_TIMEZONE_LABEL: Record<
  string,
  {
    dstCode: string;
    nonDstCode: string;
    desc: string;
    shortDesc: string;
    nonDstHr: string;
    dstHr: string;
    label: string;
  }
> = {
  'Australia/Sydney': {
    nonDstCode: 'AEST',
    dstCode: 'AEDT',
    desc: 'NSW, VIC & ACT / Sydney, Melbourne & Canberra',
    shortDesc: 'NSW, VIC & ACT',
    nonDstHr: '10',
    dstHr: '11',
    label: 'New South Wales/Sydney'
  },
  'Australia/Melbourne': {
    nonDstCode: 'AEST',
    dstCode: 'AEDT',
    desc: 'NSW, VIC & ACT / Sydney, Melbourne & Canberra',
    shortDesc: 'NSW, VIC & ACT',
    nonDstHr: '10',
    dstHr: '11',
    label: 'Victoria/Melbourne'
  },
  'Australia/Brisbane': {
    nonDstCode: 'AEST',
    dstCode: 'AEST',
    desc: 'Queensland/Brisbane',
    shortDesc: 'Queensland',
    nonDstHr: '10',
    dstHr: '10',
    label: 'Queensland/Brisbane'
  },
  'Australia/Queensland': {
    nonDstCode: 'AEST',
    dstCode: 'AEST',
    desc: 'Queensland/Brisbane',
    shortDesc: 'Queensland',
    nonDstHr: '10',
    dstHr: '10',
    label: 'Queensland/Brisbane'
  },
  'Australia/Adelaide': {
    nonDstCode: 'ACST',
    dstCode: 'ACDT',
    desc: 'South Australia/Adelaide',
    shortDesc: 'South Australia',
    nonDstHr: '9.5',
    dstHr: '10.5',
    label: 'South Australia/Adelaide'
  },
  'Australia/Darwin': {
    nonDstCode: 'ACST',
    dstCode: 'ACST',
    desc: 'Northern Territory/Darwin',
    shortDesc: 'Northern Territory',
    nonDstHr: '9.5',
    dstHr: '9.5',
    label: 'Northern Territory/Darwin'
  },
  'Australia/Perth': {
    nonDstCode: 'AWST',
    dstCode: 'AWST',
    desc: 'Western Australia/Perth',
    shortDesc: 'Western Australia',
    nonDstHr: '8',
    dstHr: '8',
    label: 'Western Australia/Perth'
  },
  'Pacific/Norfolk': {
    nonDstCode: 'NFT',
    dstCode: 'NFDT',
    desc: 'Norfolk Island/Kingston',
    shortDesc: 'Norfolk Island',
    nonDstHr: '11',
    dstHr: '12',
    label: 'Norfolk Island/Kingston'
  },
  'Indian/Christmas': {
    nonDstCode: 'CXT',
    dstCode: 'CXT',
    desc: 'Christmas Island',
    shortDesc: 'Christmas Island',
    nonDstHr: '7',
    dstHr: '7',
    label: 'Christmas Island'
  },
  'Indian/Cocos': {
    nonDstCode: 'CCT',
    dstCode: 'CCT',
    desc: 'Cocos Islands',
    shortDesc: 'Christmas Island',
    nonDstHr: '6.5',
    dstHr: '6.5',
    label: 'Cocos Islands'
  }
};

const generateAUTimeZoneLabel = (timezone: string) => {
  const getTodayTime = momentTz.tz(timezone).isDST();
  const getTimeZoneLabel = AU_TIMEZONE_LABEL[timezone];
  const dstCode = getTodayTime ? getTimeZoneLabel.dstCode : getTimeZoneLabel.nonDstCode;

  return {
    desc: `${getTimeZoneLabel.desc} (${dstCode})`,
    label: `${getTimeZoneLabel.shortDesc} (${dstCode})`
  };
};

export const helmTimeZoneLocalStorage = 'HELM-CLIENT-TIMEZONE';

// s1h
export const someoneHealthTimeZoneLocalStorage = 'SOMEONE-HEALTH-CLIENT-TIMEZONE';

// recharge
export const rechargeTimeZoneLocalStorage = 'RECHARGE-CLIENT-TIMEZONE';

// HB
export const healthBrightTimeZoneLocalStorage = 'HEALTH-BRIGHT-CLIENT-TIMEZONE';

export const defaultClinicianTimezone =
  process.env.REACT_APP_GAE_REGION === 'au'
    ? 'Australia/Melbourne'
    : process.env.REACT_APP_GAE_REGION === 'gb'
    ? 'Europe/London'
    : 'Asia/Kuala_Lumpur';

export const AU_TIME_ZONE_LIST: TimeZone[] = [
  {
    id: 'Australia/Sydney',
    desc: generateAUTimeZoneLabel('Australia/Sydney').desc,
    label: generateAUTimeZoneLabel('Australia/Sydney').label,
    timezones: ['Australia/Sydney', 'Australia/Melbourne', 'Australia/Canberra', 'Australia/Hobart']
  },
  {
    id: 'Australia/Brisbane',
    desc: generateAUTimeZoneLabel('Australia/Brisbane').desc,
    label: generateAUTimeZoneLabel('Australia/Brisbane').label,
    timezones: ['Australia/Brisbane']
  },
  {
    id: 'Australia/Adelaide',
    desc: generateAUTimeZoneLabel('Australia/Adelaide').desc,
    label: generateAUTimeZoneLabel('Australia/Adelaide').label,
    timezones: ['Australia/Adelaide']
  },
  {
    id: 'Australia/Darwin',
    desc: generateAUTimeZoneLabel('Australia/Darwin').desc,
    label: generateAUTimeZoneLabel('Australia/Darwin').label,
    timezones: ['Australia/Darwin']
  },
  {
    id: 'Australia/Perth',
    desc: generateAUTimeZoneLabel('Australia/Perth').desc,
    label: generateAUTimeZoneLabel('Australia/Perth').label,
    timezones: ['Australia/Perth']
  },
  {
    id: 'Pacific/Norfolk',
    desc: generateAUTimeZoneLabel('Pacific/Norfolk').desc,
    label: generateAUTimeZoneLabel('Pacific/Norfolk').label,
    timezones: ['Pacific/Norfolk']
  },
  {
    id: 'Indian/Christmas',
    desc: generateAUTimeZoneLabel('Indian/Christmas').desc,
    label: generateAUTimeZoneLabel('Indian/Christmas').label,
    timezones: ['Indian/Christmas']
  },
  {
    id: 'Indian/Cocos',
    desc: generateAUTimeZoneLabel('Indian/Cocos').desc,
    label: generateAUTimeZoneLabel('Indian/Cocos').label,
    timezones: ['Indian/Cocos']
  }
];

export const SOMEONE_HEALTH_TIME_ZONE_LIST: TimeZone[] = AU_TIME_ZONE_LIST; // too much place to change so temporary handle this way first
export const RECHARGE_TIME_ZONE_LIST: TimeZone[] = AU_TIME_ZONE_LIST;
export const HEALTH_BRIGHT_TIME_ZONE_LIST: TimeZone[] = AU_TIME_ZONE_LIST;

export const massageDateTimeFromSourceTzToTargetTz = ({
  date,
  startTime,
  endTime,
  sourceTz,
  targetTZ
}: {
  date: string;
  startTime: string;
  endTime: string;
  sourceTz: string;
  targetTZ: string;
}) => {
  const clinicianStartTimeZone = momentTz.tz(`${date} ${startTime}`, 'YYYY-MM-DD hh:mmA', sourceTz);
  const clientStartTimeZone = momentTz.tz(clinicianStartTimeZone, targetTZ);
  const clinicianEndTimeZone = momentTz.tz(`${date} ${endTime}`, 'YYYY-MM-DD hh:mmA', sourceTz);
  const clientEndTimeZone = momentTz.tz(clinicianEndTimeZone, targetTZ);
  return {
    date: clientStartTimeZone.format('YYYY-MM-DD'),
    startTime: clientStartTimeZone.format('hh:mmA'),
    endTime: clientEndTimeZone.format('hh:mmA')
  };
};

const generateTimeZoneLabel = (timezone: string) => {
  const getTimeZoneLabel = AU_TIMEZONE_LABEL[timezone];
  if (!getTimeZoneLabel) {
    return timezone;
  }

  const getTodayTime = momentTz.tz(timezone).isDST();
  const dstCode = getTodayTime ? getTimeZoneLabel.dstCode : getTimeZoneLabel.nonDstCode;
  const dstHr = getTodayTime ? getTimeZoneLabel.dstHr : getTimeZoneLabel.nonDstHr;
  return `${getTimeZoneLabel.label} (${dstCode}) +${dstHr}`;
};

export const TIMEZONE_OPTIONS = [
  { value: 'Australia/Brisbane', label: generateTimeZoneLabel('Australia/Brisbane') },
  { value: 'Australia/Melbourne', label: generateTimeZoneLabel('Australia/Melbourne') },
  { value: 'Australia/Sydney', label: generateTimeZoneLabel('Australia/Sydney') },
  { value: 'Australia/Adelaide', label: generateTimeZoneLabel('Australia/Adelaide') },
  { value: 'Australia/Darwin', label: generateTimeZoneLabel('Australia/Darwin') },
  { value: 'Australia/Perth', label: generateTimeZoneLabel('Australia/Perth') },
  { value: 'Pacific/Norfolk', label: generateTimeZoneLabel('Pacific/Norfolk') },
  { value: 'Indian/Christmas', label: generateTimeZoneLabel('Indian/Christmas') },
  { value: 'Indian/Cocos', label: generateTimeZoneLabel('Indian/Cocos') }
];
