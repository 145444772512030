import TacklitFooter from 'components/TacklitFooter/TacklitFooter';
import styles from './HealthBrightWrapper.module.scss';

interface HealthBrightWrapperProps {
  children?: any;
  noFooter?: boolean;
}

const HealthBrightWrapper = ({ children, noFooter }: HealthBrightWrapperProps) => (
  <div className={styles.container}>
    {children}
    {!noFooter && <TacklitFooter darkFooter className={styles.footer} linkToTacklit />}
  </div>
);

export default HealthBrightWrapper;
