import { Helmet, HelmetProvider } from 'react-helmet-async';
import FloatingHelpBtn from '../FloatingHelpBtn/FloatingHelpBtn';

interface HealthBrightHelmetWrapperProps {
  title?: string;
  children?: any;
  hubspot?: boolean;
}

const faviconUrl = `${process.env.PUBLIC_URL}/favHealthBright.png`;

const HealthBrightHelmetWrapper = ({ children, title, hubspot }: HealthBrightHelmetWrapperProps) => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>{title}</title>
        <meta name={'description'} content={'Health Bright'} />
        <link rel="icon" href={faviconUrl} />
        <link rel="apple-touch-icon" href={faviconUrl} />
      </Helmet>
      {children}
      <FloatingHelpBtn hubspot={hubspot} />
    </HelmetProvider>
  );
};

export default HealthBrightHelmetWrapper;
