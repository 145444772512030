import { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import styles from './TokenAccess.module.scss';
import TwoFAInput from 'components/v2/TwoFAInput/TwoFAInput';
import { PARTNER_CODE_STORAGE } from 'interfaces/PartnerReferral/constants';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useHealthBrightPartnerRoutesGenerator } from 'HealthBright/HealthBrightPartner/utils/Path/HealthBrightPartnerRoutesGenerator';
import { HB_PARTNER_CODE, HB_PARTNER_CODE_LENGTH, HB_PARTNER_CODE_PARAMS } from './hbPartnerCode';
import ButtonHB, { ButtonStatusType } from 'HealthBright/components/ButtonHB/ButtonHB';
import HealthBrightHeader from 'HealthBright/components/HealthBrightHeader/HealthBrightHeader';
import HealthBrightHelmetWrapper from 'HealthBright/components/HealthBrightHelmetWrapper/HealthBrightHelmetWrapper';
import HealthBrightContentLayout from 'HealthBright/components/HealthBrightContentLayout/HealthBrightContentLayout';
import { healthBrightEnvironment } from 'HealthBright/HealthBrightPartner/utils/HealthBrightEnv';

const TokenAccess = () => {
  const navigate = useNavigate();
  const [signInBtnStatus, setSignInBtnStatus] = useState<ButtonStatusType>('');
  const { HealthBrightURL, HealthBrightEmail } = healthBrightEnvironment();
  const { PRACTITIONER } = useHealthBrightPartnerRoutesGenerator();
  const [searchParams] = useSearchParams();

  const [code, setCode] = useState<string[]>([]);
  const [error, setError] = useState<string>('');
  const [isTouched, setIsTouched] = useState<boolean>(false);

  const validateAccessToken = useCallback(
    (accessToken: string) => {
      if (HB_PARTNER_CODE.some((obj) => obj.accessToken === accessToken)) {
        setSignInBtnStatus('active');
        const getPartnerCode = HB_PARTNER_CODE.find((obj) => obj.accessToken === accessToken);
        if (getPartnerCode?.partnerCode) {
          setSignInBtnStatus('finished');
          localStorage.setItem(PARTNER_CODE_STORAGE, getPartnerCode.partnerCode);
          setError('');
          setIsTouched(false);
          setTimeout(() => {
            setSignInBtnStatus('');
            navigate(PRACTITIONER.LISTING);
          }, 3000);
        }
      } else {
        setSignInBtnStatus('');
        setError('Invalid Code.');
        setIsTouched(true);
      }
    },
    [PRACTITIONER.LISTING, navigate]
  );

  useEffect(() => {
    const accessCode = searchParams.get(HB_PARTNER_CODE_PARAMS);
    if (accessCode && accessCode.length <= HB_PARTNER_CODE_LENGTH) {
      setCode(accessCode.split(''));
      validateAccessToken(accessCode);
    }
  }, [PRACTITIONER.LISTING, searchParams, navigate, validateAccessToken]);

  const onSignIn = () => {
    validateAccessToken(code.join(''));
  };

  const handleChangeCode = (code: string[]) => {
    setError('');
    setIsTouched(false);
    setCode(code);
  };

  return (
    <HealthBrightHelmetWrapper title="HealthBright - Sign Up">
      <div className={styles.containerWrapper}>
        <HealthBrightHeader noLogin />
        <div className={styles.background} />
        <HealthBrightContentLayout className={styles.contentWrapper}>
          <div className={styles.content}>
            <div className={styles.leftContent}>
              <div className={styles.formContent}>
                <div className={styles.welcomeText}>Welcome back</div>
                <div className={styles.text}>
                  This is the HealthBright partner referral channel Please enter your organisations access code
                </div>
                <TwoFAInput
                  isText
                  error={error}
                  value={code}
                  inputContainerClassName={styles.inputContainerClassName}
                  inputClassName={classNames(styles.inputClassName, error && styles.inputError)}
                  isTouched={isTouched}
                  onChangeValue={handleChangeCode}
                  length={HB_PARTNER_CODE_LENGTH}
                  hasSeparator
                  errorClassName={styles.errorClassName}
                />
                <a className={styles.link} href={`mailto:${HealthBrightEmail}`}>
                  Forgot code
                </a>
                <ButtonHB
                  fullWidth
                  loadingWord="Verifying"
                  completedWord="Verified"
                  status={signInBtnStatus}
                  className={styles.signInButton}
                  onClick={onSignIn}
                >
                  Sign in
                </ButtonHB>
                <div className={styles.smallText}>
                  Organisation not registered yet?{' '}
                  <a className={styles.link} href={`mailto:${HealthBrightEmail}`}>
                    Contact us today
                  </a>
                </div>
                <div className={styles.smallText}>
                  Looking for our direct access site?
                  <div className={styles.visitEmail}>
                    Please visit &nbsp;
                    <a className={styles.link} href={HealthBrightURL}>
                      health-bright.com.au
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className={styles.rightContent}>
              <div className={styles.infoBox}>
                <div className={styles.welcomeText}>HealthBright Psychologists</div>
                <label>Our Psychologists are available for video and telephone therapy 7 days a week.</label>
              </div>
            </div>
          </div>
          <div className={styles.footer}>© Powered by Tacklit</div>
        </HealthBrightContentLayout>
      </div>
    </HealthBrightHelmetWrapper>
  );
};

export default TokenAccess;
