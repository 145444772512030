import Button from 'components/v2/Button/Button';
import CardHeader from 'helm/components/MicroJournal/CardHeader/CardHeader';
import MicroJournalEntries from 'helm/components/MicroJournal/MicroJournalEntries/MicroJournalEntries';
import { useFetchAppetiteCheckInGraph } from 'helm/pages/IP6Page/hooks/getMicroJournalAppetiteInsights';
import { useFetchEnergyCheckInGraph } from 'helm/pages/IP6Page/hooks/getMicroJournalEnergyInsights';
import { useFetchOverallCheckInGraph } from 'helm/pages/IP6Page/hooks/getMicroJournalOverallInsights';
import { useFetchSleepCheckInGraph } from 'helm/pages/IP6Page/hooks/getMicroJournalSleepInsights';
import { useFetchCompletedCheckInStatus } from 'pages/PatientPage/HomePage/hooks/getCheckInStatus';
import BarLineChart from 'pages/ReportViewPage/components/ReportDraftContent/components/ReportContentBody/components/WidgetComponents/ChartComponents/BarLineChart/BarLineChart';
import InsightsLineChart from 'pages/ReportViewPage/components/ReportDraftContent/components/ReportContentBody/components/WidgetComponents/ChartComponents/InsightsLineChart/InsightsLineChart';
import { useNavigate } from 'react-router-dom';
import { useGetAttachedClinicianDetails } from 'redux/endpoints/clinicianProfileServices/getClientDetails';
import { useGetAccessToken } from 'utils/hooks/token';

import styles from './MicroJournalInsights.module.scss';
import SomeoneHealthHelmetWrapper from '../../components/SomeoneHealthHelmetWrapper/SomeoneHealthHelmetWrapper';

const sleepTooltipFormatter = (value: number) => `${Math.round((value + 8) * 10) / 10} hours`;

const sleepTickFormatter = (value: number) => {
  const sleepValue = value + 8;

  switch (sleepValue) {
    case 4:
      return `<${sleepValue}`;
    case 12:
      return `${sleepValue}+`;
    default:
      return `${sleepValue}`;
  }
};

const appetiteTickFormatter = (value: number) => {
  switch (value) {
    case -18:
      return 'Under';
    case 18:
      return 'Over';
    default:
      return 'OK';
  }
};

const MicroJournalInsights = () => {
  const navigate = useNavigate();
  const { token } = useGetAccessToken();
  const { attachedClinicianDetail } = useGetAttachedClinicianDetails();

  const clinicianSlugUrl = attachedClinicianDetail?.clinician?.slugUrl;
  const clinicianId = attachedClinicianDetail?.clinician?._id || clinicianSlugUrl || '';

  const { completedCheckInStatus: isAssignedMCI } = useFetchCompletedCheckInStatus(token, clinicianId);

  const { overallTitle, overallCheckInGraph, isOverallCheckInGraphLoading, fetchOverallCheckInGraph } =
    useFetchOverallCheckInGraph({ token });
  const { energyTitle, energyCheckInGraph, isEnergyCheckInGraphLoading, fetchEnergyCheckInGraph } =
    useFetchEnergyCheckInGraph({ token });
  const { sleepTitle, sleepCheckInGraph, isSleepCheckInGraphLoading, fetchSleepCheckInGraph } =
    useFetchSleepCheckInGraph({ token });
  const { appetiteTitle, appetiteCheckInGraph, isAppetiteCheckInGraphLoading, fetchAppetiteCheckInGraph } =
    useFetchAppetiteCheckInGraph({ token });

  const insightCharts = [
    {
      title: overallTitle,
      chart: { data: overallCheckInGraph, isLoading: isOverallCheckInGraphLoading },
      refetch: fetchOverallCheckInGraph
    },
    {
      title: energyTitle,
      chart: { data: energyCheckInGraph, isLoading: isEnergyCheckInGraphLoading },
      refetch: fetchEnergyCheckInGraph
    }
  ];

  const barCharts = [
    {
      title: sleepTitle,
      chart: {
        data: sleepCheckInGraph,
        isLoading: isSleepCheckInGraphLoading,
        yRange: 4,
        yLabel: 'Hours per night',
        yTickFormatter: sleepTickFormatter,
        tooltipFormatter: sleepTooltipFormatter
      },
      refetch: fetchSleepCheckInGraph
    },
    {
      title: appetiteTitle,
      chart: {
        data: appetiteCheckInGraph,
        isLoading: isAppetiteCheckInGraphLoading,
        yRange: 18,
        yLabel: 'Eating patterns',
        yTickFormatter: appetiteTickFormatter
      },
      refetch: fetchAppetiteCheckInGraph
    }
  ];

  return (
    <SomeoneHealthHelmetWrapper title={`someone.health - Mood tracking`}>
      <div className={styles.container}>
        <div className={styles.title}>Mood Tracking</div>
        <div className={styles.content}>
          <div className={styles.headerContainer}>
            <div className={styles.title}>Journal</div>
            {isAssignedMCI && clinicianSlugUrl && (
              <Button onClick={() => navigate(`/${clinicianSlugUrl}/checkIn`)}>
                New journal entry<i className={`material-icons-outlined`}>navigate_next</i>
              </Button>
            )}
          </div>
          <div className={styles.graphs}>
            {insightCharts.map(({ title, chart: { data, isLoading }, refetch }) => (
              <div className={styles.graphContainer}>
                <CardHeader title={title} onSelectDateRange={refetch} />
                <InsightsLineChart data={data} yDomain={[1, 11]} yTicks={[1, 6, 11]} isLoading={isLoading} />
              </div>
            ))}
            {barCharts.map(
              ({ title, chart: { data, isLoading, yRange, yLabel, yTickFormatter, tooltipFormatter }, refetch }) => (
                <div className={styles.graphContainer}>
                  <CardHeader title={title} onSelectDateRange={refetch} />
                  <BarLineChart
                    data={data}
                    yDomain={[-yRange, yRange]}
                    yLabel={yLabel}
                    yTicks={[-yRange, 0, yRange]}
                    yTickFormatter={yTickFormatter}
                    isLoading={isLoading}
                    tooltipFormatter={tooltipFormatter}
                  />
                </div>
              )
            )}
          </div>
          <div className={styles.divider} />
          <div className={styles.headerContainer}>
            <div className={styles.title}>Journal Entries</div>
          </div>
          <MicroJournalEntries token={token} />
        </div>
      </div>
    </SomeoneHealthHelmetWrapper>
  );
};

export default MicroJournalInsights;
