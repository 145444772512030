import styles from './AvailabilityPill.module.scss';
import classnames from 'classnames';
import { ReactNode } from 'react';
import { newHelmUpdate } from 'utils/featureToggle/featureToggle';

export interface AvailabilityPillProps {
  children: ReactNode;
  classNames?: string;
  darkYellowPill?: boolean;
}

const AvailabilityPill = ({ children, classNames, darkYellowPill }: AvailabilityPillProps) => {
  return (
    <div
      className={classnames(
        darkYellowPill && newHelmUpdate
          ? styles.darkYellowContainer
          : newHelmUpdate
          ? styles.newHelmContainer
          : styles.container,
        classNames
      )}
    >
      {children}
    </div>
  );
};

export default AvailabilityPill;
