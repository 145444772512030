import { Invoice } from 'interfaces/Invoice/Invoice';
import styles from './InvoiceTotal.module.scss';

const GAE_REGION = process.env.REACT_APP_GAE_REGION;
const MONEY_SYMBOL = GAE_REGION === 'au' ? '$' : GAE_REGION === 'gb' ? '£' : undefined;

const calculateTotal = (
  items: Invoice['items'],
  taxRate: number,
  discountType?: 'percent' | 'amount',
  discountValue?: number
) => {
  const discountValueNumber = Number(discountValue);
  const totalItemsCost = items.reduce((finalTotal, item) => finalTotal + Number(item.cost), 0);

  let totalCost = totalItemsCost;

  if (discountType && discountValue !== undefined && !isNaN(discountValue)) {
    if (!isNaN(discountValueNumber) && !isNaN(totalCost)) {
      if (discountType === 'percent' && discountValueNumber <= 100) {
        totalCost = totalItemsCost - (totalItemsCost * Math.round(discountValueNumber)) / 100;
      } else if (discountType === 'amount' && discountValueNumber <= totalItemsCost) {
        totalCost = totalItemsCost - discountValueNumber;
      }
    }
  }

  let totalTax = 0;

  const taxValue = (Number(taxRate) / 100) * Number(totalCost);

  if (!isNaN(taxValue)) {
    totalTax = taxValue;
  }

  const totalPayment = totalCost + totalTax;

  return { totalCost: totalCost.toFixed(2), totalTax: totalTax.toFixed(2), totalPayment: totalPayment.toFixed(2) };
};

interface InvoiceTotalProps {
  discount: Invoice['discount'];
  items: Invoice['items'];
  taxRate: Invoice['taxRate'];
  status: Invoice['status'];
}

const InvoiceTotal = ({ discount, items, taxRate, status }: InvoiceTotalProps) => {
  const { totalCost, totalTax, totalPayment } = calculateTotal(items, taxRate, discount?.type, discount?.value);

  return (
    <div className={styles.container}>
      <span className={styles.totalLabel}>TOTAL</span>
      <span className={styles.costTotal}>
        {MONEY_SYMBOL}
        {totalCost}
      </span>
      <span className={styles.taxTotal}>
        {MONEY_SYMBOL}
        {totalTax}
      </span>
      <span className={styles.finalTotal}>
        {status !== 'confirmPaid' && <span className={styles.label}>TO PAY</span>}
        {MONEY_SYMBOL}
        {totalPayment}
      </span>
    </div>
  );
};

export default InvoiceTotal;
