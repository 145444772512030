import { useEffect, useState } from 'react';
import queryString from 'query-string';
import styles from './PartnerPsychologistDetails.module.scss';
import { useFetchPractitionerDetails } from './hooks/getPsychologistDetails';
import PsychologistTimeSlot from './components/PsychologistTimeSlot/PsychologistTimeSlot';
import { useLocation, useParams } from 'react-router-dom';
import { scrollToView } from 'utils/scrollToView';
import LoadingDot from 'components/LoadingDot/LoadingDot';
import PractitionerImageSlideshow from 'components/PractitionerImageSlideshow/PractitionerImageSlideshow';
import { Modal } from 'antd';
import { useHealthBrightPartnerRoutesGenerator } from '../../utils/Path/HealthBrightPartnerRoutesGenerator';
import HealthBrightHelmetWrapper from 'HealthBright/components/HealthBrightHelmetWrapper/HealthBrightHelmetWrapper';
import { MENTAL_HEALTH_LIST_LABELS, MentalHealthList } from 'HealthBright/interfaces/mentalHealth';
import HealthBrightContentLayout from 'HealthBright/components/HealthBrightContentLayout/HealthBrightContentLayout';
import HealthBrightFooter from 'HealthBright/components/HealthBrightFooter/HealthBrightFooter';
import ButtonHB from 'HealthBright/components/ButtonHB/ButtonHB';
import PsychologistWelcomeNote from 'HealthBright/components/PsychologistDetails/components/PsychologistWelcomeNote/PsychologistWelcomeNote';
import PsychologistDetailsHeader from 'HealthBright/components/PsychologistDetails/components/PsychologistDetailsHeader/PsychologistDetailsHeader';
import PsychologistSpecialisation from 'HealthBright/components/PsychologistSpecialisation/PsychologistSpecialisation';
import PsychologistBonaFides from 'HealthBright/components/PsychologistBonaFides/PsychologistBonaFides';
import PsychologistExtraService from 'HealthBright/components/PsychologistDetails/components/PsychologistExtraService/PsychologistExtraService';
import PsychologistQuote from 'HealthBright/components/PsychologistDetails/components/PsychologistQuote/PsychologistQuote';
import JoinWaitlistForm from 'HealthBright/components/JoinWaitlistForm/JoinWaitlistForm';

export interface PsychologistQueryParam {
  timeSlotSection?: boolean;
}

const PartnerPsychologistDetails = () => {
  const location = useLocation();
  const queryParam = location.search;
  const { clinicianId } = useParams<{ clinicianId: string }>();
  const { PRACTITIONER } = useHealthBrightPartnerRoutesGenerator();

  const backToListingLink = `${PRACTITIONER.LISTING}${queryParam ? `${queryParam}` : ''}`;

  const { timeSlotSection }: PsychologistQueryParam = queryString.parse(queryParam);

  const { practitionerDetails, isPractitionerDetailsLoading } = useFetchPractitionerDetails(clinicianId!);
  const practitionerName = practitionerDetails.name;
  const [showWaitlistForm, setShowWaitlistForm] = useState(false);

  useEffect(() => {
    if (timeSlotSection) {
      scrollToView('HealthBrightTimeSlot');
    }
  }, [timeSlotSection, isPractitionerDetailsLoading]);

  return (
    <HealthBrightHelmetWrapper
      title={`HealthBright - ${practitionerName ? `${practitionerName} Profile` : 'psychologist profile'}`}
      hubspot
    >
      <div className="health-bright-theme">
        {isPractitionerDetailsLoading ? (
          <div className={styles.loading}>
            <LoadingDot />
          </div>
        ) : (
          <>
            <PsychologistDetailsHeader
              practitionerDetails={practitionerDetails}
              listingLink={backToListingLink}
              noLogin
            />
            <div className={styles.container}>
              <HealthBrightContentLayout>
                <div className={styles.detailsContentWrapper}>
                  {/* Left content */}
                  <div className={styles.leftContent}>
                    <PsychologistWelcomeNote practitionerDetails={practitionerDetails} />

                    {/* Just show on mobile view */}
                    <div className={styles.appointmentBtnWrapper}>
                      <ButtonHB onClick={() => scrollToView('HealthBrightTimeSlot')} className={styles.appointmentBtn}>
                        Schedule Appointment
                      </ButtonHB>
                    </div>
                    {practitionerDetails.helmControl?.primarySpecialisations &&
                      practitionerDetails.helmControl.primarySpecialisations.length > 0 && (
                        <div>
                          <PsychologistSpecialisation
                            label={'MAIN AREAS OF PRACTICE'}
                            specialisationsDetails={practitionerDetails.helmControl.primarySpecialisations.map(
                              (item) => MENTAL_HEALTH_LIST_LABELS[item as MentalHealthList]
                            )}
                            badgeClassName={styles.mainAreaBadge}
                          />
                        </div>
                      )}
                    {practitionerDetails.helmControl?.secondarySpecialisations &&
                      practitionerDetails.helmControl.secondarySpecialisations.length > 0 && (
                        <div>
                          <PsychologistSpecialisation
                            label={'I CAN HELP WITH'}
                            specialisationsDetails={practitionerDetails.helmControl.secondarySpecialisations.map(
                              (item) => MENTAL_HEALTH_LIST_LABELS[item as MentalHealthList]
                            )}
                            badgeClassName={styles.secondaryAreaBadge}
                          />
                        </div>
                      )}
                  </div>

                  {/* Right content */}
                  <div className={styles.rightContent}>
                    {practitionerDetails.helmControl?.additionalPhotos?.length > 0 && (
                      <div className={styles.additionalPhoto}>
                        <div className={styles.additionalPhotoCard}>
                          <PractitionerImageSlideshow
                            classNames={styles.slideShow}
                            slidePhoto={practitionerDetails.helmControl.additionalPhotos}
                          />
                        </div>
                      </div>
                    )}
                    <div className={styles.detailsWrapper}>
                      {/* Just show on desktop view */}
                      <div className={styles.appointmentBtnWrapper}>
                        <ButtonHB
                          onClick={() => scrollToView('HealthBrightTimeSlot')}
                          className={styles.appointmentBtn}
                        >
                          Schedule Appointment
                        </ButtonHB>
                      </div>
                      {practitionerDetails.helmControl?.bonaFides && (
                        <PsychologistBonaFides
                          role={practitionerDetails.medicare?.role}
                          helmControl={practitionerDetails.helmControl}
                        />
                      )}
                      {practitionerDetails.helmControl?.fundingMethods &&
                        practitionerDetails.helmControl.fundingMethods?.length > 0 && (
                          <PsychologistExtraService
                            label={'FUNDING METHODS ACCEPTED'}
                            icon={'credit_card'}
                            servicesAvailable={practitionerDetails.helmControl.fundingMethods}
                          />
                        )}
                      {practitionerDetails.helmControl?.servicesAvailable &&
                        practitionerDetails.helmControl.servicesAvailable?.length > 0 && (
                          <PsychologistExtraService
                            label={'SERVICES AVAILABLE'}
                            icon={'summarize'}
                            servicesAvailable={practitionerDetails.helmControl.servicesAvailable}
                          />
                        )}
                    </div>
                  </div>
                </div>
              </HealthBrightContentLayout>
            </div>
            {practitionerDetails.helmControl?.quote && (
              <PsychologistQuote quote={practitionerDetails.helmControl.quote} />
            )}
            <PsychologistTimeSlot
              helmDetails={practitionerDetails}
              onShowWaitlistForm={() => setShowWaitlistForm(true)}
            />
            <HealthBrightFooter />
          </>
        )}

        {/* Join waitlist modal */}
        <Modal
          width={700}
          visible={showWaitlistForm}
          onCancel={() => {
            setShowWaitlistForm(false);
          }}
          bodyStyle={{ padding: '40px 80px', top: 50 }}
          footer={null}
          destroyOnClose
        >
          <JoinWaitlistForm
            onComplete={() => {
              setShowWaitlistForm(false);
            }}
            practitionerDetails={practitionerDetails}
          />
        </Modal>
      </div>
    </HealthBrightHelmetWrapper>
  );
};

export default PartnerPsychologistDetails;
