import { FieldArray, useFormikContext } from 'formik';
import styles from './HBPartnerEmergencyContactForm.module.scss';
import { HB_EMERGENCY_CONTACT_RELATIONSHIP_OPTIONS } from '../../constants';
import FormikSelect from 'components/Select/FormikSelect';
import FormikInput from 'components/Input/FormikInput';
import HelmMobileFormikInput from 'helm/components/Input/HelmMobileFormikInput';
import { EmergencyContactDetail } from 'utils/http/PatientProfileService/Patient/patient';
import ButtonHB from 'HealthBright/components/ButtonHB/ButtonHB';

const HBPartnerEmergencyContactForm = () => {
  const { values } = useFormikContext<{
    emergencyContacts: EmergencyContactDetail[];
  }>();

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>Emergency contacts</div>
      <div>
        <FieldArray
          name="emergencyContacts"
          render={(arrayHelpers) => (
            <div className={styles.formWrapper}>
              {values.emergencyContacts && values.emergencyContacts.length > 0 ? (
                values.emergencyContacts.map((item, index) => (
                  <div className={styles.formWrapper} key={index}>
                    <FormikInput
                      inputClass={styles.customInput}
                      label="First Name*"
                      name={`emergencyContacts.${index}.firstName`}
                      required
                      labelClass={styles.inputLabel}
                    />
                    <FormikInput
                      inputClass={styles.customInput}
                      label="Last Name*"
                      name={`emergencyContacts.${index}.lastName`}
                      required
                      labelClass={styles.inputLabel}
                    />
                    <HelmMobileFormikInput
                      inputClass={styles.customInput}
                      id={`mobileNumber`}
                      name={`emergencyContacts.${index}.mobileNumber`}
                      label={'Mobile number*'}
                      mobilePattern="____ ___ ___"
                      placeholder={'0482 555 444'}
                      phonePrefix=""
                      labelClass={styles.inputLabel}
                    />
                    <FormikInput
                      inputClass={styles.customInput}
                      label="Email address*"
                      name={`emergencyContacts.${index}.email`}
                      labelClass={styles.inputLabel}
                      required
                    />
                    <FormikSelect
                      className={styles.customSelect}
                      labelClass={styles.selectInputLabel}
                      label={'Your relationship?*'}
                      isSearchable={false}
                      name={`emergencyContacts.${index}.relationship`}
                      options={HB_EMERGENCY_CONTACT_RELATIONSHIP_OPTIONS.map((tag) => ({ label: tag, value: tag }))}
                      styles={{
                        container: (base) => ({
                          ...base,
                          width: '100%'
                        }),
                        control: (controlBase: any) => ({
                          ...controlBase,
                          minHeight: '30px',
                          backgroundColor: 'transparent',
                          border: 'none',
                          borderRadius: 0,
                          boxShadow: 'none'
                        }),
                        valueContainer: (base) => ({ ...base, paddingLeft: 0 })
                      }}
                    />
                    <div className={styles.buttonGroup}>
                      {values.emergencyContacts.length === index + 1 && (
                        <ButtonHB
                          variant="outlined"
                          onClick={() =>
                            arrayHelpers.insert(index + 1, {
                              firstName: '',
                              lastName: '',
                              mobileNumber: '',
                              email: '',
                              relationship: ''
                            })
                          }
                        >
                          Add another emergency contact
                        </ButtonHB>
                      )}
                      <ButtonHB variant="contained" onClick={() => arrayHelpers.remove(index)}>
                        Remove emergency contact
                      </ButtonHB>
                    </div>
                  </div>
                ))
              ) : (
                <ButtonHB
                  variant="outlined"
                  onClick={() =>
                    arrayHelpers.push({
                      firstName: '',
                      lastName: '',
                      mobileNumber: '',
                      email: '',
                      relationship: ''
                    })
                  }
                >
                  Add another emergency contact
                </ButtonHB>
              )}
            </div>
          )}
        />
      </div>
    </div>
  );
};

export default HBPartnerEmergencyContactForm;
