import { clientProfilesInterface, clientRecordsInterface } from 'interfaces/Clients/clientsRecord';

export const PARTNER_HB_SIGN_UP_USER = 'PARTNER_HB_SIGN_UP_USER';

export interface UserSessionStorage {
  clientRecord: clientRecordsInterface;
  authToken: string;
  firstName?: string;
}

export const storeUserSession = ({
  storageKey,
  session,
  dateOfBirthValue,
  signUpSessionId
}: {
  storageKey: string;
  session: UserSessionStorage;
  dateOfBirthValue: string;
  signUpSessionId?: string;
}) => {
  const massageClientProfiles = (data: clientProfilesInterface[]) => {
    const massageClientList: clientProfilesInterface[] = [];
    for (let obj of data) {
      massageClientList.push({
        ...obj,
        dateOfBirth: dateOfBirthValue
      });
    }
    return massageClientList;
  };

  const massageSessionData: UserSessionStorage = {
    ...session,
    clientRecord: {
      ...session.clientRecord,
      clientProfiles: massageClientProfiles(session.clientRecord.clientProfiles)
    }
  };
  sessionStorage.setItem(signUpSessionId || storageKey, JSON.stringify(massageSessionData));
};

export const retrieveUserSession = (storageKey: string, signUpSessionId?: string): Partial<UserSessionStorage> =>
  JSON.parse(sessionStorage.getItem(signUpSessionId || storageKey) || '{}') as Partial<UserSessionStorage>;
