import { DatePicker } from 'antd';
import { PickerDateProps } from 'antd/lib/date-picker/generatePicker';
import classnames from 'classnames';
import { useField } from 'formik';
import moment, { Moment } from 'moment';
import {
  IS_CAW_APP,
  IS_HEALTH_BRIGHT_APP,
  IS_HELM_APP,
  IS_SOMEONE_HEALTH_APP
} from 'utils/hooks/AccountInfo/clientDetails';

import ErrorMessage from '../ErrorMessage/ErrorMessage';

import { KeyboardEvent, useEffect } from 'react';
import { CLIENT_BRAND, ClientBrand } from '../../interfaces/ClientBrand';
import styles from './DateSelector.module.scss';
import './FormikDatePicker.scss';
import { formatInputDatePicker } from 'utils/date';

const DATE_FORMAT = 'YYYY-MM-DD';

interface FormikInputProps extends PickerDateProps<Moment> {
  name: string;
  inputClass?: string;
  label?: string;
}

const FormikDatePicker = ({ className, label, name, ...props }: FormikInputProps) => {
  const [field, { error, touched }] = useField(name);

  const errorStyles = CLIENT_BRAND === ClientBrand.SOMEONE_HEALTH_PARTNER ? 'sh-error' : 'error';

  const { onChange, value } = field;

  const handleOnChange = (e: Moment | null) => {
    onChange(name)(e?.format(DATE_FORMAT) || '');
  };

  const handleKeyUp = (event: KeyboardEvent | any) => {
    const value = (event.target as HTMLInputElement).value;
    const formattedValue = formatInputDatePicker(value, event);
    (event.target as HTMLInputElement).value = formattedValue;
  };

  useEffect(() => {
    const antdPickerElem: HTMLCollectionOf<Element> = document.getElementsByClassName('ant-picker-input');
    const antdPickerElemList = Array.from(antdPickerElem);
    if (antdPickerElem) {
      antdPickerElemList?.forEach((el) => {
        const antdInput = el.getElementsByTagName('input');
        const input = antdInput[0];
        input.addEventListener('keyup', handleKeyUp);
        input.maxLength = 10;
      });
    }
    return () => {
      if (antdPickerElem) {
        antdPickerElemList?.forEach((el) => {
          const antdInput = el.getElementsByTagName('input');
          antdInput[0].removeEventListener('keyup', handleKeyUp);
        });
      }
    };
  }, []);

  return (
    <div className={classnames(styles.container, className)}>
      <DatePicker
        format={DATE_FORMAT}
        className={classnames(
          touched && error ? errorStyles : '',
          props.inputClass,
          IS_SOMEONE_HEALTH_APP && 'someone-health-theme',
          IS_CAW_APP && 'caw-theme',
          IS_HELM_APP && 'helm-theme',
          IS_HEALTH_BRIGHT_APP && 'health-bright-theme'
        )}
        {...props}
        {...field}
        placeholder={props.placeholder || ''}
        value={value && moment(value, DATE_FORMAT)}
        onChange={handleOnChange}
      />
      {label && <div className={styles.label}>{label}</div>}
      <ErrorMessage error={error} visible={touched} />
    </div>
  );
};

export default FormikDatePicker;
