import { useEffect, useState } from 'react';

import styles from './Personalisation.module.scss';
import classnames from 'classnames';
import MaterialInput from 'components/MaterialInput/MaterialInput';
import { ClientSignUpInterface, PersonalisationErrorInterface } from '../../SignUpFormInterface';
import AvatarSelector from './components/AvatarSelector/AvatarSelector';
import { PersonalisationInterface, validationPersonaliseForm } from './validation/PersonaliseValidation';

interface PersonalisationProps {
  checkValidation: boolean;
  title?: JSX.Element | string;
  description?: JSX.Element | string;
  inputClass?: string;
  inputContainerClass?: string;
  personalisationValue: PersonalisationInterface;
  onChangePersonalisationField: (val: PersonalisationInterface) => void;
}

const Personalisation = ({
  checkValidation,
  title,
  description,
  inputClass,
  inputContainerClass,
  personalisationValue,
  onChangePersonalisationField
}: PersonalisationProps) => {
  const [errorMessage, setErrorMessage] = useState({
    nickname: '',
    avatar: ''
  } as PersonalisationErrorInterface);

  useEffect(() => {
    if (checkValidation) {
      if (personalisationValue) {
        validatePersonaliseField(personalisationValue);
      }
    }
  }, [checkValidation, personalisationValue]);

  const validatePersonaliseField = (personaliseVal?: ClientSignUpInterface | PersonalisationInterface) => {
    const validate = validationPersonaliseForm(personaliseVal);
    setErrorMessage(validate as PersonalisationErrorInterface);
    return validate;
  };

  const handleChangeNickName = async (value: string) => {
    onChangePersonalisationField({ ...personalisationValue, nickname: value });
  };

  const handleChangeAvatar = async (value: string) => {
    onChangePersonalisationField({ ...personalisationValue, avatar: value });
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.title}>{title || 'Personalise your profile'}</div>
        <div className={styles.desc}>
          {description || "You can personalise your experience. Please tell us how you'd prefer to be addressed."}
        </div>
      </div>
      <div className={styles.formContainer}>
        <div
          className={classnames(
            inputContainerClass,
            styles.fieldContainer,
            checkValidation && errorMessage.nickname && styles.fieldError
          )}
        >
          <MaterialInput
            label={'Preferred Name*'}
            id={'nickname'}
            onChange={(e) => handleChangeNickName(e.target.value)}
            value={personalisationValue?.nickname}
            maxLength={80}
            required
            autoComplete={'off'}
            className={inputClass}
          />
          {checkValidation && errorMessage.nickname && <div className={styles.fieldError}>{errorMessage.nickname}</div>}
        </div>
      </div>
      <AvatarSelector onChangeAvatar={handleChangeAvatar} />
      {checkValidation && errorMessage.avatar && <div className={styles.fieldError}>{errorMessage.avatar}</div>}
    </div>
  );
};

export default Personalisation;
