import { AvailabilityAppointmentTypes, DeliveryType } from 'utils/hooks/appointment';
import styles from './PracticeAppointmentTypesList.module.scss';
import { PublicPracticeProfile } from 'interfaces/Practice/practice';
import { Link } from 'react-router-dom';

const GAE_REGION = process.env.REACT_APP_GAE_REGION;
const MONEY_ICON_SYMBOL = GAE_REGION === 'au' ? 'paid' : GAE_REGION === 'gb' ? 'currency_pound' : undefined;
interface AppointmentProps {
  practiceProfile: PublicPracticeProfile;
  slugUrl: string;
  availabilityAppointmentTypes: AvailabilityAppointmentTypes[];
}

const PracticeAppointmentTypesList = ({ practiceProfile, slugUrl, availabilityAppointmentTypes }: AppointmentProps) => {
  return (
    <>
      <div className={styles.container}>
        <div className={styles.appointmentList}>
          {availabilityAppointmentTypes.map((appointmentType, index) => {
            return (
              <Link
                to={{
                  pathname: `/p/${slugUrl}/availability`
                }}
                state={{
                  practiceProfile,
                  appointmentType
                }}
                key={index}
                className={styles.customLink}
              >
                <div className={styles.appointmentContainer} key={index}>
                  <div className={styles.contentContainer}>
                    <div className={styles.title}>{appointmentType.name}</div>
                    <div className={styles.description}>{appointmentType.description}</div>
                    <div className={styles.money_time}>
                      <div className={styles.text}>
                        <i className={`material-icons-outlined ${styles.icon}`}>schedule</i>
                        {appointmentType.duration}
                      </div>
                      <div className={styles.text}>
                        {appointmentType.rate || appointmentType.rate === 0 ? (
                          <>
                            <i className={`material-icons-outlined ${styles.icon}`}>{MONEY_ICON_SYMBOL}</i>
                            {appointmentType.rate}
                          </>
                        ) : null}
                      </div>
                    </div>
                    <div className={styles.availableText}>available</div>
                    <div className={styles.availableDate}>
                      {Object.keys(appointmentType.activeDays).map((dayLabel, index) => {
                        return (
                          <span
                            className={appointmentType.activeDays[dayLabel] ? styles.isAvail : styles.isUnavail}
                            key={index}
                          >
                            {dayLabel[0]}
                          </span>
                        );
                      })}
                    </div>
                    <div className={styles.viaText}>via</div>
                    <div className={styles.deliveryOptions}>
                      <div>
                        <div
                          className={`material-icons-outlined ${
                            appointmentType.deliveryOptions.includes(DeliveryType.FaceToFace)
                              ? styles.iconEnabled
                              : styles.icon
                          }`}
                        >
                          supervisor_account
                        </div>
                      </div>
                      <div>
                        <div
                          className={`material-icons-outlined ${
                            [DeliveryType.PhoneCall, DeliveryType.PhoneCallDialClient].some((deliveryType) =>
                              appointmentType.deliveryOptions.includes(deliveryType)
                            )
                              ? styles.iconEnabled
                              : styles.icon
                          }`}
                        >
                          phone
                        </div>
                      </div>
                      <div>
                        <div
                          className={`material-icons-outlined ${
                            appointmentType.deliveryOptions.includes(DeliveryType.VideoCall)
                              ? styles.iconEnabled
                              : styles.icon
                          }`}
                        >
                          videocam
                        </div>
                      </div>
                      <div>
                        <div
                          className={`material-icons-outlined ${
                            appointmentType.deliveryOptions.includes(DeliveryType.Other)
                              ? styles.iconEnabled
                              : styles.icon
                          }`}
                        >
                          connect_without_contact
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={`material-icons-outlined ${styles.icon} ${styles.button}`}>arrow_forward</div>
                </div>
              </Link>
            );
          })}
        </div>
      </div>
    </>
  );
};
export default PracticeAppointmentTypesList;
