import FlexBox from 'components/FlexBox/FlexBox';

import InvoiceField from '../InvoiceField/InvoiceField';
import styles from '../../InvoiceViewWithTemplate.module.scss';
import { Invoice } from 'interfaces/Invoice/Invoice';
import moment from 'moment';

const isRegionAU = process.env.REACT_APP_GAE_REGION === 'au';

const ServiceDetailsSection = ({ invoice }: { invoice: Invoice }) => {
  const showClaimant =
    invoice.medicare?.claimant?.name ||
    invoice.medicare?.claimant?.dateOfBirth ||
    invoice.medicare?.claimant?.medicareNumber ||
    invoice.medicare?.claimant?.irn ||
    invoice.medicare?.claimant?.address ||
    invoice.medicare?.claimant?.phoneNumber;

  const claimant = [
    { label: 'Name', value: invoice.medicare?.claimant?.name },
    { label: 'Date of birth', value: invoice.medicare?.claimant?.dateOfBirth },
    { label: 'Medicare Card No', value: invoice.medicare?.claimant?.medicareNumber },
    { label: 'IRN', value: invoice.medicare?.claimant?.irn },
    { label: 'Address', value: invoice.medicare?.claimant?.address },
    { label: 'Phone Number', value: invoice.medicare?.claimant?.phoneNumber }
  ];

  const isPastAppointment = invoice.items
    .filter(({ appointmentId, appointmentDate }) => !!appointmentId && !!appointmentDate)
    .some((item) => moment(item.appointmentDate, 'DD-MM-YYYY').isSameOrBefore(moment()));
  const showClaimAndServiceProviderDetails = isPastAppointment && invoice.items.length < 2;

  const showClaim =
    isRegionAU &&
    (invoice.medicare?.claim?.dateOfService || invoice.medicare?.claim?.mbsCode) &&
    showClaimAndServiceProviderDetails;

  const claim = [
    { label: 'Date of Service', value: invoice.medicare?.claim?.dateOfService },
    { label: 'MBS Code', value: invoice.medicare?.claim?.mbsCode }
  ];

  const showServiceProvider =
    isRegionAU &&
    (invoice.medicare?.serviceProvider?.name || invoice.medicare?.serviceProvider?.name) &&
    showClaimAndServiceProviderDetails;

  const serviceProvider = [
    { label: 'Provider Name', value: invoice.medicare?.serviceProvider?.name },
    { label: 'Provider No', value: invoice.medicare?.serviceProvider?.providerNumber }
  ];

  const showReferral =
    invoice.medicare?.referral?.name || invoice.medicare?.referral?.date || invoice.medicare?.referral?.providerNumber;

  const referral = [
    { label: 'Referral Name', value: invoice.medicare?.referral?.name },
    { label: 'Date of referral', value: invoice.medicare?.referral?.date },
    { label: 'Provider Number', value: invoice.medicare?.referral?.providerNumber }
  ];

  return (
    <div className={styles.wrapper}>
      <FlexBox direction="column" spacing={20}>
        {showClaimant && (
          <FlexBox direction="column" spacing={4}>
            <span className={styles.heading}>Client Identity Details</span>
            <FlexBox direction="row" spacing={20}>
              <FlexBox direction="column" className={styles.width350} spacing={4}>
                {claimant.slice(0, 3).map((item, index) => (
                  <InvoiceField key={index} label={item.label} value={item.value} />
                ))}
              </FlexBox>
              <FlexBox direction="column" className={styles.width350} spacing={4}>
                {claimant.slice(3, 6).map((item, index) => (
                  <InvoiceField key={index} label={item.label} value={item.value} />
                ))}
              </FlexBox>
            </FlexBox>
          </FlexBox>
        )}
        {showClaim || showServiceProvider ? (
          <FlexBox direction="row" spacing={20}>
            {showClaim && (
              <FlexBox direction="column" className={styles.width350} spacing={4}>
                <span className={styles.heading}>MBS Claim Details</span>
                {claim.map((item, index) => (
                  <InvoiceField key={index} label={item.label} value={item.value} />
                ))}
              </FlexBox>
            )}
            {showServiceProvider && (
              <FlexBox direction="column" className={styles.width350} spacing={4}>
                <span className={styles.heading}>Service Provider Details</span>{' '}
                {serviceProvider.map((item, index) => (
                  <InvoiceField key={index} label={item.label} value={item.value} />
                ))}
              </FlexBox>
            )}
          </FlexBox>
        ) : null}
        {showReferral && (
          <FlexBox direction="column" spacing={4}>
            <span className={styles.heading}>Referral Details</span>
            {referral.map((item, index) => (
              <InvoiceField key={index} label={item.label} value={item.value} />
            ))}
          </FlexBox>
        )}
      </FlexBox>
    </div>
  );
};

export default ServiceDetailsSection;
