import { httpClient } from 'utils/httpClient';
import { BentResponse } from 'bent';

const url = process.env.REACT_APP_CLINICIAN_PROFILE_SERVICE_URL;

export const getHealthBrightClinicianListing = (queryParam?: string) =>
  httpClient('GET', url!, 200)(`/accounts/health-bright/clinicians?${queryParam}`) as Promise<BentResponse>;

export const getHealthBrightClinicianDetails = (clinicianId: string) =>
  httpClient('GET', url!, 200)(`/accounts/health-bright/clinicians/${clinicianId}`) as Promise<BentResponse>;

export const getPsychologistsMinified = (queryParam?: string) =>
  httpClient('GET', url!, 200)(`/accounts/health-bright/clinicians/minified?${queryParam}`) as Promise<BentResponse>;
