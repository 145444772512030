/* eslint-disable react/no-multi-comp */
import { Outlet, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { withAuthenticationRequired, useAuth0 } from '@auth0/auth0-react';
import Loading from 'components/Loading/Loading';
import NoAuthLayout from '../NoAuthLayout/NoAuthLayout';

import styles from './AuthLayout.module.scss';
import { verifyEmail } from 'utils/http/patient';
import { useGetAccessToken } from 'utils/hooks/token';
import { useGetClientDetails } from 'utils/hooks/AccountInfo/clientDetails';

interface AuthLayoutProps {
  children?: any;
  hideHelpScout?: boolean;
}

const helmUserUrl = process.env.REACT_APP_CLIENT_DOMAIN_HELM || 'http://localhost:4040';
const someoneHealthUserUrl = process.env.REACT_APP_CLIENT_DOMAIN_SOMEONE_HEALTH || 'http://localhost:4020';
const tacklitUserUrl = process.env.REACT_APP_CLIENT_DOMAIN || 'http://localhost:4000';
const cawUserUrl = process.env.REACT_APP_CLIENT_DOMAIN_CAW || 'http://localhost:4050';
const easeUserUrl = process.env.REACT_APP_CLIENT_DOMAIN_EASE || 'http://localhost:4060';
const rechargeUserUrl = process.env.REACT_APP_CLIENT_DOMAIN_RECHARGE || 'http://localhost:4070';
const selectUserUrl = process.env.REACT_APP_CLIENT_DOMAIN_SELECT || 'http://localhost:4080';
const currentOrigin = window.location.origin;

const AuthLayout = ({ children, hideHelpScout }: AuthLayoutProps) => {
  const { user, isAuthenticated } = useAuth0();
  const navigate = useNavigate();
  const { token } = useGetAccessToken();
  const { isHelmClient, isSomeoneHealthClient, isCaWClient, isEaseClient, isRechargeClient, isSelectClient } =
    useGetClientDetails();
  const [isVerifyLoading, setIsVerifyLoading] = useState(true);
  const checkSignUpIsNotClaim = !user?.['https://tacklit.com/isSignupClaimed'];
  const checkSignUpClaimFor = !(
    user?.['https://tacklit.com/signupClaimedFor'] &&
    user['https://tacklit.com/signupClaimedFor'].some((claimed: string) =>
      user['https://tacklit.com/attachedClinicians'].includes(claimed)
    )
  );

  const fetchVerifyEmail = async (token: string) => {
    await verifyEmail(token);
    setIsVerifyLoading(false);
  };

  useEffect(() => {
    if (isAuthenticated) {
      if (helmUserUrl && isHelmClient && currentOrigin !== helmUserUrl) {
        window.location.href = helmUserUrl;
      } else if (someoneHealthUserUrl && isSomeoneHealthClient && currentOrigin !== someoneHealthUserUrl) {
        window.location.href = someoneHealthUserUrl;
      } else if (cawUserUrl && isCaWClient && currentOrigin !== cawUserUrl) {
        window.location.href = cawUserUrl;
      } else if (easeUserUrl && isEaseClient && currentOrigin !== easeUserUrl) {
        window.location.href = easeUserUrl;
      } else if (rechargeUserUrl && isRechargeClient && currentOrigin !== rechargeUserUrl) {
        window.location.href = rechargeUserUrl;
      } else if (selectUserUrl && isSelectClient && currentOrigin !== selectUserUrl) {
        window.location.href = selectUserUrl;
      } else if (
        tacklitUserUrl &&
        !isCaWClient &&
        !isHelmClient &&
        !isSomeoneHealthClient &&
        !isEaseClient &&
        !isRechargeClient &&
        !isSelectClient &&
        currentOrigin !== tacklitUserUrl
      ) {
        window.location.href = tacklitUserUrl;
      }
    }
    if (token && user) {
      if (user['https://tacklit.com/roles'].includes('clinician') && process.env.REACT_APP_CLINICIAN_DOMAIN) {
        window.location.href = process.env.REACT_APP_CLINICIAN_DOMAIN;
      }

      if (checkSignUpIsNotClaim && checkSignUpClaimFor) {
        setIsVerifyLoading(true);
        fetchVerifyEmail(token);
      } else {
        setIsVerifyLoading(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, navigate, token]);

  useEffect(() => {
    const beaconV2Script = document.createElement('script');
    const beaconInitScript = document.createElement('script');

    beaconV2Script.type = 'text/javascript';
    beaconV2Script.innerHTML = `!(function (e, t, n) {
      function a() {
        var e = t.getElementsByTagName('script')[0],
          n = t.createElement('script');
        (n.type = 'text/javascript'),
          (n.async = !0),
          (n.src = 'https://beacon-v2.helpscout.net'),
          e.parentNode.insertBefore(n, e);
      }
      if (
        ((e.Beacon = n = function (t, n, a) {
          e.Beacon.readyQueue.push({ method: t, options: n, data: a });
        }),
        (n.readyQueue = []),
        'complete' === t.readyState)
      )
        return a();
      e.attachEvent ? e.attachEvent('onload', a) : e.addEventListener('load', a, !1);
    })(window, document, window.Beacon || function () {});`;

    beaconInitScript.type = 'text/javascript';
    beaconInitScript.innerHTML = `window.Beacon('init', 'd4b9b6da-9d2d-4d52-8ada-c2f3b565df6e');`;

    // document.body.append('<!-- Helpscout code -->');
    !hideHelpScout && document.body.appendChild(beaconV2Script);
    !hideHelpScout && document.body.appendChild(beaconInitScript);
    // document.body.append('<!-- Helpscout code -->');
  }, [user, navigate, hideHelpScout]);

  return isVerifyLoading ? (
    <div className={styles.loading}>
      <Loading />
    </div>
  ) : (
    <div className={styles.container}>
      <Outlet />
    </div>
  );
};

export default withAuthenticationRequired(AuthLayout, {
  loginOptions: {
    loginType: 'patient'
  },
  onRedirecting: () => (
    <NoAuthLayout>
      <Loading />
    </NoAuthLayout>
  )
});
