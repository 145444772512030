import { TimeZone } from 'utils/constants/timeZone';

export const useTimeZone = (defaultTzList: TimeZone[], defaultTzLocalStorageName: string) => {
  const clientTimeZone = localStorage.getItem(defaultTzLocalStorageName);

  return (
    clientTimeZone ||
    // use browser timezone if browser timezone is supported
    defaultTzList.find((tzObj) =>
      // eslint-disable-next-line new-cap
      tzObj.timezones.find((tzString) => tzString === Intl.DateTimeFormat().resolvedOptions().timeZone)
    )?.id ||
    // use default client timezone
    defaultTzList.find((tzObj) => tzObj.id === process.env.REACT_APP_DEFAULT_CLIENT_TIMEZONE)?.id ||
    defaultTzList[0].id
  );
};
