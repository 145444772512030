import { useAuth0 } from '@auth0/auth0-react';
import PatientContentLayout from 'components/PatientContentLayout/PatientContentLayout';
import styles from './ReferralDetail.module.scss';
import ReferralDetailForm from 'helm/pages/ReferralDetail/components/ReferralDetailForm/ReferralDetailForm';
import SomeoneHealthHeader from 'SomeoneHealth/components/SomeoneHealthHeader/SomeoneHealthHeader';

const ReferralDetail = () => {
  const { user, isAuthenticated } = useAuth0();

  if (
    isAuthenticated &&
    user?.['https://tacklit.com/roles'].includes('clinician') &&
    process.env.REACT_APP_CLINICIAN_DOMAIN
  ) {
    window.location.href = process.env.REACT_APP_CLINICIAN_DOMAIN;
  }

  return (
    <PatientContentLayout className={styles.container}>
      <div className={styles.headerWrapper}>
        <SomeoneHealthHeader withPadding withMenu homeLink />
      </div>
      <div className={styles.contentWrapper}>
        <div className={styles.title}>Add Referral Details</div>
        <div className={styles.content}>
          <div className={styles.description}>
            Complete and share details of your referral with someone.health. This is required for any Medicare claims.
          </div>
          <ReferralDetailForm />
        </div>
      </div>
    </PatientContentLayout>
  );
};

export default ReferralDetail;
