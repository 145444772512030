import styles from './Timer.module.scss';
import CircularTimer from 'components/CircularTimer/CircularTimer';
import {
  useReserveGPAppointmentData,
  useSetReserveGPAppointmentData
} from 'utils/hooks/EngageReserved/reservedAppointment';
import { useEffect, useState } from 'react';
import classnames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { useSomeoneHealthRoutesGenerator } from 'SomeoneHealth/utils/Path/SomeoneHealthRoutesGenerator';
import { AppointmentSlot } from 'utils/hooks/appointment';
import TimeExpireAlert from '../TimeExpireAlert/TimeExpireAlert';

interface TimerProps {
  isReservedAppointmentFetching: boolean;
  reservedAppointments: AppointmentSlot[];
  hideTimer?: boolean;
  onlyText?: boolean;
}

const Timer = ({ isReservedAppointmentFetching, reservedAppointments, hideTimer, onlyText }: TimerProps) => {
  const navigate = useNavigate();
  const { GP } = useSomeoneHealthRoutesGenerator();
  const { reserveId } = useReserveGPAppointmentData();
  const { removeReserveGPAppointmentData } = useSetReserveGPAppointmentData();
  const [showExpiredAlert, setShowExpiredAlert] = useState(false);

  if (!reserveId) {
    navigate(GP.BASE);
  }

  useEffect(() => {
    if (!isReservedAppointmentFetching && reservedAppointments.length < 0) {
      setShowExpiredAlert(true);
    } else {
      setShowExpiredAlert(false);
    }
  }, [isReservedAppointmentFetching, reservedAppointments]);

  return (
    <div className={classnames(hideTimer && styles.hidden)}>
      <CircularTimer
        startTime={reservedAppointments?.[0]?.updatedAt}
        onlyText={onlyText}
        timeLabelClass={classnames(onlyText && styles.timeLabel)}
        minutes={15}
        onExpire={() => {
          setShowExpiredAlert(true);
          removeReserveGPAppointmentData();
        }}
        className={styles.circularTimer}
        strokeColor={'#F11D66'}
      />
      <TimeExpireAlert showExpiredAlert={showExpiredAlert} />
    </div>
  );
};

export default Timer;
