import styles from './LoginAvatar.module.scss';
import { useAuth0 } from '@auth0/auth0-react';
import ClientProfileAvatar from 'components/ClientProfileAvatar/ClientProfileAvatar';
import classNames from 'classnames';
import HeaderMenu from './components/HeaderMenu/HeaderMenu';
import { useCaWRoutesGenerator } from 'CaW/utils/Path/CaWRoutesGenerator';

interface LoginAvatarProps {
  whiteFont?: boolean;
  withMenu?: boolean;
  loginTextClass?: string;
  pinkMenu?: boolean;
}

const LoginAvatar = ({ whiteFont, withMenu, loginTextClass, pinkMenu }: LoginAvatarProps) => {
  const { user, isAuthenticated, loginWithRedirect } = useAuth0();

  const { LOGIN } = useCaWRoutesGenerator();

  const menuItems = [
    { name: 'Manage my appointments', url: LOGIN.APPOINTMENT_OVERVIEW, isExternal: false },
    { name: 'Manage my profile', url: LOGIN.UPDATE_PROFILE, isExternal: false }
  ];

  return (
    <div className={styles.container}>
      {isAuthenticated ? (
        <div className={styles.profileWrapper}>
          <div className={styles.profileCard}>
            <div className={styles.profile}>
              <ClientProfileAvatar fullAvatarBg avatarUrl={user?.picture} />
            </div>
          </div>
          {withMenu && (
            <div className={pinkMenu ? styles.namePink : styles.name}>
              <HeaderMenu menuList={menuItems}>
                <i className={`material-icons ${styles.menuIcon}`}>menu</i>
              </HeaderMenu>
            </div>
          )}
        </div>
      ) : (
        <div className={styles.loginWrapper}>
          <div
            onClick={() =>
              loginWithRedirect({ loginType: 'patient', appState: { returnTo: window.location.pathname } })
            }
            className={classNames(loginTextClass, whiteFont ? styles.loginWhite : styles.login)}
          >
            LOGIN
          </div>
        </div>
      )}
    </div>
  );
};

export default LoginAvatar;
