import { useAuth0 } from '@auth0/auth0-react';
import { useReserveAppointmentData } from 'helm/utils/hooks/localData';
import { useHelmRoutesGenerator } from 'helm/utils/Path/HelmRoutesGenerator';
import { useCallback, useEffect, useState } from 'react';
import { claimReservedAppointments } from 'utils/http/SchedService/ReservedAppointments/claimReservation';
import { retrieveUserSession } from '../../helper/userSession';
import { notification } from 'antd';
import queryString from 'query-string';
import { getHelmPractitionerDetailsPath } from 'helm/utils/Path/getHelmPractitionerDetailsPath';

export const useClaimReservation = () => {
  const [isClaimingReservation, setIsClaimingReservation] = useState<boolean>(true);
  const [claimStatusCode, setClaimStatusCode] = useState<number>(0);
  const [claimReservationDetails, setClaimReservation] = useState<{
    checkoutUrl?: string;
    isBookingRuleError?: boolean;
  }>({});

  const { COUNSELLORS_CALENDAR, SIGNUP, PRACTITIONER } = useHelmRoutesGenerator();
  const { clinicianId, reserveId, sid, appointmentTypeInfo, accountId } = useReserveAppointmentData();
  const { getAccessTokenSilently, isAuthenticated, isLoading } = useAuth0();

  const successNewQueryParam = {
    appointmentName: appointmentTypeInfo?.name,
    appointmentDesc: appointmentTypeInfo?.description,
    appointmentRate: appointmentTypeInfo?.rate,
    sid: sid,
    clinicianId: clinicianId
  };
  const generateQueryParam = queryString.stringify(successNewQueryParam, { sort: false });
  const detailsPagePath = clinicianId
    ? getHelmPractitionerDetailsPath({
        clinicianId,
        counsellorsCalendarDetailsPath: COUNSELLORS_CALENDAR.DETAILS,
        practitionerDetailsPath: PRACTITIONER.DETAILS
      })
    : PRACTITIONER.LISTING;

  const returnUrl = `${window.location.origin}${SIGNUP.WELCOME}?${generateQueryParam}`;
  const cancelUrl = `${window.location.origin}${detailsPagePath}`;

  const fetchClaimingReservation = useCallback(
    async (authToken: string) => {
      if (reserveId && accountId && authToken) {
        setIsClaimingReservation(true);
        try {
          const { statusCode, response } = await claimReservedAppointments({
            accountId,
            reservationId: reserveId,
            authToken,
            cancelUrl,
            returnUrl,
            shouldGenerateCheckoutSession: true
          });

          setClaimStatusCode(statusCode);

          setClaimReservation({
            ...(response?.checkoutUrl && { checkoutUrl: response.checkoutUrl }),
            ...(response?.isBookingRuleError && { isBookingRuleError: response.isBookingRuleError })
          });

          if (statusCode !== 200 && statusCode !== 403) {
            const errorMessage = `Claim reservation (ID: ${reserveId}) failed with status. ${statusCode}`;
            console.error(errorMessage);
            notification.error({ message: errorMessage });
          }
        } catch (ex) {
          console.error(ex);
        }
        setIsClaimingReservation(false);
      }
    },
    [reserveId, accountId, returnUrl, cancelUrl]
  );

  useEffect(() => {
    if (!isLoading) {
      if (isAuthenticated) {
        (async () => {
          const loginToken = await getAccessTokenSilently({
            audience: process.env.REACT_APP_API_AUDIENCE
          });
          fetchClaimingReservation(loginToken);
        })();
      } else {
        const storedTempAuthToken = retrieveUserSession().authToken;
        if (storedTempAuthToken) {
          fetchClaimingReservation(storedTempAuthToken);
        }
      }
    }
  }, [isLoading, isAuthenticated, getAccessTokenSilently, fetchClaimingReservation]);

  return { isClaimingReservation, claimStatusCode, claimReservationDetails };
};
