export enum MentalHealthList {
  Abuse = 'abuse',
  AcquiredBrainInjury = 'acquiredBrainInjury',
  AdjustmentDisorder = 'adjustmentDisorder',
  AngerManagement = 'angerManagement',
  Anxiety = 'anxiety',
  Assertiveness = 'assertiveness',
  AttachmentDisorder = 'attachmentDisorder',
  AttentionDeficitHyperactivityDisorder = 'attentionDeficitHyperactivityDisorder',
  AutismSpectrumDisorder = 'autism',
  Bereavement = 'bereavement',
  BipolarDisorder = 'bipolarAffectiveDisorder',
  BodyImage = 'bodyImage',
  Burnout = 'burnout',
  ChallengingBehaviours = 'challengingBehaviours',
  ChronicFatigueSyndrome = 'chronicFatigueSyndrome',
  ChronicPain = 'chronicPain',
  CopingWithCancer = 'copingWithCancer',
  Dementia = 'dementia',
  Depression = 'depression',
  DissociativeDisorders = 'dissociativeDisorders',
  DomesticViolence = 'domesticViolence',
  EatingDisorders = 'eatingDisorders',
  Family = 'family',
  GriefAndLoss = 'griefAndLoss',
  LowMood = 'lowMood',
  LowSelfEsteem = 'lowSelfEsteem',
  MaternalMentalHealth = 'maternalMentalHealth',
  Menopause = 'menopause',
  NeurodegenerativeDisease = 'neurodegenerativeDisease',
  ObsessiveCompulsiveDisorder = 'ocd',
  PanicAttacks = 'panicAttacks',
  Perfectionism = 'perfectionism',
  PersonalityDisorder = 'personalityDisorder',
  Phobias = 'phobias',
  PostTraumaticStressDisorder = 'ptsd',
  RelationshipProblems = 'relationshipProblems',
  SexualDifficulties = 'sexualDifficulties',
  SelfHarm = 'selfHarm',
  SleepProblems = 'sleepProblems',
  Stress = 'stress',
  TraumaticExperiences = 'traumaticExperiences',
  WeightManagement = 'weightManagement',
  Worry = 'worry'
}

export const MENTAL_HEALTH_LIST_LABELS: Record<MentalHealthList, string> = {
  [MentalHealthList.Abuse]: 'Abuse',
  [MentalHealthList.AcquiredBrainInjury]: 'Acquired Brain Injury (ABI)',
  [MentalHealthList.AdjustmentDisorder]: 'Adjustment Disorder',
  [MentalHealthList.AngerManagement]: 'Anger Management',
  [MentalHealthList.Anxiety]: 'Anxiety',
  [MentalHealthList.Assertiveness]: 'Assertiveness',
  [MentalHealthList.AttachmentDisorder]: 'Attachment Disorder',
  [MentalHealthList.AttentionDeficitHyperactivityDisorder]: 'Attention Deficit Hyperactivity Disorder (ADHD)',
  [MentalHealthList.AutismSpectrumDisorder]: 'Autism Spectrum Disorder (ASD)',
  [MentalHealthList.Bereavement]: 'Bereavement',
  [MentalHealthList.BipolarDisorder]: 'Bi Polar Disorder',
  [MentalHealthList.BodyImage]: 'Body Image',
  [MentalHealthList.Burnout]: 'Burn Out',
  [MentalHealthList.ChallengingBehaviours]: 'Challenging or Risky Behaviours',
  [MentalHealthList.ChronicFatigueSyndrome]: 'Chronic Fatigue Syndrome',
  [MentalHealthList.ChronicPain]: 'Chronic Pain',
  [MentalHealthList.CopingWithCancer]: 'Coping With Cancer',
  [MentalHealthList.Dementia]: 'Dementia',
  [MentalHealthList.Depression]: 'Depression',
  [MentalHealthList.DissociativeDisorders]: 'Dissociation',
  [MentalHealthList.DomesticViolence]: 'Domestic Volence',
  [MentalHealthList.EatingDisorders]: 'Eating Issues',
  [MentalHealthList.GriefAndLoss]: 'Grief And Loss',
  [MentalHealthList.Family]: 'Family',
  [MentalHealthList.LowMood]: 'Low mood',
  [MentalHealthList.LowSelfEsteem]: 'Low Self Esteem',
  [MentalHealthList.MaternalMentalHealth]: 'Maternal Mental Health',
  [MentalHealthList.Menopause]: 'Menopause',
  [MentalHealthList.NeurodegenerativeDisease]: 'Neurodegenrative Disease',
  [MentalHealthList.ObsessiveCompulsiveDisorder]: 'Obsessive Compulsive Disorder (OCD)',
  [MentalHealthList.PanicAttacks]: 'Panic Attacks',
  [MentalHealthList.PersonalityDisorder]: 'Personality Disorders',
  [MentalHealthList.Perfectionism]: 'Perfectionism',
  [MentalHealthList.Phobias]: 'Phobias',
  [MentalHealthList.PostTraumaticStressDisorder]: 'Post Traumatic Stress Disorder (PTSD)',
  [MentalHealthList.RelationshipProblems]: 'Relationship Problems',
  [MentalHealthList.SexualDifficulties]: 'Sexual Difficulties',
  [MentalHealthList.SelfHarm]: 'Self Harm',
  [MentalHealthList.SleepProblems]: 'Sleep Problems',
  [MentalHealthList.Stress]: 'Stress',
  [MentalHealthList.TraumaticExperiences]: 'Traumatic Experiences',
  [MentalHealthList.WeightManagement]: 'Weight Management',
  [MentalHealthList.Worry]: 'Worry'
};

export enum AgeRangeList {
  adults = 'adults',
  olderAdults = 'olderAdults',
  children = 'children',
  teenagers = 'teenagers',
  youth = 'youth'
}

export enum LocationList {
  // Select
  Tynemouth = 'tynemouth',
  Gosforth = 'gosforth',
  Durham = 'durham',
  Hexham = 'hexham',
  Darlington = 'darlington'
}

export const FIT_LOCATION_LIST_LABELS: Record<LocationList, string> = {
  [LocationList.Tynemouth]: 'Tynemouth',
  [LocationList.Gosforth]: 'Gosforth',
  [LocationList.Durham]: 'Durham',
  [LocationList.Hexham]: 'Hexham',
  [LocationList.Darlington]: 'Darlington'
};
