import { ChangeEvent, KeyboardEvent } from 'react';
import classnames from 'classnames';

import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import Input from 'components/Input/Input';

import styles from './TwoFAInput.module.scss';

interface TwoFAInputProps {
  error: string;
  value: string[];
  isTouched: boolean;
  isVerified?: boolean;
  isVerifying?: boolean;
  isText?: boolean;
  onChangeValue: (value: string[]) => void;
  onSubmitOtp?: (otp: string) => void;
  verifyingClassName?: string;
  verifiedClassName?: string;
  inputContainerClassName?: string;
  inputWrapperClassName?: string;
  inputClassName?: string;
  placeholder?: string;
  length?: number;
  hasSeparator?: boolean;
  errorClassName?: string;
}

const TwoFAInput = ({
  error,
  value,
  isTouched,
  isVerified,
  isVerifying,
  isText,
  onChangeValue,
  onSubmitOtp,
  verifyingClassName,
  verifiedClassName,
  inputContainerClassName,
  inputWrapperClassName,
  inputClassName,
  placeholder,
  length = 6,
  hasSeparator,
  errorClassName
}: TwoFAInputProps) => {
  const onChange = (index: number) => (e: ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value?.toUpperCase();

    if (!isText && inputValue && isNaN(Number(inputValue))) {
      return;
    }

    if (inputValue.length === length) {
      onChangeValue(inputValue.split(''));

      document.getElementById(`2fa-${length - 1}`)?.focus();

      onSubmitOtp && onSubmitOtp(inputValue);
    } else {
      const actualInputValue = inputValue.charAt(inputValue.length - 1);

      const newValue = [...value];
      newValue.splice(index, 1, actualInputValue);
      onChangeValue(newValue as string[]);

      if (actualInputValue) {
        document.getElementById(`2fa-${index + 1}`)?.focus();
      }

      const mfaCodeValue = newValue.join('');
      if (mfaCodeValue.length === length) {
        onSubmitOtp && onSubmitOtp(mfaCodeValue);
      }
    }
  };

  const onKeyDown = (index: number) => (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Backspace' && value[index] === '') {
      e.preventDefault();

      document.getElementById(`2fa-${index - 1}`)?.focus();
    }
  };

  const halfOfLength = Math.floor(length / 2);

  return (
    <div className={styles.container}>
      <div
        className={classnames(
          styles.inputContainer,
          inputContainerClassName,
          isVerifying && (verifyingClassName || styles.verifying),
          isVerified && (verifiedClassName || styles.verified),
          isTouched && !!error && styles.errored
        )}
      >
        {[...Array(halfOfLength)].map((_, index) => (
          <Input
            key={index}
            type={isText ? 'text' : 'tel'}
            id={`2fa-${index}`}
            value={value[index]}
            onKeyDown={onKeyDown(index)}
            onChange={onChange(index)}
            hasError={isTouched && !!error}
            disabled={isVerifying}
            noSpacing
            className={inputWrapperClassName}
            inputClass={inputClassName}
            placeholder={placeholder}
            autoComplete={'off'}
          />
        ))}
        {hasSeparator && <span className={styles.separator}>-</span>}
        {[...Array(length - halfOfLength)].map((_, index) => (
          <Input
            key={index + halfOfLength}
            type={isText ? 'text' : 'tel'}
            id={`2fa-${index + halfOfLength}`}
            value={value[index + halfOfLength]}
            onKeyDown={onKeyDown(index + halfOfLength)}
            onChange={onChange(index + halfOfLength)}
            hasError={isTouched && !!error}
            disabled={isVerifying}
            noSpacing
            className={inputWrapperClassName}
            inputClass={inputClassName}
            placeholder={placeholder}
            autoComplete={'off'}
          />
        ))}
      </div>
      <ErrorMessage error={error} visible={isTouched} className={errorClassName} />
    </div>
  );
};

export default TwoFAInput;
