import styles from './ProgressContentBody.module.scss';
import NextActivityContent from './components/NextActivityContent/NextActivityContent';
import { IP6Interface, ProgressMenuType } from 'helm/interfaces/IP6/ip6';
import YourProgressContent from './components/YourProgressContent/YourProgressContent';
import { ip6Quotes } from './components/YourProgressContent/QuoteCollection';
import YourMoodTracking from './components/YourMoodTracking/YourMoodTracking';
import classNames from 'classnames';
import PowerToChangePlan from './components/PowerToChangePlan/PowerToChangePlan';

interface ProgressContentBodyProps {
  data: IP6Interface;
  selectedMenu: ProgressMenuType;
}

const getRandomQuote = ip6Quotes[Math.floor(Math.random() * ip6Quotes.length)];

const ProgressContentBody = ({ data, selectedMenu }: ProgressContentBodyProps) => {
  return (
    <div className={styles.container}>
      <div
        className={classNames(
          styles.progressWrapper,
          selectedMenu !== ProgressMenuType.Progress && styles.hiddenWrapper
        )}
      >
        <YourProgressContent data={data.chapters} quote={getRandomQuote} />
      </div>
      <div
        className={classNames(
          styles.activityWrapper,
          selectedMenu !== ProgressMenuType.Activity && styles.hiddenWrapper
        )}
      >
        <NextActivityContent data={data} />
      </div>
      <div className={classNames(styles.moodWrapper, selectedMenu !== ProgressMenuType.Mood && styles.hiddenWrapper)}>
        <YourMoodTracking />
      </div>
      <div className={classNames(styles.moodWrapper, selectedMenu !== ProgressMenuType.SMP && styles.hiddenWrapper)}>
        <PowerToChangePlan />
      </div>
    </div>
  );
};

export default ProgressContentBody;
