import styles from './HealthBrightHeader.module.scss';
import classnames from 'classnames';
import { useNavigate } from 'react-router-dom';
import LoginAvatar from 'SomeoneHealth/components/LoginAvatar/LoginAvatar';
import healthBrightLogo from '../../assets/logo/healthBrightLogo.png';
import { useRoutesGenerator } from 'utils/Path/RoutesGenerator';
import HealthBrightContentLayout from '../HealthBrightContentLayout/HealthBrightContentLayout';

interface HealthBrightHeaderProps {
  noWrapper?: boolean;
  whiteFont?: boolean;
  withPadding?: boolean;
  withMenu?: boolean;
  noLogin?: boolean;
  classNames?: string;
  loginTextClass?: string;
}

const HealthBrightHeader = ({
  noWrapper,
  whiteFont,
  withPadding,
  withMenu,
  noLogin,
  classNames,
  loginTextClass
}: HealthBrightHeaderProps) => {
  const navigate = useNavigate();

  const { HOME } = useRoutesGenerator();

  const handleRedirection = () => {
    navigate(HOME);
  };

  return (
    <HealthBrightContentLayout
      className={classnames(
        styles.container,
        noWrapper && styles.noWrapperContainer,
        withPadding && styles.padding,
        classNames
      )}
    >
      <div id="HealthBrightHeader" className={styles.logoWrapper}>
        <div className={styles.logoBox} onClick={handleRedirection}>
          <img alt="logo" src={healthBrightLogo} />
        </div>
        {!noLogin && (
          <div className={styles.rightContent}>
            <LoginAvatar whiteFont={whiteFont} withMenu={withMenu} loginTextClass={loginTextClass} />
          </div>
        )}
      </div>
    </HealthBrightContentLayout>
  );
};

export default HealthBrightHeader;
