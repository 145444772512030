import { httpClient } from '../../../httpClient';
import { BentResponse } from 'bent';

const url = process.env.REACT_APP_CLINICIAN_PROFILE_SERVICE_URL;

export const putClientRecordBackground = (token: string, clientRecordId: string, body: Record<string, any>) =>
  httpClient('PUT', url!, 204, { Authorization: `Bearer ${token}` })(
    `/client-records/${clientRecordId}/background`,
    body
  );

export const getClientTasks = (token: string, clientRecordId: string) =>
  httpClient('GET', url!, 200, { Authorization: `Bearer ${token}` })(
    `/client-records/${clientRecordId}/tasks`
  ) as Promise<BentResponse>;

export const getAttachedClinicianDetails = (token: string) =>
  httpClient('GET', url!, 200, { Authorization: `Bearer ${token}` })(
    `/client-records/me:getAttachedAccountAndClinician`
  ) as Promise<BentResponse>;

export const getMyClientRecord = (token: string) => {
  return httpClient('GET', url!, 200, { Authorization: `Bearer ${token}` })(
    '/client-records/me'
  ) as Promise<BentResponse>;
};

export const postKeyContact = (token: string, body: Record<string, any>) =>
  httpClient('POST', url!, 201, { Authorization: `Bearer ${token}` })(
    `/client-records/me/key-client-contacts`,
    body
  ) as Promise<BentResponse>;

export const deleteKeyContact = (token: string, keyContactId: string) =>
  httpClient('DELETE', url!, 204, { Authorization: `Bearer ${token}` })(
    `/client-records/me/key-client-contacts/${keyContactId}`
  ) as Promise<BentResponse>;

export const putClientMedicare = (token: string, body: Record<string, any>) =>
  httpClient('PUT', url!, [200, 400], { Authorization: `Bearer ${token}` })(
    `/client-records/me/medicare`,
    body
  ) as Promise<BentResponse>;

export const putClientReferralDetail = (token: string, body: Record<string, any>) =>
  httpClient('PUT', url!, [204, 400], { Authorization: `Bearer ${token}` })(
    `/client-records/me/referral`,
    body
  ) as Promise<BentResponse>;

export const patchDisconnectPractitioner = (token: string, body: Record<string, any>) =>
  httpClient('PATCH', url!, 204, { Authorization: `Bearer ${token}` })(
    `/client-records/me:disconnectPractitioner`,
    body
  ) as Promise<BentResponse>;

export const putClientFundingStream = (token: string, body: Record<string, any>) =>
  httpClient('PUT', url!, [200, 400], { Authorization: `Bearer ${token}` })(
    `/client-records/me/funding-stream`,
    body
  ) as Promise<BentResponse>;
