import { ReactNode } from 'react';
import FormikDatePicker from 'components/DatePicker/FormikDatePicker';
import FormikInput from 'components/Input/FormikInput';
import HelmMobileFormikInput from 'helm/components/Input/HelmMobileFormikInput';
import styles from './HBPartnerCreateProfileForm.module.scss';
import classNames from 'classnames';
import PasswordRules from 'pages/SignUp/SignUpForm/components/Security/components/PasswordRules/PasswordRules';
import CheckBox from 'components/CheckBox/CheckBox';
import { healthBrightEnvironment } from 'HealthBright/HealthBrightPartner/utils/HealthBrightEnv';

interface HBCreateProfileFormProps {
  password: string;
  ageConfirm: boolean;
  showPwError: boolean;
  setAgeConfirm: (value: boolean) => void;
  setPwScore: (value: number) => void;
  emailErrorMsg?: ReactNode | string;
  mobileNumberErrorMsg?: ReactNode | string;
  setEmailErrorMsg: ((value?: ReactNode) => void) | ((value?: string) => void);
  setMobileNumberErrorMsg: ((value?: ReactNode) => void) | ((value?: string) => void);
}

const HBPartnerCreateProfileForm = ({
  password,
  ageConfirm,
  showPwError,
  setAgeConfirm,
  setPwScore,
  emailErrorMsg,
  mobileNumberErrorMsg,
  setEmailErrorMsg,
  setMobileNumberErrorMsg
}: HBCreateProfileFormProps) => {
  const { PrivacyPolicyURL, TermsAndConditionsURL } = healthBrightEnvironment();

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>Client Information</div>
      <div>
        <FormikInput
          inputClass={styles.customInput}
          label="First Name*"
          name="firstName"
          required
          labelClass={styles.inputLabel}
        />
        <FormikInput
          inputClass={styles.customInput}
          label="Last Name*"
          name="lastName"
          required
          labelClass={styles.inputLabel}
        />
        <FormikDatePicker
          inputClass={styles.customInput}
          label="Date of birth*"
          name="dateOfBirth"
          placeholder="DD/MM/YYYY"
          format="DD/MM/YYYY"
        />
        <HelmMobileFormikInput
          inputClass={styles.customInput}
          id={`mobileNumber`}
          name={'mobileNumber'}
          label={'Mobile number*'}
          mobilePattern="____ ___ ___"
          placeholder={'0482 555 444'}
          onChange={() => mobileNumberErrorMsg && setMobileNumberErrorMsg()}
          phonePrefix=""
          labelClass={styles.inputLabel}
          externalError={mobileNumberErrorMsg}
        />
        <FormikInput
          inputClass={styles.customInput}
          label="Email address*"
          name="email"
          externalError={emailErrorMsg}
          onChange={() => emailErrorMsg && setEmailErrorMsg()}
          labelClass={styles.inputLabel}
          required
        />
        <FormikInput
          inputClass={styles.customInput}
          label="Set Secure Password*"
          name="password"
          type="password"
          required
          showError={showPwError}
          labelClass={styles.inputLabel}
          allowShowPassword
          showPasswordButtonClass={styles.showPasswordButtonClass}
        />
      </div>
      <div className={styles.passwordRulesLabel}>It must meet the following password rules:</div>
      <div className={classNames(styles.passwordHint, showPwError && styles.passwordHintWithError)}>
        <div className={styles.hints}>
          <PasswordRules password={password} onChangePasswordScore={setPwScore} />
        </div>
      </div>
      <div className={styles.ageConfirm}>
        <div className={styles.checkBoxText}>
          <CheckBox
            id="ageConfirm"
            value={ageConfirm}
            onChange={(e) => setAgeConfirm(e.target.checked)}
            className={styles.checkBox}
            label={`I confirm I agree to the <a target="_blank" href='${PrivacyPolicyURL}'>Privacy Policy</a> and <a target="_blank" href='${TermsAndConditionsURL}'>Terms & Conditions</a>`} // TODO
          />
        </div>
      </div>
    </div>
  );
};

export default HBPartnerCreateProfileForm;
