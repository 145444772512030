import styles from './AppointmentsOverview.module.scss';
import AppointmentList from 'components/AppointmentList/AppointmentList';
import PatientContentLayout from 'components/PatientContentLayout/PatientContentLayout';
import { useGetClientProfileDetails } from 'redux/endpoints/clinicianProfileServices/getClientDetails';
import SomeoneHealthHelmetWrapper from '../../components/SomeoneHealthHelmetWrapper/SomeoneHealthHelmetWrapper';
import SomeoneHealthHeader from '../../components/SomeoneHealthHeader/SomeoneHealthHeader';
import { useSomeoneHealthRoutesGenerator } from '../../utils/Path/SomeoneHealthRoutesGenerator';
import { SOMEONE_HEALTH_TIME_ZONE_LIST } from 'utils/constants/timeZone';

const TIMEZONE = process.env.REACT_APP_GAE_REGION === 'gb' ? 'Europe/London' : 'Australia/Melbourne';

const AppointmentsOverview = () => {
  const { clientProfileData, isClientProfileLoading } = useGetClientProfileDetails();
  const timeZone = clientProfileData?.timeZone || TIMEZONE;
  const timeZoneLocation = SOMEONE_HEALTH_TIME_ZONE_LIST.find(({ id }) => id === timeZone)?.label;
  const { PRACTITIONER } = useSomeoneHealthRoutesGenerator();

  return (
    <SomeoneHealthHelmetWrapper title={`someone.health - Appointments`}>
      <PatientContentLayout className={styles.container}>
        <div className={styles.contentWrapper}>
          <SomeoneHealthHeader withPadding withMenu homeLink />
          <div className={styles.content}>
            <div className={styles.titleContainer}>
              <div className={styles.title}>Your Appointment Overview</div>
              {timeZoneLocation && <div className={styles.timeZone}>Time in {timeZoneLocation}</div>}
            </div>
            <AppointmentList
              isPatientProfileLoading={isClientProfileLoading}
              clientTimeZone={clientProfileData?.timeZone}
              className={styles.contentCardShadow}
              newAppointmentPath={PRACTITIONER.DETAILS}
            />
          </div>
        </div>
      </PatientContentLayout>
    </SomeoneHealthHelmetWrapper>
  );
};

export default AppointmentsOverview;
