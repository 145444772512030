import moment from 'moment';
import { isDevelopmentENV } from 'utils/featureToggle/DevelopmentToggle';
import * as Yup from 'yup';

export const getInitialCustomer = (presetEmployerCode?: string) => ({
  firstName: '',
  lastName: '',
  email: '',
  mobileNumber: '',
  dateOfBirth: '',
  postcode: '',
  password: '',
  theme: '',
  receiveSMS: true,
  employerCode: presetEmployerCode || '',
  issueSource: '',
  directorate: ''
});

export const workplaceOrPersonal = [
  {
    value: 'workplace',
    label: 'Workplace'
  },
  {
    value: 'personal',
    label: 'Personal'
  }
];

export const getAuMobile = (v: string) =>
  v && !isDevelopmentENV() ? '+61' + (v.startsWith('0') ? v.slice(1) : v) : `+${v}`;

export const passwordValidationSchema = (pwScore: number) =>
  Yup.object().shape({
    password: Yup.string()
      .required('Please set your password')
      .matches(/.*[!@#$%^&*-].*/, 'Password must meet requirements')
      .matches(/.*[A-Z].*/, 'Password must meet requirements')
      .matches(/.*[a-z].*/, 'Password must meet requirements')
      .matches(/.*[\d].*/, 'Password must meet requirements')
      .test('Password score', 'Weak password!', () => pwScore === 4)
      .nullable()
  });

export const formDataValidationSchema = (customAgeCheck?: number) => {
  const dobAge = customAgeCheck || 18;

  return Yup.object().shape({
    firstName: Yup.string().required('Please enter your first name'),
    lastName: Yup.string().required('Please enter your last name'),
    email: Yup.string().required('Please enter your email').email('Email must be in valid example@gmail.com format.'),
    dateOfBirth: Yup.string()
      .required('Please choose your date of birth')
      .test('Age check', `Be sure that you're over the age of ${dobAge}`, (dob) => {
        return moment(dob, 'YYYY-MM-DD').add(dobAge, 'years').isBefore(moment());
      }),
    mobileNumber: Yup.string().required('Please enter your mobile number'),
    postcode: Yup.string().required('Please enter your residential postcode'),
    employerCode: Yup.string().required('Please select your employer code'),
    issueSource: Yup.string().required('Please select your issue source'),
    directorate: Yup.string().required('Please enter your directorate')
  });
};
