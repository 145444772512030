import styles from './AppointmentsOverview.module.scss';
import AppointmentList from 'components/AppointmentList/AppointmentList';
import { useGetClientProfileDetails } from 'redux/endpoints/clinicianProfileServices/getClientDetails';
import SelectHelmetWrapper from 'Select/components/SelectHelmetWrapper/SelectHelmetWrapper';
import SelectContentLayout from 'Select/components/SelectContentLayout/SelectContentLayout';
import SelectHeader from 'Select/components/SelectHeader/SelectHeader';
import { useSelectRoutesGenerator } from 'Select/utils/path/SelectRoutesGenerator';

const AppointmentsOverview = () => {
  const { clientProfileData, isClientProfileLoading } = useGetClientProfileDetails();
  const { PRACTITIONER } = useSelectRoutesGenerator();

  return (
    <SelectHelmetWrapper title={`Select Psychology | Appointments`}>
      <SelectContentLayout className={`${styles.container} select-theme`}>
        <div className={styles.contentWrapper}>
          <SelectHeader withPadding withMenu homeLink />
          <div className={styles.content}>
            <div className={styles.titleContainer}>
              <div className={styles.title}>Your Appointment Overview</div>
            </div>
            <AppointmentList
              isPatientProfileLoading={isClientProfileLoading}
              clientTimeZone={clientProfileData?.timeZone}
              className={styles.contentCardShadow}
              newAppointmentPath={PRACTITIONER.DETAILS}
            />
          </div>
        </div>
      </SelectContentLayout>
    </SelectHelmetWrapper>
  );
};

export default AppointmentsOverview;
