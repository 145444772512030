import { Route, Routes } from 'react-router-dom';
import appRoute from '../../components/AppRoute/AppRoute';
import AuthLayout from '../../layouts/AuthLayout/AuthLayout';
import { UserContextProvider } from '../../utils/UserContextProvider';
import { useCaWRoutesGenerator } from 'CaW/utils/Path/CaWRoutesGenerator';
import CaWWrapper from 'CaW/components/CaWWrapper/CaWWrapper';
import ClinicianListing from 'CaW/pages/ClinicianListing/ClinicianListing';
import ClinicianDetails from 'CaW/pages/ClinicianDetails/ClinicianDetails';
import SignUp from 'CaW/pages/SignUp/SignUp';
import CaWHomePage from 'CaW/pages/CaWHomePage/CaWHomePage';
import CaWBookingConfirmation from 'CaW/pages/CaWBookingConfirmation/CaWBookingConfirmation';
import CaWCompletePage from 'CaW/pages/CaWCompletePage/CaWCompletePage';
import SelectAvatar from 'CaW/pages/SelectAvatar/SelectAvatar';
import UpdateProfile from 'CaW/pages/UpdateProfile/UpdateProfile';
import AppointmentsOverview from 'CaW/pages/AppointmentsOverview/AppointmentsOverview';
import PsychometricList from '../../pages/Psychometric/PsychometricList/PsychometricList';
import Onboarding from '../../pages/OnboardingAssessment/Onboarding/Onboarding';
import PatientCheckIn from '../../pages/PatientCheckIn/PatientCheckIn';
import ConsentListWrapper from 'CaW/pages/ConsentListWrapper/ConsentListWrapper';
import PatientAdHocAssessment from '../../pages/PatientAdHocAssessment/PatientAdHocAssessment';
import InvoiceViewPage from '../../pages/InvoiceViewPage/InvoiceViewPage';
import ReportViewPage from '../../pages/ReportViewPage/ReportViewPage';
import CaWSurveyQuestionWrapper from '../../CaW/pages/Survey/CaWSurveyQuestionWrapper/CaWSurveyQuestionWrapper';
import CaWSurveyListWrapper from '../../CaW/pages/Survey/CaWSurveyListWrapper/CaWSurveyListWrapper';
import ConsentForm from 'CaW/pages/ConsentForm/ConsentForm';
import { isDevelopmentENV } from '../../utils/featureToggle/DevelopmentToggle';
import CaWEmergencyContact from 'CaW/pages/CaWEmergencyContact/CaWEmergencyContact';
import LetterViewPage from 'pages/LetterViewPage/LetterViewPage';

const CaWRoutes = () => {
  const {
    PRACTITIONER,
    HOME,
    SIGNUP,
    AVATAR,
    LOGIN,
    PSYCHOMETRIC,
    ONBOARDING,
    CHECKIN,
    CONSENT,
    INVOICE,
    REPORT,
    LETTER,
    SURVEY
  } = useCaWRoutesGenerator();

  return (
    <UserContextProvider>
      <Routes>
        {appRoute({ path: SIGNUP.BASE, component: SignUp, layout: CaWWrapper })}
        {appRoute({ path: SIGNUP.WELCOME_BOOKING, component: CaWCompletePage, layout: CaWWrapper })}
        {appRoute({ path: SIGNUP.CONFIRM_BOOKING, component: CaWBookingConfirmation, layout: CaWWrapper })}
        {appRoute({ path: SIGNUP.EMERGENCY_CONTACT, component: CaWEmergencyContact, layout: CaWWrapper })}
        {appRoute({
          path: PSYCHOMETRIC.DETAILS,
          component: PatientAdHocAssessment,
          layout: CaWWrapper
        })}
        {appRoute({
          path: PRACTITIONER.DETAILS,
          component: ClinicianDetails,
          layout: CaWWrapper
        })}
        {isDevelopmentENV() &&
          appRoute({ path: PRACTITIONER.LISTING, component: ClinicianListing, layout: CaWWrapper })}
        {appRoute({
          path: INVOICE.VIEW,
          component: InvoiceViewPage,
          layout: CaWWrapper
        })}
        {appRoute({
          path: REPORT.VIEW,
          component: ReportViewPage,
          layout: CaWWrapper
        })}
        {appRoute({
          path: LETTER.VIEW,
          component: LetterViewPage,
          layout: CaWWrapper
        })}
        {appRoute({
          path: SURVEY.BASE,
          component: CaWSurveyListWrapper,
          layout: CaWWrapper
        })}
        {appRoute({
          path: SURVEY.DETAILS,
          component: CaWSurveyQuestionWrapper,
          layout: CaWWrapper
        })}
        <Route element={<AuthLayout hideHelpScout />}>
          {appRoute({ path: AVATAR, component: SelectAvatar, layout: CaWWrapper })}
          {appRoute({ path: LOGIN.UPDATE_PROFILE, component: UpdateProfile, layout: CaWWrapper })}
          {appRoute({
            path: LOGIN.APPOINTMENT_OVERVIEW,
            component: AppointmentsOverview,
            layout: CaWWrapper
          })}

          {appRoute({ path: PSYCHOMETRIC.BASE, component: PsychometricList, layout: CaWWrapper })}
          {appRoute({ path: ONBOARDING.BASE, component: Onboarding, layout: CaWWrapper })}
          {appRoute({ path: CHECKIN, component: PatientCheckIn, layout: CaWWrapper })}

          {appRoute({ path: CONSENT.BASE, component: ConsentListWrapper, layout: CaWWrapper })}
          {appRoute({ path: CONSENT.DETAILS, component: ConsentForm, layout: CaWWrapper })}

          {appRoute({ path: HOME, component: CaWHomePage, layout: CaWWrapper })}
        </Route>
      </Routes>
    </UserContextProvider>
  );
};

export default CaWRoutes;
