import { ReactNode, useState } from 'react';
import { Link } from 'react-router-dom';
import styles from './CaWCreateProfileForm.module.scss';
import { Formik, FormikErrors } from 'formik';
import Form from 'antd/lib/form/Form';
import FormikInput from 'components/Input/FormikInput';
import HelmMobileFormikInput from 'helm/components/Input/HelmMobileFormikInput';
import FormikDatePicker from 'components/DatePicker/FormikDatePicker';
import CheckBox from 'components/CheckBox/CheckBox';
import { isEmpty, isEqual } from 'lodash';
import classnames from 'classnames';
import PasswordRules from 'pages/SignUp/SignUpForm/components/Security/components/PasswordRules/PasswordRules';
import Loading from 'components/Loading/Loading';
import classNames from 'classnames';
import {
  formDataValidationSchema,
  getInitialCustomer,
  passwordValidationSchema,
  workplaceOrPersonal
} from './constants';
import ButtonCaW from 'CaW/components/ButtonCaW/ButtonCaW';
import { cawEnvironment } from 'CaW/utils/CaWEnv/cawEnv';
import FormikSelect from 'components/Select/FormikSelect';

interface CaWCreateProfileFormProps {
  isSubmitting: boolean;
  setEmailErrorMsg: ((value?: ReactNode) => void) | ((value?: string) => void);
  setMobileNumberErrorMsg: ((value?: ReactNode) => void) | ((value?: string) => void);
  onSubmit: (value: ReturnType<typeof getInitialCustomer>) => void;
  employerCodes: { label: string; value: string }[];
  emailErrorMsg?: ReactNode | string;
  mobileNumberErrorMsg?: ReactNode | string;
  customAgeCheck?: number;
  presetEmployerCode?: string;
}

const CaWCreateProfileForm = ({
  isSubmitting,
  emailErrorMsg,
  mobileNumberErrorMsg,
  employerCodes,
  setEmailErrorMsg,
  setMobileNumberErrorMsg,
  onSubmit,
  customAgeCheck,
  presetEmployerCode
}: CaWCreateProfileFormProps) => {
  const [pwScore, setPwScore] = useState(0);
  const [isTermsAndConditionsAccepted, setIsTermsAndConditionsAccepted] = useState(false);
  const [receiveSMS, setReceiveSMS] = useState(true);
  const [showPwError, setShowPwError] = useState(false);

  const { CaWPrivacyPolicy, CaWTermAndConditions } = cawEnvironment();

  const isButtonEnabled = (isValid: boolean, errors: FormikErrors<ReturnType<typeof getInitialCustomer>>) => {
    if (!isTermsAndConditionsAccepted) {
      return false;
    }
    const hasOtherErrorThanPassword = Object.values({ ...errors, password: '' }).reduce(
      (res, item) => res || !!item,
      false
    );
    if (!isValid) {
      if (!showPwError) {
        return !hasOtherErrorThanPassword;
      } else return false;
    }
    return true;
  };

  return (
    <>
      {isSubmitting && (
        <div className={styles.loading}>
          <Loading />
        </div>
      )}
      <div className={classNames(styles.container, 'caw-theme')}>
        <div className={styles.header}>
          <div className={styles.primaryHeader}>
            Welcome to <span className={styles.highlight}>Caraniche at Work</span>
          </div>
          <div className={styles.subHeader}>
            Create your secure account and take your first step towards a better future.
          </div>
        </div>
        <div className={styles.loginText}>
          Already have an account?
          <Link to={`/?from=signup`} className={styles.loginLink}>
            Sign in instead
          </Link>
        </div>
        <Formik
          initialValues={getInitialCustomer(presetEmployerCode)}
          validationSchema={formDataValidationSchema(customAgeCheck).concat(passwordValidationSchema(pwScore))}
          onSubmit={onSubmit}
        >
          {({ values, errors, isValid, validateForm }) => (
            <Form noValidate>
              <div className={styles.fixCustomInput}>
                <FormikInput
                  inputClass={styles.customInput}
                  label="First Name*"
                  name="firstName"
                  required
                  labelClass={styles.inputLabel}
                />
                <FormikInput
                  inputClass={styles.customInput}
                  label="Last Name*"
                  name="lastName"
                  required
                  labelClass={styles.inputLabel}
                />
                <FormikDatePicker
                  inputClass={styles.customInput}
                  label="Date of birth*"
                  name="dateOfBirth"
                  placeholder="DD/MM/YYYY"
                  format="DD/MM/YYYY"
                />
                <FormikInput
                  inputClass={styles.customInput}
                  label="Residential postcode*"
                  name="postcode"
                  required
                  labelClass={styles.inputLabel}
                />
                {!presetEmployerCode && (
                  <FormikSelect
                    className={styles.customSelect}
                    labelClass={styles.selectInputLabel}
                    errorClassName={styles.customSelectError}
                    label={'Employer code?*'}
                    placeholder={'Select employer code'}
                    isSearchable={false}
                    name="employerCode"
                    options={employerCodes}
                    styles={{
                      container: (base) => ({
                        ...base,
                        width: '100%'
                      }),
                      control: (controlBase: any) => ({
                        ...controlBase,
                        minHeight: '30px',
                        backgroundColor: 'transparent',
                        border: 'none',
                        borderRadius: 0,
                        boxShadow: 'none'
                      }),
                      valueContainer: (base) => ({ ...base, paddingLeft: 0 })
                    }}
                  />
                )}
                <FormikSelect
                  className={styles.customSelect}
                  labelClass={styles.selectInputLabel}
                  errorClassName={styles.customSelectError}
                  label={'Is this a workplace or personal issue?*'}
                  placeholder={'Select issue'}
                  isSearchable={false}
                  name="issueSource"
                  options={workplaceOrPersonal}
                  styles={{
                    container: (base) => ({
                      ...base,
                      width: '100%'
                    }),
                    control: (controlBase: any) => ({
                      ...controlBase,
                      minHeight: '30px',
                      backgroundColor: 'transparent',
                      border: 'none',
                      borderRadius: 0,
                      boxShadow: 'none'
                    }),
                    valueContainer: (base) => ({ ...base, paddingLeft: 0 })
                  }}
                />
                <FormikInput
                  inputClass={styles.customInput}
                  label="What is your directorate/worksite?*"
                  name="directorate"
                  required
                  labelClass={styles.inputLabel}
                />
                <HelmMobileFormikInput
                  inputClass={styles.customInput}
                  labelClass={styles.selectInputLabel}
                  id={`mobileNumber`}
                  name={'mobileNumber'}
                  label={'Mobile number*'}
                  mobilePattern="____ ___ ___"
                  placeholder={'0482 555 444'}
                  onChange={() => mobileNumberErrorMsg && setMobileNumberErrorMsg()}
                  phonePrefix=""
                  externalError={mobileNumberErrorMsg}
                  required
                />
                <FormikInput
                  inputClass={styles.customInput}
                  label="Email address*"
                  name="email"
                  required
                  externalError={emailErrorMsg}
                  onChange={() => emailErrorMsg && setEmailErrorMsg()}
                  labelClass={styles.inputLabel}
                />
                <FormikInput
                  inputClass={styles.customInput}
                  label="Set Secure Password*"
                  name="password"
                  type="password"
                  required
                  showError={showPwError}
                  labelClass={styles.inputLabel}
                  allowShowPassword
                  showPasswordButtonClass={styles.showPasswordButtonClass}
                />
              </div>
              <div className={classnames(styles.passwordHint, showPwError && styles.passwordHintWithError)}>
                It must meet the following password rules:
                <div className={styles.hints}>
                  <PasswordRules password={values.password} onChangePasswordScore={setPwScore} />
                </div>
              </div>

              <div className={styles.collectionNotice}>
                <div className={styles.title}>Collection Notice</div>
                <div className={styles.text}>
                  The information you provide to Caraniche, including any personal information as defined in the Privacy
                  Act 1988 (Cth) (Privacy Act) and information about actual or attempted access to our online platform
                  (including our websites and applications) will be used by Caraniche for the purposes of:
                  <ul>
                    <li>providing information and services to you;</li>
                    <li>ensuring that the website and application are working as they should;</li>
                    <li>and marketing.</li>
                  </ul>
                  We may also use your Personal Information for secondary purposes closely related to the primary
                  purpose, in circumstances where you would reasonably expect such use or disclosure (e.g. to
                  investigate complaints).
                  <br />
                  <br />
                  Caraniche may share this information with third parties (healthcare service providers, community
                  service providers, government agencies, and/or funding agencies) where you consent to the use or
                  disclosure for the purpose of facilitating care and to enable the payment for services; and where
                  required or authorised by law.
                  <br />
                  <br />
                  If Caraniche does not collect this information, it may impact the outcome of the services provided.
                  <br />
                  <br />
                  Information about the collection, use, disclosure and storage of personal information by Caraniche,
                  and information about how to contact us, is available in our{' '}
                  <a href={CaWPrivacyPolicy}>Privacy Statement</a>. This includes information on how you can access and
                  seek corrections to your personal information and about complaints procedures.
                </div>
              </div>
              <div className={styles.isTermsAndConditionsAccepted}>
                <div className={styles.checkBoxText}>
                  <CheckBox
                    id="isTermsAndConditionsAccepted"
                    value={isTermsAndConditionsAccepted}
                    onChange={(e) => setIsTermsAndConditionsAccepted(e.target.checked)}
                    className={styles.checkBox}
                    label={`I confirm I agree to the <a target="_blank" href="${CaWPrivacyPolicy}">Privacy Statement</a> and <a target="_blank" href="${CaWTermAndConditions}">Terms & Conditions</a>`}
                  />
                </div>
              </div>
              <div className={styles.communicationPreferences}>
                <div className={styles.title}>Communication Preferences</div>
                <div className={styles.text}>
                  We need to communicate with you via <b>email</b> to send you information about your appointments, for
                  example links to join the Telehealth room. By completing this registration you agree to us sending
                  this detail to your registered email address.
                  <br />
                  <br />
                  We can also send you important information such as appointment reminders via SMS. Please let us know
                  if you’d prefer not to.
                </div>
                <div className={styles.checkBoxes}>
                  <div className={styles.checkBoxText}>
                    <CheckBox
                      id="receiveSMS"
                      value={receiveSMS}
                      onChange={(e) => setReceiveSMS(e.target.checked)}
                      className={styles.checkBox}
                      label="I am happy to receive SMS"
                    />
                  </div>
                </div>
              </div>

              <ButtonCaW
                className={styles.submitButton}
                disabled={
                  !values.password ||
                  values.password.length < 2 ||
                  isEqual(values, getInitialCustomer()) ||
                  !isButtonEnabled(isValid, errors)
                }
                onClick={() => {
                  setShowPwError(true);
                  validateForm().then((errors) => {
                    if (isEmpty(errors)) {
                      onSubmit({ ...values, receiveSMS });
                    }
                  });
                }}
              >
                Create Your Profile
              </ButtonCaW>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default CaWCreateProfileForm;
