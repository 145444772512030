import StepTitle from 'SomeoneHealth/pages/PsychologistDetails/components/PsychologistTimeSlot/components/StepTitle/StepTitle';
import styles from './GPTimeSlot.module.scss';
import Calendar from 'SomeoneHealth/pages/PsychologistDetails/components/PsychologistTimeSlot/components/Calendar/Calendar';
import {
  BookingType,
  useGetAvailabilityAppointmentsByAppointmentTypeIdQuery
} from 'redux/endpoints/scheduleServices/availabilityAppointments';
import { useGetAttachedClinicianDetails } from 'redux/endpoints/clinicianProfileServices/getClientDetails';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  selectSelectedGPAppointmentType,
  selectSelectedTimeSlot,
  setIsTimeSlotsLoading,
  setSelectedTimeSlot
} from 'redux/globalStore/gpBookingSlice';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTimeZone } from 'utils/hooks/useTimeZone';
import { SOMEONE_HEALTH_TIME_ZONE_LIST, someoneHealthTimeZoneLocalStorage } from 'utils/constants/timeZone';
import {
  GPAppointmentAvailabilityWithTimeZone,
  GPTimeSlotsWithDateInterface
} from 'SomeoneHealth/pages/Booking/components/BookingFlow/hooks/useFetchGPAvailabilityWithTimeZone';
import { massageTimeSlot, massageTimeSlotObj } from './massageTimeSlotsByTimeZone';
import { isSlotSelected } from 'SomeoneHealth/pages/PsychologistDetails/components/PsychologistTimeSlot/components/Calendar/components/SlotPicker/SlotPicker';

const from = moment().format('YYYY-MM-DD');
const to = moment().add(12, 'month').format('YYYY-MM-DD');

const GPTimeSlot = () => {
  const dispatch = useAppDispatch();

  const selectedAppointmentType = useAppSelector(selectSelectedGPAppointmentType);
  const selectedTimeSlot = useAppSelector(selectSelectedTimeSlot);
  const { attachedClinicianDetail } = useGetAttachedClinicianDetails();
  const clientTimeZone = useTimeZone(SOMEONE_HEALTH_TIME_ZONE_LIST, someoneHealthTimeZoneLocalStorage);

  const [availabilityTimeSlots, setAvailabilityTimeSlots] = useState<GPTimeSlotsWithDateInterface[]>([]);

  const {
    data: appointmentAvailability,
    isLoading,
    isFetching
  } = useGetAvailabilityAppointmentsByAppointmentTypeIdQuery(
    {
      accountId: attachedClinicianDetail?.accountId || '',
      appointmentTypeId: selectedAppointmentType?._id || '',
      from,
      to,
      type: BookingType.BOOKING
    },
    { skip: !attachedClinicianDetail?.accountId || !selectedAppointmentType?._id }
  );

  useEffect(() => {
    if (!isFetching && !isLoading && appointmentAvailability) {
      const formattedTimeSlotsData: GPAppointmentAvailabilityWithTimeZone = {
        appointmentType: appointmentAvailability.appointmentType,
        timeSlots: massageTimeSlot(appointmentAvailability.timeSlots).map((item) =>
          massageTimeSlotObj(item, clientTimeZone)
        )
      };
      setAvailabilityTimeSlots(formattedTimeSlotsData.timeSlots);
    }
  }, [isLoading, isFetching, appointmentAvailability, clientTimeZone]);

  useEffect(() => {
    dispatch(setIsTimeSlotsLoading(isLoading || isFetching));
  }, [isLoading, isFetching, dispatch]);

  const onTimeSlotsChange = (value: GPTimeSlotsWithDateInterface) => {
    if (!isSlotSelected({ selectedSlots: selectedTimeSlot ? [selectedTimeSlot] : [], slot: value })) {
      dispatch(setSelectedTimeSlot(value));
    } else {
      dispatch(setSelectedTimeSlot(undefined));
    }
  };

  return (
    <div className={styles.container}>
      <StepTitle no="2" title="Select day and time" numberBubbleClassName={styles.numberBubbleClassName} />
      <div className={styles.content}>
        <Calendar
          onSlotClick={onTimeSlotsChange}
          timeSlots={availabilityTimeSlots}
          remainSlots={(selectedAppointmentType?.slotCount || 1) - (selectedTimeSlot ? 1 : 0)}
          selectedTimeSlots={selectedTimeSlot ? [selectedTimeSlot] : []}
          isAvailabilityListLoading={isLoading || isFetching}
          calendarWrapperClassName={styles.calendarWrapperClassName}
          calendarCardClassName={styles.calendarCardClassName}
          slotPickerCardClassName={styles.slotPickerCardClassName}
          filterClassNames={styles.filterClassNames}
          supportTimeZone={false}
        />
      </div>
    </div>
  );
};

export default GPTimeSlot;
