import moment from 'moment';
import { isDevelopmentENV } from 'utils/featureToggle/DevelopmentToggle';
import * as Yup from 'yup';

export const initialClientRecord = {
  firstName: '',
  lastName: '',
  email: '',
  mobileNumber: '',
  dateOfBirth: '',
  address: {
    line1: '',
    line2: '',
    state: '',
    suburb: '',
    postcode: '',
    country: ''
  },
  password: '',
  receiveEmail: true,
  receiveSMS: true,
  guardianFirstName: '',
  guardianLastName: '',
  guardianMobileNumber: '',
  guardianEmail: '',
  guardianRelationship: '',
  consentToContact: false,
  saveAsEmergencyContact: true,
  emergencyContacts: []
};

export const getAuMobile = (v: string) =>
  v && !isDevelopmentENV() ? '+61' + (v.startsWith('0') ? v.slice(1) : v) : `+${v}`;

export const formDataValidationSchema = (customAgeCheck?: number) => {
  const dobAge = customAgeCheck || 13;
  const minMobileNumberDigits = 11;

  return Yup.object().shape({
    firstName: Yup.string().required('Please enter your first name'),
    lastName: Yup.string().required('Please enter your last name'),
    email: Yup.string().required('Please enter your email').email('Email must be in valid example@gmail.com format.'),
    dateOfBirth: Yup.string()
      .required('Please choose your date of birth')
      .test('Age check', `Be sure that you're over the age of ${dobAge}`, (dob) => {
        return moment(dob, 'YYYY-MM-DD').add(dobAge, 'years').isBefore(moment());
      }),
    mobileNumber: Yup.string().required('Please enter your mobile number'),
    guardianFirstName: Yup.string().when('dateOfBirth', {
      is: checkIsYoungPerson,
      then: Yup.string().required('Please enter your first name'),
      otherwise: Yup.string().nullable()
    }),
    guardianLastName: Yup.string().when('dateOfBirth', {
      is: checkIsYoungPerson,
      then: Yup.string().required('Please enter your last name'),
      otherwise: Yup.string().nullable()
    }),
    guardianMobileNumber: Yup.string().when('dateOfBirth', {
      is: checkIsYoungPerson,
      then: Yup.string()
        .required('Please enter contact number')
        .min(minMobileNumberDigits, 'Mobile number must be 10 digits or longer'),
      otherwise: Yup.string().nullable()
    }),
    guardianEmail: Yup.string().when('dateOfBirth', {
      is: checkIsYoungPerson,
      then: Yup.string()
        .required('Email must be in valid example@gmail.com format.')
        .email('Email must be in valid example@gmail.com format.'),
      otherwise: Yup.string().nullable()
    }),
    guardianRelationship: Yup.string().when('dateOfBirth', {
      is: checkIsYoungPerson,
      then: Yup.string().required('Please select relationship'),
      otherwise: Yup.string().nullable()
    }),
    emergencyContacts: Yup.array()
      .of(
        Yup.object().shape({
          firstName: Yup.string().required('Please enter your first name'),
          lastName: Yup.string().required('Please enter your last name'),
          email: Yup.string()
            .required('Email must be in valid example@gmail.com format.')
            .email('Email must be in valid example@gmail.com format.'),
          mobileNumber: Yup.string()
            .required('Please enter contact number')
            .min(minMobileNumberDigits, 'Mobile number must be 10 digits or longer'),
          relationship: Yup.string().required('Please select relationship')
        })
      )
      .when(['dateOfBirth', 'saveAsEmergencyContact'], {
        is: (dateOfBirth: string, saveAsEmergencyContact: boolean) =>
          checkIsYoungPerson(dateOfBirth) && !saveAsEmergencyContact,
        then: Yup.array().min(1, 'At least one emergency contact is requiredx'),
        otherwise: Yup.array().nullable()
      })
  });
};

export const PARENT_OR_GUARDIAN_RELATIONSHIP_OPTIONS = [
  { label: 'Mother', value: 'mother' },
  { label: 'Father', value: 'father' },
  { label: 'Step Mother', value: 'stepMother' },
  { label: 'Step Father', value: 'stepFather' },
  { label: 'Grandparent', value: 'grandparent' },
  { label: 'Guardian', value: 'guardian' },
  { label: 'Sibling', value: 'sibling' },
  { label: 'Teacher', value: 'teacher' },
  { label: 'Case Worker', value: 'caseWorker' },
  { label: 'Other', value: 'other' }
];

export const HB_EMERGENCY_CONTACT_RELATIONSHIP_OPTIONS = [
  'Partner',
  'Next of Kin',
  'Husband',
  'Wife',
  'Son',
  'Daughter',
  'Family Member',
  'Employer',
  'Teacher',
  'Doctor',
  'Care Provider',
  'Bill Payer',
  'Safeguarding',
  'Human Resources',
  'Occupational Health',
  'Nurse',
  'Consent to share data',
  'No consent to share',
  'Pastoral Support Officer',
  'Social Worker',
  'Family Support Worker',
  'Mother',
  'Father',
  'Step Mother',
  'Step Father',
  'Grandparent',
  'Guardian',
  'Sibling',
  'Case Worker'
];

export const HB_DEFAULT_EMERGENCY_CONTACT_RELATIONSHIP = 'Emergency Contact';

export const getAge = (dob: string) => moment().diff(moment(dob, 'yyyy-mm-dd'), 'years');
export const checkIsChild = (dob: string) => getAge(dob) < 13;
export const checkIsYoungPerson = (dob: string) => getAge(dob) >= 13 && getAge(dob) < 18;

export const passwordValidationSchema = (pwScore: number) =>
  Yup.object().shape({
    password: Yup.string()
      .required('Please set your password')
      .matches(/.*[!@#$%^&*-].*/, 'Password must meet requirements')
      .matches(/.*[A-Z].*/, 'Password must meet requirements')
      .matches(/.*[a-z].*/, 'Password must meet requirements')
      .matches(/.*[\d].*/, 'Password must meet requirements')
      .test('Password score', 'Weak password!', () => pwScore === 4)
      .nullable()
  });
