import {
  useMemo
  // useState
} from 'react';
import { useAuth0 } from '@auth0/auth0-react';
// import { message, Modal } from 'antd';
import moment from 'moment';
import queryString from 'query-string';
import AddToCalendar from 'react-add-to-calendar';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { clientRecordsInterface } from 'interfaces/Clients/clientsRecord';
import { Clinician } from 'utils/hooks/clinician';
// import { deleteAppointment } from 'utils/http/appointment';

import PatientContentLayout from 'components/PatientContentLayout/PatientContentLayout';
import PatientFooterCard from 'components/PatientFooterCard/PatientFooterCard';
import AppointmentPatientHeader from '../components/AppointmentPatientHeader/AppointmentPatientHeader';

import styles from './AppointmentConfirmed.module.scss';
import { PublicPracticeProfile } from 'interfaces/Practice/practice';
import { DeliveryType, OtherInstructions } from 'utils/hooks/appointment';
import { AppointmentStatusOption } from 'helm/interfaces/Appointment/Appointment';
import { ClientRescheduleRequestStatus } from 'helm/interfaces/Appointment/ClientRescheduleRequestStatus';
import { Invoice } from 'interfaces/Invoice/Invoice';

export interface Appointment {
  _id: string;
  clinicianId: string;
  patientId: string;
  date: string;
  startTime: string;
  endTime: string;
  gap: number;
  rate: number;
  name: string;
  avatar: string;
  type: 'block' | 'booking' | 'busy';
  deliveryType: DeliveryType;
  invoices: Invoice[];
  faceToFaceLocation?: string;
  videoCallInstructions?: string;
  phoneCallInstructions?: string;
  phoneCallDialClientInstructions?: string;
  otherInstructions?: OtherInstructions;
  confirmation: {
    clinician: boolean;
    patient: boolean;
  };
  notes?: string;
  markedStatus?: (AppointmentStatusOption | string)[];
  sessionTypeId?: string;
  sessionTypeName?: string;
  accountId: string;
  clientRescheduleDetails?: {
    count: number;
    date?: string;
    startTime?: string;
    endTime?: string;
    status: ClientRescheduleRequestStatus;
    note?: string;
  };
  [key: string]: unknown;
}

const AppointmentConfirmed = () => {
  const {
    user
    // getAccessTokenSilently
  } = useAuth0();
  const navigate = useNavigate();
  const location = useLocation();
  const { clinicianId } = useParams() as { clinicianId: string };
  const { appointmentId }: { appointmentId?: string } = queryString.parse(location.search);

  // const [isConfirmButtonLoading, setIsConfirmButtonLoading] = useState(false);

  // const handleCancelAppointment = async () => {
  //   if (appointment) {
  //     setIsConfirmButtonLoading(true);

  //     try {
  //       const token = await getAccessTokenSilently({
  //         audience: process.env.REACT_APP_API_AUDIENCE
  //       });

  //       await deleteAppointment(appointment._id, {}, token);

  //       navigate(`/${clinicianId}/appointment`);
  //     } catch (ex) {
  //       message.error('Something went wrong while trying to cancel this appointment');
  //     }

  //     setIsConfirmButtonLoading(false);
  //   }
  // };

  // const editBooking = () => {
  //   Modal.confirm({
  //     title: 'Are you sure you want to cancel this session?',
  //     okText: 'Yes',
  //     okButtonProps: { loading: isConfirmButtonLoading },
  //     cancelText: 'No',
  //     onOk: handleCancelAppointment
  //   });
  // };

  const { appointment, clinician, practiceProfile } = useMemo(() => {
    let { appointment, clinician, practiceProfile } =
      (location.state as { appointment: Appointment; clinician: Clinician; practiceProfile: PublicPracticeProfile }) ||
      {};

    if (appointmentId && localStorage.getItem(appointmentId)) {
      const storedAppointmentObject = JSON.parse(localStorage.getItem(appointmentId) as string);

      if (storedAppointmentObject) {
        appointment = storedAppointmentObject.appointment;
        clinician = storedAppointmentObject.clinician;
        practiceProfile = storedAppointmentObject.practiceProfile;
      }
    }

    if (!practiceProfile) {
      if (!appointment || !clinician) {
        navigate(`/${clinicianId}/appointment`);
      }
    }

    return { appointment, clinician, practiceProfile };
  }, [clinicianId, navigate, location.state, appointmentId]);

  const clientRecord = appointment?.clientRecord as clientRecordsInterface;
  const clientProfile = clientRecord.clientProfiles[0];

  return (
    <PatientContentLayout withFullHeight>
      {appointment && clinician && (
        <>
          <div className={styles.contentWrapper}>
            <AppointmentPatientHeader profileImg={user?.picture} initialsName={clientProfile.initials} />
            <div className={styles.content}>
              <div className={styles.heading}>You're booked in.</div>
              <div className={styles.text}>
                Thanks {user ? user.name : clientProfile.name ?? clientProfile.firstName} you have confirmed your next
                appointment with {practiceProfile ? practiceProfile.name : `${clinician.title} ${clinician.name}`} for:
              </div>
              <div className={styles.date}>
                {moment(appointment.date, 'YYYY-MM-DD').format('ddd D MMM')}{' '}
                {moment(appointment.startTime, 'HH:mm').format('hh:mm A')}
              </div>
              {appointment.deliveryType === DeliveryType.FaceToFace && appointment.faceToFaceLocation && (
                <div className={styles.deliveryTypeInformation}>
                  Location:
                  <br />
                  {appointment.faceToFaceLocation}
                </div>
              )}
              {((appointment.deliveryType === DeliveryType.PhoneCall && appointment.phoneCallInstructions) ||
                (appointment.deliveryType === DeliveryType.PhoneCallDialClient &&
                  appointment.phoneCallDialClientInstructions) ||
                (appointment.deliveryType === DeliveryType.VideoCall && appointment.videoCallInstructions) ||
                (appointment.deliveryType === DeliveryType.Other && appointment.otherInstructions?.instructions)) && (
                <div className={styles.deliveryTypeInformation}>
                  Instructions:
                  <br />
                  {appointment.deliveryType === DeliveryType.VideoCall
                    ? appointment.videoCallInstructions
                    : appointment.deliveryType === DeliveryType.PhoneCall
                    ? appointment.phoneCallInstructions
                    : appointment.deliveryType === DeliveryType.PhoneCallDialClient
                    ? appointment.phoneCallDialClientInstructions
                    : appointment.otherInstructions?.instructions}
                </div>
              )}
              <div className={styles.text}>You can now:</div>
              <div>
                <AddToCalendar
                  event={{
                    title: `Appointment with ${clinician.title} ${clinician.name}`,
                    description: `Appointment with ${clinician.title} ${clinician.name}`,
                    location: appointment.faceToFaceLocation,
                    startTime: moment(appointment.date + ' ' + appointment.startTime).format('YYYYMMDDTHHmmssZ'),
                    endTime: moment(appointment.date + ' ' + appointment.endTime).format('YYYYMMDDTHHmmssZ')
                  }}
                  // @ts-ignore
                  buttonLabel={
                    <>
                      Add this to your diary<i className={`material-icons ${styles.suggestionIcon}`}>arrow_forward</i>
                    </>
                  }
                  buttonTemplate={{ textOnly: true }}
                  dropdownClass={styles.atcDropdown}
                  buttonWrapperClass={styles.atcButtonWrapper}
                  buttonClassClosed={styles.atcClosed}
                  buttonClassOpen={styles.atcOpened}
                />
                {/* {user ? (
                  <div className={styles.suggestionList} onClick={editBooking}>
                    Cancel this session
                    <i className={`material-icons ${styles.suggestionIcon}`}>arrow_forward</i>
                  </div>
                ) : null} */}
              </div>
            </div>
          </div>
          <PatientFooterCard className={styles.floatCard}>
            <div className={styles.confirmMessage}>
              <i className={`material-icons ${styles.tickIcon}`}>done</i> Booking confirmed
            </div>
          </PatientFooterCard>
        </>
      )}
    </PatientContentLayout>
  );
};

export default AppointmentConfirmed;
