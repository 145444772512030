import { useMemo, useState } from 'react';
import { Datepicker } from '@mobiscroll/react';
import '@mobiscroll/react/dist/css/mobiscroll.scss';
import styles from './Calendar.module.scss';
import './MobiscrollCustom.scss';
import moment from 'moment/moment';
import SlotPicker from './components/SlotPicker/SlotPicker';
import TimeFilterDropdown from './components/TimeFilterDropdown/TimeFilterDropdown';
import { TimeSlotsWithDateInterface } from 'utils/hooks/appointment';
import { TimeSlotBooking } from '../../ClinicianTimeSlot';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';
import TimeZoneDropdown from 'CaW/pages/ClinicianListing/components/TimeZoneDropdown/TimeZoneDropdown';
import { CAW_TIME_ZONE_LIST } from 'CaW/pages/ClinicianListing/ClinicianListing';
import { generateDatesfromDates } from 'utils/date';

interface CalendarProps {
  appointmentTypeSlotCount?: number;
  onSlotClick: (e: TimeSlotsWithDateInterface) => void;
  timeSlots: TimeSlotBooking['slots'];
  selectedTimeSlots: TimeSlotsWithDateInterface[];
  isAvailabilityListLoading: boolean;
  remainSlots: number;
  isAdvisorySession?: boolean;
  selectedTimeZone: string;
  handleChangeTimeZone: (val: string) => void;
  renewalDate?: string;
}

const timeRangeSlot = [
  {
    id: 'allAvailableTimes',
    label: 'All Available Times'
  },
  {
    id: 'morningSlot',
    label: 'Morning',
    desc: 'before Midday'
  },
  {
    id: 'afternoonSlot',
    label: 'Afternoon',
    desc: 'between 12 - 6pm'
  },
  {
    id: 'eveningSlot',
    label: 'Evening',
    desc: 'after 6pm'
  }
];

const getFilteredSlots = (slots: TimeSlotsWithDateInterface[], timeRange?: string) => {
  switch (timeRange) {
    case 'morningSlot':
      return slots?.filter((i) => i.endTime.endsWith('AM') || i.endTime === '12:00PM');
    case 'afternoonSlot':
      return slots?.filter((i) => i.startTime.endsWith('PM') && (i.endTime < '06:01PM' || i.endTime.startsWith('12')));
    case 'eveningSlot':
      return slots?.filter(
        (i) => i.startTime.endsWith('PM') && i.startTime > '05:59PM' && !i.startTime.startsWith('12')
      );
    case 'allAvailableTimes':
    default:
      return slots;
  }
};

const maxDate = moment().add(12, 'month');
const minDate = moment();

const allDates = (() => {
  let x = moment();
  let res: any = [];
  while (x.isBefore(maxDate)) {
    res = [...res, x.format('YYYY-MM-DD')];
    x = x.add(1, 'days');
  }
  return res;
})();

const appointmentGapDays = 6;

const Calendar = ({
  appointmentTypeSlotCount = 1,
  onSlotClick,
  selectedTimeSlots,
  timeSlots,
  isAvailabilityListLoading,
  remainSlots,
  isAdvisorySession,
  selectedTimeZone,
  handleChangeTimeZone,
  renewalDate
}: CalendarProps) => {
  const { search } = useLocation();
  const { selectedDateTime: selectedDateTimeParams } = queryString.parse(search);
  const [selectedDate, setSelectedDate] = useState<string>(
    (selectedDateTimeParams as string)?.split(',')[0] || moment().format('YYYY-MM-DD')
  );
  const [filter, setFilter] = useState<{ timeRange?: string }>();

  const [datesWithoutTimeSlots, setDatesWithoutTimeSlots] = useState(new Set<string>());

  const invalidDates = useMemo(() => {
    const userSelectedDates = selectedTimeSlots.map((i) => i.date);

    if (appointmentTypeSlotCount === 1 || userSelectedDates.length === 0) {
      return [...datesWithoutTimeSlots];
    }

    const combinedBlockedDates = new Set(datesWithoutTimeSlots);

    // Enforce 1-week gap between appointments
    const gapDates = generateDatesfromDates({
      dates: userSelectedDates,
      before: appointmentGapDays,
      after: appointmentGapDays,
      exclusive: true
    });
    gapDates.forEach((date) => combinedBlockedDates?.add(date));

    return [...combinedBlockedDates];
  }, [appointmentTypeSlotCount, datesWithoutTimeSlots, selectedTimeSlots]);

  const filteredData = useMemo(() => {
    const filterTimeSlotByDate = (selectedDate: string) => {
      return timeSlots.filter((timeSlotObj) => timeSlotObj.date === selectedDate);
    };

    const dateWithoutSlot = new Set<string>(allDates);
    const map = new Map();
    if (!timeSlots) {
      return map;
    }
    timeSlots.forEach((item) => {
      if (!map.has(item.date)) {
        const values = getFilteredSlots(filterTimeSlotByDate(item.date), filter?.timeRange);
        map.set(item.date, values);
        if (values.length > 0) {
          dateWithoutSlot.delete(item.date);
        }
      }
    });

    setDatesWithoutTimeSlots(dateWithoutSlot);
    return map;
  }, [filter, timeSlots]);

  return (
    <div className={classNames(styles.container, isAdvisorySession && styles.isAdvisorySession, 'CaW-theme')}>
      <div className={styles.filterContainer}>
        <div className={styles.timeFilterContainer}>
          <div className={styles.filterLabel}>FILTER BY:</div>
          <div className={styles.filterList}>
            <TimeFilterDropdown
              className={styles.timeRange}
              listData={timeRangeSlot}
              leftListAlign
              onChange={(val) => setFilter({ ...filter, timeRange: val.id })}
            />
          </div>
        </div>
        <TimeZoneDropdown
          listData={CAW_TIME_ZONE_LIST}
          selectedValue={CAW_TIME_ZONE_LIST.find((obj) => obj.id === selectedTimeZone) || CAW_TIME_ZONE_LIST[0]}
          onChangeValue={(value) => handleChangeTimeZone(value.id)}
          whiteFont={false}
        />
      </div>
      <div className={styles.calendarWrapper}>
        <div className={classNames(styles.calendarCard, 'CaW-theme')}>
          <Datepicker
            calendarType={'month'}
            className={styles.calendar}
            firstDay={1}
            pages={1}
            responsive={{
              custom: {
                breakpoint: 820,
                pages: 2
              }
            }}
            controls={['calendar']}
            showInput={false}
            isOpen
            min={minDate.format('YYYY-MM-DD')}
            max={renewalDate || maxDate.format('YYYY-MM-DD')}
            invalid={isAvailabilityListLoading ? Array.from(allDates) : invalidDates}
            marked={selectedTimeSlots.map((item) => ({ date: moment(item.date).toDate(), color: styles.helmBlue }))}
            value={selectedDate}
            onChange={(e) => setSelectedDate(moment(e.value).format('YYYY-MM-DD'))}
            theme={'ios'}
            themeVariant={'light'}
            display="inline"
          />
        </div>
        <div className={styles.slotPickerCard}>
          <SlotPicker
            remainSlots={remainSlots}
            selectedDate={selectedDate}
            slots={filteredData.get(selectedDate)}
            selectedSlots={selectedTimeSlots}
            isLoading={isAvailabilityListLoading}
            onClickSlot={onSlotClick}
          />
        </div>
      </div>
    </div>
  );
};

export default Calendar;
