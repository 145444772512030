import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import LoadingDot from 'components/LoadingDot/LoadingDot';
import LetterDraftContent from './components/LetterDraftContent/LetterDraftContent';

import logo from 'assets/images/tacklit-logo-white.svg';
import styles from './LetterViewPage.module.scss';
import { useFetchSharedLetter } from 'utils/hooks/useFetchSharedLetter';
import { useWindowSize } from 'utils/hooks/useWindowSize';

const LetterViewPage = () => {
  const { accountId, letterId } = useParams<{ accountId: string; letterId: string }>();
  const { letterData, isLetterLoading } = useFetchSharedLetter(accountId || '', letterId || '');
  const [isShowContent, setShowContent] = useState(false);
  const [contentDimension, setContentDimension] = useState({
    height: 600,
    width: 872
  });

  const [width, height] = useWindowSize();

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowContent(true);
    }, 800);
    return () => clearTimeout(timer);
  }, []);

  const basePage = {
    width: 874,
    height: 600,
    scale: 1,
    scaleX: 1,
    scaleY: 1
  };

  const maxWidth = width;
  const maxHeight = height;

  const scalePages = () => {
    let scaleX = 1;
    let scaleY = 1;
    scaleX = maxWidth / basePage.width;
    scaleY = maxHeight / basePage.height;
    basePage.scaleX = scaleX;
    basePage.scaleY = scaleY;
    const calcScale = scaleX > scaleY ? scaleY : scaleX;
    basePage.scale = calcScale < 1 ? parseFloat(calcScale.toFixed(2)) : 1;

    return {
      scale: basePage.scale
    } as any;
  };

  const resizeVal = scalePages();

  const handleContentHeight = (height: number, width: number) => {
    setContentDimension({
      height: Math.floor(height + 50),
      width
    });
  };

  return (
    <div className={styles.container}>
      <div className={styles.header} />
      <div
        className={styles.content}
        style={{
          transform: 'scale(' + resizeVal.scale + ')',
          height: `${contentDimension.height}px`
        }}
      >
        {!isLetterLoading && isShowContent ? (
          <>
            {letterData?._id ? (
              <div>
                <LetterDraftContent
                  data={letterData}
                  clinicianDetails={{ practice: letterData.practice, clinician: letterData.clinician }}
                  contentHeight={handleContentHeight}
                />
              </div>
            ) : (
              <div>
                Sorry, you are not allowed to access this page. Either the permission is revoked, or the link is false.
              </div>
            )}
          </>
        ) : (
          <div className={styles.loadingWrapper}>
            <LoadingDot />
          </div>
        )}
      </div>
      <div className={styles.footer}>
        <img alt={'tacklit logo'} className={styles.logo} src={logo} />
        <span>
          Letter powered by Tacklit.&nbsp;&nbsp;
          <strong>Received an error?</strong>
          &nbsp;&nbsp;
          <a href="https://www.tacklit.com/contact-us">Contact us</a>
        </span>
      </div>
    </div>
  );
};

export default LetterViewPage;
