import { Modal } from 'antd';
import Loading from 'components/Loading/Loading';
import TwoFAInput from 'components/v2/TwoFAInput/TwoFAInput';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import ButtonSH from 'SomeoneHealth/components/ButtonSH/ButtonSH';
import styles from './OTPModal.module.scss';

interface OTPModalProps {
  otp: string[];
  visible: boolean;
  isSubmitting: boolean;
  clientName: string;
  otpError: string;
  onSubmit: () => void;
  onResend: () => Promise<void>;
  onChangeValue: (value: string[]) => void;
}

const OTPModal = ({
  otp,
  visible,
  isSubmitting,
  clientName,
  otpError,
  onSubmit,
  onResend,
  onChangeValue
}: OTPModalProps) => {
  const [isOtpTouched] = useState(false);
  const [timeUntilAllowResend, setTimeUntilAllowResend] = useState(0);
  const [isResending, setIsResending] = useState(false);

  const resetInterval = () => {
    let timeUntilAllowResend = 30;
    setTimeUntilAllowResend(timeUntilAllowResend);

    const intervalId = window.setInterval(() => {
      if (timeUntilAllowResend <= 1) {
        clearInterval();
      }

      timeUntilAllowResend -= 1;

      setTimeUntilAllowResend(timeUntilAllowResend);
    }, 1000);

    const clearInterval = () => {
      window.clearInterval(intervalId);
    };
  };

  useEffect(() => {
    if (visible) {
      resetInterval();
    }
  }, [visible]);

  const handleResendOtp = async () => {
    setIsResending(true);
    await onResend();
    resetInterval();
    setIsResending(false);
  };

  return (
    <Modal width={640} bodyStyle={{ padding: 32 }} closable={false} visible={visible} footer={null} title={null}>
      <div className={styles.container}>
        {isSubmitting && (
          <div className={styles.loading}>
            <Loading />
          </div>
        )}
        <div className={styles.greeting}>{`Hi ${clientName},`}</div>
        <div className={styles.otpLabel}>Please enter your verification code:</div>
        <TwoFAInput
          value={otp}
          error={otpError}
          placeholder={'-'}
          isTouched={isOtpTouched}
          inputClassName={styles.inputClassName}
          verifiedClassName={styles.verifiedClassName}
          verifyingClassName={styles.verifyingClassName}
          inputWrapperClassName={styles.inputWrapperClassName}
          inputContainerClassName={styles.inputContainerClassName}
          onChangeValue={onChangeValue}
        />
        {otpError && (
          <div className={styles.otpError}>
            <i className={'material-icons-outlined'}>warning</i>
            Sorry this code is invalid. Please try again
          </div>
        )}
        <div className={styles.timeExpiryWarning}>This code will only be valid for the next 5 minutes.</div>
        <div>
          <button
            onClick={handleResendOtp}
            className={styles.resendButton}
            disabled={isResending || !!timeUntilAllowResend}
          >
            Send me another code
          </button>
          <div className={styles.resendTimer}>
            New code available{timeUntilAllowResend ? ` in ${timeUntilAllowResend} seconds` : ''}
          </div>
        </div>
        <ButtonSH disabled={otp.some((value) => !value)} onClick={onSubmit} className={styles.verifyButton}>
          Verify Mobile
        </ButtonSH>
        <div className={styles.suggestion}>
          Not receiving a code via SMS? Please{' '}
          <Link className={styles.link} to="https://support.someone.health/">
            contact us
          </Link>{' '}
          so we can help
        </div>
      </div>
    </Modal>
  );
};

export default OTPModal;
