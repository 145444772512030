import Button, { HelmBtnStatus } from 'components/v2/Button/Button';
import styles from './DownloadButton.module.scss';

interface DownloadButtonProps {
  status?: HelmBtnStatus;
  onClick?: () => void;
  children: string;
  disabled?: boolean;
  customClass?: string;
  isDownloading?: boolean;
}

const DownloadButton = ({ status, onClick, children, disabled, customClass, isDownloading }: DownloadButtonProps) => {
  return (
    <Button status={status} onClick={onClick} variant="secondary" disabled={disabled} className={customClass}>
      <div className={styles.center}>
        <i className={`material-icons-outlined ${styles.icon}`}>download</i>
        {isDownloading ? 'Downloading...' : children}
      </div>
    </Button>
  );
};

export default DownloadButton;
