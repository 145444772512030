interface DefaultWelcomeContentProps {
  clientName: string;
  clinicianName: string;
}

const DefaultWelcomeContent = ({ clientName }: DefaultWelcomeContentProps) => {
  return (
    <>
      <div>Hi {clientName},</div>
      <br />
      <div>Welcome to your online account powered by Tacklit.</div>
      <div>
        This will be a place for you to manage your appointments and complete documents digitally to support you get the
        very best outcome from your therapy.
      </div>
      <br />
      <div>Thank you.</div>
    </>
  );
};

export default DefaultWelcomeContent;
