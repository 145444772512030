import { TIMEZONE_OPTIONS } from 'utils/constants/timeZone';
import MaterialSelect from 'components/Select/MaterialSelect/MaterialSelect';
import MaterialInput from 'components/MaterialInput/MaterialInput';
import DateSelector from 'components/DatePicker/DateSelector';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import styles from './PersonalDetailForm.module.scss';
import classnames from 'classnames';
import { IS_SOMEONE_HEALTH_APP } from 'utils/hooks/AccountInfo/clientDetails';
import { PutPatientPayload } from 'utils/http/PatientProfileService/Patient/patient';
import { AddressForm } from '../AddressForm/AddressForm';

export type OnAddressAutoCompleteHandler = (address: PutPatientPayload['address']) => void;

export type PersonalDetailFormFields = Omit<PutPatientPayload, 'address'> & PutPatientPayload['address'];

interface PersonalDetailFormProps {
  values: PersonalDetailFormFields;
  errors?: Partial<Record<keyof PersonalDetailFormFields, string | undefined>>;
  handleChangeValues: (key: keyof PersonalDetailFormFields, value: string) => void;
  onAddressAutoComplete: OnAddressAutoCompleteHandler;
  isClientTimeZoneEnabled: boolean;
  isUsedEmail: boolean;
  setIsUsedEmail: (value: boolean) => void;
}

const PersonalDetailForm = ({
  values,
  errors,
  handleChangeValues,
  onAddressAutoComplete,
  isClientTimeZoneEnabled,
  isUsedEmail,
  setIsUsedEmail
}: PersonalDetailFormProps) => {
  return (
    <div className={styles.form}>
      <div className={classnames(styles.formItem, errors?.firstName && styles.fieldError)}>
        <MaterialInput
          label={'First Name'}
          id={'firstName'}
          onChange={(e) => handleChangeValues('firstName', e.target.value)}
          value={values.firstName || ''}
          maxLength={80}
          required
          autoComplete={'off'}
          className={styles.input}
        />
        <ErrorMessage className={styles.error} error={errors?.firstName} visible={!!errors?.firstName} />
      </div>
      <div className={classnames(styles.formItem, errors?.lastName && styles.fieldError)}>
        <MaterialInput
          label={'Last Name'}
          id={'lastName'}
          onChange={(e) => handleChangeValues('lastName', e.target.value)}
          value={values.lastName || ''}
          maxLength={80}
          required
          autoComplete={'off'}
          className={styles.input}
        />
        <ErrorMessage className={styles.error} error={errors?.lastName} visible={!!errors?.lastName} />
      </div>
      <div className={classnames(styles.formItem, errors?.name && styles.fieldError)}>
        <MaterialInput
          label={'Preferred Name'}
          id={'nickName'}
          onChange={(e) => handleChangeValues('name', e.target.value)}
          value={values.name || ''}
          maxLength={80}
          required
          autoComplete={'off'}
          className={styles.input}
        />
        <ErrorMessage className={styles.error} error={errors?.name} visible={!!errors?.name} />
      </div>
      <div className={styles.formItem}>
        <DateSelector
          inputClass={errors?.dateOfBirth ? styles.datePickerError : styles.datePicker}
          labelClass={IS_SOMEONE_HEALTH_APP ? styles.shDatePickerLabel : styles.datePickerLabel}
          label="Date of birth"
          name="dateOfBirth"
          id="dateOfBirth"
          value={values.dateOfBirth || ''}
          placeholder="DD/MM/YYYY"
          onChange={(v: any) => handleChangeValues('dateOfBirth', v)}
          inputReadOnly={false}
        />
        <ErrorMessage className={styles.error} error={errors?.dateOfBirth} visible={!!errors?.dateOfBirth} />
      </div>
      <div className={classnames(styles.formItem, errors?.email && styles.fieldError)}>
        <MaterialInput
          label={'Email'}
          id={'email'}
          onChange={(e) => {
            handleChangeValues('email', e.target.value);
            isUsedEmail && setIsUsedEmail(false);
          }}
          value={values.email || ''}
          maxLength={80}
          required
          autoComplete={'off'}
          className={styles.input}
        />
        <ErrorMessage className={styles.error} error={errors?.email} visible={!!errors?.email} />
        <ErrorMessage className={styles.error} error={'Email already in use'} visible={isUsedEmail} />
      </div>
      <div className={styles.formItem} />
      <AddressForm
        values={values}
        errors={errors}
        onAddressAutoComplete={onAddressAutoComplete}
        handleChangeValues={handleChangeValues}
      />
      {isClientTimeZoneEnabled && (
        <div className={styles.timeZoneWrapper}>
          <div className={styles.timeZoneLabel}>
            Please set your timezone for notification and calendar invites
            {/* TODO: Pending tooltip content
                      <i data-tip data-for={'timeZone'} className={`material-icons-outlined ${styles.helpIcon}`}>
                        help_outline
                      </i>
                      <ReactTooltip id={'timeZone'} className={styles.tooltip}>
                        Please set your timezone for notification and calendar invites
                      </ReactTooltip>
                    */}
          </div>
          <div className={styles.timeZoneSelect}>
            <MaterialSelect
              id={'timeZone'}
              label={'Your timezone'}
              isSearchable={false}
              optionList={TIMEZONE_OPTIONS}
              value={values.timeZone || ''}
              onChange={(value: string) => handleChangeValues('timeZone', value)}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default PersonalDetailForm;
