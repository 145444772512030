import { notification } from 'antd';
import { MedicareInformation, PatientProfile } from 'components/UpdateProfileContent/interface';
import Button, { HelmBtnStatus } from 'components/v2/Button/Button';
import { useFormError } from 'helm/utils/hooks/validate';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import ButtonSH from 'SomeoneHealth/components/ButtonSH/ButtonSH';
import MedicareCard from 'SomeoneHealth/pages/Booking/components/MedicareCard/MedicareCard';
import { IS_CAW_APP, IS_RECHARGE_APP, IS_SOMEONE_HEALTH_APP } from 'utils/hooks/AccountInfo/clientDetails';
import { useGetAccessToken } from 'utils/hooks/token';
import { putClientMedicare } from 'utils/http/ClinicianProfileService/ClientRecords/clientRecords';
import styles from './MedicareInformation.module.scss';
import ButtonCaW from '../../../../CaW/components/ButtonCaW/ButtonCaW';
import ButtonRecharge from 'Recharge/components/ButtonRecharge/ButtonRecharge';

type MedicareForm = Partial<MedicareInformation>;

interface MedicareInformationProps {
  patientProfile: PatientProfile;
}

const MedicareComponent = ({ patientProfile }: MedicareInformationProps) => {
  const initialValues = {
    ...patientProfile.medicare,
    dateOfBirth: patientProfile.medicare?.dateOfBirth || patientProfile.dateOfBirth || '',
    firstName: patientProfile.medicare?.firstName || patientProfile.firstName || '',
    lastName: patientProfile.medicare?.lastName || patientProfile.lastName || ''
  };

  const [edit, setEdit] = useState(false);
  const [values, setValues] = useState(initialValues);
  const { errors, setErrors } = useFormError<MedicareForm>();
  const [submitStatus, setSubmitStatus] = useState<HelmBtnStatus>('');

  const { token } = useGetAccessToken();
  const [isMedicareCardValidating, setIsMedicareValidating] = useState(false);
  const [medicareErrorMessage, setMedicareErrorMessage] = useState<MedicareInformation['status']>(
    patientProfile.medicare?.status || {}
  );
  const [useProfileInfoToggle, setUseProfileInfoToggle] = useState<boolean>(false);
  const [isMedicareEmpty, setIsMedicareEmpty] = useState(!patientProfile.medicare);

  /** update `isMedicareEmpty` everytime `patientProfile.medicare` changes */
  useEffect(() => {
    setIsMedicareEmpty(!patientProfile.medicare);
  }, [patientProfile.medicare]);

  /** Clear errors when user changes any of the form input */
  useEffect(() => {
    setErrors({});
  }, [values, setErrors]);

  /** Tick the match profile checkbox if info is the same */
  useEffect(() => {
    setUseProfileInfoToggle(
      values.firstName === patientProfile.firstName &&
        values.lastName === patientProfile.lastName &&
        values.dateOfBirth === patientProfile.dateOfBirth
    );
  }, [
    values.dateOfBirth,
    values.firstName,
    values.lastName,
    patientProfile.dateOfBirth,
    patientProfile.firstName,
    patientProfile.lastName
  ]);

  const cancelEdit = () => {
    setEdit(false);
    setErrors({});

    const { medicare } = patientProfile;

    if (medicare) {
      setValues(medicare);
    } else {
      setValues(initialValues);
    }
  };

  const isSaveBtnDisabled = useMemo(() => {
    if (
      !values.firstName ||
      !values.lastName ||
      !values.dateOfBirth ||
      (!values.dva && !(values.number && values.irn)) ||
      submitStatus !== ''
    ) {
      return true;
    }

    return errors && !!Object.values(errors).find((error) => !!error);
  }, [
    errors,
    values.dateOfBirth,
    values.dva,
    values.firstName,
    values.irn,
    values.lastName,
    values.number,
    submitStatus
  ]);

  const saveMedicareInfo = async () => {
    setSubmitStatus('active');
    setIsMedicareValidating(true);
    try {
      const response = await putClientMedicare(token, {
        ...values,
        dateOfBirth: moment(values.dateOfBirth, 'DD/MM/YYYY').format('YYYY-MM-DD'),
        expiryDate: values.expiryDate || null,
        shouldRejectInvalidDetails: true
      });

      if (response.statusCode === 200) {
        const medicareResponse = await response.json();
        setValues({
          ...medicareResponse,
          dateOfBirth: moment(medicareResponse.dateOfBirth, 'YYYY-MM-DD').format('DD/MM/YYYY')
        });
        setMedicareErrorMessage(medicareResponse.status);
        setSubmitStatus('finished');
        if (isMedicareEmpty) {
          setIsMedicareEmpty(false);
        }
        setTimeout(() => {
          setSubmitStatus('');
          notification.success({ message: 'Medicare information updated' });
          setEdit(false);
        }, 1000);
      } else if (response.statusCode === 400) {
        setSubmitStatus('');

        const { errors: responseErrors } = (await response.json()) as {
          errors: {
            dateOfBirth?: string;
            numberOrIrn?: string;
            dva?: string;
          };
        };

        setErrors({
          ...errors,
          ...(responseErrors.dateOfBirth && { dateOfBirth: responseErrors.dateOfBirth }),
          ...(responseErrors.dva && { dva: responseErrors.dva }),
          ...(responseErrors.numberOrIrn && { number: responseErrors.numberOrIrn, irn: responseErrors.numberOrIrn })
        });

        setMedicareErrorMessage({
          medicare: {
            code: 9633
          }
        });

        notification.error({
          message: 'Invalid Medicare details'
        });
      }
    } catch (e) {
      console.error(e);
      notification.error({ message: 'Something went wrong while trying to update your Medicare information' });
      setSubmitStatus('');
    } finally {
      setIsMedicareValidating(false);
    }
  };

  const turnOnEditMode = () => setEdit(true);
  const switchButtonIcon = isMedicareEmpty ? 'add_circle_outline' : 'edit';
  const switchButtonLabel = `${isMedicareEmpty ? 'Add' : 'Edit'} Medicare Information`;

  useEffect(() => {
    const { dateOfBirth, firstName, lastName } = patientProfile;

    if (useProfileInfoToggle && dateOfBirth && firstName && lastName) {
      setValues((form) => ({ ...form, dateOfBirth, firstName, lastName }));
    }
  }, [patientProfile, useProfileInfoToggle]);

  const handleUseProfileInfoToggle = () => {
    if (useProfileInfoToggle) {
      setValues((form) => ({
        ...form,
        dateOfBirth: patientProfile.medicare?.dateOfBirth || '',
        firstName: patientProfile.medicare?.firstName || '',
        lastName: patientProfile.medicare?.lastName || ''
      }));
    }

    setUseProfileInfoToggle((toggle) => !toggle);
  };

  return (
    <div className={styles.container}>
      <div className={styles.title}>Medicare Information</div>
      <div className={styles.description}>Add or update your details from your current medicare card.</div>

      {edit ? (
        <div className={styles.editCard}>
          <MedicareCard
            medicareCardValue={values}
            setMedicareCardValue={(newValueObj) => setValues((form) => ({ ...form, ...newValueObj }))}
            useSignUpInfoToggle={useProfileInfoToggle}
            setUseSignUpInfoToggle={handleUseProfileInfoToggle}
            showCheckToggle
            isMedicareCardValidating={isMedicareCardValidating}
            medicareErrorMessage={medicareErrorMessage}
          />
          <div className={styles.buttonWrapper}>
            {IS_CAW_APP ? (
              <ButtonCaW
                className={styles.submitButton}
                disabled={isSaveBtnDisabled}
                onClick={saveMedicareInfo}
                status={submitStatus}
                variant={'outlined'}
              >
                Save Medicare Information
              </ButtonCaW>
            ) : IS_RECHARGE_APP ? (
              <ButtonRecharge
                className={styles.submitButton}
                disabled={isSaveBtnDisabled}
                onClick={saveMedicareInfo}
                status={submitStatus}
              >
                Save Medicare Information
              </ButtonRecharge>
            ) : (
              <Button
                className={styles.submitButton}
                disabled={isSaveBtnDisabled}
                onClick={saveMedicareInfo}
                status={submitStatus}
                variant={'secondary'}
              >
                Save Medicare Information
              </Button>
            )}
            {submitStatus === '' && IS_SOMEONE_HEALTH_APP ? (
              <ButtonSH variant="outlined" onClick={cancelEdit}>
                Cancel
              </ButtonSH>
            ) : submitStatus === '' && IS_CAW_APP ? (
              <ButtonCaW variant="outlined" onClick={cancelEdit}>
                Cancel
              </ButtonCaW>
            ) : submitStatus === '' && IS_RECHARGE_APP ? (
              <ButtonRecharge variant="outlined" onClick={cancelEdit}>
                Cancel
              </ButtonRecharge>
            ) : (
              submitStatus === '' && (
                <Button variant={'secondary'} className={styles.cancelBtn} onClick={cancelEdit}>
                  Cancel
                </Button>
              )
            )}
          </div>
        </div>
      ) : (
        <>
          {!isMedicareEmpty && (
            <>
              <div className={styles.infoRow}>
                <div className={styles.label}>First Name</div>
                <div className={styles.value}>{values.firstName || '-'}</div>
              </div>
              <div className={styles.infoRow}>
                <div className={styles.label}>Last Name</div>
                <div className={styles.value}>{values.lastName || '-'}</div>
              </div>
              <div className={styles.infoRow}>
                <div className={styles.label}>Date of Birth</div>
                <div className={styles.value}>{values.dateOfBirth || '-'}</div>
              </div>
              <div className={styles.infoRow}>
                <div className={styles.label}>Medicare #</div>
                <div className={styles.value}>{values.number || '-'}</div>
              </div>
              <div className={styles.infoRow}>
                <div className={styles.label}>IRN</div>
                <div className={styles.value}>{values.irn || '-'}</div>
              </div>
              <div className={styles.infoRow}>
                <div className={styles.label}>Expiry Date</div>
                <div className={styles.value}>{values.expiryDate || '-'}</div>
              </div>
              <div className={styles.infoRow}>
                <div className={styles.label}>DVA #</div>
                <div className={styles.value}>{values.dva || '-'}</div>
              </div>
              {values.dvaCardDetails && (
                <>
                  <div className={styles.infoRow}>
                    <div className={styles.label}>DVA Card Type</div>
                    <div className={styles.value}>{values.dvaCardDetails.type?.toUpperCase() || '-'}</div>
                  </div>
                  <div className={styles.infoRow}>
                    <div className={styles.label}>DVA Expiry Date</div>
                    <div className={styles.value}>{values.dvaCardDetails.expiryDate || '-'}</div>
                  </div>
                </>
              )}
            </>
          )}
          <div className={styles.buttonWrapper}>
            {IS_SOMEONE_HEALTH_APP ? (
              <ButtonSH variant="outlined" onClick={turnOnEditMode} icon={switchButtonIcon}>
                {switchButtonLabel}
              </ButtonSH>
            ) : IS_CAW_APP ? (
              <ButtonCaW variant="outlined" onClick={turnOnEditMode} icon={switchButtonIcon}>
                {switchButtonLabel}
              </ButtonCaW>
            ) : IS_RECHARGE_APP ? (
              <ButtonRecharge variant="outlined" onClick={turnOnEditMode} icon={switchButtonIcon}>
                {switchButtonLabel}
              </ButtonRecharge>
            ) : (
              <Button className={styles.editButton} onClick={turnOnEditMode}>
                <i className={`material-icons-outlined ${styles.icon}`}>{switchButtonIcon}</i>
                {switchButtonLabel}
              </Button>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default MedicareComponent;
