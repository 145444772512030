import { notification } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import {
  AppointmentBookingClaimType,
  availabilitiesInterface,
  nextAvailability,
  PractitionerDetailsInterface,
  timeSlotsInterface
} from 'interfaces/PublicProfile/Practitioner/practitioner';
import momentTz from 'moment-timezone';
import { TimeSlotsWithDateInterface } from 'utils/hooks/appointment';
import queryString from 'query-string';
import { getHealthBrightClinicianListing } from 'HealthBright/utils/http/ClinicianProfileService/Health-Bright/healthBright';
import { PsychologistFitFilter } from '../PartnerPsychologistListing';

export const defaultClinicianTimeZone = 'Australia/Melbourne';

const massageTimeSlot = ({
  clientTimeZone,
  timeSlotList
}: {
  clientTimeZone: string;
  timeSlotList: timeSlotsInterface[];
}) =>
  timeSlotList.map((timeSlotObj) => {
    const clientStartTimeZone = momentTz.tz(timeSlotObj.startDateTime, clientTimeZone).format('hh:mmA');
    const clientEndTimeZone = momentTz.tz(timeSlotObj.endDateTime, clientTimeZone).format('hh:mmA');

    return {
      ...timeSlotObj,
      startTime: clientStartTimeZone,
      endTime: clientEndTimeZone
    };
  });

export const massageTimeSlotObj = (
  timeSlotObj: TimeSlotsWithDateInterface,
  clientTimeZone: string
): TimeSlotsWithDateInterface => {
  const clientStartTimeZone = momentTz.tz(timeSlotObj.startDateTime, clientTimeZone);
  const clientEndTimeZone = momentTz.tz(timeSlotObj.endDateTime, clientTimeZone);

  return {
    ...timeSlotObj,
    date: clientStartTimeZone.format('YYYY-MM-DD'),
    startTime: clientStartTimeZone.format('hh:mmA'),
    endTime: clientEndTimeZone.format('hh:mmA')
  };
};

export const massageTimeSlotReverse = (
  timeSlot: TimeSlotsWithDateInterface,
  clientTimeZone: string
): TimeSlotsWithDateInterface => {
  const clientStartTimeZone = momentTz
    .tz(`${timeSlot.date} ${timeSlot.startTime}`, 'YYYY-MM-DD hh:mmA', clientTimeZone)
    .utc();
  const clientEndTimeZone = momentTz
    .tz(`${timeSlot.date} ${timeSlot.endTime}`, 'YYYY-MM-DD hh:mmA', clientTimeZone)
    .utc();

  return {
    ...timeSlot,
    date: clientStartTimeZone.format('YYYY-MM-DD'),
    startTime: clientStartTimeZone.format('HH:mm'),
    endTime: clientEndTimeZone.format('HH:mm'),
    startDateTime: clientStartTimeZone.toDate(),
    endDateTime: clientEndTimeZone.toDate()
  };
};

const defaultPerPage = 10;

interface CliniciansResponse {
  clinicians: PractitionerDetailsInterface[];
  paging: { page: number; perPage: number; totalItem: number };
  matchedSpecialisations: string[];
}

interface UseFetchPractitionerListParams {
  clientTimeZone: string;
  filterQuery?: PsychologistFitFilter & {
    page?: number;
    perPage?: number;
    clinicianIds?: string;
    claimType?: AppointmentBookingClaimType;
    startTime?: string;
    endTime?: string;
    isNewClient?: boolean;
  };
  shouldFetch?: boolean;
}
const massageAvailabilities = ({
  availabilitiesList,
  clientTimeZone
}: {
  availabilitiesList: availabilitiesInterface[];
  clientTimeZone: string;
}) =>
  availabilitiesList.map((availabilitiesObj) => ({
    ...availabilitiesObj,
    timeSlots: massageTimeSlot({
      clientTimeZone,
      timeSlotList: availabilitiesObj.timeSlots
    })
  }));

const massageNextAvailabilities = ({
  nextAvailabilitiesList = [],
  clientTimeZone
}: {
  nextAvailabilitiesList?: nextAvailability[];
  clientTimeZone: string;
}) =>
  nextAvailabilitiesList
    .filter(({ shouldHideFromListing }) => !shouldHideFromListing)
    .map((nextAvailabilitiesObj) => ({
      ...nextAvailabilitiesObj,
      availabilities: massageAvailabilities({
        availabilitiesList: nextAvailabilitiesObj.availabilities,
        clientTimeZone
      }).filter(({ timeSlots }) => timeSlots.length)
    }));

const massagePsychologistList = (practitionerList: PractitionerDetailsInterface[], clientTimeZone: string) =>
  practitionerList.map((practitionerListObj) => {
    const nextAvailabilities = massageNextAvailabilities({
      nextAvailabilitiesList: practitionerListObj.matchedNextAvailabilities || [],
      clientTimeZone
    });

    const nextAvailability = nextAvailabilities
      .filter(({ availabilities }) => availabilities.length)
      .sort((availability1, availability2) => {
        const earliestAvailability1 = availability1.availabilities.filter(({ timeSlots }) => timeSlots.length)[0];
        const earliestAvailabilityTimeSlot1 = earliestAvailability1.timeSlots[0];

        const earliestAvailability2 = availability2.availabilities.filter(({ timeSlots }) => timeSlots.length)[0];
        const earliestAvailabilityTimeSlot2 = earliestAvailability2.timeSlots[0];

        if (earliestAvailability1.date < earliestAvailability2.date) {
          return -1;
        } else if (earliestAvailability1.date > earliestAvailability2.date) {
          return 1;
        } else if (earliestAvailabilityTimeSlot1.startTime < earliestAvailabilityTimeSlot2.startTime) {
          return -1;
        } else if (earliestAvailabilityTimeSlot1.startTime > earliestAvailabilityTimeSlot2.startTime) {
          return 1;
        }

        return 0;
      })[0]?.availabilities[0];
    const nextAvailableTimeSlot = nextAvailability?.timeSlots[0];

    return {
      ...practitionerListObj,
      nextAvailabilities,
      ...(nextAvailability && {
        nextAvailableTimeSlot: {
          date: nextAvailability?.date,
          time: nextAvailableTimeSlot?.startTime
        }
      })
    };
  });

export const useFetchPractitionerList = ({
  clientTimeZone,
  filterQuery,
  shouldFetch = true
}: UseFetchPractitionerListParams) => {
  const [psychologistList, setPsychologistList] = useState<PractitionerDetailsInterface[]>([]);
  const [isPractitionerListLoading, setIsPractitionerListLoading] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [hasMorePsychologists, setHasMorePsychologists] = useState(true);
  const [nextPage, setNextPage] = useState(1);
  const [matchedSpecialisations, setMatchedSpecialisations] = useState<string[]>([]);

  const fetchPsychologistList = useCallback(
    async ({ page = 1 }: { page: number }) => {
      try {
        const { clinicians, paging, matchedSpecialisations }: CliniciansResponse = await (
          await getHealthBrightClinicianListing(
            queryString.stringify(
              { page, perPage: defaultPerPage, ...filterQuery },
              { skipEmptyString: true, skipNull: true, arrayFormat: 'comma' }
            )
          )
        ).json();

        const massageData: PractitionerDetailsInterface[] = massagePsychologistList(clinicians, clientTimeZone);
        const counsellorList: PractitionerDetailsInterface[] = massageData.filter(
          (practitionerObj) => !practitionerObj.helmControl.isAdvisor
        );

        setHasMorePsychologists(paging.totalItem - paging.page * paging.perPage > 0);

        return { counsellorList, matchedSpecialisations };
      } catch (ex) {
        console.error(ex);
        notification.error({ message: 'Something went wrong while trying to get HealthBright psychologist list' });
      } finally {
        setNextPage((nextPage) => nextPage + 1);
      }
      return {};
    },
    [clientTimeZone, filterQuery]
  );

  useEffect(() => {
    if (shouldFetch && !isPractitionerListLoading && nextPage === 1) {
      const fetch = async () => {
        setIsPractitionerListLoading(true);

        const { counsellorList, matchedSpecialisations } = await fetchPsychologistList({ page: 1 });
        setPsychologistList(counsellorList || []);
        setMatchedSpecialisations(matchedSpecialisations || []);
        setIsPractitionerListLoading(false);
      };
      fetch();
    }
  }, [fetchPsychologistList, filterQuery, isPractitionerListLoading, nextPage, shouldFetch]);

  useEffect(() => {
    setNextPage(1);
  }, [filterQuery]);

  useEffect(() => {
    setPsychologistList((psychologistList) => massagePsychologistList(psychologistList, clientTimeZone));
  }, [clientTimeZone]);

  const loadMorePsychologists = async () => {
    if (!isLoadingMore && hasMorePsychologists) {
      setIsLoadingMore(true);

      const { counsellorList = [] } = await fetchPsychologistList({ page: nextPage });
      setPsychologistList((existing) => [...existing, ...counsellorList]);

      setIsLoadingMore(false);
    }
  };

  return {
    psychologistList,
    isPractitionerListLoading,
    fetchPsychologistList,
    loadMorePsychologists,
    hasMorePsychologists,
    matchedSpecialisations
  };
};
