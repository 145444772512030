import { decode } from 'js-base64';
import queryString from 'query-string';
import { useNavigate, useLocation, useParams } from 'react-router-dom';

import { useRoutesGenerator } from 'utils/Path/RoutesGenerator';

import NoAuthLayout from 'layouts/NoAuthLayout/NoAuthLayout';
import ContentLayout from 'components/ContentLayout/ContentLayout';
import TaskBox from 'components/TaskBox/TaskBox';

import styles from './SuccessSignUpPage.module.scss';
import { useTranslation } from 'react-i18next';

interface tokenObject {
  onboarding: number;
  consent: boolean;
}

const SuccessSignUpPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { clinicianSlugUrlOrId } = useParams<{ clinicianSlugUrlOrId: string }>();
  const [t] = useTranslation();

  const queryParam: { successToken?: string } = queryString.parse(location.search);

  const { CONSENT, ONBOARDING } = useRoutesGenerator(clinicianSlugUrlOrId);

  let onBoardingQuestionCount = 0;
  let isShowConsentTask = false;

  if (!queryParam.successToken) {
    navigate('/');
  } else {
    const taskList = decode(queryParam?.successToken);

    if (!taskList.includes('"onboarding"')) {
      navigate('/');
    } else {
      const taskObject = JSON.parse(taskList) as tokenObject;
      onBoardingQuestionCount = taskObject.onboarding;
      isShowConsentTask = taskObject.consent;
    }
  }

  const totalTask = (isShowConsentTask ? 1 : 0) + (onBoardingQuestionCount > 0 ? 1 : 0);

  const handleHomepagePath = () => {
    navigate('/');
  };

  const handleConsentPath = () => {
    navigate(CONSENT.BASE);
  };

  const handleOnboardingPath = () => {
    navigate(ONBOARDING.BASE);
  };

  return (
    <NoAuthLayout>
      <div className={styles.container}>
        <div id={'signUpFormHeader'} />
        <ContentLayout>
          <div className={styles.completeTaskBox}>
            <div className={styles.title}>Your account is all set up</div>
            <TaskBox title={'Set up your free account'} desc={'SUCCESS'} complete />
          </div>
          <div className={styles.continueTaskBox}>
            <div className={styles.continueTaskHeader}>
              <div className={styles.continueTaskTitle}>Continue to secure area</div>
              {totalTask ? (
                <div className={styles.continueTaskDesc}>
                  Login and complete your next{' '}
                  <strong>
                    {totalTask} {totalTask > 1 ? 'tasks' : 'task'}
                  </strong>
                  .
                </div>
              ) : (
                <div className={styles.continueTaskDesc}>Please proceed to log in.</div>
              )}
            </div>
            {isShowConsentTask && (
              <TaskBox
                title={t('label.esign_client_consent_form')}
                desc={'Read and agree'}
                onClick={handleConsentPath}
              />
            )}
            {onBoardingQuestionCount > 0 && (
              <TaskBox
                title={'Onboarding assessment'}
                desc={`${onBoardingQuestionCount} questions`}
                onClick={handleOnboardingPath}
              />
            )}
            {totalTask === 0 && <TaskBox title={'Log in'} onClick={handleHomepagePath} />}
          </div>
        </ContentLayout>
      </div>
    </NoAuthLayout>
  );
};

export default SuccessSignUpPage;
