import { FC } from 'react';
import { Skeleton } from 'antd';
import styles from './PsychologistSkeleton.module.scss';
import HealthBrightContentLayout from '../HealthBrightContentLayout/HealthBrightContentLayout';

const PsychologistListSkeleton: FC = () => {
  return (
    <div className={styles.listSkeletonContainer}>
      {[...Array(4)].map((_, i) => (
        <div key={i} className={styles.contentWrapper}>
          <HealthBrightContentLayout>
            <div className={styles.content}>
              <div className={styles.loadingWrapper}>
                <div className={styles.profileWrapper}>
                  <Skeleton.Avatar active size={70} className={styles.headerLoading} />
                  <div className={styles.bonaFidesLoadingWrapper}>
                    <Skeleton.Button active className={styles.bonaFidesLoading} />
                    <Skeleton.Button active className={styles.bonaFidesLoading} />
                  </div>
                </div>
                <div className={styles.detailsContainer}>
                  <Skeleton active />
                </div>
              </div>
            </div>
          </HealthBrightContentLayout>
        </div>
      ))}
    </div>
  );
};

export default PsychologistListSkeleton;
