import { ButtonHTMLAttributes } from 'react';
import classnames from 'classnames';
import { get } from 'lodash';
import { SyncOutlined, CheckOutlined } from '@ant-design/icons';
import {
  IS_CAW_APP,
  IS_EASE_APP,
  IS_RECHARGE_APP,
  IS_SELECT_APP,
  IS_SOMEONE_HEALTH_APP,
  IS_HEALTH_BRIGHT_APP
} from 'utils/hooks/AccountInfo/clientDetails';
import styles from './Button.module.scss';
import ButtonSH from '../../../SomeoneHealth/components/ButtonSH/ButtonSH';
import { ButtonAltSize } from '../../../SomeoneHealth/components/ButtonSH/ButtonSH';
import ButtonCaW from '../../../CaW/components/ButtonCaW/ButtonCaW';
import ButtonEase from 'Ease/components/ButtonEase/ButtonEase';
import ButtonRecharge from 'Recharge/components/ButtonRecharge/ButtonRecharge';
import ButtonSelect from 'Select/components/ButtonSelect/ButtonSelect';
import ButtonHB from 'HealthBright/components/ButtonHB/ButtonHB';

export type HelmBtnStatus = '' | 'active' | 'finished';
export interface ButtonProps {
  status?: HelmBtnStatus;
  variant?: 'secondary' | 'primary' | 'pink' | 'link';
  icon?: string;
  iconPostFix?: boolean;
  size?: ButtonAltSize;
}

const Button = ({
  status = '',
  variant,
  disabled,
  size,
  ...props
}: ButtonProps & ButtonHTMLAttributes<HTMLButtonElement>) => {
  const statusClass = get({ active: styles.active, finished: styles.finished }, status, '');
  const { className } = props;

  return IS_CAW_APP ? (
    <ButtonCaW
      {...props}
      status={status}
      disabled={disabled}
      variant={variant === 'secondary' ? 'outlined' : 'contained'}
      icon={props.icon}
      size={size}
    >
      {props.children}
    </ButtonCaW>
  ) : IS_SOMEONE_HEALTH_APP ? (
    <ButtonSH
      {...props}
      status={status}
      disabled={disabled}
      variant={variant === 'secondary' ? 'outlined' : 'contained'}
      icon={props.icon}
      size={size}
    >
      {props.children}
    </ButtonSH>
  ) : IS_EASE_APP ? (
    <ButtonEase
      {...props}
      status={status}
      disabled={disabled}
      variant={variant === 'secondary' ? 'outlined' : 'contained'}
      icon={props.icon}
      size={size}
    >
      {props.children}
    </ButtonEase>
  ) : IS_RECHARGE_APP ? (
    <ButtonRecharge
      {...props}
      status={status}
      disabled={disabled}
      variant={variant === 'secondary' ? 'outlined' : 'contained'}
      icon={props.icon}
      size={size}
    >
      {props.children}
    </ButtonRecharge>
  ) : IS_SELECT_APP ? (
    <ButtonSelect
      {...props}
      status={status}
      disabled={disabled}
      variant={variant === 'secondary' ? 'outlined' : 'contained'}
      icon={props.icon}
      size={size}
    >
      {props.children}
    </ButtonSelect>
  ) : IS_HEALTH_BRIGHT_APP ? (
    <ButtonHB
      {...props}
      status={status}
      disabled={disabled}
      variant={variant === 'secondary' ? 'outlined' : 'contained'}
      icon={props.icon}
      size={size}
    >
      {props.children}
    </ButtonHB>
  ) : (
    <button
      {...props}
      className={classnames(styles.container, className, statusClass, variant && styles[variant])}
      disabled={disabled || !!status}
    >
      <span className={styles.submit}>{props.children}</span>
      <span className={styles.loading}>
        <SyncOutlined />
      </span>
      <span className={styles.check}>
        <CheckOutlined />
      </span>
    </button>
  );
};

export default Button;
