import styles from './IP6ActivitiesCard.module.scss';
import Button from 'components/v2/Button/Button';
import { SessionActivityInterface } from 'helm/interfaces/IP6/ip6';
import classnames from 'classnames';
import { putIP6Activities } from 'helm/utils/http/ClinicianProfileService/IP6/ip6';
import { notification } from 'antd';
import { useGetAccessToken } from 'utils/hooks/token';
import { useState } from 'react';
import { useGetClientDetails } from 'utils/hooks/AccountInfo/clientDetails';
import moment from 'moment';
import LifeBalanceWheelChart from './LifeBalanceWheelChart/LifeBalanceWheelChart';

interface IP6ActivitiesCardProps {
  activitiesData: SessionActivityInterface[];
  className?: string;
  cardClassName?: string;
}

const IP6ActivitiesCard = ({ activitiesData, className, cardClassName }: IP6ActivitiesCardProps) => {
  const { token } = useGetAccessToken();
  const { clientRecordId, clientProfileId } = useGetClientDetails();
  const [activityStartDate, setActivityStartDate] = useState<Date>();

  const onClickLink = (link?: string) => {
    if (link) {
      window.location.href = link;
    }
  };

  const handleExploreActivity = async (id: string, link?: string) => {
    try {
      const response = await (await putIP6Activities(token, id, clientRecordId, clientProfileId)).json();
      setActivityStartDate(response.startedAt);
      onClickLink(link);
    } catch (ex) {
      console.error(ex);
      notification.error({ message: 'Something went wrong!' });
    }
  };

  const surveyResponseChart: { [key: string]: (arg0: SessionActivityInterface['surveyResponse']) => JSX.Element } = {
    lifeBalanceWheel: (response) => <LifeBalanceWheelChart surveyResponse={response} />
  };

  return (
    <div className={classnames(styles.container, className)}>
      {activitiesData.map((activityObj, index) => (
        <div key={index} className={classnames(styles.activityCard, cardClassName)}>
          {activityObj.completed ? (
            <>
              <div className={styles.completeCard}>
                <div className={styles.activityTitle}>
                  COMPLETED{' '}
                  <span className={styles.highlight}>ON {moment(activityObj.completedAt).format('DD MMM YYYY')}</span>
                </div>
                <div className={styles.name}>{activityObj.name}</div>
                <div className={styles.buttonWrapper}>
                  <Button onClick={() => onClickLink(activityObj.link)} variant={'secondary'} className={styles.button}>
                    Revisit this activity
                  </Button>
                </div>
              </div>
              {activityObj.surveyResponse &&
                activityObj.id in surveyResponseChart &&
                surveyResponseChart[activityObj.id](activityObj.surveyResponse)}
            </>
          ) : activityObj.link && (activityObj.startedAt || activityStartDate) ? (
            <div className={styles.checkBoxCard}>
              <div className={styles.activityTitle}>
                STARTED ON {moment(activityObj.startedAt).format('DD MMM YYYY')}
              </div>
              <div className={styles.name}>{activityObj.name}</div>
              <div className={styles.desc}>{activityObj.description}</div>
              <div className={styles.buttonWrapper}>
                <Button onClick={() => onClickLink(activityObj.link)} className={styles.button}>
                  Continue activity
                </Button>
              </div>
            </div>
          ) : (
            <div className={styles.checkBoxCard}>
              <div className={styles.name}>{activityObj.name}</div>
              <div className={styles.desc}>{activityObj.description}</div>
              <div className={styles.buttonWrapper}>
                <Button
                  onClick={() => handleExploreActivity(activityObj.id, activityObj.link)}
                  className={styles.button}
                >
                  Explore this activity
                </Button>
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default IP6ActivitiesCard;
