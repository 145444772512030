import { useState, UIEvent } from 'react';
import Cookies from 'js-cookie';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import tacklitLogo from 'assets/images/tacklit-logo-blue.svg';
import ContentLayout from 'components/ContentLayout/ContentLayout';
import AUPrivacy from './components/AUPrivacy';
import UKPrivacy from './components/UKPrivacy';
import Button from 'components/v2/Button/Button';
import Loading from 'components/Loading/Loading';
import { useFetchInvitedClinicianDetails } from 'pages/SignUp/hooks/getInvitedClinicianDetails';
import { useRoutesGenerator } from 'utils/Path/RoutesGenerator';

import styles from './PolicyPage.module.scss';

const PolicyPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [enableBtn, setEnableBtn] = useState(false);
  const { clinicianSlugUrlOrId = '' } = useParams() as { clinicianSlugUrlOrId: string };
  const { SIGNUP } = useRoutesGenerator(clinicianSlugUrlOrId);
  const { invitedClinicianDetail, isInvitedCLoading } = useFetchInvitedClinicianDetails(clinicianSlugUrlOrId);

  const handleNextPath = () => {
    if (enableBtn) {
      Cookies.set('signUpPolicy', 'true', { expires: 1 });
      navigate(`${SIGNUP.NEW}${location.search}`);
    }
  };

  const handleScroll = (e: UIEvent<HTMLElement>) => {
    const scrollTillBottom =
      e.currentTarget.scrollHeight - e.currentTarget.scrollTop <= e.currentTarget.clientHeight + 1;
    setEnableBtn(scrollTillBottom);
  };

  return isInvitedCLoading ? (
    <div className={styles.loading}>
      <Loading />
    </div>
  ) : (
    <div className={styles.container}>
      <ContentLayout className={styles.body}>
        <div className={styles.headerWrapper}>
          <div className={styles.header}>
            {(invitedClinicianDetail.practice?.logo || invitedClinicianDetail?.avatar) && (
              <img
                className={styles.logoWrapper}
                src={invitedClinicianDetail.practice?.logo || invitedClinicianDetail?.avatar}
                alt={'practiceLogo'}
              />
            )}
            <div className={styles.practiceName}>
              {invitedClinicianDetail.practice?.name || invitedClinicianDetail.name}
            </div>
          </div>
          <div className={styles.workingWithWrapper}>
            <div className={styles.label}>working securely with</div>
            <img className={styles.tacklitLogo} src={tacklitLogo} alt={'tacklitLogo'} />
          </div>
        </div>
        <div className={styles.policyContainer} onScroll={(e) => handleScroll(e)}>
          {process.env.REACT_APP_GAE_REGION === 'au' ? <AUPrivacy /> : <UKPrivacy />}
        </div>
        <div className={styles.buttonContainer}>
          <Button disabled={!enableBtn} className={styles.agreeBtn} onClick={() => handleNextPath()}>
            Agree and continue
          </Button>
        </div>
      </ContentLayout>
    </div>
  );
};

export default PolicyPage;
