import { useState } from 'react';
import { Link } from 'react-router-dom';
import styles from './CreateProfileForm.module.scss';
import * as Yup from 'yup';
import { Formik, FormikErrors } from 'formik';
import Form from 'antd/lib/form/Form';
import FormikInput from 'components/Input/FormikInput';
import HelmMobileFormikInput from 'helm/components/Input/HelmMobileFormikInput';
import FormikDatePicker from 'components/DatePicker/FormikDatePicker';
import CheckBox from 'components/CheckBox/CheckBox';
import Button from 'components/v2/Button/Button';
import { isEmpty, isEqual } from 'lodash';
import classnames from 'classnames';
import PasswordRules from 'pages/SignUp/SignUpForm/components/Security/components/PasswordRules/PasswordRules';
import { validatePhoneNumber } from 'pages/SignUp/SignUpForm/components/BasicDetails/validation/BasicDetailsValidation';
import { postPatientSignupWithoutInvitation } from 'utils/http/PatientProfileService/Patient/patient';
import { notification } from 'antd';
import Loading from 'components/Loading/Loading';
import { clientRecordsInterface, CommunicationPreference } from 'interfaces/Clients/clientsRecord';
import { useReserveAppointmentData } from 'helm/utils/hooks/localData';
import { timeZoneLocalStorage } from '../../../PractitionerListing/PractitionerListing';
import { isAUProduction, isDevelopmentENV } from 'utils/featureToggle/DevelopmentToggle';
import moment from 'moment';
import { IS_HELM_APP } from 'utils/hooks/AccountInfo/clientDetails';
import FormikSelect from '../../../../../components/Select/FormikSelect';
import { SpecialistTheme } from 'helm/interfaces/Filter/searchFilter';
import { toCapitalize } from 'utils/generateCamelCase';
import { helmEnvironment } from 'helm/utils/HelmEnv/helmEnv';
import { newHelmUpdate } from 'utils/featureToggle/featureToggle';
import { checkEmailAlreadyExisted } from 'utils/http/ClinicianProfileService/Accounts/checkEmailAlreadyExisted';
import { AU_TIME_ZONE_LIST } from 'utils/constants/timeZone';

interface CreateProfileFormProps {
  accountId: string;
  onComplete: (input: { clientRecord: clientRecordsInterface; authToken: string }) => void;
}

export const getAuMobile = (v: string) => (isAUProduction && v && !isDevelopmentENV() ? '+61' + v.slice(1) : `+${v}`);

export const validateData = Yup.object().shape({
  firstName: Yup.string().required('Please enter your first name'),
  lastName: Yup.string().required('Please enter your last name'),
  email: Yup.string().required('Please enter your email').email('Email must be in valid example@gmail.com format.'),
  theme: Yup.string().required('Please select the main focus area for you'),
  dateOfBirth: Yup.string()
    .required('Please choose your date of birth')
    .test('Age check', "Be sure that you're over the age of 18", (dob) =>
      moment(dob, 'YYYY-MM-DD').add(18, 'years').isBefore(moment())
    ),
  mobileNumber: Yup.string().required('Please enter your mobile number')
});

const initialCustomer = {
  firstName: '',
  lastName: '',
  email: '',
  mobileNumber: '',
  dateOfBirth: '',
  password: '',
  theme: ''
};

export const specialistList = Object.values(SpecialistTheme).map((obj) => ({
  value: obj,
  label:
    obj === SpecialistTheme.Emotions
      ? 'Handling Emotions'
      : obj === SpecialistTheme.All
      ? 'Other difficulties in life'
      : obj === SpecialistTheme.Relationship
      ? 'Relationship Concerns'
      : toCapitalize(obj)
}));

const CreateProfileForm = ({ accountId, onComplete }: CreateProfileFormProps) => {
  const [ageConfirm, setAgeConfirm] = useState(false);
  const URLs = helmEnvironment();
  // const [receiveMail, setReceiveMail] = useState(true);
  // const [receiveSMS, setReceiveSMS] = useState(true);
  const clientTimeZone = localStorage.getItem(timeZoneLocalStorage);
  const [showPwError, setShowPwError] = useState(false);
  const [pwScore, setPwScore] = useState(0);
  const [invalidMobileNumber, setInvalidMobileNumber] = useState(false);
  const [isUsedEmail, setIsUsedEmail] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { theme, clinicianId, appointmentTypeInfo } = useReserveAppointmentData();

  const validatePassword = Yup.object().shape({
    password: Yup.string()
      .required('Please set your password')
      .matches(/.*[!@#$%^&*-].*/, 'Password must meet requirements')
      .matches(/.*[A-Z].*/, 'Password must meet requirements')
      .matches(/.*[a-z].*/, 'Password must meet requirements')
      .matches(/.*[\d].*/, 'Password must meet requirements')
      .test('Password score', 'Week password!', () => pwScore === 4)
      .nullable()
  });

  const handleOnSubmit = async (values: typeof initialCustomer) => {
    const formatMobile = getAuMobile(values.mobileNumber);
    setIsSubmitting(true);
    const phoneValidate = await validatePhoneNumber(formatMobile);
    const checkEmailExisted = await checkEmailAlreadyExisted({
      accountId: accountId!,
      email: values.email
    });

    if (phoneValidate.valid) {
      if (checkEmailExisted?.response?.used === false)
        try {
          const { email, firstName, lastName, password } = values;
          const dob = moment(values.dateOfBirth).format('DD/MM/YYYY');
          // const communicationPreferenceLogic = receiveMail && receiveSMS
          //   ? CommunicationPreference.NoPreference
          //   : receiveMail
          //     ? CommunicationPreference.Email
          //     : receiveSMS
          //       ? CommunicationPreference.SMS
          //       : CommunicationPreference.NONE

          const payloadMassage = {
            clinicianId: clinicianId,
            clientRecord: {
              avatar: '',
              mobile: formatMobile,
              email,
              name: firstName,
              password,
              dateOfBirth: dob,
              firstName,
              lastName,
              postcode: '',
              tag: !newHelmUpdate ? theme : values.theme,
              timeZone: clientTimeZone || AU_TIME_ZONE_LIST[0].id,
              communicationPreference: CommunicationPreference.NoPreference,
              appointmentTypeId: appointmentTypeInfo?.sessionTypeId
            }
          };
          const callPatientSignup = await postPatientSignupWithoutInvitation(accountId, payloadMassage);
          setIsSubmitting(false);
          const { clientRecord, authToken } = await callPatientSignup.json();
          onComplete({ clientRecord, authToken });
        } catch (ex) {
          setIsSubmitting(false);
          console.error(ex);
          notification.error({
            message: 'Something went wrong while trying to signup account'
          });
        }
      else {
        setIsSubmitting(false);
        setIsUsedEmail(true);
      }
    } else {
      setIsSubmitting(false);
      setInvalidMobileNumber(true);
    }
  };

  const isButtonEnabled = (isValid: boolean, errors: FormikErrors<typeof initialCustomer>) => {
    if (!ageConfirm) {
      return false;
    }
    const hasOtherErrorThanPassword = Object.values({ ...errors, password: '' }).reduce(
      (res, item) => res || !!item,
      false
    );
    if (!isValid) {
      if (!showPwError) {
        return !hasOtherErrorThanPassword;
      } else return false;
    }
    return true;
  };

  return (
    <>
      {isSubmitting && (
        <div className={styles.loading}>
          <Loading />
        </div>
      )}
      <div className={styles.container}>
        <div className={styles.header}>
          Welcome to <span className={styles.highlight}>Helm</span>
        </div>
        <div className={styles.subHeader}>
          Create your <b>secure</b> account and take your first step towards a better future.
        </div>
        <div className={styles.loginText}>
          Already have an account?
          <Link to={`/?from=signup`} className={styles.loginLink}>
            Sign in instead
          </Link>
        </div>
        <Formik
          initialValues={initialCustomer}
          validationSchema={validateData.concat(validatePassword)}
          onSubmit={handleOnSubmit}
        >
          {({ values, errors, isValid, validateForm }) => (
            <Form noValidate>
              <div className={styles.fixCustomInput}>
                <FormikInput inputClass={styles.customInput} label="First Name*" name="firstName" required />
                <FormikInput inputClass={styles.customInput} label="Last Name*" name="lastName" required />
                <FormikDatePicker
                  inputClass={styles.customInput}
                  label="Date of birth*"
                  name="dateOfBirth"
                  placeholder="DD/MM/YYYY"
                  format="DD/MM/YYYY"
                />
                {newHelmUpdate && (
                  <FormikSelect
                    className={styles.customSelect}
                    labelClass={styles.customSelectLabel}
                    errorClassName={styles.customSelectError}
                    label={'I am seeking behavioural health support for*'}
                    placeholder={'Select main focus area'}
                    isSearchable={false}
                    name="theme"
                    ignoreTouched
                    options={specialistList}
                    styles={{
                      control: (controlBase: any) => ({
                        ...controlBase,
                        minHeight: '30px',
                        backgroundColor: 'transparent',
                        border: 'none',
                        borderRadius: 0,
                        boxShadow: 'none'
                      })
                    }}
                  />
                )}
                <HelmMobileFormikInput
                  inputClass={styles.customInput}
                  id={`mobileNumber`}
                  name={'mobileNumber'}
                  label={'Mobile number*'}
                  mobilePattern="____ ___ ___"
                  placeholder={IS_HELM_APP ? '0482 666 666' : ''}
                  phonePrefix={IS_HELM_APP ? '04' : ''}
                  onChange={() => invalidMobileNumber && setInvalidMobileNumber(false)}
                  isMobileValid={!invalidMobileNumber}
                  required
                />
                <FormikInput
                  inputClass={styles.customInput}
                  label="Email address*"
                  name="email"
                  required
                  externalError={isUsedEmail ? 'Email already in use' : ''}
                  onChange={() => isUsedEmail && setIsUsedEmail(false)}
                />
                <FormikInput
                  inputClass={styles.customInput}
                  label="Set Secure Password*"
                  name="password"
                  type="password"
                  required
                  showError={showPwError}
                />
              </div>
              <div className={classnames(styles.passwordHint, showPwError && styles.passwordHintWithError)}>
                It must meet the following password rules:
                <div className={styles.hints}>
                  <PasswordRules password={values.password} onChangePasswordScore={setPwScore} />
                </div>
              </div>

              <div className={styles.collectionNotice}>
                <div className={styles.title}>Collection Notice</div>
                <div className={styles.text}>
                  The information you provide to Caraniche, including any personal information as defined in the Privacy
                  Act 1988 (Cth) (Privacy Act) and information about actual or attempted access to our online platform
                  (including our websites and applications) will be used by Caraniche for the purposes of:
                  <ul>
                    <li>providing information and services to you;</li>
                    <li>ensuring that the website and application are working as they should;</li>
                    <li>and marketing.</li>
                  </ul>
                  We may also use your Personal Information for secondary purposes closely related to the primary
                  purpose, in circumstances where you would reasonably expect such use or disclosure (e.g. to
                  investigate complaints).
                  <br />
                  <br />
                  Caraniche may share this information with third parties (healthcare service providers, community
                  service providers, government agencies, and/or funding agencies) where you consent to the use or
                  disclosure for the purpose of facilitating care and to enable the payment for services; and where
                  required or authorised by law.
                  <br />
                  <br />
                  If Caraniche does not collect this information, it may impact the outcome of the services provided.
                  <br />
                  <br />
                  Information about the collection, use, disclosure and storage of personal information by Caraniche,
                  and information about how to contact us, is available in our{' '}
                  <a href={URLs.HelmPrivacyPolicy}>Privacy Statement</a>. This includes information on how you can
                  access and seek corrections to your personal information and about complaints procedures.
                </div>
              </div>
              <div className={styles.ageConfirm}>
                <div className={styles.checkBoxText}>
                  <CheckBox
                    id="ageConfirm"
                    value={ageConfirm}
                    onChange={(e) => setAgeConfirm(e.target.checked)}
                    className={styles.checkBox}
                    label={`I confirm I am over the age of 18 and agree to Helm's <a target="_blank" href=${URLs.HelmPrivacyPolicy}>Privacy Statement</a> and <a target="_blank" href=${URLs.HelmTermAndConditions}>Terms & Conditions</a>`}
                  />
                </div>
              </div>
              {/*<div className={styles.communicationPreferences}>*/}
              {/*  <div className={styles.title}>Communication Preferences</div>*/}
              {/*  <div className={styles.text}>*/}
              {/*    We will send you relevant information regarding your appointment via email and SMS. This includes*/}
              {/*    items like appointment reminders, and requests to complete documents relevant to your program. This is*/}
              {/*    not marketing.*/}
              {/*  </div>*/}
              {/*  <div className={styles.checkBoxes}>*/}
              {/*    <div className={styles.checkBoxText}>*/}
              {/*      <CheckBox*/}
              {/*        id="receiveMail"*/}
              {/*        value={receiveMail}*/}
              {/*        onChange={(e) => setReceiveMail(e.target.checked)}*/}
              {/*        className={styles.checkBox}*/}
              {/*        label="I am happy to receive emails from Helm"*/}
              {/*      />*/}
              {/*    </div>*/}
              {/*    <div className={styles.checkBoxText}>*/}
              {/*      <CheckBox*/}
              {/*        id="receiveSMS"*/}
              {/*        value={receiveSMS}*/}
              {/*        onChange={(e) => setReceiveSMS(e.target.checked)}*/}
              {/*        className={styles.checkBox}*/}
              {/*        label="I am happy to receive SMS from Helm"*/}
              {/*      />*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*</div>*/}
              <Button
                className={styles.submitButton}
                disabled={
                  !values.password ||
                  values.password.length < 2 ||
                  isEqual(values, initialCustomer) ||
                  !isButtonEnabled(isValid, errors)
                }
                onClick={() => {
                  setShowPwError(true);
                  validateForm().then((errors) => {
                    if (isEmpty(errors)) {
                      handleOnSubmit(values);
                    }
                  });
                }}
              >
                Create Your Profile
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default CreateProfileForm;
