import { useEffect, useMemo, useState } from 'react';
import styles from './PartnerPsychologistListing.module.scss';
import { useFetchPractitionerList } from './hooks/getPsychologistList';
import { useParams, generatePath, useLocation, useNavigate } from 'react-router-dom';
import { Modal } from 'antd';
import { BookingRuleType, useFetchClientBookingRule } from 'utils/hooks/clientRecords';
import { listInterface } from 'components/FilterDropdown/FilterDropdown';
import {
  AppointmentBookingClaimType,
  PractitionerDetailsInterface
} from 'interfaces/PublicProfile/Practitioner/practitioner';
import { useAuth0 } from '@auth0/auth0-react';
import queryString from 'query-string';
import { useHealthBrightPartnerRoutesGenerator } from '../../utils/Path/HealthBrightPartnerRoutesGenerator';
import { PARTNER_CODE_STORAGE } from '../../../../interfaces/PartnerReferral/constants';
import {
  getDaysFromQuery,
  sortWeekDays
} from '../../../../SomeoneHealth/pages/PsychologistListing/utils/getDaysFromQuery';
import { getTimeFilterInMelbourneTimezone } from '../../../../SomeoneHealth/pages/PsychologistListing/utils/getTimeFilterInMelbourneTimezone';
import {
  FitDaysList,
  FitDeliveryList,
  FitLanguageList,
  FitPsychologistGenderList,
  FitStyleList,
  FitTimesList
} from 'HealthBright/interfaces/fitFilter';
import HealthBrightHelmetWrapper from 'HealthBright/components/HealthBrightHelmetWrapper/HealthBrightHelmetWrapper';
import HealthBrightHeader from 'HealthBright/components/HealthBrightHeader/HealthBrightHeader';
import ListingFilter from 'HealthBright/components/HealthBrightFilter/ListingFilter/ListingFilter';
import FitFilter from 'HealthBright/components/HealthBrightFilter/FitFilter/FitFilter';
import JoinWaitlistForm from 'HealthBright/components/JoinWaitlistForm/JoinWaitlistForm';
import PsychologistListView from 'HealthBright/components/PsychologistView/PsychologistListView/PsychologistListView';
import PsychologistGridView from 'HealthBright/components/PsychologistView/PsychologistGridView/PsychologistGridView';
import HealthBrightFooter from 'HealthBright/components/HealthBrightFooter/HealthBrightFooter';
import { GRID_PSYCHOLOGIST_PAGE_SIZE, LIST_PSYCHOLOGIST_PAGE_SIZE, ViewMode } from './constants';
import { HEALTH_BRIGHT_TIME_ZONE_LIST, healthBrightTimeZoneLocalStorage } from 'utils/constants/timeZone';
import { AgeRangeList, GenderList, MentalHealthList } from 'HealthBright/interfaces/mentalHealth';

export interface PsychologistFitFilter {
  gender?: FitPsychologistGenderList;
  days?: FitDaysList[];
  time?: FitTimesList;
  deliveryMode?: FitDeliveryList;
  language?: FitLanguageList;
  style?: FitStyleList;
}

export interface PsychologistListingQuery extends PsychologistFitFilter {
  view?: ViewMode;
}

const PartnerPsychologistListing = () => {
  const { isAuthenticated } = useAuth0();
  const location = useLocation();
  const navigate = useNavigate();
  const queryParam = location.search;
  const healthBrightClientTimeZone = localStorage.getItem(healthBrightTimeZoneLocalStorage);
  const { filter } = useParams<{ filter: string }>();
  const getPathFilter = filter?.split('-') || [];

  const getMentalConcernFilter: MentalHealthList[] = Object.values(MentalHealthList).filter((obj) =>
    getPathFilter.includes(obj)
  ) as MentalHealthList[];
  const getAgeRangeFilter: AgeRangeList = Object.keys(AgeRangeList).filter((obj) =>
    getPathFilter.includes(obj)
  )[0] as AgeRangeList;
  const getGenderFilter: GenderList = Object.keys(GenderList).filter((obj) =>
    getPathFilter.includes(obj)
  )[0] as GenderList;

  const { gender, days, time, deliveryMode, language, style, view }: PsychologistListingQuery =
    queryString.parse(queryParam);

  const viewMode = view && [ViewMode.Grid, ViewMode.List].includes(view) ? view : ViewMode.Grid;
  const perPage = viewMode === ViewMode.Grid ? GRID_PSYCHOLOGIST_PAGE_SIZE : LIST_PSYCHOLOGIST_PAGE_SIZE;

  const [selectedMentalHealthConcern, setSelectedMentalHealthConcern] =
    useState<MentalHealthList[]>(getMentalConcernFilter);
  const [selectedAgeRange, setSelectedAgeRange] = useState<AgeRangeList | undefined>(getAgeRangeFilter);
  const [selectedGender, setSelectedGender] = useState<GenderList | undefined>(getGenderFilter);
  const [showWaitlistForm, setShowWaitlistForm] = useState(false);

  const [selectedFitFilter, setSelectedFitFilter] = useState<PsychologistFitFilter>({
    gender: gender || undefined,
    days: getDaysFromQuery(days) || undefined,
    time: time || undefined,
    deliveryMode: deliveryMode || undefined,
    language: language || undefined,
    style: style || undefined
  });
  const [selectedPractitioner, setSelectedPractitioner] = useState<PractitionerDetailsInterface | undefined>(undefined);

  useEffect(() => {
    // Listen to path change
    setSelectedMentalHealthConcern(getMentalConcernFilter);
    setSelectedAgeRange(getAgeRangeFilter);
    setSelectedGender(getGenderFilter);
    setSelectedFitFilter({
      gender: gender || undefined,
      days: getDaysFromQuery(days) || undefined,
      time: time || undefined,
      deliveryMode: deliveryMode || undefined,
      language: language || undefined,
      style: style || undefined
    });
    // will hit infinity loop if we listen to filter value change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const { PRACTITIONER } = useHealthBrightPartnerRoutesGenerator();

  const [selectedTimezone, setSelectedTimezone] = useState(
    healthBrightClientTimeZone ||
      // use browser timezone if browser timezone is supported
      HEALTH_BRIGHT_TIME_ZONE_LIST.find((tzObj) =>
        // eslint-disable-next-line new-cap
        tzObj.timezones.find((tzString) => tzString === Intl.DateTimeFormat().resolvedOptions().timeZone)
      )?.id ||
      // use default client timezone
      HEALTH_BRIGHT_TIME_ZONE_LIST.find((tzObj) => tzObj.id === process.env.REACT_APP_DEFAULT_CLIENT_TIMEZONE)?.id ||
      HEALTH_BRIGHT_TIME_ZONE_LIST[0].id
  );

  const getPartnerCode = localStorage.getItem(PARTNER_CODE_STORAGE) as AppointmentBookingClaimType;
  const clientAppointmentType: AppointmentBookingClaimType = getPartnerCode;

  const { clientBookingRule, isClientBookingRuleLoading } = useFetchClientBookingRule();
  const isNewClient = clientBookingRule === BookingRuleType.New || !isAuthenticated;

  const filterQuery = useMemo(
    () => ({
      ...selectedFitFilter,
      clientMentalHealthConcern: selectedMentalHealthConcern.join(','),
      clientAgeRange: selectedAgeRange,
      days: selectedFitFilter.days,
      deliveryMode: selectedFitFilter.deliveryMode,
      ...(selectedGender !== GenderList.genderNotListed && { clientGender: selectedGender }),
      claimType: clientAppointmentType,
      ...getTimeFilterInMelbourneTimezone({ time: selectedFitFilter.time, timezone: selectedTimezone }),
      isNewClient,
      perPage
    }),
    [
      clientAppointmentType,
      selectedAgeRange,
      selectedFitFilter,
      selectedGender,
      selectedMentalHealthConcern,
      selectedTimezone,
      isNewClient,
      perPage
    ]
  );

  const {
    psychologistList,
    isPractitionerListLoading,
    loadMorePsychologists,
    hasMorePsychologists,
    matchedSpecialisations
  } = useFetchPractitionerList({
    clientTimeZone: selectedTimezone,
    filterQuery,
    shouldFetch: !isClientBookingRuleLoading
  });

  const isPsychologistFound = useMemo(() => Boolean(psychologistList.length), [psychologistList]);

  const noResultFilterQuery = useMemo(
    () => ({
      claimType: clientAppointmentType,
      isNewClient,
      perPage
    }),
    [clientAppointmentType, isNewClient, perPage]
  );

  const { psychologistList: psychologistListNoResult } = useFetchPractitionerList({
    clientTimeZone: selectedTimezone,
    filterQuery: noResultFilterQuery
  });

  const handleChangeTimeZone = (val: string) => {
    setSelectedTimezone(val);
    localStorage.setItem(healthBrightTimeZoneLocalStorage, val);
  };

  const handleNavigatePath = (value: string[], queryParamValue?: PsychologistFitFilter) => {
    const generateFilterPath = value.filter((obj) => obj !== undefined).join('-');
    const newPath = generatePath(PRACTITIONER.LISTING_WITH_FILTER, {
      filter: generateFilterPath
    });
    const queryParam = queryParamValue || selectedFitFilter;
    const sortedQueryParam = {
      ...queryParam,
      view,
      days: queryParam.days?.length ? sortWeekDays(queryParam.days) : undefined
    };

    const generateQueryParam = queryString.stringify(sortedQueryParam, {
      sort: false,
      arrayFormat: 'comma'
    });
    navigate(`${newPath}${generateQueryParam ? `?${generateQueryParam}` : ''}`);
  };

  const handleMentalHealthChange = (value: listInterface) => {
    const checkIfDuplicate = selectedMentalHealthConcern.some((mhObj) => mhObj === value.id);

    const newCollection: MentalHealthList[] = checkIfDuplicate
      ? selectedMentalHealthConcern.filter((mhObj) => mhObj !== value.id)
      : [...selectedMentalHealthConcern, value.id as MentalHealthList];

    setSelectedMentalHealthConcern(() => newCollection);
    const newPathCollection = [...newCollection, selectedAgeRange, selectedGender] as string[];
    handleNavigatePath(newPathCollection);
  };

  const handleAgeRangeChange = (value: listInterface | undefined) => {
    const newAgeRangeValue = value?.id as AgeRangeList;
    setSelectedAgeRange(newAgeRangeValue);
    const newPathCollection = [...selectedMentalHealthConcern, newAgeRangeValue, selectedGender] as string[];
    handleNavigatePath(newPathCollection);
  };

  const handleResetValue = () => {
    setSelectedMentalHealthConcern([]);
    setSelectedAgeRange(undefined);
    setSelectedGender(undefined);
    handleNavigatePath([]);
  };

  const handleFitResetValue = () => {
    const defaultValue: PsychologistFitFilter = {
      gender: undefined,
      days: undefined,
      time: undefined,
      deliveryMode: undefined,
      language: undefined,
      style: undefined
    };
    setSelectedFitFilter(defaultValue);
    const newPathCollection = [...selectedMentalHealthConcern, selectedAgeRange, selectedGender] as string[];
    handleNavigatePath(newPathCollection, defaultValue);
  };

  const clearAllFilter = () => {
    setSelectedMentalHealthConcern([]);
    setSelectedAgeRange(undefined);
    setSelectedGender(undefined);
    const defaultValue: PsychologistFitFilter = {
      gender: undefined,
      days: undefined,
      time: undefined,
      deliveryMode: undefined,
      language: undefined,
      style: undefined
    };
    setSelectedFitFilter(defaultValue);
    handleNavigatePath([], defaultValue);
  };

  const handleChangeFit = (fitValue: PsychologistFitFilter) => {
    setSelectedFitFilter(fitValue);
    const newPathCollection = [...selectedMentalHealthConcern, selectedAgeRange, selectedGender] as string[];
    handleNavigatePath(newPathCollection, fitValue);
  };

  const handleOpenWaitListModal = (practitioner: PractitionerDetailsInterface) => {
    setSelectedPractitioner(practitioner);
    setShowWaitlistForm(true);
  };

  return (
    <HealthBrightHelmetWrapper title={'HealthBright - Talk to someone about your mental health today'} hubspot>
      <div className="health-bright-theme">
        <div className={styles.headerContainer}>
          <div className={styles.header}>
            <HealthBrightHeader noLogin />
            <div className={styles.headerText}>Our Psychologists</div>
          </div>
          <ListingFilter
            selectedMentalHealthConcern={selectedMentalHealthConcern}
            onChangeMentalHealthConcern={handleMentalHealthChange}
            selectedAgeRange={selectedAgeRange}
            onChangeAgeRange={handleAgeRangeChange}
            handleResetValue={handleResetValue}
            isLoading={isPractitionerListLoading}
          />
        </div>
        <FitFilter
          selectedFitValue={selectedFitFilter}
          onChangeFitValue={handleChangeFit}
          handleChangeTimeZone={handleChangeTimeZone}
          selectedTimezone={selectedTimezone}
          handleFitResetValue={handleFitResetValue}
          isLoading={isPractitionerListLoading}
        />
        {viewMode === ViewMode.List ? (
          <PsychologistListView
            isLoading={isPractitionerListLoading || isClientBookingRuleLoading}
            isPsychologistFound={isPsychologistFound}
            psychologistList={isPsychologistFound ? psychologistList : psychologistListNoResult}
            clearAllFilter={clearAllFilter}
            clientAppointmentType={clientAppointmentType}
            handleOpenWaitListModal={handleOpenWaitListModal}
            hasMorePsychologists={hasMorePsychologists}
            isNewClient={isNewClient}
            loadMorePsychologists={loadMorePsychologists}
            matchedSpecialisations={matchedSpecialisations}
            selectedFitFilter={selectedFitFilter}
            selectedMentalHealthConcern={selectedMentalHealthConcern}
          />
        ) : (
          <PsychologistGridView
            isLoading={isPractitionerListLoading || isClientBookingRuleLoading}
            isPsychologistFound={isPsychologistFound}
            psychologistList={isPsychologistFound ? psychologistList : psychologistListNoResult}
            clientAppointmentType={clientAppointmentType}
            handleOpenWaitListModal={handleOpenWaitListModal}
            hasMorePsychologists={hasMorePsychologists}
            isNewClient={isNewClient}
            loadMorePsychologists={loadMorePsychologists}
            matchedSpecialisations={matchedSpecialisations}
            selectedMentalHealthConcern={selectedMentalHealthConcern}
            clearAllFilter={clearAllFilter}
          />
        )}
        <HealthBrightFooter />

        {/* Join waitlist modal */}
        <Modal
          width={700}
          visible={showWaitlistForm && !!selectedPractitioner}
          onCancel={() => {
            setShowWaitlistForm(false);
          }}
          bodyStyle={{ padding: '40px 80px', top: 50 }}
          footer={null}
          destroyOnClose
        >
          {selectedPractitioner && (
            <JoinWaitlistForm
              onComplete={() => {
                setShowWaitlistForm(false);
              }}
              practitionerDetails={selectedPractitioner}
            />
          )}
        </Modal>
      </div>
    </HealthBrightHelmetWrapper>
  );
};

export default PartnerPsychologistListing;
