import { ConsentList } from 'pages/ConsentForm/ConsentFormSignUp/ConsentList';
import styles from './ConsentListWrapper.module.scss';
import SomeoneHealthContentLayout from 'SomeoneHealth/components/SomeoneHealthContentLayout/SomeoneHealthContentLayout';
import SomeoneHealthHeader from 'SomeoneHealth/components/SomeoneHealthHeader/SomeoneHealthHeader';

const ConsentListWrapper = () => (
  <div className={styles.container}>
    <SomeoneHealthContentLayout className={styles.headerContent}>
      <div className={styles.headerWrapper}>
        <SomeoneHealthHeader withPadding withMenu />
      </div>
    </SomeoneHealthContentLayout>
    <SomeoneHealthContentLayout className={styles.content}>
      <ConsentList />
    </SomeoneHealthContentLayout>
  </div>
);

export default ConsentListWrapper;
