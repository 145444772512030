import { ClientBrand } from 'interfaces/ClientBrand';
import { Invoice } from 'interfaces/Invoice/Invoice';

export const shouldShowStripePayment = ({
  clientBrand,
  status
}: {
  clientBrand: ClientBrand;
  status: Invoice['status'];
}): Boolean =>
  ![ClientBrand.HELM, ClientBrand.EASE].includes(clientBrand) &&
  (clientBrand !== ClientBrand.SOMEONE_HEALTH || status !== 'confirmPaid');
