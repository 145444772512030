import { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import Button from 'components/v2/Button/Button';
import TwoFAInput from 'components/v2/TwoFAInput/TwoFAInput';
import ResendOtp from './components/ResendOtp/ResendOtp';

import { useSendOtp } from '../../hooks/useSendOtp';

import styles from './NotAuthCard.module.scss';
import { useTranslation } from 'react-i18next';

interface NotAuthCardProps {
  consentId: string;
  allowLogin: boolean;
  isOtpVerified: boolean;
  isOtpVerifiedLoading: boolean;
  setIsConsentFormCompleted: (completed: boolean) => void;
  verifyOtp: (otp: number) => Promise<boolean>;
}

const NotAuthCard = ({
  consentId,
  allowLogin,
  isOtpVerified,
  isOtpVerifiedLoading,
  verifyOtp,
  setIsConsentFormCompleted
}: NotAuthCardProps) => {
  const { loginWithRedirect } = useAuth0();

  const { isOtpSent, isOtpSentLoading, sendOtp } = useSendOtp(consentId, setIsConsentFormCompleted);

  const [otp, setOtp] = useState<string[]>(['', '', '', '', '', '']);
  const [otpError, setOtpError] = useState('false');
  const [isOtpTouched, setIsOtpTouched] = useState(false);
  const [t] = useTranslation();

  const onClickLogin = () => {
    loginWithRedirect({ loginType: 'patient', appState: { returnTo: window.location.pathname } });
  };

  const handleChangeOtp = async (newOtp: typeof otp) => {
    setOtp(newOtp);

    if (newOtp.length === 6) {
      setOtpError('');
    }
  };

  const handleSubmitOtp = async (otp: string) => {
    setIsOtpTouched(true);

    if (otp.length !== 6) {
      setOtpError('The OTP must be 6 numbers long');
      return;
    }

    const otpNumber = Number(otp);

    if (isNaN(otpNumber)) {
      setOtpError('The OTP consists of numbers only');
      return;
    }

    const otpVerified = await verifyOtp(otpNumber);

    if (!otpVerified) {
      setOtpError('Invalid OTP. Please try again');
    }
  };

  return (
    <div className={styles.container}>
      {isOtpSent ? (
        <>
          <div className={styles.description}>
            A one-time-password has been sent to your mobile number. Please enter the six-digit verification code below.
          </div>
          <TwoFAInput
            error={otpError}
            value={otp}
            isTouched={isOtpTouched}
            isVerified={isOtpVerified}
            isVerifying={isOtpVerifiedLoading}
            onChangeValue={handleChangeOtp}
            onSubmitOtp={handleSubmitOtp}
          />
          <ResendOtp sendOtp={sendOtp} />
        </>
      ) : (
        <>
          <div className={styles.header}>Welcome.</div>
          <div className={styles.description}>
            {allowLogin
              ? t('consent_form.request_one_time_password_allow_login')
              : t('consent_form.request_one_time_password')}
            .
          </div>
          <div className={styles.buttonsContainer}>
            <Button
              className={styles.otpButton}
              variant="primary"
              status={isOtpSent ? 'finished' : isOtpSentLoading ? 'active' : ''}
              disabled={isOtpSent || isOtpSentLoading}
              onClick={sendOtp}
            >
              Request OTP
            </Button>
            {allowLogin && (
              <>
                <div className={styles.or}>
                  <span className={styles.text}>or</span>
                </div>
                <Button className={styles.loginButton} variant="secondary" onClick={onClickLogin}>
                  Log in
                </Button>
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default NotAuthCard;
