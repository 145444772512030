import { Invoice } from 'interfaces/Invoice/Invoice';
import styles from './ItemsList.module.scss';

const GAE_REGION = process.env.REACT_APP_GAE_REGION;
const MONEY_SYMBOL = GAE_REGION === 'au' ? '$' : GAE_REGION === 'gb' ? '£' : undefined;
const TAX_LABEL = GAE_REGION === 'au' ? 'TAX' : GAE_REGION === 'gb' ? 'VAT' : undefined;

const calculateDiscount = (items: Invoice['items'], discountType?: 'percent' | 'amount', discountValue?: number) => {
  if (!discountType || discountValue === undefined || isNaN(Number(discountValue))) {
    return undefined;
  }

  const totalCost = items
    .map((item) => Number(item.cost))
    .reduce((finalTotal, currentCost) => finalTotal + currentCost, 0);

  if (discountType === 'percent' && discountValue <= 100) {
    return (totalCost * Math.round(discountValue)) / 100;
  } else if (discountType === 'amount' && discountValue <= totalCost) {
    return discountValue;
  }
};

interface ItemsListProps {
  discount: Invoice['discount'];
  items: Invoice['items'];
  taxRate: Invoice['taxRate'];
}

const ItemsList = ({ discount, items, taxRate }: ItemsListProps) => {
  const discountValue = calculateDiscount(items, discount?.type, discount?.value);

  return (
    <div className={styles.container}>
      <div className={styles.title}>Items</div>
      <div className={styles.content}>
        <div className={styles.headingContainer}>
          <span className={styles.heading}>#</span>
          <span className={styles.heading}>OVERVIEW</span>
          <span className={styles.heading}>COST</span>
          <span className={styles.heading}>
            {TAX_LABEL}
            {taxRate ? ` (${taxRate}%)` : ''}
          </span>
          <span className={styles.heading}>TOTAL</span>
        </div>
        {items.map(({ cost, tax, total, overview, tags = [] }, index) => (
          <div className={styles.itemContainer} key={index}>
            <span className={styles.index}>{index + 1}</span>
            <span className={styles.label}>
              {overview}
              {tags.length > 0 && (
                <div className={styles.tags}>
                  {tags.map((status, index) => (
                    <div className={styles.tag} key={index}>
                      {status}
                    </div>
                  ))}
                </div>
              )}
            </span>
            <span className={styles.cost}>
              {MONEY_SYMBOL}
              {cost.toFixed(2)}
            </span>
            <span className={styles.tax}>
              {MONEY_SYMBOL}
              {tax.toFixed(2)}
            </span>
            <span className={styles.total}>
              {MONEY_SYMBOL}
              {total.toFixed(2)}
            </span>
          </div>
        ))}
        {discount && (
          <div className={styles.discountContainer}>
            <span className={styles.label}>Discount</span>
            <span className={styles.discount}>
              - {MONEY_SYMBOL}
              {discountValue?.toFixed(2)}
            </span>
            <div className={styles.discount} />
            <div className={styles.discount} />
          </div>
        )}
      </div>
    </div>
  );
};

export default ItemsList;
