import CheckBox from 'components/CheckBox/CheckBox';
import { useContext, useEffect, useRef, useState } from 'react';
import BookingSummary from './components/BookingSummary/BookingSummary';
import { reservedHBPartnerReferral, useReserveAppointmentData } from 'utils/hooks/EngageReserved/reservedAppointment';
import styles from './PartnerBookingSummary.module.scss';
import { message } from 'antd';
import { useNavigate } from 'react-router-dom';
import { fetchClaimingReservation } from 'utils/hooks/EngageReserved/useClaimReservationWithoutCheckoutSession';
import { useHealthBrightPartnerRoutesGenerator } from '../../utils/Path/HealthBrightPartnerRoutesGenerator';
import { putProcessWithoutPayment } from 'utils/http/appointment';
import ButtonHB, { ButtonStatusType } from 'HealthBright/components/ButtonHB/ButtonHB';
import HealthBrightHelmetWrapper from 'HealthBright/components/HealthBrightHelmetWrapper/HealthBrightHelmetWrapper';
import HealthBrightHeader from 'HealthBright/components/HealthBrightHeader/HealthBrightHeader';
import HealthBrightContentLayout from 'HealthBright/components/HealthBrightContentLayout/HealthBrightContentLayout';
import HealthBrightTimer from 'HealthBright/components/HealthBrightTimer/HealthBrightTimer';
import { UserContext } from 'utils/UserContextProvider';
import { PARTNER_HB_SIGN_UP_USER, retrieveUserSession } from 'utils/userSession';
import { useHealthBrightToken } from 'HealthBright/utils/hooks/useHealthBrightToken';
import { useFetchReservedAppointmentsWithTimeZone } from 'HealthBright/utils/hooks/appointment';
import { HEALTH_BRIGHT_TIME_ZONE_LIST, healthBrightTimeZoneLocalStorage } from 'utils/constants/timeZone';
import Loading from 'components/Loading/Loading';

const PartnerBookingSummary = () => {
  const clientTimeZone = localStorage.getItem(healthBrightTimeZoneLocalStorage) || HEALTH_BRIGHT_TIME_ZONE_LIST[0].id;

  const { token } = useHealthBrightToken(PARTNER_HB_SIGN_UP_USER);
  const navigate = useNavigate();
  const { clientProfile } = useContext(UserContext);

  const { SIGNUP } = useHealthBrightPartnerRoutesGenerator();
  const [policyAccepted, setPolicyAccepted] = useState(false);
  const [mobileTimer, setMobileTimer] = useState<boolean>(false);
  const [confirmBookingStatus, setConfirmBookingStatus] = useState<ButtonStatusType>('');

  const bookingContentRef = useRef<HTMLDivElement>(null);

  const retrieveUserProfile = retrieveUserSession(PARTNER_HB_SIGN_UP_USER).clientRecord?.clientProfiles || [];
  const { reserveId, accountId } = useReserveAppointmentData(reservedHBPartnerReferral);
  const { fetching, appointments } = useFetchReservedAppointmentsWithTimeZone({
    reserveId: reserveId!,
    accountId: accountId!,
    timeZone: clientTimeZone
  });

  useEffect(() => {
    const handleResize = () => {
      if (bookingContentRef.current !== null) {
        const { width } = bookingContentRef.current.getBoundingClientRect();
        setMobileTimer(width <= 992);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleConfirmBooking = async () => {
    if (reserveId && accountId && token) {
      setConfirmBookingStatus('active');
      try {
        await fetchClaimingReservation(reserveId, accountId, token);

        const res = await putProcessWithoutPayment(accountId, reserveId, token);

        if (res.statusCode === 204) {
          setConfirmBookingStatus('finished');
          navigate(SIGNUP.CONFIRM_BOOKING);
        }
        setConfirmBookingStatus('');
      } catch (ex) {
        console.error(ex);
        message.error('Something went wrong while trying to submit your appointment request. Please try again');
        setConfirmBookingStatus('');
      }
    } else {
      console.error(`Something is missing: reserveId: ${reserveId}, accountId: ${accountId}, token: ${token}`);
      message.error('Something went wrong while trying to submit your appointment request. Please try again.');
    }
  };

  return (
    <div ref={bookingContentRef}>
      <HealthBrightHelmetWrapper title={'HealthBright - Sign Up'}>
        <HealthBrightHeader noLogin loginTextClass={styles.loginTextClass} />
        <HealthBrightContentLayout className="health-bright-theme">
          {fetching ? (
            <div className={styles.loadingContainer}>
              <Loading />
            </div>
          ) : (
            <div className={styles.container}>
              <div className={styles.content}>
                <div className={styles.leftContent}>
                  <div className={styles.greet}>You are about to confirm the following</div>
                  <div className={styles.subHeader}>
                    <div className={styles.name}>
                      This is the appointment being held for{' '}
                      <strong>{clientProfile ? clientProfile.firstName : retrieveUserProfile?.[0]?.firstName}</strong>
                    </div>
                    <br />
                    <div className={styles.desc}>
                      It will not be confirmed unless you complete within the required time limit
                    </div>
                  </div>
                  <BookingSummary
                    isReservedAppointmentFetching={fetching}
                    reservedAppointments={appointments}
                    containerClassName={styles.summaryContainer}
                    contentClassName={styles.summaryContent}
                  />
                  <div className={styles.cancellationPolicy}>
                    <div className={styles.title}>IMPORTANT INFORMATION</div>
                    <p className={styles.content}>
                      We are providing your information securely to HealthBright to facilitate the delivery of this
                      appointment. They will provide you with more details before your session commences
                    </p>
                  </div>

                  <CheckBox
                    id="policyAccepted"
                    value={policyAccepted}
                    onChange={(event) => setPolicyAccepted(event.target.checked)}
                    className={styles.checkBox}
                    label="I have read the important information to the client and they have acknowledged and accepted"
                  />

                  <ButtonHB
                    status={confirmBookingStatus}
                    onClick={handleConfirmBooking}
                    disabled={!policyAccepted || !token}
                    className={styles.submitButton}
                  >
                    Confirm Appointment Booking
                  </ButtonHB>
                </div>
                <div className={styles.rightContent}>
                  <div className={styles.notice}>
                    <HealthBrightTimer
                      isReservedAppointmentFetching={fetching}
                      reservedAppointments={appointments}
                      onlyText={mobileTimer}
                      reversedAppointmentId={reservedHBPartnerReferral}
                    />
                    <div className={styles.noticeMsg}>
                      <div>
                        To confirm these appointments you need to complete registration and payment within 15 minutes
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </HealthBrightContentLayout>
      </HealthBrightHelmetWrapper>
    </div>
  );
};

export default PartnerBookingSummary;
