import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { notification } from 'antd';
import queryString from 'query-string';
import { useLocation, useParams } from 'react-router-dom';

import { SignatureInterface } from 'interfaces/ConsentForm/consentForm';

import { postAdHocConsentForm } from 'utils/http/DocumentService/ConsentForm/consentForm';

import ContentLayout from 'components/ContentLayout/ContentLayout';
import LoadingCircle from 'components/LoadingCircle/LoadingCircle';
import ConsentContent from '../components/ConsentContent/ConsentContent';
import DoneCard from './components/DoneCard/DoneCard';
import NotAuthCard from './components/NotAuthCard/NotAuthCard';

import { useConsentFormApi } from './hooks/useConsentFormApi';
import { useVerifyOtp } from './hooks/useVerifyOtp';

import styles from './AdHocConsentForm.module.scss';
import classNames from 'classnames';
import CORDSDoneCard from 'CORDS/components/CORDSDoneCard/CORDSDoneCard';
import {
  IS_CORDS_APP,
  IS_EASE_APP,
  IS_RECHARGE_APP,
  IS_SELECT_APP,
  IS_SOMEONE_HEALTH_APP
} from 'utils/hooks/AccountInfo/clientDetails';

interface AdHocConsentFormProps {
  containerClassName?: string;
  contentClassName?: string;
  loadingClassName?: string;
}

const AdHocConsentForm = ({ containerClassName, contentClassName, loadingClassName }: AdHocConsentFormProps) => {
  const { isLoading, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const { consentId = '' } = useParams<{ consentId: string }>();
  const { search } = useLocation();
  const { allowLogin, token: queryParamToken } = queryString.parse(search) as { allowLogin?: string; token?: string };

  const [isConsentFormCompleted, setIsConsentFormCompleted] = useState(false);

  const { consentForm, isConsentFormLoading, fetchConsentForm, setConsentForm } = useConsentFormApi(
    consentId,
    setIsConsentFormCompleted,
    queryParamToken
  );
  const { otpToken, isOtpVerified, isOtpVerifiedLoading, verifyOtp } = useVerifyOtp(
    consentId,
    setIsConsentFormCompleted
  );

  const [submitBtnStatus, setSubmitBtnStatus] = useState<'' | 'active' | 'finished'>('');
  const [errorMessage, setErrorMessage] = useState({
    clientSignature: false,
    otherSignature: false
  });

  useEffect(() => {
    if (otpToken) {
      fetchConsentForm(otpToken);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otpToken]);

  const handleChangeClientSignature = (signVal: SignatureInterface) => {
    const newConsentDetail = {
      ...consentForm!,
      signature: {
        ...consentForm!.signature,
        client: signVal
      }
    };
    const newErrorMessage = {
      ...errorMessage,
      clientSignature: !(signVal.textVal || signVal.drawVal)
    };
    setErrorMessage(newErrorMessage);
    setConsentForm(newConsentDetail);
  };

  const handleChangeOtherSignature = (signVal: SignatureInterface) => {
    const newConsentDetail = {
      ...consentForm!,
      signature: {
        ...consentForm!.signature,
        other: signVal
      }
    };
    const newErrorMessage = {
      ...errorMessage,
      otherSignature: !(signVal.textVal || signVal.drawVal)
    };
    setErrorMessage(newErrorMessage);
    setConsentForm(newConsentDetail);
  };

  const validation = () => {
    if (!consentForm) {
      return false;
    }

    const clientSignatureValidate = consentForm.signature.client
      ? consentForm.signature.client.type &&
        (consentForm.signature.client.textVal || consentForm.signature.client.drawVal)
      : true;
    const otherSignatureValidate = consentForm.signature.other
      ? consentForm.signature.other.type && (consentForm.signature.other.textVal || consentForm.signature.other.drawVal)
      : true;

    const errorMessage = {
      clientSignature: !clientSignatureValidate,
      otherSignature: !otherSignatureValidate
    };
    setErrorMessage(errorMessage);
    return clientSignatureValidate && otherSignatureValidate;
  };

  const handleSubmitConsentForm = async () => {
    if (validation()) {
      setSubmitBtnStatus('active');
      try {
        const token =
          otpToken ||
          queryParamToken ||
          (await getAccessTokenSilently({
            audience: process.env.REACT_APP_API_AUDIENCE
          }));

        await postAdHocConsentForm(token, consentId, consentForm);

        setSubmitBtnStatus('finished');
        setIsConsentFormCompleted(true);

        notification.success({
          message: 'Consent form submitted',
          duration: 2,
          closeIcon: <span className="success">OK</span>
        });

        setTimeout(() => {
          setSubmitBtnStatus('');
        }, 2000);
      } catch (ex) {
        console.error(ex);

        setSubmitBtnStatus('');

        notification.error({
          message: 'Something went wrong while trying to submit your consent form. Please try again',
          duration: 2,
          closeIcon: <span className="success">OK</span>
        });
      }
    }
  };

  return (
    <div
      className={classNames(
        IS_SOMEONE_HEALTH_APP && 'someone-health-theme',
        IS_EASE_APP && 'ease-theme',
        IS_RECHARGE_APP && 'recharge-theme',
        IS_SELECT_APP && 'select-theme'
      )}
    >
      {isAuthenticated && isLoading ? (
        <div className={classNames(styles.loading, loadingClassName)}>
          <LoadingCircle />
        </div>
      ) : (
        <div className={classNames(styles.container, containerClassName)}>
          {isConsentFormCompleted && IS_CORDS_APP ? (
            <div className={styles.cordsDoneContainer}>
              <CORDSDoneCard title={'Thank you for signing the consent form'} />
            </div>
          ) : (
            <ContentLayout className={classNames(styles.content, contentClassName)}>
              {isConsentFormCompleted ? (
                !IS_CORDS_APP && <DoneCard />
              ) : isConsentFormLoading || consentForm ? (
                <div className={styles.contentContainer}>
                  {isConsentFormLoading && (
                    <div className={classNames(styles.loading, loadingClassName)}>
                      <LoadingCircle />
                    </div>
                  )}
                  {consentForm && (
                    <ConsentContent
                      consentFormDetail={consentForm}
                      handleChangeClientSignature={handleChangeClientSignature}
                      handleChangeOtherSignature={handleChangeOtherSignature}
                      handleSubmitConsentForm={handleSubmitConsentForm}
                      submitBtnStatus={submitBtnStatus}
                      errorMessage={errorMessage}
                      noBackground={IS_CORDS_APP}
                    />
                  )}
                </div>
              ) : (
                <NotAuthCard
                  consentId={consentId}
                  allowLogin={!!allowLogin}
                  isOtpVerified={isOtpVerified}
                  isOtpVerifiedLoading={isOtpVerifiedLoading}
                  setIsConsentFormCompleted={setIsConsentFormCompleted}
                  verifyOtp={verifyOtp}
                />
              )}
            </ContentLayout>
          )}
        </div>
      )}
    </div>
  );
};

export default AdHocConsentForm;
