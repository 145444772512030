import { Routes } from 'react-router-dom';
import appRoute from 'components/AppRoute/AppRoute';
import { UserContextProvider } from 'utils/UserContextProvider';
import { useHealthBrightPartnerRoutesGenerator } from 'HealthBright/HealthBrightPartner/utils/Path/HealthBrightPartnerRoutesGenerator';
import TokenAccess from 'HealthBright/HealthBrightPartner/pages/TokenAccess/TokenAccess';
import PartnerPsychologistListing from 'HealthBright/HealthBrightPartner/pages/PartnerPsychologistListing/PartnerPsychologistListing';
import PartnerPsychologistDetails from 'HealthBright/HealthBrightPartner/pages/PartnerPsychologistDetails/PartnerPsychologistDetails';
import PartnerSignUp from 'HealthBright/HealthBrightPartner/pages/PartnerSignUp/PartnerSignUp';
import PartnerBookingSummary from 'HealthBright/HealthBrightPartner/pages/PartnerBookingSummary/PartnerBookingSummary';
import PartnerConfirmPage from 'HealthBright/HealthBrightPartner/pages/PartnerConfirmPage/PartnerConfirmPage';
import AccessTokenAuthWrapper from '../../HealthBright/HealthBrightPartner/components/AccessTokenAuthWrapper/AccessTokenAuthWrapper';
import HealthBrightWrapper from 'HealthBright/components/HealthBrightWrapper/HealthBrightWrapper';

const HealthBrightPartnerRoutes = () => {
  const { TOKEN, PRACTITIONER, SIGNUP } = useHealthBrightPartnerRoutesGenerator();

  return (
    <UserContextProvider>
      <Routes>
        {appRoute({
          path: TOKEN.BASE,
          component: TokenAccess,
          layout: (props) => <HealthBrightWrapper {...props} noFooter />
        })}
        {appRoute({
          path: PRACTITIONER.LISTING,
          component: PartnerPsychologistListing,
          layout: AccessTokenAuthWrapper
        })}
        {appRoute({
          path: PRACTITIONER.LISTING_WITH_FILTER,
          component: PartnerPsychologistListing,
          layout: AccessTokenAuthWrapper
        })}
        {appRoute({
          path: PRACTITIONER.DETAILS,
          component: PartnerPsychologistDetails,
          layout: AccessTokenAuthWrapper
        })}
        {appRoute({ path: SIGNUP.BASE, component: PartnerSignUp, layout: AccessTokenAuthWrapper })}
        {appRoute({ path: SIGNUP.SUMMARY_BOOKING, component: PartnerBookingSummary, layout: AccessTokenAuthWrapper })}
        {appRoute({ path: SIGNUP.CONFIRM_BOOKING, component: PartnerConfirmPage, layout: AccessTokenAuthWrapper })}
      </Routes>
    </UserContextProvider>
  );
};

export default HealthBrightPartnerRoutes;
