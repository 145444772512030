import classNames from 'classnames';
import styles from './HealthBrightContentLayout.module.scss';

interface HealthBrightContentLayoutProps {
  children?: any;
  className?: string;
}

const HealthBrightContentLayout = ({ children, className }: HealthBrightContentLayoutProps) => {
  return <div className={classNames(styles.contentLayout, className)}>{children}</div>;
};

export default HealthBrightContentLayout;
