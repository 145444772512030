import { listInterface } from 'CaW/components/CaWFilter/components/FilterDropdown/FilterDropdown';
import { CAW_MENTAL_HEALTH_LIST_LABELS, CaWMentalHealthList } from 'CaW/interfaces/mentalHealth';

import styles from './FilterDropdownWrapper.module.scss';
import FilterDropdownMultiSelect from 'CaW/components/CaWFilter/components/FilterDropdown/FilterDropdownMultiSelect/FilterDropdownMultiSelect';
import classnames from 'classnames';

const generateMentalHealthList = () => {
  return Object.values(CaWMentalHealthList).map((item) => ({
    id: item,
    label: CAW_MENTAL_HEALTH_LIST_LABELS[item]
  }));
};

interface FilterDropdownWrapperProps {
  classNames?: string;
  mentalHealthConcernValue?: listInterface[];
  onChangeMentalHealthConcernValue: (value: listInterface) => void;
}

const FilterDropdownWrapper = ({
  classNames,
  mentalHealthConcernValue,
  onChangeMentalHealthConcernValue
}: FilterDropdownWrapperProps) => (
  <div className={classnames(styles.container, classNames)}>
    <FilterDropdownMultiSelect
      id={'mentalValue'}
      label={'I’m here because…'}
      listData={generateMentalHealthList()}
      onChangeValue={onChangeMentalHealthConcernValue}
      selectedMultipleValue={mentalHealthConcernValue || []}
      className={styles.mentalHealthConcern}
      withOtherSection
      withSearch
    />
  </div>
);

export default FilterDropdownWrapper;
