import { useState } from 'react';
import { Menu, Dropdown, Skeleton } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import Masonry from 'react-masonry-css';

import JournalEntryCard from './components/JournalEntryCard/JournalEntryCard';

import styles from './MicroJournalEntries.module.scss';
import { useFetchMicroJournalEntries } from 'helm/pages/IP6Page/hooks/getMicroJournalEntries';

const DATE_RANGE_OPTIONS = [
  { label: 'All', value: 'all' },
  { label: 'Last 7 days', value: '7' },
  { label: 'Last 14 days', value: '14' },
  { label: 'Last 30 days', value: '30' }
];

interface MicroJournalEntriesProps {
  token: string;
}

const MicroJournalEntries = ({ token }: MicroJournalEntriesProps) => {
  const [selectedDateRange, setSelectedDateRange] = useState('30');
  const { microJournalEntries, isMicroJournalEntriesLoading } = useFetchMicroJournalEntries({
    token,
    dateRange: selectedDateRange === 'all' ? undefined : Number.parseInt(selectedDateRange, 10) || 30
  });

  const handleDateRangeSelect = (filter: string) => {
    setSelectedDateRange(filter);
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.headerFilter}>
          <div className={styles.filter}>Filter</div>
          <div>
            <Dropdown
              overlay={
                <Menu onClick={({ key }) => handleDateRangeSelect(String(key))}>
                  {DATE_RANGE_OPTIONS.map(({ label, value }) => (
                    <Menu.Item key={value}>{label}</Menu.Item>
                  ))}
                </Menu>
              }
              trigger={['click']}
            >
              <span className={styles.headerFilterDropdown} onClick={(e) => e.preventDefault()}>
                {DATE_RANGE_OPTIONS.find((option) => option.value === selectedDateRange)?.label} <DownOutlined />
              </span>
            </Dropdown>
          </div>
        </div>
      </div>
      <div className={styles.content}>
        {isMicroJournalEntriesLoading ? (
          <Skeleton active />
        ) : (
          <>
            {microJournalEntries.length > 0 ? (
              <Masonry
                breakpointCols={{
                  default: 3,
                  800: 1
                }}
                className={styles.masonryContainer}
              >
                {microJournalEntries.map(({ date, title, response, tags }, index) => (
                  <JournalEntryCard key={index} date={date} title={title} response={response} tags={tags} />
                ))}
              </Masonry>
            ) : (
              <div className={styles.noContent}>
                {selectedDateRange === 'all'
                  ? 'There is no data.'
                  : `There is no data for the ${DATE_RANGE_OPTIONS.find(
                      (option) => option.value === selectedDateRange
                    )?.label.toLowerCase()}`}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default MicroJournalEntries;
