import { useState } from 'react';

import SelectContentLayout from 'Select/components/SelectContentLayout/SelectContentLayout';
import {
  FIT_DAYS_LIST_LABELS,
  FIT_DELIVERY_LIST_LABELS,
  FIT_PSYCHOLOGIST_GENDER_LIST_LABELS,
  FIT_TIMES_LIST_LABELS,
  FitDaysList,
  FitDeliveryList,
  FitPsychologistGenderList,
  FitTimesList
} from 'Select/interfaces/fitFilter';
import { PsychologistFitFilter } from 'Select/pages/PsychologistListing/PsychologistListing';
import FilterDropdown, { listInterface } from 'components/FilterDropdown/FilterDropdown';
import styles from './FitFilter.module.scss';
import FitFilterMobilePopup, { FitFilterInterface } from './components/FitFilterMobilePopup/FitFilterMobilePopup';

const generateDaysList = () => {
  return Object.keys(FitDaysList).map((listObj) => ({
    id: listObj,
    label: FIT_DAYS_LIST_LABELS[listObj as keyof typeof FitDaysList]
  }));
};

const generateTimesList = () => {
  return Object.keys(FitTimesList).map((listObj) => ({
    id: listObj,
    label: FIT_TIMES_LIST_LABELS[listObj as keyof typeof FitTimesList]
  }));
};

const generateDeliveryList = () => {
  return Object.keys(FitDeliveryList).map((listObj) => ({
    id: listObj,
    label: FIT_DELIVERY_LIST_LABELS[listObj as FitDeliveryList]
  }));
};

const generateGenderList = () => {
  return Object.keys(FitPsychologistGenderList).map((listObj) => ({
    id: listObj,
    label: FIT_PSYCHOLOGIST_GENDER_LIST_LABELS[listObj as keyof typeof FitPsychologistGenderList]
  }));
};

interface FitFilterProps {
  selectedFitValue?: PsychologistFitFilter;
  onChangeFitValue: (value: PsychologistFitFilter) => void;
  handleFitResetValue: () => void;
}

const FitFilter = ({ selectedFitValue, onChangeFitValue, handleFitResetValue }: FitFilterProps) => {
  const [isFilterModalShow, setIsFilterModalShow] = useState(false);
  const [filterIsSelected, setFilterIsSelected] = useState<string>('');

  const generateList = (id?: any, listLabel?: any, listEnum?: any) => {
    return id
      ? {
          id,
          label: listLabel[id as keyof typeof listEnum]
        }
      : undefined;
  };

  const massageFitDays = generateList(selectedFitValue?.day, FIT_DAYS_LIST_LABELS, FitDaysList);
  const massageFitTimes = generateList(selectedFitValue?.time, FIT_TIMES_LIST_LABELS, FitTimesList);
  const massageFitDeliveryMode = generateList(
    selectedFitValue?.deliveryMode,
    FIT_DELIVERY_LIST_LABELS,
    FitDeliveryList
  );
  const massageFitGender = generateList(
    selectedFitValue?.gender,
    FIT_PSYCHOLOGIST_GENDER_LIST_LABELS,
    FitPsychologistGenderList
  );
  const [scrollCount, setScrollCount] = useState<number>(0);

  const handleChangeDays = (value: listInterface | undefined) => {
    const massageFitCollection: PsychologistFitFilter = {
      ...selectedFitValue,
      day: value?.id as FitDaysList
    };
    onChangeFitValue(massageFitCollection);
  };

  const handleChangeTimes = (value: listInterface | undefined) => {
    const massageFitCollection: PsychologistFitFilter = {
      ...selectedFitValue,
      time: value?.id as FitTimesList
    };
    onChangeFitValue(massageFitCollection);
  };

  const handleChangeDeliveryMode = (value: listInterface | undefined) => {
    const massageFitCollection: PsychologistFitFilter = {
      ...selectedFitValue,
      deliveryMode: value?.id as FitDeliveryList
    };
    onChangeFitValue(massageFitCollection);
  };

  const handleChangeGender = (value: listInterface | undefined) => {
    const massageFitCollection: PsychologistFitFilter = {
      ...selectedFitValue,
      gender: value?.id as FitPsychologistGenderList
    };
    onChangeFitValue(massageFitCollection);
  };

  const onFilterClickHandle = (filterName: string) => {
    setFilterIsSelected(filterName);
    setIsFilterModalShow(true);
  };

  const selectedFilters: FitFilterInterface = {
    day: massageFitDays,
    time: massageFitTimes,
    delivery: massageFitDeliveryMode,
    gender: massageFitGender
  };

  return (
    <div className={styles.container}>
      <SelectContentLayout>
        <div className={styles.content}>
          <div className={styles.filterWrapper} onScroll={() => setScrollCount(scrollCount + 1)}>
            <div className={styles.labelWrapper}>
              <div className={styles.title}>REFINE YOUR FIT</div>
              <div className={styles.desc}>Filter psychologists by...</div>
            </div>
            <div className={styles.fitFilterWrapper}>
              <FilterDropdown
                label={'Days'}
                noFloatLabel
                badgeStyles
                listData={generateDaysList()}
                onChangeValue={handleChangeDays}
                selectedValue={massageFitDays}
                className={styles.days}
                refreshPosition={scrollCount}
                onClick={() => onFilterClickHandle('day')}
              />
              <FilterDropdown
                label={'Times'}
                noFloatLabel
                badgeStyles
                listData={generateTimesList()}
                onChangeValue={handleChangeTimes}
                selectedValue={massageFitTimes}
                className={styles.times}
                refreshPosition={scrollCount}
                onClick={() => onFilterClickHandle('time')}
              />
              <FilterDropdown
                label={'Delivery Mode'}
                noFloatLabel
                badgeStyles
                listData={generateDeliveryList()}
                onChangeValue={handleChangeDeliveryMode}
                selectedValue={massageFitDeliveryMode}
                className={styles.deliveryMode}
                refreshPosition={scrollCount}
                onClick={() => onFilterClickHandle('delivery')}
              />
              <FilterDropdown
                label={'Gender'}
                noFloatLabel
                badgeStyles
                listData={generateGenderList()}
                onChangeValue={handleChangeGender}
                selectedValue={massageFitGender}
                className={styles.gender}
                refreshPosition={scrollCount}
                onClick={() => onFilterClickHandle('gender')}
              />
              <div className={styles.fitReset} onClick={handleFitResetValue}>
                Clear All
              </div>
            </div>
          </div>
        </div>
        <FitFilterMobilePopup
          visible={isFilterModalShow}
          filterIsSelected={filterIsSelected}
          selectedFiltersProp={selectedFilters}
          genderOptions={generateGenderList()}
          dayOptions={generateDaysList()}
          timeOptions={generateTimesList()}
          deliveryOptions={generateDeliveryList()}
          onView={onChangeFitValue}
          onClearAll={handleFitResetValue}
          onCancel={() => setIsFilterModalShow(false)}
        />
      </SelectContentLayout>
    </div>
  );
};

export default FitFilter;
