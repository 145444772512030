import { useFormikContext } from 'formik';
import CheckBox from 'components/CheckBox/CheckBox';
import FormikInput from 'components/Input/FormikInput';
import FormikSelect from 'components/Select/FormikSelect';
import HelmMobileFormikInput from 'helm/components/Input/HelmMobileFormikInput';
import { EmergencyContactDetail } from 'utils/http/PatientProfileService/Patient/patient';

import { PARENT_OR_GUARDIAN_RELATIONSHIP_OPTIONS, getAge } from '../../constants';
import styles from './HBPartnerParentOrGuardianForm.module.scss';

const HBPartnerParentOrGuardianForm = () => {
  const { values, setFieldValue } = useFormikContext<{
    guardianFirstName: string;
    guardianLastName: string;
    guardianEmail: string;
    guardianMobileNumber: string;
    guardianRelationship: string;
    dateOfBirth: string;
    saveAsEmergencyContact: boolean;
    consentToContact: boolean;
    emergencyContacts: EmergencyContactDetail[];
  }>();

  return (
    <div className={styles.wrapper}>
      <div className={styles.desc}>
        Due to your age is {getAge(values.dateOfBirth)} you need to fill in your parent or guardian, and provide us 1
        emergency contact details below.
      </div>
      <div className={styles.parentOrGuardianDetails}>
        <div className={styles.header}>Parent or Guardian Details</div>
        <div className={styles.checkBoxes}>
          <div className={styles.checkBoxText}>
            <CheckBox
              id="saveAsEmergencyContact"
              className={styles.checkBox}
              label="Save parent or guardian details as emergency contact."
              onChange={(e) => {
                setFieldValue('saveAsEmergencyContact', e.target.checked);
                !e.target.checked &&
                  values.emergencyContacts.length < 1 &&
                  setFieldValue('emergencyContacts', [
                    {
                      firstName: '',
                      lastName: '',
                      mobileNumber: '',
                      email: '',
                      relationship: ''
                    }
                  ]);
              }}
              value={values.saveAsEmergencyContact}
            />
          </div>
        </div>
      </div>
      <div>
        <FormikInput
          inputClass={styles.customInput}
          label="First Name*"
          name="guardianFirstName"
          required
          labelClass={styles.inputLabel}
        />
        <FormikInput
          inputClass={styles.customInput}
          label="Last Name*"
          name="guardianLastName"
          required
          labelClass={styles.inputLabel}
        />
        <HelmMobileFormikInput
          inputClass={styles.customInput}
          id={`mobileNumber`}
          name={'guardianMobileNumber'}
          label={'Mobile number*'}
          mobilePattern="____ ___ ___"
          placeholder={'0482 555 444'}
          phonePrefix=""
          labelClass={styles.inputLabel}
        />
        <FormikInput
          inputClass={styles.customInput}
          label="Email address*"
          name="guardianEmail"
          labelClass={styles.inputLabel}
          required
        />
        <FormikSelect
          className={styles.customSelect}
          labelClass={styles.selectInputLabel}
          label={'Your relationship?*'}
          isSearchable={false}
          name="guardianRelationship"
          options={PARENT_OR_GUARDIAN_RELATIONSHIP_OPTIONS.map((option) => ({
            label: option.label,
            value: option.value
          }))}
          styles={{
            container: (base) => ({
              ...base,
              width: '100%'
            }),
            control: (controlBase: any) => ({
              ...controlBase,
              minHeight: '30px',
              backgroundColor: 'transparent',
              border: 'none',
              borderRadius: 0,
              boxShadow: 'none'
            }),
            valueContainer: (base) => ({ ...base, paddingLeft: 0 })
          }}
        />
      </div>
      <div className={styles.parentOrGuardianDetails}>
        <div className={styles.header}>Parental or Guardian Consent</div>
        <div className={styles.checkBoxes}>
          <div className={styles.checkBoxText}>
            <CheckBox
              id="consentToContact"
              className={styles.checkBox}
              label="I give consent to contact my parents or guardian to discuss the appointment, send notifications, messages, appointment reminders and form requests."
              onChange={(e) => {
                setFieldValue('consentToContact', e.target.checked);
              }}
              value={values.consentToContact}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HBPartnerParentOrGuardianForm;
