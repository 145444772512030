import MaterialInput from 'components/MaterialInput/MaterialInput';
import { AddressSuggestion } from '../AddressSuggestion/AddressSuggestion';
import styles from './AddressForm.module.scss';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import classnames from 'classnames';
import { PutPatientPayload } from 'utils/http/PatientProfileService/Patient/patient';

type Address = NonNullable<PutPatientPayload['address']>;

export type OnAddressAutoCompleteHandler = (address: Address) => void;

interface AddressFormProps {
  values: Address;
  errors?: Partial<Record<keyof Address, string | undefined>>;
  handleChangeValues: (key: keyof Address, value: string) => void;
  onAddressAutoComplete: OnAddressAutoCompleteHandler;
}

export const AddressForm = ({ values, errors, handleChangeValues, onAddressAutoComplete }: AddressFormProps) => {
  return (
    <div className={styles.container}>
      <h4 className={styles.title}>ADDRESS</h4>
      <div className={styles.addressForm}>
        <div className={classnames(styles.formItem, errors?.line1 && styles.fieldError)}>
          <MaterialInput
            label={'Address 1'}
            id={'line1'}
            onChange={(e) => handleChangeValues('line1', e.target.value)}
            value={values.line1 || ''}
            maxLength={80}
            required
            autoComplete={'off'}
            className={styles.input}
          />
          <ErrorMessage className={styles.error} error={errors?.line1} visible={!!errors?.line1} />
        </div>
        <div className={styles.addressSuggestion}>
          <AddressSuggestion
            keyword={values.line1 || ''}
            region={process.env.REACT_APP_GAE_REGION ?? 'au'}
            onSelect={onAddressAutoComplete}
          />
        </div>
        <div className={classnames(styles.formItem, errors?.line2 && styles.fieldError)}>
          <MaterialInput
            label={'Address 2'}
            id={'line2'}
            onChange={(e) => handleChangeValues('line2', e.target.value)}
            value={values.line2 || ''}
            maxLength={80}
            required
            autoComplete={'off'}
            className={styles.input}
          />
          <ErrorMessage className={styles.error} error={errors?.line2} visible={!!errors?.line2} />
        </div>
        <div className={classnames(styles.formItem, errors?.suburb && styles.fieldError)}>
          <MaterialInput
            label={process.env.REACT_APP_GAE_REGION === 'gb' ? 'City' : 'Suburb'}
            id={'suburb'}
            onChange={(e) => handleChangeValues('suburb', e.target.value)}
            value={values.suburb || ''}
            maxLength={80}
            required
            autoComplete={'off'}
            className={styles.input}
          />
          <ErrorMessage className={styles.error} error={errors?.suburb} visible={!!errors?.suburb} />
        </div>
        <div className={classnames(styles.formItem, errors?.postcode && styles.fieldError)}>
          <MaterialInput
            label={'Postcode'}
            id={'postcode'}
            onChange={(e) => handleChangeValues('postcode', e.target.value)}
            value={values.postcode || ''}
            maxLength={80}
            required
            autoComplete={'off'}
            className={styles.input}
          />
          <ErrorMessage className={styles.error} error={errors?.postcode} visible={!!errors?.postcode} />
        </div>
        <div className={classnames(styles.formItem, errors?.state && styles.fieldError)}>
          <MaterialInput
            label={process.env.REACT_APP_GAE_REGION === 'gb' ? 'County' : 'State'}
            id={'state'}
            onChange={(e) => handleChangeValues('state', e.target.value)}
            value={values.state || ''}
            maxLength={80}
            required
            autoComplete={'off'}
            className={styles.input}
          />
          <ErrorMessage className={styles.error} error={errors?.state} visible={!!errors?.state} />
        </div>
        <div className={classnames(styles.formItem, errors?.country && styles.fieldError)}>
          <MaterialInput
            label={'Country'}
            id={'country'}
            onChange={(e) => handleChangeValues('country', e.target.value)}
            value={values.country || ''}
            maxLength={80}
            required
            autoComplete={'off'}
            className={styles.input}
          />
          <ErrorMessage className={styles.error} error={errors?.country} visible={!!errors?.country} />
        </div>
      </div>
    </div>
  );
};
