import styles from './LoginAvatar.module.scss';
import { useAuth0 } from '@auth0/auth0-react';
import ClientProfileAvatar from 'components/ClientProfileAvatar/ClientProfileAvatar';
import HeaderMenu from '../HeaderMenu/HeaderMenu';
import { helmEnvironment } from 'helm/utils/HelmEnv/helmEnv';
import { useHelmRoutesGenerator } from 'helm/utils/Path/HelmRoutesGenerator';
import { useContext } from 'react';
import { UserContext } from 'utils/UserContextProvider';

interface LoginAvatarProps {
  whiteFont?: boolean;
  withMenu?: boolean;
}

const LoginAvatar = ({ whiteFont, withMenu }: LoginAvatarProps) => {
  const { user, isAuthenticated, loginWithRedirect } = useAuth0();
  const { HelmContactUsURL } = helmEnvironment();
  const { UPDATE_PROFILE, LOGIN, REFERRAL, IP6, PRACTITIONER } = useHelmRoutesGenerator();
  const { clientProfile } = useContext(UserContext);

  const menuItems = [
    ...(clientProfile?.isIp6Enabled ? [{ name: 'Power to Change Program', url: IP6.DETAILS, isExternal: false }] : []),
    { name: 'Manage my appointments', url: LOGIN.APPOINTMENT_OVERVIEW, isExternal: false },
    { name: 'Add referral details', url: REFERRAL, isExternal: false },
    { name: 'Manage my profile', url: UPDATE_PROFILE, isExternal: false },
    { name: 'Contact the Helm team', url: HelmContactUsURL, isExternal: true }
  ];

  return (
    <div className={styles.container}>
      {isAuthenticated ? (
        <div className={styles.profileWrapper}>
          <div className={styles.profileCard}>
            <div className={styles.profile}>
              <ClientProfileAvatar avatarUrl={user?.picture} />
            </div>
          </div>
          {withMenu && (
            <div className={whiteFont ? styles.nameWhite : styles.name}>
              <HeaderMenu menuList={menuItems}>
                <i className={`material-icons ${styles.menuIcon}`}>menu</i>
              </HeaderMenu>
            </div>
          )}
        </div>
      ) : (
        <div className={styles.loginWrapper}>
          <div
            onClick={() =>
              loginWithRedirect({
                loginType: 'patient',
                redirectUri: window.location.origin + PRACTITIONER.LISTING,
                appState: { returnTo: window.location.pathname }
              })
            }
            className={whiteFont ? styles.loginWhite : styles.login}
          >
            LOGIN
          </div>
        </div>
      )}
    </div>
  );
};

export default LoginAvatar;
