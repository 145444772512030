import { useEffect, useMemo, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { message } from 'antd';

import {
  FirstAssessment,
  CheckInQuestion,
  ClinicalAssessment,
  MicroJournalQuestion,
  OnboardingQuestion
} from './AssessmentInterfaces';

import { Clinician } from 'utils/hooks/clinician';
import { useLogout } from 'utils/hooks/logout';
import { putQuestionsResponses } from 'utils/http/checkIn';
import { GetAttachedClinicianDetails } from 'interfaces/Clinician/clinicianDetails';
import AssessmentQuestion from 'components/AssessmentQuestion/AssessmentQuestion';
import ClinicalAssessmentResults from './components/ClinicalAssessmentResults/ClinicalAssessmentResults';

import './PatientQuestionForm.scss';
import styles from './PatientQuestionForm.module.scss';
import { isErrorBentStatusError } from 'utils/isErrorWithStatusCode';
import { CLIENT_BRAND_LOWER_CASE } from 'interfaces/ClientBrand';
import classnames from 'classnames';
import { IS_SOMEONE_HEALTH_APP } from 'utils/hooks/AccountInfo/clientDetails';

export interface PatientQuestionFormHeaderProps {
  completedQuestions: number;
  totalQuestions?: number;
  patientDetails?: { name: string; picture: string };
  isFirstQuestionSet?: boolean;
  isSkippable?: boolean;
  onBack: () => void;
  onSkip: (values: any) => void;
  onSaveAndExit: () => void;
}

interface PatientQuestionFormProps {
  assessment?: FirstAssessment;
  clinician?: Clinician | GetAttachedClinicianDetails['clinician'];
  clinicianName: string;
  defaultPatientDetails?: { name: string; picture: string };
  header: (props: PatientQuestionFormHeaderProps) => JSX.Element;
  onUpdateAssessment: (
    assessmentId: string,
    responses: { assessmentId: string; questionId: string; response: { value: any; otherValue?: any } }[],
    updatedQuestionsResponses?: FirstAssessment
  ) =>
    | {
        updatedAssessment: FirstAssessment;
        updatedQuestionSet?: CheckInQuestion | ClinicalAssessment | MicroJournalQuestion | OnboardingQuestion;
      }
    | undefined;
  onSaveAndExit: () => void;
  onUpdateStatusDone: () => void;
  token: string;
}

export interface ClinicalAssessmentResult extends ClinicalAssessment {
  clinicianId: string;
  patientId: string;
  questionsResponsesId: string;
  charts: {
    key?: string;
    label?: string;
    max: number;
    total: number;
    ranges: { score: string; label: string }[];
    scales: Required<ClinicalAssessment>['scales'][number]['ranges'];
  }[];
  processedScore: {
    [key: string]: number;
  };
  createdAt: Date;
}

const useQuestionFormStage = () => {
  const [stage, setStage] = useState(-1);

  const nextStage = () => {
    setStage(stage + 1);
  };

  const prevStage = () => {
    if (stage > 0) {
      setStage(stage - 1);
    }
  };

  return { stage, nextStage, prevStage, setStage };
};

const PatientQuestionForm = ({
  assessment,
  clinician,
  clinicianName,
  defaultPatientDetails,
  header,
  onUpdateAssessment,
  onSaveAndExit,
  onUpdateStatusDone,
  token
}: PatientQuestionFormProps) => {
  const { loginWithRedirect } = useAuth0();

  const { checkInQuestions = [], clinicalAssessments = [], microJournalQuestions = [] } = assessment?.assessment || {};

  const { logout } = useLogout();
  const { stage, nextStage, prevStage, setStage } = useQuestionFormStage();

  const [clinicalAssessmentResults, setClinicalAssessmentResults] = useState<ClinicalAssessmentResult[]>([]);
  const [showClinicalAssessmentResults, setShowClinicalAssessmentResults] = useState(false);

  useEffect(() => {
    if (assessment) {
      checkAndSetStage(assessment);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [!!assessment]);

  const { currentAssessment, reverseStemAndHideTitle, isSkippable } = useMemo(() => {
    const currentAssessmentSkippable: {
      currentAssessment?: CheckInQuestion | ClinicalAssessment | MicroJournalQuestion | OnboardingQuestion;
      reverseStemAndHideTitle: boolean;
      isSkippable: boolean;
    } = { currentAssessment: undefined, reverseStemAndHideTitle: false, isSkippable: true };
    const onboardingQuestions = assessment?.assessment.onboardingQuestions.assessmentField || [];
    if (stage < clinicalAssessments.length) {
      currentAssessmentSkippable.currentAssessment = clinicalAssessments[stage];
      currentAssessmentSkippable.isSkippable = false;
    } else if (stage - clinicalAssessments.length < onboardingQuestions.length) {
      currentAssessmentSkippable.currentAssessment = onboardingQuestions[stage - clinicalAssessments.length];
      currentAssessmentSkippable.reverseStemAndHideTitle = true;
    } else if (stage - (clinicalAssessments.length + onboardingQuestions.length) < checkInQuestions.length) {
      currentAssessmentSkippable.currentAssessment =
        checkInQuestions[stage - (clinicalAssessments.length + onboardingQuestions.length)];
    } else if (
      stage - (clinicalAssessments.length + onboardingQuestions.length + checkInQuestions.length) <
      microJournalQuestions.length
    ) {
      currentAssessmentSkippable.currentAssessment =
        microJournalQuestions[
          stage - (clinicalAssessments.length + onboardingQuestions.length + checkInQuestions.length)
        ];
      currentAssessmentSkippable.reverseStemAndHideTitle = true;
    }

    return currentAssessmentSkippable;
  }, [checkInQuestions, clinicalAssessments, microJournalQuestions, stage, assessment]);

  const { completedQuestions, totalQuestions } = useMemo(() => {
    const {
      checkInQuestions = [],
      clinicalAssessments = [],
      microJournalQuestions = []
    } = assessment?.assessment || {};
    const onboardingQuestions = assessment?.assessment.onboardingQuestions.assessmentField || [];
    let completedQuestions = 0;
    let totalQuestions = clinicalAssessments.length;

    for (let i = 0; i < clinicalAssessments.length; i++) {
      if (clinicalAssessments[i].sections[0]?.questions[0]?.response) {
        completedQuestions++;
      }
    }

    for (let i = 0; i < onboardingQuestions.length; i++) {
      const currentOnboardingQuestionSet = onboardingQuestions[i];

      for (let j = 0; j < currentOnboardingQuestionSet.sections.length; j++) {
        const currentOnboardingQuestionSection = currentOnboardingQuestionSet.sections[j];

        for (let k = 0; k < currentOnboardingQuestionSection.questions.length; k++) {
          totalQuestions++;

          // eslint-disable-next-line max-depth
          if (currentOnboardingQuestionSection.questions[k].response) {
            completedQuestions++;
          }
        }
      }
    }

    for (let i = 0; i < checkInQuestions.length; i++) {
      const currentCheckInQuestionSet = checkInQuestions[i];

      for (let j = 0; j < currentCheckInQuestionSet.sections.length; j++) {
        const currentCheckInQuestionSection = currentCheckInQuestionSet.sections[j];

        for (let k = 0; k < currentCheckInQuestionSection.questions.length; k++) {
          totalQuestions++;

          // eslint-disable-next-line max-depth
          if (currentCheckInQuestionSection.questions[k].response) {
            completedQuestions++;
          }
        }
      }
    }

    for (let i = 0; i < microJournalQuestions.length; i++) {
      const currentmicroJournalQuestionSet = microJournalQuestions[i];

      for (let j = 0; j < currentmicroJournalQuestionSet.sections.length; j++) {
        const currentmicroJournalQuestionSection = currentmicroJournalQuestionSet.sections[j];

        for (let k = 0; k < currentmicroJournalQuestionSection.questions.length; k++) {
          totalQuestions++;

          // eslint-disable-next-line max-depth
          if (currentmicroJournalQuestionSection.questions[k].response) {
            completedQuestions++;
          }
        }
      }
    }

    return { completedQuestions, totalQuestions: totalQuestions === 0 ? undefined : totalQuestions };
  }, [assessment]);

  const checkAndSetStage = (assessment: FirstAssessment) => {
    const { checkInQuestions, clinicalAssessments, microJournalQuestions } = assessment.assessment;
    const onboardingQuestions = assessment?.assessment.onboardingQuestions.assessmentField || [];
    let currentStage = 0;

    let isFormIncomplete = false;

    for (let i = 0; i < clinicalAssessments.length; i++) {
      if (clinicalAssessments[i].sections[0]?.questions[0]?.response) {
        currentStage++;
      } else {
        isFormIncomplete = true;
        break;
      }
    }

    if (!isFormIncomplete) {
      for (let i = 0; i < onboardingQuestions.length; i++) {
        const questions = onboardingQuestions[i].sections.map((section) => section.questions).flat();

        if (questions.every((question) => question.response)) {
          currentStage++;
        } else {
          isFormIncomplete = true;
          break;
        }
      }
    }

    if (!isFormIncomplete) {
      for (let i = 0; i < checkInQuestions.length; i++) {
        const questions = checkInQuestions[i].sections.map((section) => section.questions).flat();

        if (questions.every((question) => question.response)) {
          currentStage++;
        } else {
          isFormIncomplete = true;
          break;
        }
      }
    }

    if (!isFormIncomplete) {
      for (let i = 0; i < microJournalQuestions.length; i++) {
        const questions = microJournalQuestions[i].sections.map((section) => section.questions).flat();

        if (questions.every((question) => question.response)) {
          currentStage++;
        } else {
          isFormIncomplete = true;
          break;
        }
      }
    }

    if (isFormIncomplete) {
      setStage(currentStage);
    } else {
      setStage(currentStage - 1);
    }
  };

  const handleSubmit = async (
    assessmentId: string,
    values: { [key: string]: Record<string, any> },
    setStageToNextQuestion: boolean
  ) => {
    if (!token) {
      loginWithRedirect({ loginType: 'patient', appState: { returnTo: window.location.pathname } });
      return;
    }

    const onboardingQuestions = assessment?.assessment.onboardingQuestions.assessmentField || [];

    const responses = Object.keys(values)
      .map((valueKey) => {
        if (valueKey in values) {
          const response = values[valueKey];

          return {
            assessmentId,
            questionId: valueKey,
            response: response
          };
        } else {
          return undefined;
        }
      })
      .filter(
        (
          response
        ): response is { assessmentId: string; questionId: string; response: { value: any; otherValue?: any } } =>
          !!response
      );

    try {
      if (!assessment) {
        return;
      }

      const onboardingIndividualQuestions = onboardingQuestions.flatMap((oq) => oq.sections[0].questions);
      const checkInIndividualQuestions = checkInQuestions.flatMap((ci) => ci.sections[0].questions);
      const microJournalIndividualQuestions = microJournalQuestions.flatMap((mj) => mj.sections[0].questions);

      const completedOnboardingIndividualQuestions = onboardingIndividualQuestions.filter(
        (question) => question.response
      );
      const completedCheckInIndividualQuestions = checkInIndividualQuestions.filter((question) => question.response);
      const completedMicroJournalIndividualQuestions = microJournalIndividualQuestions.filter(
        (question) => question.response
      );

      const individualQuestionsLength =
        onboardingIndividualQuestions.length +
        checkInIndividualQuestions.length +
        microJournalIndividualQuestions.length;

      const completedIndividualQuestionsLength =
        completedOnboardingIndividualQuestions.length +
        completedCheckInIndividualQuestions.length +
        completedMicroJournalIndividualQuestions.length;

      const status =
        setStageToNextQuestion &&
        stage ===
          checkInQuestions.length +
            clinicalAssessments.length +
            microJournalQuestions.length +
            onboardingQuestions.length -
            1 &&
        (individualQuestionsLength === 0 || completedIndividualQuestionsLength >= individualQuestionsLength - 1)
          ? 'done'
          : 'pending';

      let updatedQuestionsResponses;

      if (token) {
        updatedQuestionsResponses = await (
          await putQuestionsResponses(token, assessment.clinicianId, assessment._id, {
            status,
            responses
          })
        ).json();
      }

      const header = document.getElementById('header');

      header && header.scrollIntoView();

      const updated = onUpdateAssessment(
        assessmentId,
        responses,
        !updatedQuestionsResponses.clinicalAssessmentResults ? updatedQuestionsResponses : undefined
      );

      if (setStageToNextQuestion && updated && updated.updatedQuestionSet) {
        const { updatedAssessment, updatedQuestionSet } = updated;

        const questions = updatedQuestionSet.sections.map((section) => section.questions).flat();

        if (questions.every((question) => question.response)) {
          // eslint-disable-next-line max-depth
          if (status === 'done') {
            if (updatedQuestionsResponses.clinicalAssessmentResults?.length > 0) {
              setClinicalAssessmentResults(updatedQuestionsResponses.clinicalAssessmentResults);
              setShowClinicalAssessmentResults(true);
            } else {
              onUpdateStatusDone();
            }
          } else {
            checkAndSetStage(updatedAssessment);
          }
        }

        return updatedQuestionSet;
      } else if (status === 'done') {
        if (updatedQuestionsResponses.clinicalAssessmentResults?.length > 0) {
          setClinicalAssessmentResults(updatedQuestionsResponses.clinicalAssessmentResults);
          setShowClinicalAssessmentResults(true);
        } else {
          onUpdateStatusDone();
        }
      }
    } catch (e) {
      if (isErrorBentStatusError(e) && e.statusCode === 401) {
        logout();
        return;
      }

      console.error(e);
      message.error(await (e as any).text());
    }
  };

  return (
    <div
      className={classnames(
        `question-form-container ${styles.container} ${styles[CLIENT_BRAND_LOWER_CASE]}`,
        IS_SOMEONE_HEALTH_APP && 'someone-health-theme'
      )}
    >
      <div className={styles.form}>
        {showClinicalAssessmentResults ? (
          <ClinicalAssessmentResults
            clinicalAssessmentResults={clinicalAssessmentResults}
            clinician={clinician}
            clinicianName={clinicianName}
            defaultPatientDetails={defaultPatientDetails}
            onUpdateStatusDone={onUpdateStatusDone}
          />
        ) : (
          currentAssessment && (
            <AssessmentQuestion
              assessment={currentAssessment}
              completedQuestions={completedQuestions}
              totalQuestions={totalQuestions}
              clinician={clinician}
              defaultPatientDetails={defaultPatientDetails}
              header={header}
              reverseStemAndHideTitle={reverseStemAndHideTitle}
              isSkippable={isSkippable}
              isFirstQuestionSet={stage === 0}
              onBack={prevStage}
              onSkip={nextStage}
              onSubmit={handleSubmit}
              onSaveAndExit={onSaveAndExit}
            />
          )
        )}
      </div>
    </div>
  );
};

export default PatientQuestionForm;
