import { useState, useRef } from 'react';
import SignaturePad from 'react-signature-canvas';
import { Modal } from 'antd';
import { SignatureInterface } from 'interfaces/ConsentForm/consentForm';
import Button from 'components/v2/Button/Button';
import Radio from 'components/Radio/Radio';

import styles from './SignatureBox.module.scss';
import ErrorMessage from '../ErrorMessage/ErrorMessage';

export interface SignatureBoxProps {
  id: string;
  title: string;
  visible: boolean;
  buttonLabel?: string;
  onCloseSignature: () => void;
  onChangeSignatureValue: (signVal: SignatureInterface) => void;
}

const SignatureBox = ({
  id,
  title,
  visible,
  buttonLabel,
  onCloseSignature,
  onChangeSignatureValue
}: SignatureBoxProps) => {
  let sigCanvas = useRef<SignaturePad>(null);

  const [textSignatureVal, setTextSignatureVal] = useState({
    textVal: '',
    drawVal: ''
  });

  const [signVersion, setSignVersion] = useState('typeVer');

  const [showErrorMessage, setShowErrorMessage] = useState(false);

  const getSignVersionOptions = () => {
    return [
      { value: 'typeVer', label: 'Type Signature' },
      { value: 'drawVer', label: 'Draw with mouse or finger' }
    ];
  };

  const onClearSignature = () => {
    sigCanvas.current!.clear();
  };

  const handleChangeTextSignature = (e: any) => {
    setShowErrorMessage(!e.target.value);
    setTextSignatureVal({ ...textSignatureVal, textVal: e.target.value });
  };

  const handleChangeDraw = () => {
    setShowErrorMessage(false);
  };

  const validation = () => {
    if (signVersion === 'typeVer') {
      if (textSignatureVal.textVal) {
        setShowErrorMessage(false);
        return true;
      }
      setShowErrorMessage(true);
      return false;
    } else if (signVersion === 'drawVer') {
      if (sigCanvas.current!.isEmpty()) {
        setShowErrorMessage(true);
        return false;
      }
      setShowErrorMessage(false);
      return true;
    } else {
      setShowErrorMessage(true);
      return false;
    }
  };

  const submitSignature = () => {
    if (validation()) {
      const typeVal = signVersion === 'typeVer' ? textSignatureVal.textVal : '';
      const signatureVal =
        signVersion === 'drawVer' ? sigCanvas.current!.getTrimmedCanvas().toDataURL('image/png') : '';

      const signVal = {
        type: signVersion,
        textVal: typeVal,
        drawVal: signatureVal
      };

      onChangeSignatureValue(signVal);
      onCloseSignature();
    }
  };

  return (
    <Modal
      className={styles.wrapper}
      bodyStyle={{ padding: 0 }}
      footer={null}
      visible={visible}
      onCancel={onCloseSignature}
    >
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.title}>{title}</div>
        </div>
        <div className={styles.content}>
          <div className={styles.signatureTypeWrapper}>
            <Radio
              id={id}
              className={styles.radioBtn}
              options={getSignVersionOptions()}
              onChange={(e) => setSignVersion(e.target.value)}
              radioClassName={styles.radioBtn}
              radioWrapperClass={styles.radioWrapper}
              value={signVersion}
            />
          </div>
          <div className={styles.box}>
            {signVersion === 'typeVer' && (
              <div>
                <input
                  id={id}
                  value={textSignatureVal.textVal}
                  className={styles.signatureInput}
                  onChange={(e) => handleChangeTextSignature(e)}
                  placeholder={'Sign here'}
                  autoComplete={'off'}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      submitSignature();
                    }
                  }}
                />
              </div>
            )}
            {signVersion === 'drawVer' && (
              <div>
                <SignaturePad
                  canvasProps={{ className: styles.signaturePad }}
                  ref={sigCanvas}
                  onEnd={handleChangeDraw}
                />
                <div className={styles.signatureBtnWrapper}>
                  <Button className={styles.clearBtn} onClick={onClearSignature} variant={'secondary'}>
                    Clear
                  </Button>
                </div>
              </div>
            )}
            <ErrorMessage error={'Please sign the signature'} visible={showErrorMessage} />
          </div>
          <div className={styles.btnBox}>
            <Button className={styles.button} onClick={() => submitSignature()}>
              {buttonLabel || 'Sign & Save'}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default SignatureBox;
