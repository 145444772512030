import styles from './PaymentMethodDetail.module.scss';
import PreviewItemInfo from '../PreviewItemInfo/PreviewItemInfo';
import { PaymentMethod } from 'interfaces/Invoice/Invoice';

const GAE_REGION = process.env.REACT_APP_GAE_REGION;
const CODE_LABEL = GAE_REGION === 'au' ? 'BSB Code' : GAE_REGION === 'gb' ? 'Sort Code' : undefined;

interface PaymentMethodDetailProps {
  paymentMethod: PaymentMethod;
}

const PaymentMethodDetail = ({
  paymentMethod: { accountNumber, code, email, instructions, paymentType, paypalMeLink, stripeConnectedBank }
}: PaymentMethodDetailProps) => {
  switch (paymentType) {
    case 'bankTransfer':
      return (
        <>
          <PreviewItemInfo className={styles.customInfo} label="Account Number:" value={accountNumber || ''} />
          <PreviewItemInfo className={styles.customInfo} label={CODE_LABEL} value={code || ''} />
          {instructions && <PreviewItemInfo className={styles.customInfo} label="Instructions:" value={instructions} />}
        </>
      );
    case 'paypal':
      return (
        <>
          <PreviewItemInfo className={styles.customInfo} label="ID:" value={email || ''} />
          {paypalMeLink && <PreviewItemInfo className={styles.customInfo} label="Link:" value={paypalMeLink} />}
          {instructions && <PreviewItemInfo className={styles.customInfo} label="Instructions:" value={instructions} />}
        </>
      );
    case 'cash':
      return (
        <>
          {instructions && <PreviewItemInfo className={styles.customInfo} label="Instructions:" value={instructions} />}
        </>
      );
    case 'other':
      return (
        <>
          {instructions && <PreviewItemInfo className={styles.customInfo} label="Instructions:" value={instructions} />}
        </>
      );
    case 'stripe':
      return (
        <>
          {stripeConnectedBank?.bankName && (
            <PreviewItemInfo
              className={styles.customInfo}
              label={`Stripe paying into ${stripeConnectedBank.bankName}`}
              value={''}
            />
          )}
        </>
      );
    default:
      return <></>;
  }
};

export default PaymentMethodDetail;
