import { MouseEvent as ReactMouseEvent } from 'react';
import Button from 'components/Button/Button';
import styles from './SQDone.module.scss';
import { useRoutesGenerator } from 'utils/Path/RoutesGenerator';
import { useGetAccessToken } from 'utils/hooks/token';
import classnames from 'classnames';
import { IS_CAW_APP, IS_HELM_APP, IS_SELECT_APP } from 'utils/hooks/AccountInfo/clientDetails';

interface SQDoneProps {
  totalQuestion?: number;
}

const SQDone = ({ totalQuestion }: SQDoneProps) => {
  const { token, isQueryParamToken } = useGetAccessToken(true);
  const { SURVEY } = useRoutesGenerator();

  const handleHomeBtn = (e: ReactMouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    window.location.href = `${window.origin}${SURVEY.BASE}${isQueryParamToken ? `?token=${token}` : ''}`;
  };

  const labelByBrand =
    IS_HELM_APP || IS_CAW_APP
      ? 'Thank you for completing these questions.'
      : IS_SELECT_APP
      ? 'I look forward to meeting you at our appointment.'
      : 'Thank you for taking time to complete these questions, we appreciate your time and the service will be in touch in due course.';

  const titleByBrand =
    IS_HELM_APP || IS_CAW_APP
      ? ''
      : IS_SELECT_APP
      ? 'Thank you for taking the time to complete these questions.'
      : 'All done.';

  return (
    <div className={classnames(styles.container, IS_CAW_APP && 'caw-theme')}>
      <div className={styles.title}>{titleByBrand}</div>
      <div className={styles.introNote}>{labelByBrand}</div>
      <div className={styles.footerBtnCard}>
        <div className={styles.doneContent}>
          <div className={styles.successWrapper}>
            <span className={`material-icons ${styles.checkIcon}`}>done</span>
            <span className={styles.doneText}>
              {totalQuestion ? `All ${totalQuestion} questions answered.` : 'Survey submitted'}
            </span>
          </div>
          <div className={styles.homePageBtn}>
            <Button onClick={handleHomeBtn}>Back to Task List</Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SQDone;
