import {
  AppointmentBookingClaimType,
  PractitionerDetailsInterface
} from 'interfaces/PublicProfile/Practitioner/practitioner';
import { FC } from 'react';
import { generatePath } from 'react-router-dom';
import PsychologistBasicDetails from '../../PsychologistBasicDetails/PsychologistBasicDetails';
import PsychologistSpecialisation from '../../PsychologistSpecialisation/PsychologistSpecialisation';
import styles from './PsychologistListCard.module.scss';
import PsychologistAvailabilityCalendar from '../../PsychologistAvailabilityCalendar/PsychologistAvailabilityCalendar';
import { MentalHealthList } from 'HealthBright/interfaces/mentalHealth';
import { getMentalHealthLabels } from 'SomeoneHealth/pages/PsychologistListing/utils/utils';
import HealthBrightContentLayout from 'HealthBright/components/HealthBrightContentLayout/HealthBrightContentLayout';
import { PsychologistFitFilter } from 'HealthBright/HealthBrightPartner/pages/PartnerPsychologistListing/PartnerPsychologistListing';
import PsychologistAvatar from 'HealthBright/components/PsychologistAvatar/PsychologistAvatar';
import PsychologistBonaFides from 'HealthBright/components/PsychologistBonaFides/PsychologistBonaFides';
import { useHealthBrightPartnerRoutesGenerator } from 'HealthBright/HealthBrightPartner/utils/Path/HealthBrightPartnerRoutesGenerator';

interface PsychologistListCardProps {
  practitioner: PractitionerDetailsInterface;
  matchedSpecialisations: string[];
  reverseBg?: boolean;
  selectedFitFilter: PsychologistFitFilter;
  selectedMentalHealthConcern: MentalHealthList[];
  isNewClient: boolean;
  clientAppointmentType: AppointmentBookingClaimType;
  isDarkYellowPill: boolean;
  handleOpenWaitListModal: (practitioner: PractitionerDetailsInterface) => void;
}

const PsychologistListCard: FC<PsychologistListCardProps> = ({
  practitioner,
  matchedSpecialisations,
  reverseBg,
  selectedFitFilter,
  selectedMentalHealthConcern,
  isNewClient,
  clientAppointmentType,
  handleOpenWaitListModal,
  isDarkYellowPill
}) => {
  const { PRACTITIONER } = useHealthBrightPartnerRoutesGenerator();

  const detailsPagePath = generatePath(PRACTITIONER.DETAILS, {
    clinicianId: practitioner.slugUrl
  });

  const matchedSecondarySpecialisations =
    matchedSpecialisations &&
    matchedSpecialisations.length > 0 &&
    practitioner.helmControl.secondarySpecialisations &&
    practitioner.helmControl.secondarySpecialisations.length > 0
      ? getMentalHealthLabels(
          practitioner.helmControl.secondarySpecialisations.filter((item) => matchedSpecialisations.includes(item))
        )
      : [];

  return (
    <div className={reverseBg ? styles.contentWrapperRs : styles.contentWrapper}>
      <HealthBrightContentLayout>
        <div className={styles.content}>
          <PsychologistBasicDetails
            practitionerDetails={practitioner}
            detailPath={detailsPagePath}
            className={styles.mobileDetails}
            selectedValue={[selectedFitFilter.deliveryMode || '', selectedFitFilter.language || '']}
          />
          <div className={styles.profileWrapper}>
            <PsychologistAvatar
              className={styles.profile}
              classNameFrame={styles.profileFrame}
              classNameImage={styles.profileImg}
              profileImg={practitioner.avatar}
              detailPath={detailsPagePath}
            />
            <PsychologistBonaFides
              id={practitioner._id}
              limitDisplayQualifications
              role={practitioner.medicare?.role}
              helmControl={practitioner.helmControl}
            />
          </div>
          <div className={styles.detailsContainer}>
            <PsychologistBasicDetails
              practitionerDetails={practitioner}
              detailPath={detailsPagePath}
              className={styles.details}
              selectedValue={[selectedFitFilter.deliveryMode || '', selectedFitFilter.language || '']}
            />
            {practitioner.helmControl.primarySpecialisations &&
              practitioner.helmControl.primarySpecialisations.length > 0 && (
                <PsychologistSpecialisation
                  label="MAIN AREAS EXPERTISE"
                  specialisationsDetails={getMentalHealthLabels(practitioner.helmControl.primarySpecialisations)}
                  selectedValue={getMentalHealthLabels(selectedMentalHealthConcern)}
                  matchedSpecialisations={getMentalHealthLabels(matchedSpecialisations)}
                  limit={5}
                />
              )}

            {/*  Show this only if any in secondary specialisations matched with selected filters
             *   and show only matched value
             */}
            {!!matchedSecondarySpecialisations.length && (
              <PsychologistSpecialisation
                label="I CAN ALSO HELP WITH"
                specialisationsDetails={matchedSecondarySpecialisations}
                badgeClassName={styles.highlightSecondarySpecs}
              />
            )}
            <PsychologistAvailabilityCalendar
              practitionerDetails={practitioner}
              detailPath={detailsPagePath}
              nextAvailabilityDetails={practitioner.nextAvailabilities?.filter(
                (item) =>
                  (isNewClient
                    ? item.appointmentTypeBookingRules?.new.available
                    : item.appointmentTypeBookingRules?.existing.available) &&
                  item.appointmentTypeClaimType === clientAppointmentType
              )}
              isDarkYellowPill={isDarkYellowPill}
              onShowWaitlistForm={() => handleOpenWaitListModal(practitioner)}
              noFilter
            />
          </div>
        </div>
      </HealthBrightContentLayout>
    </div>
  );
};

export default PsychologistListCard;
