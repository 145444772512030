import styles from './IP6HomeWorkCard.module.scss';
import { SessionActivityInterface } from 'helm/interfaces/IP6/ip6';
import IP6ActivitiesCard from '../../../IP6ActivitiesCard/IP6ActivitiesCard';

interface IP6HomeWorkCardProps {
  title: string;
  activitiesData: SessionActivityInterface[];
}

const IP6HomeWorkCard = ({ title, activitiesData }: IP6HomeWorkCardProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.title}>{title}</div>
      <IP6ActivitiesCard className={styles.content} activitiesData={activitiesData} />
    </div>
  );
};

export default IP6HomeWorkCard;
