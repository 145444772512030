import styles from './FloatingHelpBtn.module.scss';
import ButtonHB from '../ButtonHB/ButtonHB';
import FloatingHelpBtnHubSpot from '../FloatingHelpBtnHubSpot/FloatingHelpBtnHubSpot';
import { healthBrightEnvironment } from 'HealthBright/HealthBrightPartner/utils/HealthBrightEnv';

interface FloatingHelpBtnProps {
  hubspot?: boolean;
}

const FloatingHelpBtn = ({ hubspot }: FloatingHelpBtnProps) => {
  const URLs = healthBrightEnvironment();

  return hubspot ? (
    <FloatingHelpBtnHubSpot />
  ) : (
    <ButtonHB variant={'contained'} onClick={() => (window.location.href = URLs.Contact)} className={styles.container}>
      <i className={`material-icons-outlined ${styles.icon}`}>question_mark</i>
    </ButtonHB>
  );
};

export default FloatingHelpBtn;
