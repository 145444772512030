import { ChangeEvent, KeyboardEvent } from 'react';
import styles from './ReferralForm.module.scss';
import classnames from 'classnames';
import MaterialInput from 'components/MaterialInput/MaterialInput';
import MaterialPhoneInput, { contactMaskNumber, phonePrefix } from 'components/MaterialPhoneInput/MaterialPhoneInput';
import ToggleSwitch from 'components/ToggleSwitch/ToggleSwitch';
import MaterialSelect from 'components/Select/MaterialSelect/MaterialSelect';
import { CONTACT_OPTIONS, REFERRAL_RECORD_TYPE, RELATIONSHIP_OPTIONS } from './constants';
import Radio from 'components/Radio/Radio';
import { convertDateFormat } from 'pages/SignUp/SignUpForm/components/BasicDetails/actions/dateChecker';
import { IS_HELM_APP, IS_SOMEONE_HEALTH_APP } from 'utils/hooks/AccountInfo/clientDetails';
import HelmMobileMaterialInput from 'helm/components/Input/HelmMobileMaterialInput';
import { isDevelopmentENV } from 'utils/featureToggle/DevelopmentToggle';
import HelpOutLineWithTooltips from 'components/HelpOutlineTooltip/HelpOutlineTooltip';
import classNames from 'classnames';
import { GuardianForm } from 'components/GuardianForm/GuardianForm';
import { CoupleForm } from 'components/CoupleForm/CoupleForm';

interface ReferralFormProps {
  formValues: any;
  setFormValues: any;
  referralDetailOption: any;
  errorMessage: any;
}

const ReferralForm = ({ formValues, setFormValues, referralDetailOption, errorMessage }: ReferralFormProps) => {
  const onIsForMySelf = () => {
    const newFormValues = {
      ...formValues,
      isForMySelf: !formValues.isForMySelf,
      contactMethod: formValues.isForMySelf ? 'myContact' : 'otherContact'
    };
    setFormValues(newFormValues);
  };

  const onReferralFirstNameChange = (name: string) => {
    setFormValues({ ...formValues, referralFirstName: name });
  };

  const handleKeyDownDateChange = (event: KeyboardEvent<HTMLInputElement>, id: 'referralDoB' | 'dateOfBirth') => {
    if (event.keyCode === 8 || event.keyCode === 46) {
      event.preventDefault();
      const newValue = formValues.referralDoB?.slice(0, formValues.referralDoB.length - 1);
      const newFormValues = {
        ...formValues,
        [id]: newValue
      };
      setFormValues(newFormValues);
    }
  };

  const handleDateChange = (dateValue: string, id: 'referralDoB' | 'dateOfBirth') => {
    const newDateFormat = convertDateFormat(dateValue);
    const newFormValues = {
      ...formValues,
      [id]: newDateFormat
    };
    setFormValues(newFormValues);
  };

  return (
    <div>
      <div className={styles.toggleSwitchWrapper}>
        <div className={styles.fieldLabel}>Who is this referral for?</div>
        <ToggleSwitch
          buttonWidth={313}
          id={'isForMySelf'}
          isCheckedProps={!formValues.isForMySelf}
          unCheckLabel={'MYSELF'}
          checkLabel={'SOMEONE ELSE'}
          onChangeProps={onIsForMySelf}
        />
      </div>

      <div className={styles.referralForm}>
        {!formValues.isForMySelf && <div className={styles.subTitle}>About them</div>}
        <div className={styles.content}>
          {!formValues.isForMySelf && (
            <div className={styles.selectContainer}>
              <div>
                <MaterialSelect
                  id={'referralRecordType'}
                  label={'You are seeking support for?*'}
                  isSearchable={false}
                  optionList={REFERRAL_RECORD_TYPE}
                  value={formValues.referralRecordType}
                  onChange={(event: string) => {
                    setFormValues({ ...formValues, referralRecordType: event });
                  }}
                  isError={!!errorMessage?.referralRecordType}
                />
              </div>
              {errorMessage?.referralRecordType && (
                <div className={styles.fieldError}>{errorMessage?.referralRecordType}</div>
              )}
            </div>
          )}
          {!formValues.isForMySelf && formValues.referralRecordType !== 'couple' && (
            <div>
              <div className={classnames(styles.fieldContainer, errorMessage?.referralFirstName && styles.fieldError)}>
                <MaterialInput
                  label={'First Name*'}
                  id={'referralFirstName'}
                  value={formValues.referralFirstName}
                  onChange={(event) => {
                    onReferralFirstNameChange(event.target.value);
                  }}
                  maxLength={200}
                  required
                />
                {errorMessage?.referralFirstName && (
                  <div className={styles.fieldError}>{errorMessage?.referralFirstName}</div>
                )}
              </div>

              <div className={classnames(styles.fieldContainer, errorMessage?.referralLastName && styles.fieldError)}>
                <MaterialInput
                  label={'Last Name*'}
                  id={'referralLastName'}
                  value={formValues.referralLastName}
                  onChange={(event) => {
                    setFormValues({ ...formValues, referralLastName: event.target.value });
                  }}
                  maxLength={200}
                  required
                />
                {errorMessage?.referralLastName && (
                  <div className={styles.fieldError}>{errorMessage?.referralLastName}</div>
                )}
              </div>

              {!['child', 'youngPerson'].includes(formValues.referralRecordType) && (
                <div className={styles.selectContainer}>
                  <MaterialSelect
                    id={'relationship'}
                    label={`Your relationship to ${formValues.referralFirstName}`}
                    isSearchable={false}
                    optionList={RELATIONSHIP_OPTIONS}
                    value={formValues.relationship}
                    onChange={(event: string) => {
                      setFormValues({ ...formValues, relationship: event });
                    }}
                    isError={!!errorMessage?.relationship}
                  />
                  {errorMessage?.relationship && <div className={styles.fieldError}>{errorMessage?.relationship}</div>}
                </div>
              )}

              <div className={classNames(styles.fieldContainer, styles.fieldWithTooltip)}>
                <div className={styles.leftContainer}>
                  <MaterialInput
                    placeholder={'DD/MM/YYYY'}
                    id={`clientDateOfBirth`}
                    label={`${formValues.referralFirstName ? `${formValues.referralFirstName}'s` : ''} Date of Birth`}
                    onKeyDown={(e) => handleKeyDownDateChange(e, 'referralDoB')}
                    onChange={(e) => handleDateChange(e.target.value, 'referralDoB')}
                    value={formValues.referralDoB}
                    type={'tel'}
                    pattern={'[0-9\\/]*'}
                    maxLength={10}
                    autoComplete={'off'}
                  />
                  {errorMessage?.referralDoB && <div className={styles.fieldError}>{errorMessage?.referralDoB}</div>}
                </div>
                <HelpOutLineWithTooltips
                  id="clientDateOfBirth-tooltip"
                  text="Why we ask?"
                  desc={<p>We ask this to help us match you to services that are age appropriate</p>}
                  hideIcon
                />
              </div>

              <div className={classNames(styles.fieldContainer, styles.fieldWithTooltip)}>
                <div className={styles.leftContainer}>
                  <MaterialInput
                    id={`postcode`}
                    label={`${formValues.referralFirstName ? `${formValues.referralFirstName}'s` : ''} Post Code`}
                    onChange={(e) => setFormValues({ ...formValues, postcode: e.target.value })}
                    value={formValues.postcode}
                    maxLength={7}
                    autoComplete={'off'}
                  />
                  {errorMessage?.postcode && <div className={styles.fieldError}>{errorMessage?.postcode}</div>}
                </div>
                <HelpOutLineWithTooltips
                  id="postcode-tooltip"
                  text="Why we ask?"
                  desc={
                    <p>We ask this to help us determine different ways we might support you based on your location</p>
                  }
                  hideIcon
                />
              </div>

              {!formValues.isForMySelf && formValues.referralRecordType === 'adult' && (
                <>
                  <div
                    className={classnames(
                      styles.fieldContainer,
                      errorMessage?.referralEmailAddress && styles.fieldError
                    )}
                  >
                    <MaterialInput
                      label={'Email Address*'}
                      id={'referralEmailAddress'}
                      value={formValues.referralEmailAddress}
                      onChange={(event) => {
                        setFormValues({ ...formValues, referralEmailAddress: event.target.value });
                      }}
                      maxLength={200}
                      required
                    />
                    {errorMessage?.referralEmailAddress && (
                      <div className={styles.fieldError}>{errorMessage?.referralEmailAddress}</div>
                    )}
                  </div>
                  <div className={styles.phoneContainer}>
                    {IS_HELM_APP || IS_SOMEONE_HEALTH_APP ? (
                      <HelmMobileMaterialInput
                        inputClass={styles.customInput}
                        id={'referralContactNumber'}
                        name={'contactNumber'}
                        label={'Contact number*'}
                        mobilePattern={isDevelopmentENV() ? '+__ __-____-____' : '____ ___ ___'}
                        placeholder={contactMaskNumber}
                        phonePrefix={phonePrefix}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                          setFormValues({ ...formValues, referralContactNumber: e.target.value });
                        }}
                        isMobileValid={!errorMessage?.referralContactNumber}
                        required
                      />
                    ) : (
                      <MaterialPhoneInput
                        id={'referralContactNumber'}
                        label={'Contact number*'}
                        placeholder={contactMaskNumber}
                        onChange={(e: string) => {
                          setFormValues({ ...formValues, referralContactNumber: e });
                        }}
                        value={formValues.referralContactNumber}
                        isError={!!errorMessage?.referralContactNumber}
                      />
                    )}
                    {errorMessage?.referralContactNumber && (
                      <div className={styles.fieldError}>{errorMessage?.referralContactNumber}</div>
                    )}
                  </div>
                </>
              )}

              <GuardianForm
                renderIf={['child', 'youngPerson'].includes(formValues.referralRecordType)}
                formValues={formValues}
                setFormValues={setFormValues}
                errorMessage={errorMessage}
              />

              <div className={styles.referralContactContainer}>
                <div className={styles.radioTitle}>{`How do we best contact ${formValues.referralFirstName}`}</div>
                <Radio
                  name={'contactMethod'}
                  options={CONTACT_OPTIONS}
                  value={formValues.contactMethod}
                  radioWrapperClass={styles.radioWrapperClass}
                  radioLabelClass={styles.radioLabelClass}
                  onChange={(event) => {
                    setFormValues({ ...formValues, contactMethod: event.target.defaultValue });
                  }}
                />
                {formValues.contactMethod === 'otherContact' && (
                  <div className={styles.contactFormContainer}>
                    <div
                      className={classnames(
                        styles.fieldContainer,
                        errorMessage?.otherContactFirstName && styles.fieldError
                      )}
                    >
                      <MaterialInput
                        label={'First Name*'}
                        id={'otherContactFirstName'}
                        value={formValues.otherContactFirstName}
                        onChange={(event) => {
                          setFormValues({ ...formValues, otherContactFirstName: event.target.value });
                        }}
                        maxLength={200}
                        required
                      />
                      {errorMessage?.otherContactFirstName && (
                        <div className={styles.fieldError}>{errorMessage?.otherContactFirstName}</div>
                      )}
                    </div>
                    <div
                      className={classnames(
                        styles.fieldContainer,
                        errorMessage?.otherContactLastName && styles.fieldError
                      )}
                    >
                      <MaterialInput
                        label={'Last Name*'}
                        id={'otherContactLastName'}
                        value={formValues.otherContactLastName}
                        onChange={(event) => {
                          setFormValues({ ...formValues, otherContactLastName: event.target.value });
                        }}
                        maxLength={200}
                        required
                      />
                      {errorMessage?.otherContactLastName && (
                        <div className={styles.fieldError}>{errorMessage?.otherContactLastName}</div>
                      )}
                    </div>
                    <div
                      className={classnames(
                        styles.fieldContainer,
                        errorMessage?.otherContactEmail && styles.fieldError
                      )}
                    >
                      <MaterialInput
                        label={'Email Address*'}
                        id={'otherContactEmail'}
                        value={formValues.otherContactEmail}
                        onChange={(event) => {
                          setFormValues({ ...formValues, otherContactEmail: event.target.value });
                        }}
                        maxLength={200}
                        required
                      />
                      {errorMessage?.otherContactEmail && (
                        <div className={styles.fieldError}>{errorMessage?.otherContactEmail}</div>
                      )}
                    </div>
                    <div className={styles.phoneContainer}>
                      {IS_HELM_APP || IS_SOMEONE_HEALTH_APP ? (
                        <HelmMobileMaterialInput
                          inputClass={styles.phoneInput}
                          id={'otherContactNumber'}
                          name={'contactNumber'}
                          label={'Contact number*'}
                          mobilePattern={isDevelopmentENV() ? '+__ __-____-____' : '____ ___ ___'}
                          placeholder={contactMaskNumber}
                          phonePrefix={phonePrefix}
                          onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            setFormValues({ ...formValues, otherContactNumber: e.target.value });
                          }}
                          error={errorMessage?.otherContactNumber}
                          required
                        />
                      ) : (
                        <>
                          <MaterialPhoneInput
                            id={'otherContactNumber'}
                            label={'Contact number*'}
                            onChange={(e: string) => {
                              setFormValues({ ...formValues, otherContactNumber: e });
                            }}
                            placeholder={contactMaskNumber}
                            value={formValues.otherContactNumber}
                            isError={!!errorMessage?.otherContactNumber}
                          />
                          {errorMessage?.otherContactNumber && (
                            <div className={styles.fieldError}>{errorMessage?.otherContactNumber}</div>
                          )}
                        </>
                      )}
                    </div>
                    <div className={styles.selectContainer}>
                      <MaterialSelect
                        id={'otherContactRelationship'}
                        label={'Whose details are they?'}
                        isSearchable={false}
                        optionList={referralDetailOption}
                        value={formValues.otherContactRelationship}
                        onChange={(event: string) => {
                          setFormValues({ ...formValues, otherContactRelationship: event });
                        }}
                        isError={!!errorMessage?.otherContactRelationship}
                      />
                      {errorMessage?.otherContactRelationship && (
                        <div className={styles.fieldError}>{errorMessage?.otherContactRelationship}</div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
          {!formValues.isForMySelf && formValues.referralRecordType === 'couple' && (
            <CoupleForm
              formValues={formValues}
              setFormValues={setFormValues}
              errorMessage={errorMessage}
              referralDetailOption={referralDetailOption}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ReferralForm;
