import { useAuth0 } from '@auth0/auth0-react';
import { MouseEvent, useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { useLogout } from 'utils/hooks/logout';
import styles from './HeaderMenu.module.scss';
import { useCaWRoutesGenerator } from 'CaW/utils/Path/CaWRoutesGenerator';
import ButtonCaW from 'CaW/components/ButtonCaW/ButtonCaW';
import { useGetAttachedClinicianDetails } from 'redux/endpoints/clinicianProfileServices/getClientDetails';

interface HeaderMenuProps {
  children: JSX.Element;
  menuList: {
    name: string;
    url: string;
    isExternal: boolean;
  }[];
}

const HeaderMenu = ({ children, menuList }: HeaderMenuProps) => {
  const { user } = useAuth0();
  const { attachedClinicianDetail } = useGetAttachedClinicianDetails();
  const { logout } = useLogout();
  const { PRACTITIONER } = useCaWRoutesGenerator();
  const [showMenu, setShowMenu] = useState(false);

  const navigate = useNavigate();

  const navigateTo = (url: string, isExternal: boolean) => {
    if (isExternal) {
      window.location.href = url;
    } else {
      navigate(url);
    }
  };

  const stopPropagation = (e: MouseEvent) => {
    e.stopPropagation();
  };

  const bookNewAppointmentUrl = attachedClinicianDetail?.clinician
    ? generatePath(PRACTITIONER.DETAILS, {
        clinicianId: attachedClinicianDetail.clinician._id
      })
    : PRACTITIONER.LISTING;

  return (
    <div className={styles.container}>
      <div className={styles.menuTrigger} onClick={() => setShowMenu(true)}>
        {children}
      </div>

      {showMenu && (
        <>
          <div className={styles.overlay} onClick={() => setShowMenu(false)} />
          <div className={styles.menuBox}>
            <div className={styles.greeting} onClick={stopPropagation}>
              Hi {user?.name}
            </div>
            {bookNewAppointmentUrl && (
              <ButtonCaW
                className={styles.bookAppointment}
                icon="event"
                iconPostFix
                onClick={() => navigateTo(bookNewAppointmentUrl, false)}
              >
                Book new appointment
              </ButtonCaW>
            )}
            {menuList.map((item, index) => (
              <div key={index} className={styles.menuItem} onClick={() => navigateTo(item.url, item.isExternal)}>
                <div>{item.name}</div>
                <div className={`material-icons ${styles.arrowIcon}`}>arrow_forward</div>
              </div>
            ))}
            <div className={styles.signOut} onClick={() => logout()}>
              Sign Out
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default HeaderMenu;
