export const useCaWRoutesGenerator = () => {
  const firstPath = window.location.pathname.split('/')[1];
  const initPath = firstPath !== 'p' ? '' : `/${firstPath}`;

  return {
    PRACTITIONER: {
      LISTING: `${initPath}/psychologists`,
      LISTING_WITH_FILTER: `${initPath}/psychologists/:filter`,
      DETAILS: `${initPath}/psychologists-profile/:clinicianId`
    },
    HOME: '/',
    LETTER: {
      VIEW: `${initPath}/accounts/:accountId/letters/:letterId`
    },
    LOGIN: {
      APPOINTMENT_OVERVIEW: `${initPath}/appointment/overview`,
      UPDATE_PROFILE: `${initPath}/profile`
    },
    SIGNUP: {
      BASE: `${initPath}/signup`,
      CONFIRM_BOOKING: `${initPath}/booking-confirm`,
      WELCOME_BOOKING: `${initPath}/welcome`,
      EMERGENCY_CONTACT: `${initPath}/key-contact`
    },
    AVATAR: `${initPath}/avatar`,
    CONSENT: {
      BASE: `${initPath}/consent`,
      DETAILS: `${initPath}/consent/:consentFormId`
    },
    INVOICE: {
      VIEW: `${initPath}/invoice/:invoiceId`
    },
    REPORT: {
      VIEW: `${initPath}/report/:reportId`
    },
    SURVEY: {
      BASE: `${initPath}/surveys`,
      DETAILS: `${initPath}/surveys/:surveyId`
    },
    ONBOARDING: {
      BASE: `${initPath}/onboarding`
    },
    PSYCHOMETRIC: {
      BASE: `${initPath}/psychometrics`,
      DETAILS: `${initPath}/:clinicianSlugUrlOrId/assessment/:assessmentId`
    },
    CHECKIN: `${initPath}/:clinicianSlugUrlOrId/checkin`
  };
};
