// TODO: Update
export const healthBrightEnvironment = () => ({
  HealthBrightURL: 'https://health-bright.com.au/',
  HealthBrightEmail: 'feedback@health-bright.com.au',
  Contact: 'https://health-bright.com.au/contact',
  About: 'https://health-bright.com.au/about',
  OurServices: 'https://health-bright.com.au/services',
  PrivacyPolicyURL: 'https://health-bright.com.au/privacy-policy/',
  TermsAndConditionsURL: 'https://health-bright.com.au/terms-and-conditions/'
});

export const DefaultClientTimeZone = 'Australia/Melbourne';
