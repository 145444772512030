import { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import ContentLayout from 'components/ContentLayout/ContentLayout';
import LoadingCircle from 'components/LoadingCircle/LoadingCircle';
import Header from '../components/Header/Header';
import { useFetchFacilitatedOA } from './hooks/GetFacilitatedOnboarding';

import styles from './OnboardingFacilitated.module.scss';
import { useGetAttachedClinicianDetails } from 'redux/endpoints/clinicianProfileServices/getClientDetails';
import jwt_decode from 'jwt-decode';
import queryString from 'query-string';
import { FacilitatedTokenInterface } from 'interfaces/Assessment/OnboardingAssessment';
import OnboardingQuestionForm from '../components/OnboardingQuestionForm/OnboardingQuestionForm';
import BackgroundForm from '../../../components/BackgroundForm/BackgroundForm';
import { BackgroundResponse } from 'components/PatientQuestionForm/AssessmentInterfaces';
import { putBackgroundResponses } from 'utils/http/CheckInService/ClientRecords/clientRecords';
import { uploadDocumentWithEncryptionWithoutSignin } from 'utils/http/upload';
import { notification } from 'antd';
import ClientHeader from '../../../components/ClientHeader/ClientHeader';
import { useRoutesGenerator } from 'utils/Path/RoutesGenerator';
import { useFetchBackgroundImageUrl } from 'utils/hooks/checkIn';
import { useAuth0 } from '@auth0/auth0-react';
import Gateway from 'pages/PatientAdHocAssessment/components/Gateway/Gateway';
import {
  IS_EASE_APP,
  IS_RECHARGE_APP,
  IS_SELECT_APP,
  IS_SOMEONE_HEALTH_APP
} from '../../../utils/hooks/AccountInfo/clientDetails';
import EaseHeader from 'Ease/components/EaseHeader/EaseHeader';
import classNames from 'classnames';
import RechargeHeader from 'Recharge/components/RechargeHeader/RechargeHeader';
import SelectHeader from 'Select/components/SelectHeader/SelectHeader';

const OnboardingFacilitated = () => {
  const navigate = useNavigate();
  const { user } = useAuth0();
  const { backgroundImageUrl, isBackgroundImageUrlLoading } = useFetchBackgroundImageUrl(
    user?.sub || '',
    Boolean(user?.['https://tacklit.com/use-check-in-bg'])
  );
  const { search } = useLocation();
  const queryParam = queryString.parse(search);
  const queryParamToken = queryParam.token as string;
  const { FACILITATED } = useRoutesGenerator();

  if (!queryParamToken) {
    navigate(`/404`);
  }

  const [viewState, setViewState] = useState<
    'done' | 'saveAndExit' | 'welcomeBack' | 'questions' | 'background' | 'welcomeSurvey'
  >('welcomeSurvey');

  const decodeObj = jwt_decode(queryParamToken) as FacilitatedTokenInterface;
  const clientRecordId = decodeObj['https://tacklit.com/clientRecordId'];
  const { attachedClinicianDetail, isAttachedClinicianDetailLoading } = useGetAttachedClinicianDetails();
  const { facilitatedOADetails, isFacilitatedOALoading, setFacilitatedOADetails } = useFetchFacilitatedOA(
    queryParamToken,
    setViewState,
    clientRecordId
  );

  const [pageSubmitStatus, setPageSubmitStatus] = useState<'' | 'active' | 'finished'>('');
  const handleSaveAndExit = () => {
    setViewState('saveAndExit');
  };

  const handleUpdateStatusDone = () => {
    navigate(`${FACILITATED.ONBOARDING}/next?token=${queryParamToken}`);
  };

  const handleUpdateAssessment = (
    assessmentId: string,
    responses: { questionId: string; response: { value: any; otherValue?: any } }[]
  ) => {
    if (facilitatedOADetails) {
      const newFirstAssessment = { ...facilitatedOADetails };

      const foundQuestionSet =
        newFirstAssessment.assessment.clinicalAssessments.find((assessment) => assessment.id === assessmentId) ||
        newFirstAssessment.assessment.onboardingQuestions.assessmentField.find(
          (assessment) => assessment.id === assessmentId
        );

      if (foundQuestionSet) {
        const questions = foundQuestionSet.sections.map((section) => section.questions).flat();

        for (let i = 0; i < responses.length; i++) {
          const { questionId, response } = responses[i];

          const foundQuestion = questions.find((question) => question.id === questionId);

          foundQuestion && (foundQuestion.response = response);
        }
      }

      setFacilitatedOADetails(newFirstAssessment);

      return { updatedQuestionSet: foundQuestionSet, updatedAssessment: newFirstAssessment };
    }
  };

  const handleCompleteBackground = async (val: BackgroundResponse, file: File) => {
    setPageSubmitStatus('active');
    if (!facilitatedOADetails) {
      return;
    }
    try {
      const bgData = val;
      if (val.referral?.code === 'yes') {
        const uploadedFile =
          file.size > 0
            ? ((await uploadDocumentWithEncryptionWithoutSignin(queryParamToken, file)) as {
                bucketName: string;
                fileName: string;
                fileUrl: string;
              })
            : undefined;
        bgData.referral = {
          ...val.referral,
          file: uploadedFile
        };
      }
      facilitatedOADetails.assessment.onboardingQuestions.backgroundResponse = bgData;
      setFacilitatedOADetails(facilitatedOADetails);
      await putBackgroundResponses(queryParamToken, facilitatedOADetails._id, bgData);
      setTimeout(() => {
        setPageSubmitStatus('finished');

        const isHaveQuestion = facilitatedOADetails.assessment.onboardingQuestions.assessmentField.find((obj: any) => {
          return obj.sections && obj.sections.find((sec: any) => sec.questions.length > 0);
        });
        if (isHaveQuestion) {
          setViewState('questions');
        } else {
          handleUpdateStatusDone();
        }
      }, 500);
      setTimeout(() => {
        setPageSubmitStatus('');
      }, 1000);
    } catch (e) {
      console.error(e);
      notification.error({
        message: 'Background details fail to update',
        duration: 2,
        closeIcon: <span className="success">OK</span>
      });
      setTimeout(() => {
        setPageSubmitStatus('');
      }, 1000);
    }
  };

  const getStep = (state: string) => {
    switch (state) {
      case 'questions':
        return (
          <ContentLayout className={styles.content}>
            <OnboardingQuestionForm
              assessment={facilitatedOADetails}
              clinician={attachedClinicianDetail?.clinician}
              clinicianName={clinicianName}
              header={Header}
              onUpdateAssessment={handleUpdateAssessment}
              onSaveAndExit={handleSaveAndExit}
              onUpdateStatusDone={handleUpdateStatusDone}
              token={queryParamToken}
            />
          </ContentLayout>
        );
      case 'background':
        if (!facilitatedOADetails?.assessment.onboardingQuestions.backgroundResponse) {
          setViewState('questions');
          return <></>;
        }
        return (
          <>
            {IS_EASE_APP ? (
              <EaseHeader withPadding withMenu homeLink greenMenu />
            ) : IS_RECHARGE_APP ? (
              <RechargeHeader withPadding withMenu homeLink whiteMenu lightLogo />
            ) : IS_SELECT_APP ? (
              <SelectHeader withPadding withMenu homeLink />
            ) : (
              <ClientHeader />
            )}
            {attachedClinicianDetail && (
              <div className={styles.bgContainer}>
                <div className={styles.bgContent}>
                  <BackgroundForm
                    clinicianDetail={attachedClinicianDetail}
                    backgroundResponse={facilitatedOADetails?.assessment.onboardingQuestions.backgroundResponse}
                    pageSubmitStatus={pageSubmitStatus}
                    onCompleteBackground={handleCompleteBackground}
                    noHeader
                    autoHeight
                  />
                </div>
              </div>
            )}
          </>
        );
      default:
        return <></>;
    }
  };

  const clinicianName = attachedClinicianDetail?.clinician?.name
    ? `${attachedClinicianDetail.clinician?.title} ${attachedClinicianDetail.clinician?.name}`
    : attachedClinicianDetail?.practice?.name || '';

  return isFacilitatedOALoading || isAttachedClinicianDetailLoading ? (
    <div
      className={
        IS_SOMEONE_HEALTH_APP
          ? styles.shLoading
          : IS_EASE_APP
          ? styles.easeLoading
          : IS_RECHARGE_APP
          ? styles.rechargeLoading
          : IS_SELECT_APP
          ? styles.selectLoading
          : styles.loading
      }
    >
      <LoadingCircle />
    </div>
  ) : viewState === 'questions' || viewState === 'background' ? (
    <div
      className={classNames(
        IS_SOMEONE_HEALTH_APP
          ? styles.shContainer
          : IS_EASE_APP
          ? styles.easeContainer
          : IS_RECHARGE_APP
          ? styles.rechargeContainer
          : IS_SELECT_APP
          ? styles.selectContainer
          : styles.container,
        IS_EASE_APP && 'ease-theme',
        IS_RECHARGE_APP && 'recharge-theme',
        IS_SELECT_APP && 'select-theme'
      )}
    >
      {getStep(viewState)}
    </div>
  ) : viewState !== 'welcomeSurvey' ? (
    <div
      className={classNames(
        IS_SOMEONE_HEALTH_APP
          ? styles.shGatewayContainer
          : IS_EASE_APP
          ? styles.easeGatewayContainer
          : IS_RECHARGE_APP
          ? styles.rechargeGatewayContainer
          : IS_SELECT_APP
          ? styles.selectGatewayContainer
          : styles.gatewayContainer,
        IS_EASE_APP && 'ease-theme',
        IS_RECHARGE_APP && 'recharge-theme',
        IS_SELECT_APP && 'select-theme'
      )}
      style={
        !isBackgroundImageUrlLoading && backgroundImageUrl
          ? {
              backgroundImage: `url('${backgroundImageUrl}')`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat'
            }
          : undefined
      }
    >
      <Gateway state={viewState} clinicianName={clinicianName} onReturnToAssessment={() => navigate(-1)} />
    </div>
  ) : (
    <div
      className={
        IS_SOMEONE_HEALTH_APP
          ? styles.shGatewayContainer
          : IS_EASE_APP
          ? styles.easeGatewayContainer
          : IS_RECHARGE_APP
          ? styles.rechargeGatewayContainer
          : IS_SELECT_APP
          ? styles.selectGatewayContainer
          : styles.gatewayContainer
      }
    />
  );
};

export default OnboardingFacilitated;
