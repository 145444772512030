import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import LoadingCircle from 'components/LoadingCircle/LoadingCircle';

import styles from '../../Survey/SurveyList/SurveyList.module.scss';
import TaskBox from 'components/TaskBox/TaskBox';
import { useRoutesGenerator } from 'utils/Path/RoutesGenerator';
import { useGetAttachedClinicianDetails } from 'redux/endpoints/clinicianProfileServices/getClientDetails';
import classnames from 'classnames';
import { IS_CAW_APP, IS_EASE_APP } from 'utils/hooks/AccountInfo/clientDetails';
import { useGetTasksQuery } from 'redux/endpoints/clinicianProfileServices/client';

export const ConsentList = () => {
  const { user, isAuthenticated } = useAuth0();
  const navigate = useNavigate();
  const clientRecordId = user?.['https://tacklit.com/clientRecordId'] || '';
  const { attachedClinicianDetail, isAttachedClinicianDetailLoading } = useGetAttachedClinicianDetails();
  const { data: clientTasks, isLoading: isClientTasksLoading } = useGetTasksQuery(
    { clientRecordId },
    { skip: !clientRecordId }
  );
  const { CONSENT } = useRoutesGenerator();

  const clinicianName = attachedClinicianDetail?.clinician?.name
    ? `${attachedClinicianDetail.clinician?.title} ${attachedClinicianDetail.clinician?.name}`
    : attachedClinicianDetail?.practice?.name || '';

  return (
    <div className={classnames(styles.container, IS_CAW_APP && 'caw-theme', IS_EASE_APP && 'ease-theme')}>
      {isAttachedClinicianDetailLoading || isClientTasksLoading ? (
        <div className={styles.loading}>
          <LoadingCircle />
        </div>
      ) : (
        <div className={styles.contentBox}>
          {isAuthenticated && (
            <div className={styles.backBtnWrapper}>
              <div className={styles.backBtn} onClick={() => navigate(`/`)}>
                <i className={`material-icons ${styles.icon}`}>keyboard_arrow_left</i>
                Return to home page
              </div>
            </div>
          )}
          {clientTasks && clientTasks.consentList.length > 0 ? (
            <div className={styles.taskListContainer}>
              <div className={styles.taskListTitle}>
                <div className={styles.heading}>Onwards.</div>
                <strong>{clinicianName}</strong> has asked you to please review and complete the following{' '}
                <strong>
                  {clientTasks.consentList.length === 1 ? 'action' : `${clientTasks.consentList.length} actions`}
                </strong>
              </div>
              <div className={styles.taskListBox}>
                {clientTasks.consentList.map((consentObj, index) => (
                  <TaskBox
                    key={index}
                    title={consentObj.name}
                    onClick={() => navigate(`${CONSENT.BASE}/${consentObj._id}`)}
                  />
                ))}
              </div>
            </div>
          ) : (
            <div className={styles.taskListContainer}>
              <div className={styles.taskListTitle}>All consent forms completed.</div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
