import { PractitionerDetailsInterface } from 'interfaces/PublicProfile/Practitioner/practitioner';
import { practiceInterface } from '../Practice/practice';

export interface ClinicianAccountSettings {
  absenceReasons?: string[];
  bazaarUrl?: string;
  bazaarChapterTagIds?: string[];
  customLabels?: Record<string, string>;
  timezone?: string;
  logo?: {
    dark?: string;
    light?: string;
  };
}

export interface CustomiseWelcomeMessageInterface {
  checkIn: {
    checkInDescription: string;
    firstCheckInDescription: string;
    firstCheckInNudge: string;
  };
  checkInNudge: {
    days: string[];
  };
}

export enum ClinicianMedicareRole {
  ClinicalPsychologists = 'clinicalPsychologists',
  RegisteredPsychologists = 'registeredPsychologists',
  OccupationalTherapists = 'occupationalTherapists',
  SocialWorkers = 'socialWorkers',
  GeneralPractitioner = 'generalPractitioner'
}

export interface clinicianDetailsInterface {
  _id: string;
  accessRight: string;
  accountId: string;
  avatar: string;
  bio: string;
  caseLoad: {
    availableSlot?: number;
    remainingSlots?: number;
    isOpenToNewClients?: boolean;
  };
  customisations?: CustomiseWelcomeMessageInterface;
  consultantLocations: string[];
  memberships: string[];
  name: string;
  partOfPractice: boolean;
  practice: practiceInterface;
  pronouns: string;
  slugUrl: string;
  specialisations: string[];
  status: string;
  title: string;
  accountSettings?: ClinicianAccountSettings;
  workTimeZone?: string;
  medicare?: {
    role: ClinicianMedicareRole;
  };
  helmControl: PractitionerDetailsInterface['helmControl'];
}

export interface GetAttachedClinicianDetails {
  accountId: string;
  clinician?: clinicianDetailsInterface;
  practice?: practiceInterface;
}
