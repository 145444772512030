import { useParams } from 'react-router-dom';
import SMPPlan from 'components/SMP/SMPPlan/SMPPlan';

import { useSmpDetails } from 'utils/hooks/smp';

const SMPPreviewPage = () => {
  const { smpId } = useParams();
  const { smpDetails } = useSmpDetails(smpId);

  return <SMPPlan smpDetails={smpDetails} />;
};

export default SMPPreviewPage;
