import { DeliveryType, OtherInstructions, TimeSlotsWithDateInterface } from 'utils/hooks/appointment';

interface ReserveAppointmentCookies {
  reserveId?: string;
  clinicianId?: string;
  accountId?: string;
  theme?: string;
  sid?: string;
  appointmentTypeInfo?: {
    name: string;
    description: string;
    rate: number;
    isAdvisory: boolean;
    deliveryType: DeliveryType;
    otherInstructions?: OtherInstructions;
    timeSlot: TimeSlotsWithDateInterface[];
    sessionTypeId: string;
  };
  supportStaffProfile?: {
    name: string;
    avatar: string;
  };
  programId?: string;
  psychologistName?: string;
  gpDetails?: {
    appointmentTypeName?: string;
    deliveryType?: string;
    gpName?: string;
  };
  isGPAppointmentSelected?: boolean;
  presetEmployerCode?: string;
}

export const reservedAppointmentName = 'reservedCAWAppointmentData';

export const useReserveAppointmentData = () => {
  const data: ReserveAppointmentCookies | undefined = JSON.parse(localStorage.getItem(reservedAppointmentName) || '{}');
  return {
    ...data
  };
};

export const useSetReserveAppointmentData = () => {
  const setReserveAppointmentData = (reserveAppointment: ReserveAppointmentCookies) =>
    localStorage.setItem(reservedAppointmentName, JSON.stringify(reserveAppointment));
  const removeReserveAppointmentData = () => localStorage.removeItem(reservedAppointmentName);
  return { setReserveAppointmentData, removeReserveAppointmentData };
};
