import classnames from 'classnames';
import { PractitionerDetailsInterface } from 'interfaces/PublicProfile/Practitioner/practitioner';
import { Link } from 'react-router-dom';
import styles from './PsychologistBasicDetails.module.scss';
import { truncateStringByMaxCharacter } from 'utils/general';
import PsychologistTagCollection from '../PsychologistTagCollection/PsychologistTagCollection';

interface PsychologistBasicDetailsProps {
  className?: string;
  detailPath: string;
  practitionerDetails: PractitionerDetailsInterface;
  selectedValue: string[];
}

export const PRONOUN_OPTIONS = {
  heHim: 'He / Him',
  sheHer: 'She / Her',
  theyThem: 'They / Them'
};

const INTRO_MAX_LENGTH = 750;

const PsychologistBasicDetails = ({
  className,
  detailPath,
  practitionerDetails,
  selectedValue
}: PsychologistBasicDetailsProps) => {
  const qualificationValue = practitionerDetails.helmControl?.bonaFides?.qualifications?.value?.slice(0, 1) ?? [];

  const getIntroDetails = (value: string) => {
    const shorterIntro = truncateStringByMaxCharacter(value, INTRO_MAX_LENGTH);
    return `${shorterIntro}${
      shorterIntro !== value ? `... <a class='readMore' href='${detailPath}'>read more</a>` : ''
    }`;
  };

  return (
    <div className={classnames(styles.container, className && className)}>
      <div className={styles.topContent}>
        <Link to={detailPath} className={styles.name}>
          {practitionerDetails.name}
        </Link>
        <PsychologistTagCollection practitionerDetails={practitionerDetails} selectedValue={selectedValue} />
      </div>
      <div className={styles.middleContent}>
        <div className={styles.pronouns}>
          {practitionerDetails.pronouns
            ? `${PRONOUN_OPTIONS[practitionerDetails.pronouns as keyof typeof PRONOUN_OPTIONS]}`
            : ''}
        </div>
        {!!qualificationValue.length && (
          <div className={styles.qualification}>
            |<div className={styles.qualificationItem}> {qualificationValue}</div>
          </div>
        )}
      </div>
      <div className={styles.aboutMeSection}>
        <div className={styles.aboutMeLabel}>ABOUT ME</div>
        <div
          className={styles.aboutMe}
          dangerouslySetInnerHTML={{ __html: getIntroDetails(practitionerDetails.helmControl.shortDescription) }}
        />
      </div>
    </div>
  );
};

export default PsychologistBasicDetails;
