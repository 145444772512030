import { isDevelopmentENV } from 'utils/featureToggle/DevelopmentToggle';

// TODO: Update accessToken and partner code
export const HB_PARTNER_CODE = [
  ...(isDevelopmentENV()
    ? [
        {
          accessToken: '6868686868',
          partnerCode: 'HB-PARTNER'
        }
      ]
    : []),
  {
    accessToken: '6868686868',
    partnerCode: 'HB-PARTNER'
  }
];

export const HB_PARTNER_CODE_LENGTH = 10;
export const HB_PARTNER_CODE_PARAMS = 'code';
