import { AppointmentBookingClaimType } from 'interfaces/PublicProfile/Practitioner/practitioner';
import { healthBrightEnvironment } from './HealthBrightEnv';

export const getHealthBrightSupportEmail = (code?: AppointmentBookingClaimType) => {
  const { HealthBrightEmail } = healthBrightEnvironment();
  switch (code) {
    case AppointmentBookingClaimType.HBPartner:
      return HealthBrightEmail;
    default:
      return HealthBrightEmail;
  }
};
