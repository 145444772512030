import styles from './IP6Page.module.scss';

import HelmHeader from 'helm/components/HelmHeader/HelmHeader/HelmHeader';
import HelmHelmetWrapper from 'helm/components/HelmHelmetWrapper/HelmHelmetWrapper';
import IP6HeaderContent from './components/IP6HeaderContent/IP6HeaderContent';
import HelmContentLayout from 'helm/components/HelmContentLayout/HelmContentLayout';
import IP6YourProgress from './components/IP6YourProgress/IP6YourProgress';
import IP6YourProgram from './components/IP6YourProgram/IP6YourProgram';
import PractitionerFAQ from '../PractitionerDetails/components/PractitionerFAQ/PractitionerFAQ';
import NoAvailableMessageBox from '../../components/NoAvailableMessageBox/NoAvailableMessageBox';
import { helmEnvironment } from 'helm/utils/HelmEnv/helmEnv';
import { useFetchIP6Details } from './hooks/getIP6Details';
import LoadingCircle from 'components/LoadingCircle/LoadingCircle';
import { useGetClientDetails } from 'utils/hooks/AccountInfo/clientDetails';
import { scrollToView } from 'utils/scrollToView';
import { useState } from 'react';

const { HelmContactUsURL } = helmEnvironment();

export const ip6FAQ = {
  title: 'Frequently Asked Questions',
  faqData: [
    {
      title: 'Can I change my appointments?',
      desc: "Yes, you can update your appointments as long as you provide more than 24 hours' notice in line with our cancellation policy. If you move appointments, don't worry the flow through the program won't change, you'll do the next chapter at the next session. We aren't able to give refunds for no-shows or cancellations where less than 24 hours' notice is provided. You will receive all content by the planned session date whether you attend the sessions or not. Content will be available to access for four months from the completion date of the program. This may be extended if you choose to engage in further Helm services."
    },
    {
      title: 'What is my power to change plan?',
      desc: 'In each session, you will be asked to complete at least one activity that will be populated into your personal “Power to Change Plan”, which you will come back to and review in the final session. Even if you have discussed or thought about the answers, we encourage you to complete the activities. Writing down your thoughts can make the learning more concrete, supporting you in the change journey. Bringing these together in the final session also gives you the opportunity to see your learning over the Power to Change program and make decisions about your next steps.'
    },
    {
      title: 'How do I access the session content?',
      desc: "Each session's content will become available 1 hour before the start time of your session. As you progress through the program you can revisit any previous exercises or content whenever you like."
    },
    {
      title: 'What should I expect from my Counsellor?',
      desc: 'Helm is a behavioural health service that helps men with problems such as difficulty handling emotions, excessive use of drugs, alcohol or gambling. Helm offers counselling and other support services that can be accessed quickly, confidentially and without judgement.'
    },
    {
      title: 'Do I need to complete the between session activities?',
      desc: 'Between session activities include short questionnaires, videos and self-directed packages related to the Power to Change program content. Completing these activities will allow you to get the most out of each program session. Activities are designed to take between 5-10 minutes to complete and support the behavioural health journey by providing you with tools and knowledge both within and outside of program sessions.'
    }
  ]
};

const IP6Page = () => {
  const { clientRecordId, clientProfileId } = useGetClientDetails();
  const { ip6Details, isIP6DetailLoading } = useFetchIP6Details(clientRecordId, clientProfileId);
  const [handleNextActivityClick, setHandleNextActivityClick] = useState<number>(0);

  const handleToNextActivity = () => {
    scrollToView('yourProgress', true, false, true);
    setHandleNextActivityClick(handleNextActivityClick + 1);
  };

  return (
    <HelmHelmetWrapper title={`HELM - Power to Change Program`}>
      <div className={styles.container}>
        <HelmContentLayout className={styles.headerWrapper}>
          <HelmHeader
            noWrapper
            whiteFont
            whiteLogo
            logoWithoutText
            withPadding
            withMenu
            homeLink
            classNames={styles.header}
          />
        </HelmContentLayout>
        {isIP6DetailLoading ? (
          <div className={styles.loadingWrapper}>
            <LoadingCircle />
          </div>
        ) : (
          ip6Details && (
            <>
              <HelmContentLayout className={styles.headerContentWrapper}>
                <div className={styles.content}>
                  <IP6HeaderContent onClickToNextActivity={handleToNextActivity} />
                </div>
              </HelmContentLayout>
              <HelmContentLayout className={styles.progressWrapper}>
                <div className={styles.content}>
                  <IP6YourProgress data={ip6Details} handleNextActivityClick={handleNextActivityClick} />
                </div>
              </HelmContentLayout>
              <HelmContentLayout className={styles.programWrapper}>
                <IP6YourProgram data={ip6Details} />
              </HelmContentLayout>
              <HelmContentLayout className={styles.faqWrapper}>
                <div className={styles.content}>
                  <PractitionerFAQ data={ip6FAQ} id={'ip6FAQ'} className={styles.faqContent} />
                  <div className={styles.contactUsWrapper}>
                    <NoAvailableMessageBox
                      title={`Need support?`}
                      desc={'Contact us and we will be in touch with you.'}
                      buttonLabel={'Contact us'}
                      onClick={() => (window.location.href = HelmContactUsURL)}
                      titleClassName={styles.contactUsTitle}
                      buttonClassName={styles.contactUsButton}
                    />
                  </div>
                </div>
              </HelmContentLayout>
            </>
          )
        )}
      </div>
    </HelmHelmetWrapper>
  );
};

export default IP6Page;
