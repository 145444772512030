export const useHelmRoutesGenerator = (slugUrl?: string) => {
  const firstPath = window.location.pathname.split('/')[1];
  const initPath = firstPath !== 'p' ? '' : `/${firstPath}`;

  return {
    /**
     * Engage platform
     */
    PRACTITIONER: {
      LISTING: `${initPath}/register/counsellors`,
      DETAILS: `${initPath}/register/counsellors/:clinicianId`
    },
    COUNSELLORS_CALENDAR: {
      DETAILS: `${initPath}/register/counsellors-calendar/:clinicianId`
    },
    CHECKOUT: `${initPath}/register/checkout`,
    SIGNUP: {
      BASE: `${initPath}/register/signup`,
      WELCOME: `${initPath}/register/welcome`
    },
    WAITLIST: `${initPath}/register/waitlist`,
    WAITLIST_SUCCESS: `${initPath}/register/waitlist-success`,
    /**
     * Client portal paths
     */
    INVOICE: {
      VIEW: `${initPath}/invoice/:invoiceId`
    },
    AVATAR: `${initPath}/avatar`,
    UPDATE_PROFILE: `${initPath}/profile`,
    HOME: initPath,
    CONTENT: `${initPath}/program/:theme`,
    LOGIN: {
      APPOINTMENT_OVERVIEW: `${initPath}/appointment/overview`
    },
    CONSENT: {
      BASE: `${initPath}/consent`,
      DETAILS: `${initPath}/consent/:consentFormId`
    },
    SURVEY: {
      BASE: `${initPath}/surveys`,
      DETAILS: `${initPath}/surveys/:surveyId`
    },
    ONBOARDING: {
      BASE: `${initPath}/onboarding`
    },
    PSYCHOMETRIC: {
      BASE: `${initPath}/psychometrics`,
      DETAILS: `${initPath}/:clinicianSlugUrlOrId/assessment/:assessmentId`
    },
    REFERRAL: `${initPath}/referral`,
    CHECKIN: `${initPath}/:clinicianSlugUrlOrId/checkin`,
    IP6: {
      DETAILS: `${initPath}/ip6`
    },
    SMP: {
      PREVIEW: `${initPath}/smp/preview/:smpId`,
      NEW: `${initPath}/smp/new`,
      DETAILS: `${initPath}/smp/:smpId`,
      LISTING: `${initPath}/smp`
    },
    MICRO_JOURNAL_INSIGHTS: `${initPath}/journal-insights`
  };
};
