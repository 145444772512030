export enum FitPsychologistGenderList {
  woman = 'woman',
  man = 'man',
  transgenderWoman = 'transgenderWoman',
  transgenderMan = 'transgenderMan',
  nonBinary = 'nonBinary',
  agender = 'agender'
}

export const FIT_PSYCHOLOGIST_GENDER_LIST_LABELS: Record<FitPsychologistGenderList, string> = {
  woman: 'Woman',
  man: 'Man',
  transgenderWoman: 'Transgender Woman',
  transgenderMan: 'Transgender Man',
  nonBinary: 'Non-Binary',
  agender: 'Agender'
};

export enum FitDaysList {
  monday = 'monday',
  tuesday = 'tuesday',
  wednesday = 'wednesday',
  thursday = 'thursday',
  friday = 'friday',
  weekend = 'weekend'
}

export const FIT_DAYS_LIST_LABELS: Record<FitDaysList, string> = {
  monday: 'Monday',
  tuesday: 'Tuesday',
  wednesday: 'Wednesday',
  thursday: 'Thursday',
  friday: 'Friday',
  weekend: 'Weekend'
};

export enum FitTimesList {
  morning = 'morning',
  afternoon = 'afternoon',
  evening = 'evening'
}

export const FIT_TIMES_LIST_LABELS: Record<FitTimesList, string> = {
  morning: 'Morning',
  afternoon: 'Afternoon',
  evening: 'Evening'
};

export enum FitDeliveryList {
  phone = 'phone',
  video = 'video'
}

export const FIT_DELIVERY_LIST_LABELS: Record<string, string> = {
  phone: 'Phone Consult',
  video: 'Video Consult'
};

export enum FitLanguageList {
  en = 'english',
  es = 'spanish',
  vi = 'vietnamese',
  fr = 'french',
  it = 'italian',
  sr = 'serbian',
  bs = 'bosnian',
  hr = 'croatian',
  fa = 'persian',
  af = 'afrikaans',
  pt = 'portuguese',
  ta = 'tamil',
  de = 'german',
  ko = 'korean',
  bn = 'bengali',
  zh = 'zh'
}

export const FIT_LANGUAGE_LIST_LABELS: Record<keyof typeof FitLanguageList, string> = {
  en: 'English',
  es: 'Spanish',
  vi: 'Vietnamese',
  fr: 'French (Standard)',
  it: 'Italian (Standard)',
  sr: 'Serbian',
  bs: 'Bosnian',
  hr: 'Croatian',
  fa: 'Persian/Farsi',
  af: 'Afrikaans',
  pt: 'Portuguese',
  ta: 'Tamil',
  de: 'German (Standard)',
  ko: 'Korean',
  bn: 'Bengali',
  zh: 'Mandarin'
};

export const CHINESE_MANDARIN = 'Chinese (Mandarin)';

export const HEALTHBRIGHT_FIT_LANGUAGE_LIST_LABELS: Record<keyof typeof FitLanguageList, string> = {
  en: 'English',
  es: 'Spanish',
  vi: 'Vietnamese',
  fr: 'French (Standard)',
  it: 'Italian (Standard)',
  sr: 'Serbian',
  bs: 'Bosnian',
  hr: 'Croatian',
  fa: 'Persian/Farsi',
  af: 'Afrikaans',
  pt: 'Portuguese',
  ta: 'Tamil',
  de: 'German (Standard)',
  ko: 'Korean',
  bn: 'Bengali',
  zh: 'Chinese (Mandarin)'
};

export enum FitStyleList {
  'Emotion Focused Therapy' = 'Emotion Focused Therapy',
  'Interpersonal Psychotherapy' = 'Interpersonal Psychotherapy',
  'Mindfulness' = 'Mindfulness',
  'Cognitive Behavioral Therapy (CBT)' = 'Cognitive Behavioral Therapy (CBT)',
  'Narrative Therapy' = 'Narrative Therapy',
  'Positive Psychology' = 'Positive Psychology',
  'Psychodynamic Therapy' = 'Psychodynamic Therapy',
  'Solution-Focused Brief Therapy' = 'Solution-Focused Brief Therapy',
  'Acceptance and Commitment Therapy (ACT)' = 'Acceptance and Commitment Therapy (ACT)',
  'Dialectical Behavior Therapy' = 'Dialectical Behavior Therapy',
  'Attachment-Based Therapy' = 'Attachment-Based Therapy',
  'Coaching' = 'Coaching',
  'Motivational Interviewing' = 'Motivational Interviewing',
  'Person-Centered Therapy' = 'Person-Centered Therapy',
  'Schema Therapy' = 'Schema Therapy'
}

export const FIT_STYLE_LIST_LABELS: Record<FitStyleList, string> = {
  'Emotion Focused Therapy': 'Emotion Focused Therapy',
  'Interpersonal Psychotherapy': 'Interpersonal Psychotherapy',
  Mindfulness: 'Mindfulness',
  'Cognitive Behavioral Therapy (CBT)': 'Cognitive Behavioral Therapy (CBT)',
  'Narrative Therapy': 'Narrative Therapy',
  'Positive Psychology': 'Positive Psychology',
  'Psychodynamic Therapy': 'Psychodynamic Therapy',
  'Solution-Focused Brief Therapy': 'Solution-Focused Brief Therapy',
  'Acceptance and Commitment Therapy (ACT)': 'Acceptance and Commitment Therapy (ACT)',
  'Dialectical Behavior Therapy': 'Dialectical Behavior Therapy',
  'Attachment-Based Therapy': 'Attachment-Based Therapy',
  Coaching: 'Coaching',
  'Motivational Interviewing': 'Motivational Interviewing',
  'Person-Centered Therapy': 'Person-Centered Therapy',
  'Schema Therapy': 'Schema Therapy'
};
