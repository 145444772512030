import LoadingDot from 'components/LoadingDot/LoadingDot';
import {
  AppointmentBookingClaimType,
  PractitionerDetailsInterface
} from 'interfaces/PublicProfile/Practitioner/practitioner';
import { FC } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import NoPsychologistBanner from '../../NoPsychologistBanner/NoPsychologistBanner';
import PsychologistListCard from '../../PsychologistCard/PsychologistListCard/PsychologistListCard';
import PsychologistListSkeleton from '../../PsychologistSkeleton/PsychologistListSkeleton';
import styles from './PsychologistListView.module.scss';
import { MentalHealthList } from 'HealthBright/interfaces/mentalHealth';
import { PsychologistFitFilter } from 'HealthBright/HealthBrightPartner/pages/PartnerPsychologistListing/PartnerPsychologistListing';

interface PsychologistListViewProps {
  psychologistList: PractitionerDetailsInterface[];
  hasMorePsychologists: boolean;
  matchedSpecialisations: string[];
  reverseBg?: boolean;
  selectedFitFilter: PsychologistFitFilter;
  selectedMentalHealthConcern: MentalHealthList[];
  isNewClient: boolean;
  clientAppointmentType: AppointmentBookingClaimType;
  isPsychologistFound: boolean;
  isLoading: boolean;
  loadMorePsychologists: () => void;
  handleOpenWaitListModal: (practitioner: PractitionerDetailsInterface) => void;
  clearAllFilter: () => void;
}

const PsychologistListView: FC<PsychologistListViewProps> = ({
  isPsychologistFound,
  psychologistList,
  hasMorePsychologists,
  matchedSpecialisations,
  reverseBg,
  selectedFitFilter,
  selectedMentalHealthConcern,
  isNewClient,
  clientAppointmentType,
  isLoading,
  loadMorePsychologists,
  handleOpenWaitListModal,
  clearAllFilter
}) => {
  return isLoading ? (
    <PsychologistListSkeleton />
  ) : (
    <div className={styles.container}>
      <InfiniteScroll
        loadMore={loadMorePsychologists}
        initialLoad={false}
        pageStart={0}
        hasMore={isPsychologistFound ? hasMorePsychologists : false}
        loader={
          <div className={styles.loadMoreWrapper} key="loading">
            <LoadingDot />
          </div>
        }
      >
        <div>
          {!isPsychologistFound && <NoPsychologistBanner clearAllFilter={clearAllFilter} />}
          {psychologistList.map((practitioner, index) => (
            <PsychologistListCard
              key={index}
              isDarkYellowPill={index % 2 === 1}
              clientAppointmentType={clientAppointmentType}
              handleOpenWaitListModal={handleOpenWaitListModal}
              isNewClient={isNewClient}
              matchedSpecialisations={matchedSpecialisations}
              practitioner={practitioner}
              selectedFitFilter={selectedFitFilter}
              selectedMentalHealthConcern={selectedMentalHealthConcern}
              reverseBg={reverseBg}
            />
          ))}
        </div>
      </InfiniteScroll>
    </div>
  );
};

export default PsychologistListView;
