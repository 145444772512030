import styles from './UpdateProfile.module.scss';
import PatientContentLayout from 'components/PatientContentLayoutV2/PatientContentLayout';
import UpdateProfileContent from 'components/UpdateProfileContent/UpdateProfileContent';
import SomeoneHealthHelmetWrapper from '../../components/SomeoneHealthHelmetWrapper/SomeoneHealthHelmetWrapper';
import SomeoneHealthHeader from '../../components/SomeoneHealthHeader/SomeoneHealthHeader';
import classNames from 'classnames';

const UpdateProfile = () => {
  return (
    <SomeoneHealthHelmetWrapper title={`someone.health - Profile`}>
      <PatientContentLayout className={styles.container}>
        <SomeoneHealthHeader withPadding withMenu homeLink />
        <UpdateProfileContent contentClassName={classNames(styles.content, 'someone-health-theme')} />
      </PatientContentLayout>
    </SomeoneHealthHelmetWrapper>
  );
};

export default UpdateProfile;
