import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getSharedReport } from 'utils/http/report';
import { ReportInterface } from 'interfaces/report';
import LoadingDot from 'components/LoadingDot/LoadingDot';
import ReportDraftContent from './components/ReportDraftContent/ReportDraftContent';

import logoBlue from 'assets/images/tacklit-logo-blue.svg';
import logoWhite from 'assets/images/tacklit-logo-white.svg';
import styles from './ReportViewPage.module.scss';
import { useWindowSize } from 'utils/hooks/useWindowSize';
import classNames from 'classnames';
import { IS_EASE_APP } from 'utils/hooks/AccountInfo/clientDetails';

const useFetchSharedReport = () => {
  const [reportData, setReportData] = useState({} as ReportInterface);
  const [isReportLoading, setIsReportLoading] = useState(true);
  const { reportId } = useParams() as { reportId: string };

  const fetchSharedReport = async () => {
    try {
      setIsReportLoading(true);
      const getReportRes = await (await getSharedReport(reportId)).json();
      setReportData(getReportRes);
    } catch (ex) {
      console.error(ex);
      setReportData({} as ReportInterface);
    } finally {
      setIsReportLoading(false);
    }
  };

  useEffect(() => {
    fetchSharedReport();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportId]);

  return { reportData, isReportLoading, fetchSharedReport };
};

const ReportViewPage = () => {
  const { reportData, isReportLoading } = useFetchSharedReport();
  const [isShowContent, setShowContent] = useState(false);
  const [contentDimension, setContentDimension] = useState({
    height: 600,
    width: 872
  });

  const [width, height] = useWindowSize();

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowContent(true);
    }, 800);
    return () => clearTimeout(timer);
  }, []);

  const basePage = {
    width: 874,
    height: 600,
    scale: 1,
    scaleX: 1,
    scaleY: 1
  };

  const maxWidth = width;
  const maxHeight = height;

  const scalePages = () => {
    let scaleX = 1;
    let scaleY = 1;
    scaleX = maxWidth / basePage.width;
    scaleY = maxHeight / basePage.height;
    basePage.scaleX = scaleX;
    basePage.scaleY = scaleY;
    const calcScale = scaleX > scaleY ? scaleY : scaleX;
    basePage.scale = calcScale < 1 ? parseFloat(calcScale.toFixed(2)) : 1;

    return {
      scale: basePage.scale
    } as any;
  };

  const resizeVal = scalePages();

  const handleContentHeight = (height: number, width: number) => {
    setContentDimension({
      height: Math.floor(height + 50),
      width
    });
  };

  return (
    <div className={classNames(styles.container, IS_EASE_APP && 'ease-theme')}>
      <div className={styles.header} />
      <div
        className={styles.content}
        style={{
          transform: 'scale(' + resizeVal.scale + ')',
          height: `${contentDimension.height}px`
        }}
      >
        {!isReportLoading && isShowContent ? (
          <>
            {reportData._id ? (
              <div>
                <ReportDraftContent
                  data={reportData}
                  clinicianDetails={{ practice: reportData.practice, clinician: reportData.clinician }}
                  contentHeight={handleContentHeight}
                />
              </div>
            ) : (
              <div>
                Sorry, you are not allowed to access this page. Either the permission is revoked, or the link is false.
              </div>
            )}
          </>
        ) : (
          <div className={styles.loadingWrapper}>
            <LoadingDot />
          </div>
        )}
      </div>
      <div className={styles.footer}>
        <img alt={'tacklit logo'} className={styles.logo} src={IS_EASE_APP ? logoBlue : logoWhite} />
        <span>
          Report powered by Tacklit.&nbsp;&nbsp;
          <strong>Received in error?</strong>
          &nbsp;&nbsp;
          <a href="https://www.tacklit.com/contact-us">Contact us</a>
        </span>
      </div>
    </div>
  );
};

export default ReportViewPage;
