import { useEffect } from 'react';
import styles from './CheckoutPage.module.scss';
import CreateProfileRemainingTime, {
  getReservedAppointmentTimeString
} from '../components/CreateProfileRemainingTime/CreateProfileRemainingTime';
import HelmSignUpFooter from 'helm/components/HelmFooter/HelmSignUpFooter/HelmSignUpFooter';
import CreateProfileCheckout from '../components/CreateProfileCheckout/CreateProfileCheckout';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import Loading from 'components/Loading/Loading';
import HelmContentLayout from 'helm/components/HelmContentLayout/HelmContentLayout';
import { useFetchReservedAppointments } from 'helm/utils/hooks/appointment';
import ClientTestimonial from '../components/ClientTestimonial/ClientTestimonial';
import { useFetchPractitionerDetails } from 'helm/pages/PractitionerDetails/hooks/getPractitionerDetails';
import { useHelmRoutesGenerator } from 'helm/utils/Path/HelmRoutesGenerator';
import HelmHeader from 'helm/components/HelmHeader/HelmHeader/HelmHeader';
import HelmHelmetWrapper from 'helm/components/HelmHelmetWrapper/HelmHelmetWrapper';
import { useReserveAppointmentData } from 'helm/utils/hooks/localData';
import { useClaimReservation } from './hooks/useClaimReservation';
import { retrieveUserSession } from '../helper/userSession';
import Button from 'helm/components/Button/Button';
import { helmEnvironment } from 'helm/utils/HelmEnv/helmEnv';
import { getHelmPractitionerDetailsPath } from 'helm/utils/Path/getHelmPractitionerDetailsPath';

export interface CheckoutPayload {
  rebateClaim: boolean;
  reserveId?: string;
  userId?: string;
  clinicianId?: string;
}

const CheckoutPage = () => {
  const { user, isAuthenticated, isLoading } = useAuth0();
  const { reserveId, clinicianId, appointmentTypeInfo, sid, accountId, programId } = useReserveAppointmentData();
  const { claimReservationDetails, isClaimingReservation, claimStatusCode } = useClaimReservation();
  const { HelmContactUsURL } = helmEnvironment();

  const { COUNSELLORS_CALENDAR, PRACTITIONER } = useHelmRoutesGenerator();
  const navigate = useNavigate();
  const sidQueryParam = sid ? `?sid=${sid}` : '';

  const { practitionerDetails } = useFetchPractitionerDetails(clinicianId!);
  const { fetching, appointments } = useFetchReservedAppointments(reserveId!, accountId!);

  const userInfo = isAuthenticated ? user : retrieveUserSession().clientRecord?.clientProfiles?.[0];
  const clinicianName = practitionerDetails.name;
  const clinicianAvatar = practitionerDetails.avatar;

  useEffect(() => {
    if (user?.['https://tacklit.com/roles'].includes('clinician') && process.env.REACT_APP_CLINICIAN_DOMAIN) {
      window.location.href = process.env.REACT_APP_CLINICIAN_DOMAIN;
    }
  }, [user]);

  const handleRedirectBack = () => {
    if (clinicianId) {
      const detailsPagePath = getHelmPractitionerDetailsPath({
        clinicianId,
        counsellorsCalendarDetailsPath: COUNSELLORS_CALENDAR.DETAILS,
        practitionerDetailsPath: PRACTITIONER.DETAILS
      });
      navigate(`${detailsPagePath}${sidQueryParam}`);
    } else {
      navigate(`${PRACTITIONER.LISTING}${sidQueryParam}`);
    }
  };

  if (!reserveId) {
    handleRedirectBack();
  }

  const handleCheckout = () => {
    if (claimReservationDetails.checkoutUrl) {
      window.location.href = claimReservationDetails.checkoutUrl;
    }
  };

  const handleContactHelm = () => {
    localStorage.removeItem('reservedAppointmentData');
    window.location.href = HelmContactUsURL;
  };

  return (
    <HelmHelmetWrapper title={'HELM - Checkout'}>
      <div className={styles.headerWrapper}>
        <HelmHeader />
      </div>
      {fetching || isLoading || isClaimingReservation ? (
        <div className={styles.loading}>
          <Loading />
        </div>
      ) : claimStatusCode !== 200 && !claimReservationDetails.isBookingRuleError ? (
        <HelmContentLayout>
          <div className={styles.errorContainer}>
            {claimStatusCode === 404 ? (
              <div>Your reservation does not exist</div>
            ) : (
              claimStatusCode === 403 &&
              !claimReservationDetails.isBookingRuleError && (
                <HelmContentLayout>
                  <div className={styles.noTimeSlotContainer}>
                    <div className={styles.noTimeSlotTitle}>Want to book with {clinicianName}?</div>
                    <div className={styles.noTimeSlotDesc}>
                      As you are an existing Helm customer, please contact our support team to discuss working with a
                      new counsellor
                    </div>
                    <div className={styles.noTimeSlotButtonWrapper}>
                      <Button className={styles.noTimeSlotButton} onClick={handleContactHelm}>
                        Contact Helm Team
                      </Button>
                    </div>
                  </div>
                </HelmContentLayout>
              )
            )}
          </div>
        </HelmContentLayout>
      ) : (
        <HelmContentLayout>
          <div className={styles.container}>
            {!isAuthenticated && (
              <div className={styles.clientTestimonialCard}>
                <ClientTestimonial />
              </div>
            )}
            <div className={styles.leftContent}>
              <CreateProfileCheckout
                practitionerName={clinicianName}
                userName={userInfo?.firstName || userInfo?.name}
                packName={appointmentTypeInfo?.name || 'unknown'}
                packDescription={appointmentTypeInfo?.description || 'unknown'}
                deliveryMode={appointments[0]?.deliveryType}
                otherInstructions={appointmentTypeInfo?.otherInstructions}
                dateAndTimes={appointments.map((item) => getReservedAppointmentTimeString(item))}
                totalToPay={appointments.reduce((res, item) => res + (item.rate || 0), 0)}
                buttonDisabled={claimStatusCode !== 200}
                isBookingRuleError={!!claimReservationDetails.isBookingRuleError}
                onCheckout={handleCheckout}
                onReturnToCounsellorProfile={handleRedirectBack}
                programId={programId}
              />
            </div>
            <div className={styles.rightContent}>
              <CreateProfileRemainingTime
                practitionerAvatar={clinicianAvatar}
                practitionerName={clinicianName}
                appointments={appointments}
                showSlotCard={false}
                clientTestimonialCard={!isLoading && !isAuthenticated ? <ClientTestimonial /> : undefined}
                isBookingRuleError={!!claimReservationDetails.isBookingRuleError}
              />
            </div>
          </div>
        </HelmContentLayout>
      )}
      <HelmSignUpFooter />
    </HelmHelmetWrapper>
  );
};

export default CheckoutPage;
