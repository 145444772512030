import { Modal, notification } from 'antd';
import AvailableTimeSelect from 'components/AvailableTimeSelect/AvailableTimeSelect';
import Button, { HelmBtnStatus } from 'components/v2/Button/Button';
import { Formik } from 'formik';
import moment from 'moment';
import momentTz from 'moment-timezone';
import { Appointment } from 'pages/PatientAppointment/AppointmentConfirmed/AppointmentConfirmed';
import { useState } from 'react';
import styles from './ChangeAppointmentModal.module.scss';
import * as Yup from 'yup';
import { SelectedAppointmentSlot } from 'components/AvailableTimeSelect/components/DayCard/DayCard';
import { useAuth0 } from '@auth0/auth0-react';
import {
  rescheduleAppointment,
  rescheduleAppointmentRequest,
  UpdateAppointmentPayload
} from 'utils/http/SchedService/Appointments/reschedule';
import { RescheduleRules } from 'utils/hooks/appointment';
import { checkRescheduleRules, RescheduleAction } from 'utils/appointmentReschedule';
import { helmEnvironment } from 'helm/utils/HelmEnv/helmEnv';
import classnames from 'classnames';
import {
  IS_HELM_APP,
  IS_RECHARGE_APP,
  IS_SELECT_APP,
  IS_SOMEONE_HEALTH_APP
} from 'utils/hooks/AccountInfo/clientDetails';
import CancelAppointmentModal from './components/CancelAppointmentModal';
import { formatEndDate } from '../../../../utils/date';
import { RechargeSupportMobile } from 'Recharge/utils/RechargeEnv/RechargeEnv';
import { SelectSupportMobile } from 'Select/utils/env/SelectEnv';

const schema = Yup.object().shape({
  date: Yup.string(),
  startTime: Yup.string().required('Please select time'),
  endTime: Yup.string()
    .required('Please select time')
    .test('invalidTimeSlot', 'Invalid time slot', function (value) {
      const { startTime } = this.parent;
      const beginningTime = moment(startTime, 'hh:mm');
      const endTime = moment(value, 'hh:mm');
      return beginningTime.isBefore(endTime);
    })
});

interface ChangeAppointmentModalProps {
  appointment: Appointment;
  rescheduleRules?: RescheduleRules;
  clientTimeZone?: string;
  clinicianTimeZone: string;
  modalWrapperClassName?: string;
  closeDialog: (reloadData: boolean) => void;
}

const getErrorMessage = (action: RescheduleAction, rescheduleRule?: RescheduleRules) => {
  switch (action) {
    case RescheduleAction.NotAllowed:
      return `Sorry, this appointment can't be changed online`;
    case RescheduleAction.LimitExceeded:
      return `As this appointment has been previously rescheduled you are not able to make further changes online.`;
    case RescheduleAction.NotInRange:
      return `Sorry, this appointment can't be changed online at this point.`;
    case RescheduleAction.ShortNotice:
      if (rescheduleRule) {
        const lastTo = rescheduleRule.requestChanges.active
          ? rescheduleRule.requestChanges.value.to
          : rescheduleRule.selfManage.value.to;
        const hours = moment.duration(lastTo, 'minutes').asHours();
        return `You can not change this appointment online as there is less than ${hours} hours notice.`;
      }
      return `You can not change this appointment online due to a short notice.`;
  }
};

const formatPhoneNumber = (phoneNumber: string) => phoneNumber.replace(/(\d{4})(\d{3})(\d{3})/, '$1 $2 $3');
const RechargeFormattedSupportTel = formatPhoneNumber(RechargeSupportMobile);
const SelectFormattedSupportTel = formatPhoneNumber(SelectSupportMobile);

const ChangeAppointmentModal = ({
  appointment,
  rescheduleRules,
  clientTimeZone,
  clinicianTimeZone,
  modalWrapperClassName,
  closeDialog
}: ChangeAppointmentModalProps) => {
  const [timeslot, setTimeslot] = useState<SelectedAppointmentSlot>();
  const [isCancelModalOpened, setIsCancelModalOpened] = useState(false);
  const [status, setStatus] = useState<HelmBtnStatus>('');
  const { getAccessTokenSilently } = useAuth0();

  const currentAppointmentStartTime = momentTz.tz(`${appointment.date} ${appointment.startTime}`, clinicianTimeZone);
  const currentAppointmentEndTime = momentTz.tz(`${appointment.date} ${appointment.endTime}`, clinicianTimeZone);

  const newAppointmentStartTime = momentTz(`${timeslot?.startDateTime}`).tz(clientTimeZone || clinicianTimeZone);
  const newAppointmentEndTime = momentTz(`${timeslot?.endDateTime}`).tz(clientTimeZone || clinicianTimeZone);

  const rescheduleAction = checkRescheduleRules(
    currentAppointmentStartTime,
    rescheduleRules,
    appointment.clientRescheduleDetails?.count || 0,
    clientTimeZone,
    clinicianTimeZone
  );

  const reschedulingAllowed = [RescheduleAction.Instant, RescheduleAction.Request].includes(rescheduleAction);

  const { HelpSupportTel } = helmEnvironment();

  const submitRescheduleAppointment = ({
    token,
    appointmentId,
    values
  }: {
    token: string;
    appointmentId: string;
    values: UpdateAppointmentPayload;
  }) => {
    if (rescheduleAction === RescheduleAction.Instant) {
      return rescheduleAppointment(token, appointmentId, {
        date: values.date,
        endDate: formatEndDate(new Date(values.endDateTime)),
        endTime: values.endTime,
        startTime: values.startTime,
        startDateTime: values.startDateTime,
        endDateTime: values.endDateTime
      });
    } else if (rescheduleAction === RescheduleAction.Request) {
      return rescheduleAppointmentRequest(token, appointmentId, {
        date: values.date,
        endDate: values.date,
        endTime: values.endTime,
        startTime: values.startTime,
        startDateTime: values.startDateTime,
        endDateTime: values.endDateTime
      });
    } else {
      throw new Error('Rescheduling this appointment is not allowed');
    }
  };

  const handleSubmit = async (values: UpdateAppointmentPayload) => {
    setStatus('active');
    const token = await getAccessTokenSilently({
      audience: process.env.REACT_APP_API_AUDIENCE
    });
    if (token) {
      try {
        const response = await submitRescheduleAppointment({ token, appointmentId: appointment._id, values });
        if (response.statusCode === 204) {
          notification.success({
            message: `Your request has been sent${
              IS_HELM_APP ? ' to the Helm team' : IS_RECHARGE_APP || IS_SELECT_APP ? ' to the support team' : ''
            }.`
          });
          setStatus('finished');
          closeDialog(true);
        } else {
          const result = await response.json();
          notification.error({
            message: result.message || 'Something went wrong while trying to reschedule this appointment.'
          });
          setStatus('');
        }
      } catch (ex) {
        notification.error({
          message: 'Something went wrong while trying to reschedule this appointment.'
        });
      }
    }
  };

  const notAllowedDescription = IS_HELM_APP ? (
    <>
      Please contact the Helm team on <span className={styles.phoneNumber}>{HelpSupportTel}</span> to discuss any
      changes you'd like to make.
    </>
  ) : IS_RECHARGE_APP ? (
    <>
      Please contact the support team on <span className={styles.phoneNumber}>{RechargeFormattedSupportTel}</span> to
      discuss any changes you'd like to make.
    </>
  ) : IS_SELECT_APP ? (
    <>
      Please contact the support team on <span className={styles.phoneNumber}>{SelectFormattedSupportTel}</span> to
      discuss any changes you'd like to make.
    </>
  ) : (
    <>Please contact your practice/practitioner to discuss any changes you'd like to make.</>
  );

  return (
    <Modal
      title={IS_RECHARGE_APP || IS_SELECT_APP ? 'Reschedule this appointment' : 'Change your appointment'}
      visible
      onCancel={() => closeDialog(false)}
      className={styles.container}
      wrapClassName={modalWrapperClassName}
      width={800}
      footer={null}
      zIndex={1050}
    >
      <Formik
        initialValues={{
          date: '',
          endDate: '',
          startTime: '',
          endTime: '',
          startDateTime: '',
          endDateTime: ''
        }}
        onSubmit={handleSubmit}
        validationSchema={schema}
      >
        {({ submitForm }) => (
          <>
            {rescheduleAction === RescheduleAction.Request && (
              <div className={styles.informationBoxContainer}>
                <div className={classnames(styles.backgroundContainer, styles.info)}>
                  <div className={styles.messageContainer}>
                    <i className={`material-icons-outlined ${styles.infoIcon}`}>info</i>
                    <div className={styles.descriptionContainer}>
                      <div className={styles.description}>
                        You may request a change to this appointment, but please note that it will not be agreed until
                        it is confirmed{IS_HELM_APP ? 'by the Helm team' : ''}. You will receive a notification to let
                        you know if the request is approved.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className={styles.currentAppointmentDetails}>
              <div className={styles.detailContainer}>
                <div className={styles.label}>APPOINTMENT</div>
                <div className={styles.value}>{appointment.sessionTypeName}</div>
              </div>
              <div className={styles.detailWrapper}>
                <div className={styles.detailContainer}>
                  <div className={styles.label}>CURRENT APPOINTMENT DAY AND TIME</div>
                  <div className={styles.value}>
                    <div className={styles.dayValue}>{`${currentAppointmentStartTime.format('dddd, Do MMMM')} `}</div>
                    &nbsp;
                    <span className={styles.divider} />
                    &nbsp;
                    <div className={styles.timeValue}>
                      {`${currentAppointmentStartTime.format('hh:mm A')} : ${currentAppointmentEndTime.format(
                        'hh:mm A'
                      )}`}
                    </div>
                  </div>
                </div>
                {/* Currently only enable for someone health and select */}
                {(IS_SELECT_APP || IS_SOMEONE_HEALTH_APP) && (
                  <>
                    <Button size="small" variant="secondary" onClick={() => setIsCancelModalOpened(true)} type="button">
                      Cancel this appointment
                    </Button>
                    <CancelAppointmentModal
                      appointmentId={appointment._id}
                      visible={isCancelModalOpened}
                      setVisible={setIsCancelModalOpened}
                    />
                  </>
                )}
              </div>
            </div>
            {reschedulingAllowed ? (
              <>
                <div className={styles.timeSlotsContainer}>
                  <div className={styles.title}>
                    Please select an alternative day and time from the following available sessions
                  </div>
                  <div className={styles.availableTimeSelect}>
                    <AvailableTimeSelect
                      appointmentTypeId={appointment.sessionTypeId || ''}
                      selectedClinicianId={appointment.clinicianId}
                      accountId={appointment.accountId}
                      isEditing
                      clientTimeZone={clientTimeZone}
                      clinicianTimeZone={clinicianTimeZone}
                      onSelectSlot={setTimeslot}
                    />
                  </div>
                </div>
                <div className={styles.footer}>
                  <div className={styles.title}>
                    {IS_RECHARGE_APP || IS_SELECT_APP ? 'CHANGING DAY AND TIME FROM' : 'RESCHEDULE APPOINTMENT'}
                  </div>
                  <div className={styles.content}>
                    <div className={styles.timeContainer}>
                      <div>
                        {currentAppointmentStartTime.format('dddd Do MMMM')}
                        <br />
                        {currentAppointmentStartTime.format('hh:mm')} to {currentAppointmentEndTime.format('hh:mm A')}
                      </div>
                      {timeslot && (
                        <>
                          <div className={styles.to}>TO</div>
                          <div>
                            {newAppointmentStartTime.format('dddd Do MMMM')}
                            <br />
                            {newAppointmentStartTime.format('hh:mm')} to {newAppointmentEndTime.format('hh:mm A')}
                          </div>
                        </>
                      )}
                    </div>
                    <Button variant="primary" onClick={submitForm} status={status} className={styles.rescheduleButton}>
                      {rescheduleAction === RescheduleAction.Instant ? 'Reschedule Appointment' : 'Request Reschedule'}
                    </Button>
                  </div>
                </div>
              </>
            ) : (
              <div className={styles.informationBoxContainer}>
                <div className={classnames(styles.backgroundContainer, styles.warning)}>
                  <div className={styles.messageContainer}>
                    <i className={`material-icons-outlined ${styles.warningIcon}`}>warning_amber</i>
                    <div className={styles.descriptionContainer}>
                      <div className={styles.description}>{getErrorMessage(rescheduleAction, rescheduleRules)}</div>
                      <div className={styles.description}>{notAllowedDescription}</div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </Formik>
    </Modal>
  );
};

export default ChangeAppointmentModal;
