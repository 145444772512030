import { useContext, useEffect, useState } from 'react';

import styles from './ClinicianTimeSlot.module.scss';
import { PractitionerDetailsInterface } from 'interfaces/PublicProfile/Practitioner/practitioner';
import StepTitle from './components/StepTitle/StepTitle';
import AppointmentTypeCard from './components/AppointmentTypeCard/AppointmentTypeCard';
import Calendar from './components/Calendar/Calendar';
import BookingSession from './components/BookingSession/BookingSession';
import moment from 'moment';
import { useAuth0 } from '@auth0/auth0-react';
import {
  AvailabilityAppointmentTypes,
  DeliveryType,
  TimeSlotsWithDateInterface,
  useAvailabilityWithTimeZone,
  useFetchAvailabilityAppointmentTypes
} from 'utils/hooks/appointment';
import { notification, Skeleton } from 'antd';
import { scrollToView } from 'utils/scrollToView';
import { postReservedAppointment } from 'utils/http/appointment';
import { useSetReserveAppointmentData } from 'CaW/utils/hooks/localData';
import { useNavigate, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { massageTimeSlotReverse } from 'CaW/pages/ClinicianListing/hooks/getPsychologistList';
import NoAvailableMessageBox from 'CaW/components/NoAvailableMessageBox/NoAvailableMessageBox';
import { BookingRuleType, useFetchClientBookingRule } from 'utils/hooks/clientRecords';
import { useCaWRoutesGenerator } from 'CaW/utils/Path/CaWRoutesGenerator';
import { cawEnvironment } from 'CaW/utils/CaWEnv/cawEnv';
import { CAW_TIME_ZONE_LIST, cawTimeZoneLocalStorage } from '../../../ClinicianListing/ClinicianListing';
import { fetchClaimingReservation } from 'CaW/utils/hooks/useClaimReservationWithoutCheckoutSession';
import { useCaWToken } from 'CaW/utils/hooks/useCaWToken';
import { isSlotSelected } from './components/Calendar/components/SlotPicker/SlotPicker';
import CaWContentLayout from 'CaW/components/CaWContentLayout/CaWContentLayout';
import { UserContext } from 'utils/UserContextProvider';
import { massageDateTimeFromSourceTzToTargetTz } from 'utils/constants/timeZone';
import { getEngageDefaultAppointmentDeliveryType } from 'utils/appointment';

export interface TimeSlotBooking {
  accountId: string;
  sessionTypeId: string;
  slots: TimeSlotsWithDateInterface[];
  returnUrl: string;
  cancelUrl: string;
}

interface PsychologistTimeSlotProps {
  helmDetails: PractitionerDetailsInterface;
  presetEmployerCode?: string;
  onShowWaitlistForm: () => void;
}

const ClinicianTimeSlot = ({ helmDetails, presetEmployerCode }: PsychologistTimeSlotProps) => {
  const { user, isAuthenticated } = useAuth0();
  const { setReserveAppointmentData } = useSetReserveAppointmentData();
  const { token } = useCaWToken();
  const { clientProfile } = useContext(UserContext);
  const { clientBookingRule, isClientBookingRuleLoading } = useFetchClientBookingRule();

  const { availabilityAppointmentTypes: fullAvailabilityAppointmentTypes, isAvailabilityAppointmentTypesLoading } =
    useFetchAvailabilityAppointmentTypes({
      accountId: helmDetails.accountId,
      clinicianId: helmDetails._id
    });
  const [availabilityATWithoutQuotaChecking, setAvailabilityATWithoutQuotaChecking] = useState<
    AvailabilityAppointmentTypes[]
  >([]);
  const [availabilityAppointmentTypes, setAvailabilityAppointmentTypes] = useState<AvailabilityAppointmentTypes[]>([]);
  const [isNewClient, setIsNewClient] = useState<boolean>();
  const { SIGNUP } = useCaWRoutesGenerator();
  const navigate = useNavigate();
  const { search } = useLocation();
  const { CaWContactUsURL } = cawEnvironment();
  const { selectedDateTime }: { selectedDateTime?: string } = queryString.parse(search);

  const [selectedAppointmentType, setSelectedAppointmentType] = useState<AvailabilityAppointmentTypes | undefined>();
  const [selectedDeliveryType, setSelectedDeliveryType] = useState<DeliveryType>(DeliveryType.VideoCall);
  const [selectedTimeSlots, setSelectedTimeSlots] = useState<TimeSlotsWithDateInterface[]>([]);
  const [isInitParamsUsed, setIsInitParamsUsed] = useState(false);
  const [isProcessingReservation, setIsProcessingReservation] = useState(false);

  const clientTimeZone = localStorage.getItem(cawTimeZoneLocalStorage);
  const [selectedTimeZone, setSelectedTimezone] = useState(
    clientTimeZone ||
      // use browser timezone if browser timezone is supported
      CAW_TIME_ZONE_LIST.find((tzObj) =>
        // eslint-disable-next-line new-cap
        tzObj.timezones.find((tzString) => tzString === Intl.DateTimeFormat().resolvedOptions().timeZone)
      )?.id ||
      // use default client timezone
      CAW_TIME_ZONE_LIST.find((tzObj) => tzObj.id === process.env.REACT_APP_DEFAULT_CLIENT_TIMEZONE)?.id ||
      CAW_TIME_ZONE_LIST[0].id
  );

  const handleChangeAppointmentType = (valItem: AvailabilityAppointmentTypes) => {
    if (valItem.name !== selectedAppointmentType?.name) {
      setSelectedTimeSlots([]);
    }
    setSelectedAppointmentType(valItem);
    setSelectedDeliveryType(getEngageDefaultAppointmentDeliveryType(valItem?.deliveryOptions || []));
  };

  const handleChangeTimeZone = (val: string) => {
    if (val !== selectedTimeZone) {
      setSelectedTimezone(val);
      localStorage.setItem(cawTimeZoneLocalStorage, val);

      if (selectedTimeSlots.length) {
        const newSelectedSlots = selectedTimeSlots.map((slot) => {
          const { date, startTime, endTime } = massageDateTimeFromSourceTzToTargetTz({
            ...slot,
            sourceTz: selectedTimeZone,
            targetTZ: val
          });
          return {
            ...slot,
            date,
            startTime,
            endTime
          };
        });
        setSelectedTimeSlots(newSelectedSlots);
      }
    }
  };

  const { appointmentAvailability, isAvailabilityListLoading } = useAvailabilityWithTimeZone({
    appointmentTypeId: selectedAppointmentType?._id!,
    accountId: helmDetails?.accountId!,
    from: moment().format('YYYY-MM-DD'),
    to: moment().add(12, 'month').format('YYYY-MM-DD'),
    timeZone: selectedTimeZone,
    clinicianId: helmDetails._id,
    clinicianTimeZone: helmDetails.workTimeZone
  });

  useEffect(() => {
    if (!isAvailabilityAppointmentTypesLoading && fullAvailabilityAppointmentTypes) {
      const filteredATWithoutQuotaChecking = fullAvailabilityAppointmentTypes.filter(
        (item: AvailabilityAppointmentTypes) =>
          isNewClient ? item.bookingRules?.new.available : item.bookingRules?.existing.available
      );

      const filteredATWithQuotaChecking = filteredATWithoutQuotaChecking.filter(
        (item: AvailabilityAppointmentTypes) =>
          isNewClient || (clientProfile?.appointmentQuota || 0) >= (item?.slotCount || 1)
      );

      const appointmentType = filteredATWithQuotaChecking?.[0];
      setSelectedAppointmentType(appointmentType);
      const allAppointmentType: string[] = [];
      filteredATWithQuotaChecking?.map((obj) => obj.deliveryOptions.map((typeObj) => allAppointmentType.push(typeObj)));
      setSelectedDeliveryType(getEngageDefaultAppointmentDeliveryType(appointmentType?.deliveryOptions || []));
      setAvailabilityATWithoutQuotaChecking(filteredATWithoutQuotaChecking);
      setAvailabilityAppointmentTypes(filteredATWithQuotaChecking);
    }
  }, [isAvailabilityAppointmentTypesLoading, isNewClient, fullAvailabilityAppointmentTypes, clientProfile]);

  useEffect(() => {
    if (!isAvailabilityListLoading && appointmentAvailability && !isInitParamsUsed) {
      const [date, startTime, endTime] = selectedDateTime?.split(',') || [];
      if (date && startTime && endTime) {
        const getTimeSlotInfo = appointmentAvailability.timeSlots.find(
          (item) => item.date === date && item.startTime === startTime && item.endTime === endTime
        );
        getTimeSlotInfo && setSelectedTimeSlots([getTimeSlotInfo]);
      }
      setIsInitParamsUsed(true);
    }
  }, [appointmentAvailability, isAvailabilityListLoading, selectedDateTime, isInitParamsUsed]);

  useEffect(() => {
    if (!isClientBookingRuleLoading) {
      const checkNewClient = clientBookingRule === BookingRuleType.New || !isAuthenticated;
      setIsNewClient(checkNewClient);
    }
  }, [clientBookingRule, isAuthenticated, isClientBookingRuleLoading]);

  const onContinue = async () => {
    if (selectedAppointmentType) {
      setIsProcessingReservation(true);
      try {
        const res = await postReservedAppointment(helmDetails.accountId, {
          isNewClient: clientBookingRule === BookingRuleType.New || !isAuthenticated,
          slots: selectedTimeSlots.map((item) => massageTimeSlotReverse(item, selectedTimeZone)),
          deliveryType: selectedDeliveryType,
          appointmentTypeId: selectedAppointmentType._id,
          ...(!selectedAppointmentType.isAdvisory && {
            clinicianId: helmDetails._id
          })
        });
        if (res.statusCode === 200) {
          const reservedAppointmentData = await res.json();
          // claim here got login user
          if (isAuthenticated) {
            await fetchClaimingReservation(reservedAppointmentData.reserveId, helmDetails.accountId, token!);
          }
          setReserveAppointmentData({
            reserveId: reservedAppointmentData.reserveId,
            clinicianId: reservedAppointmentData.clinicianId,
            accountId: helmDetails.accountId,
            appointmentTypeInfo: {
              name: selectedAppointmentType.name || '',
              description: selectedAppointmentType.description || '',
              rate: selectedAppointmentType.rate || 0,
              isAdvisory: selectedAppointmentType.isAdvisory || false,
              deliveryType: selectedDeliveryType,
              otherInstructions: selectedAppointmentType.otherInstructions,
              timeSlot: selectedTimeSlots.map((item) => massageTimeSlotReverse(item, selectedTimeZone)),
              sessionTypeId: selectedAppointmentType._id
            },
            programId: selectedAppointmentType.programId,
            psychologistName: helmDetails.name,
            presetEmployerCode
          });
          scrollToView('CaWHeader');
          if (isAuthenticated) {
            navigate(SIGNUP.CONFIRM_BOOKING);
          } else {
            navigate(SIGNUP.BASE);
          }
        } else if (res.statusCode === 409) {
          const conflictAppointmentData = await res.json();
          const conflictSlotList: TimeSlotsWithDateInterface[] = conflictAppointmentData.conflictingSlots || [];

          const timeSlotWithStatus = selectedTimeSlots.map((appointmentListObj) => {
            const startTimeSlot = moment(appointmentListObj.startTime, 'hh:mmA').format('HH:mm');
            const endTimeSlot = moment(appointmentListObj.endTime, 'hh:mmA').format('HH:mm');

            const isConflictSlot = conflictSlotList.some(
              (slotObj) =>
                slotObj.date === appointmentListObj.date &&
                slotObj.startTime === startTimeSlot &&
                slotObj.endTime === endTimeSlot
            );

            return {
              ...appointmentListObj,
              isConflict: isConflictSlot
            };
          });
          setSelectedTimeSlots(timeSlotWithStatus);
        }
      } catch (ex) {
        console.error(ex);
        notification.error({ message: 'Something went wrong while trying to reserve the appointment' });
      }
      setIsProcessingReservation(false);
    }
  };

  const onTimeSlotsChange = (val: TimeSlotsWithDateInterface) => {
    if (!isSlotSelected({ selectedSlots: selectedTimeSlots, slot: val })) {
      setSelectedTimeSlots([...selectedTimeSlots, val]);
    } else onRemoveSelectedSlot(val);
  };

  const onRemoveSelectedSlot = (slot: TimeSlotsWithDateInterface) => {
    const index = selectedTimeSlots.findIndex(
      (selectedSlot) =>
        selectedSlot.date === slot.date &&
        selectedSlot.startTime === slot.startTime &&
        selectedSlot.endTime === slot.endTime
    );
    if (index !== undefined && index >= 0) {
      selectedTimeSlots.splice(index, 1);
      setSelectedTimeSlots?.([...selectedTimeSlots]);
    }
  };

  const checkSignUpClaimForSameClinician = isAuthenticated
    ? user?.['https://tacklit.com/attachedClinicians'].includes(helmDetails._id)
    : true;

  const isNewClientHasNoSlots =
    !isAuthenticated && helmDetails.helmControl?.shouldUseCaseLoad && (helmDetails.caseLoad?.remainingSlots || 0) <= 0;

  const countCalendarMaxDate = () => {
    const todayDate = moment();
    const employerRenewalDate = moment(clientProfile?.renewalDate, 'DD/MM');
    const countHowManyDayLeft = employerRenewalDate.diff(todayDate, 'day');

    return (countHowManyDayLeft <= 0 ? employerRenewalDate.add(1, 'year') : employerRenewalDate)
      .add(-1, 'day')
      .format('YYYY-MM-DD');
  };

  const calendarMaxDate = clientProfile?.renewalDate ? countCalendarMaxDate() : '';

  return (
    <div id={'CaWTimeSlot'}>
      {!isNewClient && (clientProfile?.appointmentQuota || 0) <= 0 ? (
        <div className={styles.noAppointmentContainer}>
          <NoAvailableMessageBox
            title={`Want to book with ${helmDetails.name}?`}
            desc={
              <span>
                Unfortunately you have exhausted your eligible EAP allocation for this contract year. Options for
                additional sessions include a request for extra sessions from your Clinician or appropriate
                authorisation of extra sessions by your Organisation. Please contact CaW Client Services on{' '}
                <b className={styles.phoneNumber}>1800 099 444</b> to discuss further if needed.
              </span>
            }
            showButton={false}
          />
        </div>
      ) : isNewClientHasNoSlots ? (
        <div className={styles.noAppointmentContainer}>
          <NoAvailableMessageBox
            title={`Want to book with ${helmDetails.name}?`}
            desc={
              <span>
                Unfortunately, {helmDetails.name} is not open to new clients at this time. Please select another
                Clinician or contact the Client Services Team on 1800 099 444.
              </span>
            }
            showButton={false}
          />
        </div>
      ) : !checkSignUpClaimForSameClinician ? (
        <div className={styles.noAppointmentContainer}>
          <NoAvailableMessageBox
            title={`Want to book with ${helmDetails.name}?`}
            desc={
              'As you are an existing Caraniche at Work customer please contact our support team to discuss working with a new counsellor'
            }
            buttonLabel={'Contact Caraniche at Work Team'}
            onClick={() => (window.location.href = CaWContactUsURL)}
          />
        </div>
      ) : (
        <>
          <div className={styles.appointmentSlotContainer}>
            <CaWContentLayout>
              {availabilityATWithoutQuotaChecking.length <= 0 && !isAvailabilityAppointmentTypesLoading ? (
                <div className={styles.noAppointmentContainer}>
                  <NoAvailableMessageBox
                    title={`Want to book with ${helmDetails.name}?`}
                    desc={
                      <span>
                        Unfortunately, {helmDetails.name} is not open to new clients at this time. Please select another
                        Clinician or contact the Client Services Team on 1800 099 444.
                      </span>
                    }
                    showButton={false}
                  />
                </div>
              ) : (
                <div className={styles.content}>
                  <div className={styles.header}>
                    <div className={styles.leftContent}>
                      <div className={styles.titleWrapper}>
                        <div className={styles.title}>
                          {isAuthenticated ? 'Book time with me' : 'Book your first appointment with me'}
                        </div>
                      </div>
                      {helmDetails.helmControl?.appointmentPhoto && (
                        <img
                          className={styles.profile}
                          alt={'profile'}
                          src={helmDetails.helmControl.appointmentPhoto}
                        />
                      )}
                    </div>
                  </div>
                  <div className={styles.appointmentContentWrapper}>
                    <div>
                      <StepTitle no={'1'} title={'Select appointment'} />
                      <div className={styles.appointmentType}>
                        {isAvailabilityAppointmentTypesLoading ? (
                          <div className={styles.loadingWrapper}>
                            {[...Array(4)].map((obj, i) => (
                              <Skeleton active key={i} className={styles.loading} />
                            ))}
                          </div>
                        ) : (
                          availabilityAppointmentTypes.map((item, index) => (
                            <AppointmentTypeCard
                              key={index}
                              appointmentTypeData={item}
                              onClick={() => !isAvailabilityListLoading && handleChangeAppointmentType(item)}
                              selected={selectedAppointmentType?.name === item.name}
                              disable={isAvailabilityListLoading}
                            />
                          ))
                        )}
                      </div>
                    </div>
                    <div>
                      <StepTitle no={'2'} title={'Select day and time'} />
                      <Calendar
                        onSlotClick={onTimeSlotsChange}
                        timeSlots={appointmentAvailability?.timeSlots || []}
                        remainSlots={(selectedAppointmentType?.slotCount || 1) - selectedTimeSlots.length}
                        appointmentTypeSlotCount={selectedAppointmentType?.slotCount}
                        selectedTimeSlots={selectedTimeSlots}
                        isAvailabilityListLoading={isAvailabilityListLoading}
                        selectedTimeZone={selectedTimeZone}
                        handleChangeTimeZone={handleChangeTimeZone}
                        renewalDate={calendarMaxDate}
                      />
                    </div>
                  </div>
                </div>
              )}
            </CaWContentLayout>
          </div>
          {(!selectedAppointmentType?.isAdvisory || helmDetails.helmControl?.isAdvisor) &&
            availabilityAppointmentTypes.length > 0 && (
              <BookingSession
                selectedAppointmentType={selectedAppointmentType}
                selectedTimeSlots={selectedTimeSlots}
                isAvailabilityListLoading={isAvailabilityListLoading}
                isProcessingReservation={isProcessingReservation}
                onContinue={onContinue}
                onRemoveSelectedSlot={onRemoveSelectedSlot}
                selectedDeliveryPreference={selectedDeliveryType}
                onChangeDeliveryPreference={(value) => setSelectedDeliveryType(value)}
              />
            )}
        </>
      )}
    </div>
  );
};

export default ClinicianTimeSlot;
