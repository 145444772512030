import { useEffect, useState } from 'react';

import styles from './PsychologistTimeSlot.module.scss';
import {
  AppointmentBookingClaimType,
  PractitionerDetailsInterface
} from 'interfaces/PublicProfile/Practitioner/practitioner';
import moment from 'moment';
import momentTz from 'moment-timezone';
import { useAuth0 } from '@auth0/auth0-react';
import {
  AvailabilityAppointmentTypes,
  DeliveryType,
  TimeSlotsWithDateInterface,
  useAvailabilityWithTimeZone,
  useFetchAvailabilityAppointmentTypes
} from 'utils/hooks/appointment';
import { notification, Skeleton } from 'antd';
import { scrollToView } from 'utils/scrollToView';
import { postReservedAppointment } from 'utils/http/appointment';
import {
  reservedHBPartnerReferral,
  useSetReserveAppointmentData
} from 'utils/hooks/EngageReserved/reservedAppointment';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import queryString from 'query-string';
import { BookingRuleType, useFetchClientBookingRule } from 'utils/hooks/clientRecords';
import { fetchClaimingReservation } from 'utils/hooks/EngageReserved/useClaimReservationWithoutCheckoutSession';
import { isSlotSelected } from 'SomeoneHealth/pages/PsychologistDetails/components/PsychologistTimeSlot/components/Calendar/components/SlotPicker/SlotPicker';
import LoadingDot from 'components/LoadingDot/LoadingDot';
import { useGetAttachedClinicianDetails } from 'redux/endpoints/clinicianProfileServices/getClientDetails';
import { useHealthBrightPartnerRoutesGenerator } from 'HealthBright/HealthBrightPartner/utils/Path/HealthBrightPartnerRoutesGenerator';
import { PARTNER_CODE_STORAGE } from 'interfaces/PartnerReferral/constants';
import { getEngageDefaultAppointmentDeliveryType } from 'utils/appointment';
import HealthBrightContentLayout from 'HealthBright/components/HealthBrightContentLayout/HealthBrightContentLayout';
import AppointmentTypeCard from 'HealthBright/components/PsychologistDetails/components/PsychologistTimeSlot/components/AppointmentTypeCard/AppointmentTypeCard';
import BookingSession from 'HealthBright/components/PsychologistDetails/components/PsychologistTimeSlot/components/BookingSession/BookingSession';
import NoAvailableMessageBox from 'HealthBright/components/NoAvailableMessageBox/NoAvailableMessageBox';
import StepTitle from 'HealthBright/components/PsychologistDetails/components/PsychologistTimeSlot/components/StepTitle/StepTitle';
import Calendar, {
  displayMonth
} from 'HealthBright/components/PsychologistDetails/components/PsychologistTimeSlot/components/Calendar/Calendar';
import { useHealthBrightToken } from 'HealthBright/utils/hooks/useHealthBrightToken';
import { HEALTH_BRIGHT_TIME_ZONE_LIST, healthBrightTimeZoneLocalStorage } from 'utils/constants/timeZone';
import { healthBrightEnvironment } from 'HealthBright/HealthBrightPartner/utils/HealthBrightEnv';
import { massageTimeSlotReverse } from 'HealthBright/HealthBrightPartner/pages/PartnerPsychologistListing/hooks/getPsychologistList';

interface PsychologistTimeSlotProps {
  helmDetails: PractitionerDetailsInterface;
  onShowWaitlistForm: () => void;
}

export const massageDateTimeFromSourceTzToTargetTz = ({
  date,
  startTime,
  endTime,
  sourceTz,
  targetTZ
}: {
  date: string;
  startTime: string;
  endTime: string;
  sourceTz: string;
  targetTZ: string;
}) => {
  const clinicianStartTimeZone = momentTz.tz(`${date} ${startTime}`, 'YYYY-MM-DD hh:mmA', sourceTz);
  const clientStartTimeZone = momentTz.tz(clinicianStartTimeZone, targetTZ);
  const clinicianEndTimeZone = momentTz.tz(`${date} ${endTime}`, 'YYYY-MM-DD hh:mmA', sourceTz);
  const clientEndTimeZone = momentTz.tz(clinicianEndTimeZone, targetTZ);
  return {
    date: clientStartTimeZone.format('YYYY-MM-DD'),
    startTime: clientStartTimeZone.format('hh:mmA'),
    endTime: clientEndTimeZone.format('hh:mmA')
  };
};

const PsychologistTimeSlot = ({ helmDetails, onShowWaitlistForm }: PsychologistTimeSlotProps) => {
  const navigate = useNavigate();
  const { SIGNUP, PRACTITIONER } = useHealthBrightPartnerRoutesGenerator();
  const { isAuthenticated } = useAuth0();
  const { setReserveAppointmentData } = useSetReserveAppointmentData(reservedHBPartnerReferral);
  const { token } = useHealthBrightToken();
  const { attachedClinicianDetail, isAttachedClinicianDetailLoading } = useGetAttachedClinicianDetails();

  const { clientBookingRule, isClientBookingRuleLoading } = useFetchClientBookingRule();

  const getPartnerCode = localStorage.getItem(PARTNER_CODE_STORAGE) as AppointmentBookingClaimType;
  const clientAppointmentType: AppointmentBookingClaimType = getPartnerCode;

  const { availabilityAppointmentTypes: fullAvailabilityAppointmentTypes, isAvailabilityAppointmentTypesLoading } =
    useFetchAvailabilityAppointmentTypes({
      accountId: helmDetails.accountId,
      clinicianId: helmDetails._id,
      claimType: clientAppointmentType
    });
  const [availabilityAppointmentTypes, setAvailabilityAppointmentTypes] = useState<AvailabilityAppointmentTypes[]>([]);
  const [isNewClient, setIsNewClient] = useState<boolean>();

  const { search } = useLocation();
  const { Contact } = healthBrightEnvironment();
  const { selectedDateTime }: { selectedDateTime?: string } = queryString.parse(search);

  const [selectedAppointmentType, setSelectedAppointmentType] = useState<AvailabilityAppointmentTypes | undefined>();
  const [selectedDeliveryType, setSelectedDeliveryType] = useState<DeliveryType>(DeliveryType.VideoCall);
  const [selectedTimeSlots, setSelectedTimeSlots] = useState<TimeSlotsWithDateInterface[]>([]);
  const [isInitParamsUsed, setIsInitParamsUsed] = useState(false);
  const [isProcessingReservation, setIsProcessingReservation] = useState(false);

  const clientTimeZone = localStorage.getItem(healthBrightTimeZoneLocalStorage);
  const [selectedTimeZone, setSelectedTimezone] = useState(
    clientTimeZone ||
      // use browser timezone if browser timezone is supported
      HEALTH_BRIGHT_TIME_ZONE_LIST.find((tzObj) =>
        // eslint-disable-next-line new-cap
        tzObj.timezones.find((tzString) => tzString === Intl.DateTimeFormat().resolvedOptions().timeZone)
      )?.id ||
      // use default client timezone
      HEALTH_BRIGHT_TIME_ZONE_LIST.find((tzObj) => tzObj.id === process.env.REACT_APP_DEFAULT_CLIENT_TIMEZONE)?.id ||
      HEALTH_BRIGHT_TIME_ZONE_LIST[0].id
  );

  const handleChangeAppointmentType = (valItem: AvailabilityAppointmentTypes) => {
    if (valItem.name !== selectedAppointmentType?.name) {
      setSelectedTimeSlots([]);
    }
    setSelectedAppointmentType(valItem);
    setSelectedDeliveryType(getEngageDefaultAppointmentDeliveryType(valItem?.deliveryOptions || []));
  };

  const handleChangeTimeZone = (val: string) => {
    if (val !== selectedTimeZone) {
      setSelectedTimezone(val);
      localStorage.setItem(healthBrightTimeZoneLocalStorage, val);

      if (selectedTimeSlots.length) {
        const newSelectedSlots = selectedTimeSlots.map((slot) => {
          const { date, startTime, endTime } = massageDateTimeFromSourceTzToTargetTz({
            ...slot,
            sourceTz: selectedTimeZone,
            targetTZ: val
          });
          return {
            ...slot,
            date,
            startTime,
            endTime
          };
        });
        setSelectedTimeSlots(newSelectedSlots);
      }
    }
  };

  const { appointmentAvailability, isAvailabilityListLoading } = useAvailabilityWithTimeZone({
    appointmentTypeId: selectedAppointmentType?._id!,
    accountId: helmDetails?.accountId!,
    from: moment().format('YYYY-MM-DD'),
    to: moment().add(displayMonth, 'month').format('YYYY-MM-DD'),
    clinicianId: helmDetails._id,
    timeZone: selectedTimeZone || HEALTH_BRIGHT_TIME_ZONE_LIST[0].id,
    clinicianTimeZone: helmDetails.workTimeZone
  });

  useEffect(() => {
    if (!isAvailabilityAppointmentTypesLoading && fullAvailabilityAppointmentTypes) {
      const newFilteredAppointmentType = fullAvailabilityAppointmentTypes.filter(
        ({ bookingRules }: AvailabilityAppointmentTypes) =>
          isNewClient ? bookingRules?.new.available : bookingRules?.existing.available
      );

      const appointmentType = newFilteredAppointmentType?.[0];
      setSelectedAppointmentType(appointmentType);
      const allAppointmentType: string[] = [];
      newFilteredAppointmentType?.map((obj) => obj.deliveryOptions.map((typeObj) => allAppointmentType.push(typeObj)));
      setSelectedDeliveryType(getEngageDefaultAppointmentDeliveryType(appointmentType?.deliveryOptions || []));
      setAvailabilityAppointmentTypes(newFilteredAppointmentType);
    }
  }, [isAvailabilityAppointmentTypesLoading, isNewClient, fullAvailabilityAppointmentTypes, clientAppointmentType]);

  useEffect(() => {
    if (!isAvailabilityListLoading && appointmentAvailability && !isInitParamsUsed) {
      const [date, startTime, endTime] = selectedDateTime?.split(',') || [];
      if (date && startTime && endTime) {
        const getTimeSlotInfo = appointmentAvailability.timeSlots.find(
          (item) => item.date === date && item.startTime === startTime && item.endTime === endTime
        );
        getTimeSlotInfo && setSelectedTimeSlots([getTimeSlotInfo]);
      }
      setIsInitParamsUsed(true);
    }
  }, [appointmentAvailability, isAvailabilityListLoading, selectedDateTime, isInitParamsUsed]);

  useEffect(() => {
    if (!isClientBookingRuleLoading) {
      const checkNewClient = clientBookingRule === BookingRuleType.New || !isAuthenticated;
      setIsNewClient(checkNewClient);
    }
  }, [clientBookingRule, isAuthenticated, isClientBookingRuleLoading]);

  const onContinue = async () => {
    if (selectedAppointmentType) {
      setIsProcessingReservation(true);
      try {
        const payload = {
          isNewClient: clientBookingRule === BookingRuleType.New || !isAuthenticated,
          slots: selectedTimeSlots.map((item) => massageTimeSlotReverse(item, selectedTimeZone)),
          deliveryType: selectedDeliveryType,
          appointmentTypeId: selectedAppointmentType._id,
          ...(!selectedAppointmentType.isAdvisory && {
            clinicianId: helmDetails._id
          })
        };
        const res = await postReservedAppointment(helmDetails.accountId, payload);
        if (res.statusCode === 200) {
          const reservedAppointmentData = await res.json();
          // claim here got login user
          if (isAuthenticated) {
            await fetchClaimingReservation(reservedAppointmentData.reserveId, helmDetails.accountId, token!);
          }
          setReserveAppointmentData({
            reserveId: reservedAppointmentData.reserveId,
            clinicianId: reservedAppointmentData.clinicianId,
            accountId: helmDetails.accountId,
            appointmentTypeInfo: {
              name: selectedAppointmentType.name || '',
              description: selectedAppointmentType.description || '',
              rate: selectedAppointmentType.rate || 0,
              isAdvisory: selectedAppointmentType.isAdvisory || false,
              deliveryType: selectedDeliveryType,
              otherInstructions: selectedAppointmentType.otherInstructions,
              timeSlot: selectedTimeSlots.map((item) => massageTimeSlotReverse(item, selectedTimeZone)),
              sessionTypeId: selectedAppointmentType._id
            },
            programId: selectedAppointmentType.programId,
            psychologistName: helmDetails.name
          });
          scrollToView('HealthBrightHeader');
          navigate(SIGNUP.BASE);
        } else if (res.statusCode === 409) {
          const conflictAppointmentData = await res.json();
          const conflictSlotList: TimeSlotsWithDateInterface[] = conflictAppointmentData.conflictingSlots || [];

          const timeSlotWithStatus = selectedTimeSlots.map((appointmentListObj) => {
            const startTimeSlot = moment(appointmentListObj.startTime, 'hh:mmA').format('HH:mm');
            const endTimeSlot = moment(appointmentListObj.endTime, 'hh:mmA').format('HH:mm');

            const isConflictSlot = conflictSlotList.some(
              (slotObj) =>
                slotObj.date === appointmentListObj.date &&
                slotObj.startTime === startTimeSlot &&
                slotObj.endTime === endTimeSlot
            );

            return {
              ...appointmentListObj,
              isConflict: isConflictSlot
            };
          });
          setSelectedTimeSlots(timeSlotWithStatus);
        }
      } catch (ex) {
        console.error(ex);
        notification.error({ message: 'Something went wrong while trying to reserve the appointment' });
      }
      setIsProcessingReservation(false);
    }
  };

  const onTimeSlotsChange = (val: TimeSlotsWithDateInterface) => {
    if (!isSlotSelected({ selectedSlots: selectedTimeSlots, slot: val })) {
      setSelectedTimeSlots([...selectedTimeSlots, val]);
    } else onRemoveSelectedSlot(val);
  };

  const onRemoveSelectedSlot = (slot: TimeSlotsWithDateInterface) => {
    const index = selectedTimeSlots.findIndex(
      (selectedSlot) =>
        selectedSlot.date === slot.date &&
        selectedSlot.startTime === slot.startTime &&
        selectedSlot.endTime === slot.endTime
    );
    if (index !== undefined && index >= 0) {
      selectedTimeSlots.splice(index, 1);
      setSelectedTimeSlots?.([...selectedTimeSlots]);
    }
  };

  const checkSignUpClaimForSameClinician = isAuthenticated
    ? !attachedClinicianDetail?.clinician || attachedClinicianDetail?.clinician._id === helmDetails._id
    : true;

  const isNewClientHasNoSlots =
    !isAuthenticated && helmDetails.helmControl?.shouldUseCaseLoad && (helmDetails.caseLoad?.remainingSlots || 0) <= 0;

  return (
    <div id="HealthBrightTimeSlot">
      {isNewClientHasNoSlots ? (
        <div className={styles.noAppointmentContainer}>
          <NoAvailableMessageBox
            title={`Want to book with ${helmDetails.name}?`}
            desc={
              <span>
                Unfortunately, {helmDetails.name} is not open to new clients at this time. If you’d like to register
                your interest, you can join the waitlist here or
                <Link
                  className={styles.otherPsychologists}
                  to={PRACTITIONER.LISTING}
                  onClick={() => scrollToView('HealthBrightHeader')}
                >
                  {' '}
                  see other psychologists available{' '}
                </Link>
                now.
              </span>
            }
            buttonLabel={`Join waitlist for ${helmDetails.name}`}
            onClick={onShowWaitlistForm}
          />
        </div>
      ) : isAttachedClinicianDetailLoading && isAuthenticated ? (
        <div className={styles.noAppointmentContainer}>
          <LoadingDot />
        </div>
      ) : !checkSignUpClaimForSameClinician ? (
        <div className={styles.noAppointmentContainer}>
          <NoAvailableMessageBox
            title={`Want to book with ${helmDetails.name}?`}
            desc={
              'As you are an existing HealthBright customer please contact our support team to discuss working with a new counsellor'
            }
            buttonLabel={'Contact HealthBright Team'}
            onClick={() => (window.location.href = Contact)}
          />
        </div>
      ) : (
        <>
          <div className={styles.appointmentSlotContainer}>
            <HealthBrightContentLayout>
              {availabilityAppointmentTypes.length <= 0 && !isAvailabilityAppointmentTypesLoading ? (
                <div className={styles.noAppointmentContainer}>
                  <NoAvailableMessageBox
                    title={`Want to book with ${helmDetails.name}?`}
                    desc={
                      <span>
                        Unfortunately, {helmDetails.name} is not open to new clients at this time. If you’d like to
                        register your interest, you can join the waitlist here or
                        <Link
                          className={styles.otherPsychologists}
                          to={PRACTITIONER.LISTING}
                          onClick={() => scrollToView('HealthBrightHeader')}
                        >
                          {' '}
                          see other psychologists available{' '}
                        </Link>
                        now.
                      </span>
                    }
                    buttonLabel={`Join waitlist for ${helmDetails.name}`}
                    onClick={onShowWaitlistForm}
                  />
                </div>
              ) : (
                <div className={styles.content}>
                  <div className={styles.header}>
                    <div className={styles.leftContent}>
                      <div className={styles.titleWrapper}>
                        <div className={styles.title}>
                          {isAuthenticated ? 'Book time with me' : 'Book your first appointment with me'}
                        </div>
                      </div>
                      {helmDetails.helmControl?.appointmentPhoto && (
                        <img
                          className={styles.profile}
                          alt={'profile'}
                          src={helmDetails.helmControl.appointmentPhoto}
                        />
                      )}
                    </div>
                  </div>
                  <div className={styles.appointmentContentWrapper}>
                    <div>
                      <StepTitle no={'1'} title={'Select appointment'} />
                      <div className={styles.desc}>
                        Please click on the appointment type you prefer, e.g., half hour or on the hour. When selected,
                        the appointment type will colour blue.
                      </div>
                      <div className={styles.appointmentType}>
                        {isAvailabilityAppointmentTypesLoading ? (
                          <div className={styles.loadingWrapper}>
                            {[...Array(4)].map((obj, i) => (
                              <Skeleton active key={i} className={styles.loading} />
                            ))}
                          </div>
                        ) : (
                          availabilityAppointmentTypes.map((item, index) => (
                            <AppointmentTypeCard
                              key={index}
                              appointmentTypeData={item}
                              onClick={() => !isAvailabilityListLoading && handleChangeAppointmentType(item)}
                              selected={selectedAppointmentType?.name === item.name}
                              disable={isAvailabilityListLoading}
                            />
                          ))
                        )}
                      </div>
                    </div>
                    <div>
                      <StepTitle no={'2'} title={'Select day and time'} />
                      <Calendar
                        onSlotClick={onTimeSlotsChange}
                        timeSlots={appointmentAvailability?.timeSlots || []}
                        remainSlots={(selectedAppointmentType?.slotCount || 1) - selectedTimeSlots.length}
                        selectedTimeSlots={selectedTimeSlots}
                        isAvailabilityListLoading={isAvailabilityListLoading}
                        selectedTimeZone={selectedTimeZone}
                        handleChangeTimeZone={handleChangeTimeZone}
                      />
                    </div>
                  </div>
                </div>
              )}
            </HealthBrightContentLayout>
          </div>
          {availabilityAppointmentTypes.length > 0 && (
            <BookingSession
              helmDetails={helmDetails}
              selectedAppointmentType={selectedAppointmentType}
              selectedTimeSlots={selectedTimeSlots}
              isAvailabilityListLoading={isAvailabilityListLoading}
              isProcessingReservation={isProcessingReservation}
              onContinue={onContinue}
              onRemoveSelectedSlot={onRemoveSelectedSlot}
              selectedDeliveryPreference={selectedDeliveryType}
              onChangeDeliveryPreference={(value) => setSelectedDeliveryType(value)}
              classname={styles.bookingSession}
            />
          )}
        </>
      )}
    </div>
  );
};

export default PsychologistTimeSlot;
