import { useState } from 'react';
import FilterDropdown, { listInterface } from 'CaW/components/CaWFilter/components/FilterDropdown/FilterDropdown';
import styles from './FitFilter.module.scss';
import {
  FIT_PSYCHOLOGIST_GENDER_LIST_LABELS,
  FIT_DAYS_LIST_LABELS,
  FIT_DELIVERY_LIST_LABELS,
  FIT_LANGUAGE_LIST_LABELS,
  FIT_STYLE_LIST_LABELS,
  FIT_TIMES_LIST_LABELS,
  FitPsychologistGenderList,
  FitDaysList,
  FitDeliveryList,
  FitLanguageList,
  FitStyleList,
  FitTimesList
} from 'CaW/interfaces/fitFilter';
import TimeZoneDropdown from 'CaW/pages/ClinicianListing/components/TimeZoneDropdown/TimeZoneDropdown';
import { PsychologistFitFilter, CAW_TIME_ZONE_LIST } from 'CaW/pages/ClinicianListing/ClinicianListing';
import CaWContentLayout from 'CaW/components/CaWContentLayout/CaWContentLayout';

const generateGenderList = () => {
  return Object.keys(FitPsychologistGenderList).map((listObj) => ({
    id: listObj,
    label: FIT_PSYCHOLOGIST_GENDER_LIST_LABELS[listObj as keyof typeof FitPsychologistGenderList]
  }));
};

const generateDaysList = () => {
  return Object.keys(FitDaysList).map((listObj) => ({
    id: listObj,
    label: FIT_DAYS_LIST_LABELS[listObj as keyof typeof FitDaysList]
  }));
};

const generateTimesList = () => {
  return Object.keys(FitTimesList).map((listObj) => ({
    id: listObj,
    label: FIT_TIMES_LIST_LABELS[listObj as keyof typeof FitTimesList]
  }));
};

const generateDeliveryList = () => {
  return Object.keys(FitDeliveryList).map((listObj) => ({
    id: listObj,
    label: FIT_DELIVERY_LIST_LABELS[listObj as keyof typeof FitDeliveryList]
  }));
};

const generateLanguageList = () => {
  return Object.keys(FitLanguageList).map((listObj) => ({
    id: listObj,
    label: FIT_LANGUAGE_LIST_LABELS[listObj as keyof typeof FitLanguageList]
  }));
};

const generateStylesList = () => {
  return Object.keys(FitStyleList).map((listObj) => ({
    id: listObj,
    label: FIT_STYLE_LIST_LABELS[listObj as keyof typeof FitStyleList]
  }));
};

interface FitFilterProps {
  selectedFitValue?: PsychologistFitFilter;
  onChangeFitValue: (value: PsychologistFitFilter) => void;
  selectedTimezone: string;
  handleChangeTimeZone: (value: string) => void;
  handleFitResetValue: () => void;
}

const FitFilter = ({
  selectedFitValue,
  onChangeFitValue,
  selectedTimezone,
  handleChangeTimeZone,
  handleFitResetValue
}: FitFilterProps) => {
  const generateList = (id?: any, listLabel?: any, listEnum?: any) => {
    return id
      ? {
          id,
          label: listLabel[id as keyof typeof listEnum]
        }
      : undefined;
  };

  const massageFitGender = generateList(
    selectedFitValue?.gender,
    FIT_PSYCHOLOGIST_GENDER_LIST_LABELS,
    FitPsychologistGenderList
  );
  const massageFitDays = generateList(selectedFitValue?.day, FIT_DAYS_LIST_LABELS, FitDaysList);
  const massageFitTimes = generateList(selectedFitValue?.time, FIT_TIMES_LIST_LABELS, FitTimesList);
  const massageFitDeliveryMode = generateList(
    selectedFitValue?.deliveryMode,
    FIT_DELIVERY_LIST_LABELS,
    FitDeliveryList
  );
  const massageFitLanguage = generateList(selectedFitValue?.language, FIT_LANGUAGE_LIST_LABELS, FitLanguageList);
  const massageFitStyles = generateList(selectedFitValue?.style, FIT_STYLE_LIST_LABELS, FitStyleList);
  const [scrollCount, setScrollCount] = useState<number>(0);

  const handleChangeGender = (value: listInterface | undefined) => {
    const massageFitCollection: PsychologistFitFilter = {
      ...selectedFitValue,
      gender: value?.id as FitPsychologistGenderList
    };
    onChangeFitValue(massageFitCollection);
  };

  const handleChangeDays = (value: listInterface | undefined) => {
    const massageFitCollection: PsychologistFitFilter = {
      ...selectedFitValue,
      day: value?.id as FitDaysList
    };
    onChangeFitValue(massageFitCollection);
  };

  const handleChangeTimes = (value: listInterface | undefined) => {
    const massageFitCollection: PsychologistFitFilter = {
      ...selectedFitValue,
      time: value?.id as FitTimesList
    };
    onChangeFitValue(massageFitCollection);
  };

  const handleChangeDeliveryMode = (value: listInterface | undefined) => {
    const massageFitCollection: PsychologistFitFilter = {
      ...selectedFitValue,
      deliveryMode: value?.id as FitDeliveryList
    };
    onChangeFitValue(massageFitCollection);
  };

  const handleChangeLanguage = (value: listInterface | undefined) => {
    const massageFitCollection: PsychologistFitFilter = {
      ...selectedFitValue,
      language: value?.id as FitLanguageList
    };
    onChangeFitValue(massageFitCollection);
  };

  const handleChangeStyles = (value: listInterface | undefined) => {
    const massageFitCollection: PsychologistFitFilter = {
      ...selectedFitValue,
      style: value?.id as FitStyleList
    };
    onChangeFitValue(massageFitCollection);
  };

  return (
    <div className={styles.container}>
      <CaWContentLayout>
        <div className={styles.content}>
          <div className={styles.filterWrapper} onScroll={() => setScrollCount(scrollCount + 1)}>
            <div className={styles.labelWrapper}>
              <div className={styles.title}>REFINE YOUR FIT</div>
              <div className={styles.desc}>Filter psychologists by...</div>
            </div>
            <div className={styles.fitFilterWrapper}>
              <FilterDropdown
                label={'Gender'}
                noFloatLabel
                badgeStyles
                listData={generateGenderList()}
                onChangeValue={handleChangeGender}
                selectedValue={massageFitGender}
                className={styles.gender}
                refreshPosition={scrollCount}
              />
              <FilterDropdown
                label={'Days'}
                noFloatLabel
                badgeStyles
                listData={generateDaysList()}
                onChangeValue={handleChangeDays}
                selectedValue={massageFitDays}
                className={styles.days}
                refreshPosition={scrollCount}
              />
              <FilterDropdown
                label={'Times'}
                noFloatLabel
                badgeStyles
                listData={generateTimesList()}
                onChangeValue={handleChangeTimes}
                selectedValue={massageFitTimes}
                className={styles.times}
                refreshPosition={scrollCount}
              />
              <FilterDropdown
                label={'Delivery'}
                noFloatLabel
                badgeStyles
                listData={generateDeliveryList()}
                onChangeValue={handleChangeDeliveryMode}
                selectedValue={massageFitDeliveryMode}
                className={styles.deliveryMode}
                refreshPosition={scrollCount}
              />
              <FilterDropdown
                label={'Language'}
                noFloatLabel
                badgeStyles
                listData={generateLanguageList()}
                onChangeValue={handleChangeLanguage}
                selectedValue={massageFitLanguage}
                className={styles.language}
                refreshPosition={scrollCount}
              />
              <FilterDropdown
                label={'Style'}
                noFloatLabel
                badgeStyles
                listData={generateStylesList()}
                onChangeValue={handleChangeStyles}
                selectedValue={massageFitStyles}
                className={styles.style}
                refreshPosition={scrollCount}
              />
              <div className={styles.fitReset} onClick={handleFitResetValue}>
                Clear All
              </div>
            </div>
          </div>
          <div className={styles.timezone}>
            <TimeZoneDropdown
              listData={CAW_TIME_ZONE_LIST}
              selectedValue={CAW_TIME_ZONE_LIST.find((obj) => obj.id === selectedTimezone) || CAW_TIME_ZONE_LIST[0]}
              onChangeValue={(value) => handleChangeTimeZone(value.id)}
            />
          </div>
        </div>
      </CaWContentLayout>
    </div>
  );
};

export default FitFilter;
