import { useGetAppointmentTypesQuery } from 'redux/endpoints/scheduleServices/appointmentType';
import styles from './GPAppointmentTypeSelect.module.scss';
import GPAppointmentTypeCard from './components/GPAppointmentTypeCard/GPAppointmentTypeCard';
import { Skeleton } from 'antd';
import { useGetAttachedClinicianDetails } from 'redux/endpoints/clinicianProfileServices/getClientDetails';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  selectSelectedGPAppointmentType,
  setSelectedDeliveryType,
  setSelectedGPAppointmentType
} from 'redux/globalStore/gpBookingSlice';
import { AvailabilityAppointmentTypes, DeliveryType } from 'utils/hooks/appointment';
import { useEffect } from 'react';
import StepTitle from 'SomeoneHealth/pages/PsychologistDetails/components/PsychologistTimeSlot/components/StepTitle/StepTitle';

const GPAppointmentTypeSelect = () => {
  const dispatch = useAppDispatch();
  const selectedGPAppointmentType = useAppSelector(selectSelectedGPAppointmentType);

  const { attachedClinicianDetail } = useGetAttachedClinicianDetails();
  const { data, isLoading, isFetching } = useGetAppointmentTypesQuery(
    {
      accountId: attachedClinicianDetail?.accountId || '',
      isGP: true
    },
    { skip: !attachedClinicianDetail?.accountId }
  );

  useEffect(() => {
    if (!isLoading && !isFetching && data && data.length) {
      const firstAppointmentType = data[0];
      dispatch(setSelectedGPAppointmentType(firstAppointmentType));
      if (!firstAppointmentType.deliveryOptions.includes(DeliveryType.VideoCall)) {
        dispatch(setSelectedDeliveryType(firstAppointmentType.deliveryOptions[0]));
      }
    }
  }, [data, isLoading, isFetching, dispatch]);

  const onSelectAppointment = (selectedAppointmentType: AvailabilityAppointmentTypes) => {
    dispatch(setSelectedGPAppointmentType(selectedAppointmentType));
  };

  return (
    <div className={styles.container}>
      <StepTitle
        no="1"
        title="Select the appointment you need"
        numberBubbleClassName={styles.numberBubbleClassName}
        titleClassName={styles.title}
      />
      <div className={styles.content}>
        {isLoading || isFetching || !data ? (
          <div className={styles.loadingWrapper}>
            {[...Array(2)].map((obj, i) => (
              <Skeleton active key={i} className={styles.loading} />
            ))}
          </div>
        ) : (
          <div className={styles.listWrapper}>
            <div className={styles.listOfAppointmentTypes}>
              {data.map((item, index) => (
                <GPAppointmentTypeCard
                  key={index}
                  isSelected={selectedGPAppointmentType?._id === item._id}
                  appointmentType={item}
                  onSelect={onSelectAppointment}
                />
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default GPAppointmentTypeSelect;
