import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { message } from 'antd';
import { useLocation, useParams } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import queryString from 'query-string';

import { Clinician } from 'utils/hooks/clinician';
import { useLogout } from 'utils/hooks/logout';
import { getCheckIn, getClientCompletedCheckIn } from 'utils/http/checkIn';
import { getPatientDetailsMinified } from 'utils/http/patient';
import { getPatientToken } from 'utils/patientToken';

import ContentLayout from 'components/ContentLayout/ContentLayout';
import LoadingCircle from 'components/LoadingCircle/LoadingCircle';
import PatientQuestionForm from 'components/PatientQuestionForm/PatientQuestionForm';
import Gateway from './components/Gateway/Gateway';
import Header from './components/Header/Header';

import { FirstAssessment } from 'components/PatientQuestionForm/AssessmentInterfaces';

import styles from './PatientCheckIn.module.scss';
import { useFetchBackgroundImageUrl } from 'utils/hooks/checkIn';
import { useGetAccessToken } from 'utils/hooks/token';
import { isErrorBentStatusError } from 'utils/isErrorWithStatusCode';
import { useTranslation } from 'react-i18next';
import {
  IS_CAW_APP,
  IS_EASE_APP,
  IS_RECHARGE_APP,
  IS_SELECT_APP,
  IS_SOMEONE_HEALTH_APP
} from '../../utils/hooks/AccountInfo/clientDetails';
import { useGetClinicianDetailsByIdQuery } from '../../redux/endpoints/clinicianProfileServices/getClinicianDetails';
import { skipToken } from '@reduxjs/toolkit/query';
import classNames from 'classnames';

const useFetchCheckIn = (
  setViewState: Dispatch<SetStateAction<'done' | 'saveAndExit' | 'welcomeBack' | 'questions'>>,
  clinician?: Clinician
) => {
  const { isLoading, isAuthenticated, getAccessTokenSilently, loginWithRedirect } = useAuth0();

  const { logout } = useLogout();
  const [t] = useTranslation();

  const [checkIn, setCheckIn] = useState<FirstAssessment | undefined>(undefined);
  const [isCheckInLoading, setIsCheckInLoading] = useState(true);

  const fetchCheckIn = async () => {
    setIsCheckInLoading(true);

    try {
      let token = '';

      if (isAuthenticated) {
        token = await getAccessTokenSilently({
          audience: process.env.REACT_APP_API_AUDIENCE
        });
      } else {
        token = getPatientToken() || '';
      }

      if (!token) {
        loginWithRedirect({ loginType: 'patient', appState: { returnTo: window.location.pathname } });
        return;
      }
      const checkIn = await (await getCheckIn(token, clinician!._id)).json();

      if (checkIn && (checkIn.status === 'done' || checkIn.status === 'none')) {
        setViewState('done');
      }

      setCheckIn(checkIn);
    } catch (ex) {
      if (isErrorBentStatusError(ex) && ex.statusCode === 401) {
        logout();
        return;
      }

      message.error(t('form.error.failed_to_retrieve_micro_checkin'));
    }

    setIsCheckInLoading(false);
  };

  useEffect(() => {
    if (clinician?._id && !isLoading) {
      fetchCheckIn();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clinician?._id, isLoading]);

  return { checkIn, isCheckInLoading, setCheckIn };
};

const useFetchCompletedCheckInStatus = (clinicianSlugUrlOrId: string) => {
  const { isLoading, isAuthenticated, getAccessTokenSilently, loginWithRedirect } = useAuth0();

  const { logout } = useLogout();

  const [completedCheckInStatus, setCompletedCheckInStatus] = useState<boolean>();

  const fetchCheckIn = async () => {
    try {
      let token = '';

      if (isAuthenticated) {
        token = await getAccessTokenSilently({
          audience: process.env.REACT_APP_API_AUDIENCE
        });
      } else {
        token = getPatientToken() || '';
      }

      if (!token) {
        loginWithRedirect({ loginType: 'patient', appState: { returnTo: window.location.pathname } });
        return;
      }

      const { status } = await (await getClientCompletedCheckIn(token, clinicianSlugUrlOrId)).json();
      setCompletedCheckInStatus(status);
    } catch (ex) {
      if (isErrorBentStatusError(ex) && ex.statusCode === 401) {
        logout();
        return;
      }

      console.error(ex);
    }
  };

  useEffect(() => {
    if (!isLoading && !!clinicianSlugUrlOrId) {
      fetchCheckIn();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  return { completedCheckInStatus };
};

export const useFetchPatientDetails = (dummyName?: string) => {
  const { user, isLoading } = useAuth0();
  const [patientDetails, setPatientDetails] = useState<{ picture: string; name: string }>();

  const fetchAndSetPatientDetails = async () => {
    if (user) {
      const { name, picture } = user;
      // @ts-ignore
      setPatientDetails({ name, picture });
    } else {
      const token = getPatientToken();

      if (token) {
        try {
          const { avatar, name } = await (await getPatientDetailsMinified(token)).json();

          setPatientDetails({ name, picture: avatar });
        } catch (ex) {
          message.error('Something went wrong while trying to fetch your details.');
        }
      }
    }
  };

  useEffect(() => {
    if (!isLoading) {
      fetchAndSetPatientDetails();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  return { patientDetails };
};

const PatientCheckIn = () => {
  const location = useLocation();
  const { name }: { name?: string } = queryString.parse(decodeURIComponent(location.search));

  const { token } = useGetAccessToken(true);

  const { clinicianSlugUrlOrId = '' } = useParams() as { clinicianSlugUrlOrId: string };
  const { user } = useAuth0();
  const { backgroundImageUrl, isBackgroundImageUrlLoading } = useFetchBackgroundImageUrl(
    user?.sub || '',
    Boolean(user?.['https://tacklit.com/use-check-in-bg'])
  );

  const { data: clinician } = useGetClinicianDetailsByIdQuery(
    clinicianSlugUrlOrId ? { clinicianId: clinicianSlugUrlOrId } : skipToken
  );

  const [viewState, setViewState] = useState<'done' | 'saveAndExit' | 'welcomeBack' | 'questions'>('welcomeBack');
  const { checkIn, isCheckInLoading, setCheckIn } = useFetchCheckIn(setViewState, clinician);
  const { completedCheckInStatus } = useFetchCompletedCheckInStatus(clinicianSlugUrlOrId);
  const { patientDetails } = useFetchPatientDetails(name);

  const handleSaveAndExit = () => {
    setViewState('saveAndExit');
  };

  const handleReturnToCheckIn = () => {
    setViewState('questions');
  };

  const handleUpdateStatusDone = () => {
    setViewState('done');
  };

  const handleUpdateAssessment = (
    assessmentId: string,
    responses: { questionId: string; response: { value: any; otherValue?: any } }[],
    updatedQuestionsResponses?: FirstAssessment
  ) => {
    if (checkIn) {
      const newCheckIn = updatedQuestionsResponses?._id ? { ...updatedQuestionsResponses } : { ...checkIn };

      const foundQuestionSet =
        newCheckIn.assessment.clinicalAssessments.find((assessment) => assessment.id === assessmentId) ||
        newCheckIn.assessment.onboardingQuestions.assessmentField.find(
          (assessment) => assessment.id === assessmentId
        ) ||
        newCheckIn.assessment.checkInQuestions.find((assessment) => assessment.id === assessmentId) ||
        newCheckIn.assessment.microJournalQuestions.find((assessment) => assessment.id === assessmentId);

      if (foundQuestionSet) {
        const questions = foundQuestionSet.sections.map((section) => section.questions).flat();

        for (let i = 0; i < responses.length; i++) {
          const { questionId, response } = responses[i];

          const foundQuestion = questions.find((question) => question.id === questionId);

          foundQuestion && (foundQuestion.response = response);
        }
      }

      setCheckIn(newCheckIn);

      return { updatedQuestionSet: foundQuestionSet, updatedAssessment: newCheckIn };
    }
  };

  const clinicianName = clinician?.name
    ? `${clinician?.title} ${clinician?.name}`
    : clinician?.practices?.[0]?.name || '';

  return isCheckInLoading ? (
    <div
      className={
        IS_CAW_APP
          ? styles.cawLoading
          : IS_SOMEONE_HEALTH_APP
          ? styles.shLoading
          : IS_EASE_APP
          ? styles.easeLoading
          : IS_RECHARGE_APP
          ? styles.rechargeLoading
          : IS_SELECT_APP
          ? styles.selectLoading
          : styles.loading
      }
    >
      <LoadingCircle />
    </div>
  ) : viewState === 'questions' ? (
    <div
      className={classNames(
        IS_CAW_APP
          ? styles.cawContainer
          : IS_SOMEONE_HEALTH_APP
          ? styles.shContainer
          : IS_EASE_APP
          ? styles.easeContainer
          : IS_RECHARGE_APP
          ? styles.rechargeContainer
          : IS_SELECT_APP
          ? styles.selectContainer
          : styles.container,
        IS_EASE_APP && 'ease-theme',
        IS_RECHARGE_APP && 'recharge-theme',
        IS_SELECT_APP && 'select-theme'
      )}
    >
      <ContentLayout className={styles.content}>
        <PatientQuestionForm
          assessment={checkIn}
          clinician={clinician}
          clinicianName={clinicianName}
          header={Header}
          defaultPatientDetails={patientDetails}
          onUpdateAssessment={handleUpdateAssessment}
          onSaveAndExit={handleSaveAndExit}
          onUpdateStatusDone={handleUpdateStatusDone}
          token={token}
        />
      </ContentLayout>
    </div>
  ) : (
    <div
      className={classNames(
        IS_CAW_APP
          ? styles.cawGatewayContainer
          : IS_SOMEONE_HEALTH_APP
          ? styles.shGatewayContainer
          : IS_EASE_APP
          ? styles.easeGatewayContainer
          : IS_RECHARGE_APP
          ? styles.rechargeGatewayContainer
          : IS_SELECT_APP
          ? styles.selectGatewayContainer
          : styles.gatewayContainer,
        IS_EASE_APP && 'ease-theme',
        IS_RECHARGE_APP && 'recharge-theme',
        IS_SELECT_APP && 'select-theme'
      )}
      style={
        !isBackgroundImageUrlLoading && backgroundImageUrl
          ? {
              backgroundImage: `url('${backgroundImageUrl}')`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat'
            }
          : undefined
      }
    >
      <Gateway
        state={viewState}
        onReturnToCheckIn={handleReturnToCheckIn}
        assessment={checkIn}
        clinician={clinician}
        completedCheckInStatus={completedCheckInStatus}
        patientDetails={patientDetails}
      />
    </div>
  );
};

export default PatientCheckIn;
