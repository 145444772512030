import { useRef, useState } from 'react';

import styles from './TimeZoneDropdown.module.scss';
import { newHelmUpdate } from 'utils/featureToggle/featureToggle';
import classNames from 'classnames';
import useOnClickOutside from 'utils/hooks/useOnClickOutside';

interface listInterface {
  id: string;
  label: string;
  desc?: string;
}

interface TimeZoneDropdownProps {
  listData: listInterface[];
  selectedValue: listInterface;
  onChangeValue: (val: listInterface) => void;
  className?: string;
}

const TimeZoneDropdown = ({ listData, selectedValue, onChangeValue, className }: TimeZoneDropdownProps) => {
  const timeZoneMenuRef = useRef<HTMLDivElement>(null);
  const [showList, setShowList] = useState(false);

  const handleCloseMenu = () => {
    setShowList(false);
  };

  const handleSelectButtonClick = () => {
    if (showList) {
      handleCloseMenu();
    } else {
      setShowList(true);
    }
  };

  const clickOutsideHandler = () => {
    handleCloseMenu();
  };

  useOnClickOutside(timeZoneMenuRef, clickOutsideHandler);

  const handleChangeFilter = (val: listInterface) => {
    onChangeValue(val);
    handleCloseMenu();
  };

  return (
    <div
      ref={timeZoneMenuRef}
      className={classNames(newHelmUpdate ? styles.newHelmContainer : styles.container, className)}
    >
      <div className={styles.selectButton} onClick={handleSelectButtonClick}>
        <div className={styles.selectedValue}>Time in {selectedValue.label}</div>
        <div className={styles.changeButton}>Change</div>
      </div>
      <div className={styles.menuWrapper}>
        <div className={showList ? styles.menuBoxShow : styles.menuBoxHide}>
          {listData.map((listObj, index) => (
            <div key={index} className={styles.listBox} onClick={() => handleChangeFilter(listObj)}>
              <div className={styles.title}>{listObj.label}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TimeZoneDropdown;
