import styles from './HealthBrightTimer.module.scss';
import CircularTimer from 'components/CircularTimer/CircularTimer';
import TimExpireAlert from 'components/TimeExpireAlert/TimeExpireAlert';
import {
  useReserveAppointmentData,
  useSetReserveAppointmentData
} from 'utils/hooks/EngageReserved/reservedAppointment';
import { useEffect, useState } from 'react';
import classnames from 'classnames';
import { generatePath, useNavigate } from 'react-router-dom';
import { useHealthBrightPartnerRoutesGenerator } from 'HealthBright/HealthBrightPartner/utils/Path/HealthBrightPartnerRoutesGenerator';
import { AppointmentSlot } from 'utils/hooks/appointment';

interface HealthBrightTimerProps {
  isReservedAppointmentFetching: boolean;
  reservedAppointments: AppointmentSlot[];
  hideTimer?: boolean;
  onlyText?: boolean;
  reversedAppointmentId?: string;
}

const HealthBrightTimer = ({
  isReservedAppointmentFetching,
  reservedAppointments,
  hideTimer,
  onlyText,
  reversedAppointmentId
}: HealthBrightTimerProps) => {
  const navigate = useNavigate();
  const { PRACTITIONER } = useHealthBrightPartnerRoutesGenerator();
  const { clinicianId, reserveId } = useReserveAppointmentData(reversedAppointmentId);
  const { removeReserveAppointmentData } = useSetReserveAppointmentData();
  const [showExpiredAlert, setShowExpiredAlert] = useState(false);

  const detailsPagePath = generatePath(PRACTITIONER.DETAILS, {
    clinicianId
  });

  if (!reserveId) {
    if (clinicianId) {
      navigate(detailsPagePath);
    } else {
      navigate(PRACTITIONER.LISTING);
    }
  }

  useEffect(() => {
    if (!isReservedAppointmentFetching && reservedAppointments.length < 0) {
      setShowExpiredAlert(true);
    } else {
      setShowExpiredAlert(false);
    }
  }, [isReservedAppointmentFetching, reservedAppointments]);
  const returnRoutes = clinicianId ? detailsPagePath : PRACTITIONER.LISTING;

  return (
    <div className={classnames(hideTimer && styles.hidden)}>
      <CircularTimer
        startTime={reservedAppointments?.[0]?.updatedAt}
        onlyText={onlyText}
        timeLabelClass={classnames(onlyText && styles.timeLabel)}
        minutes={15}
        onExpire={() => {
          setShowExpiredAlert(true);
          removeReserveAppointmentData();
        }}
        className={styles.circularTimer}
        strokeColor={'#0F332F'}
      />
      <TimExpireAlert
        showExpiredAlert={showExpiredAlert}
        returnRoute={returnRoutes}
        modalClassname="health-bright-theme"
      />
    </div>
  );
};

export default HealthBrightTimer;
