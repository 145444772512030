import styles from './SomeoneHealthLoginWrapper.module.scss';

interface SomeoneHealthLoginWrapperProps {
  children?: any;
}

const SomeoneHealthLoginWrapper = ({ children }: SomeoneHealthLoginWrapperProps) => (
  <div className={styles.container}>{children}</div>
);

export default SomeoneHealthLoginWrapper;
