import { clientProfilesInterface, clientRecordsInterface } from 'interfaces/Clients/clientsRecord';

export interface UserSessionStorage {
  clientRecord: clientRecordsInterface;
  authToken: string;
  firstName?: string;
}

export const SIGN_UP_CREATED_USER = 'CAW_SIGN_UP_CREATED_USER';

export const storeUserSession = (session: UserSessionStorage, dateOfBirthValue?: string) => {
  const massageClientProfiles = (data: clientProfilesInterface[]) => {
    const massageClientList: clientProfilesInterface[] = [];
    for (let obj of data) {
      massageClientList.push({
        ...obj,
        dateOfBirth: dateOfBirthValue
      });
    }
    return massageClientList;
  };

  const massageSessionData: UserSessionStorage = {
    ...session,
    clientRecord: {
      ...session.clientRecord,
      clientProfiles: massageClientProfiles(session.clientRecord.clientProfiles)
    }
  };
  sessionStorage.setItem(SIGN_UP_CREATED_USER, JSON.stringify(massageSessionData));
};

export const retrieveUserSession = (): Partial<UserSessionStorage> =>
  JSON.parse(sessionStorage.getItem(SIGN_UP_CREATED_USER) || '{}') as Partial<UserSessionStorage>;
