import { Route, Routes } from 'react-router-dom';
import appRoute from '../../components/AppRoute/AppRoute';
import AuthLayout from '../../layouts/AuthLayout/AuthLayout';
import { UserContextProvider } from '../../utils/UserContextProvider';
import { useSomeoneHealthRoutesGenerator } from '../../SomeoneHealth/utils/Path/SomeoneHealthRoutesGenerator';
import PsychologistListing from '../../SomeoneHealth/pages/PsychologistListing/PsychologistListing';
import SomeoneHealthHomePage from '../../SomeoneHealth/pages/SomeoneHealthHomePage/SomeoneHealthHomePage';
import SomeoneHealthWrapper from '../../SomeoneHealth/components/SomeoneHealthWrapper/SomeoneHealthWrapper';
import SomeoneHealthLoginWrapper from '../../SomeoneHealth/components/SomeoneHealthLoginWrapper/SomeoneHealthLoginWrapper';
import PsychologistLanding from '../../SomeoneHealth/pages/PsychologistLanding/PsychologistLanding';
import PsychologistDetails from '../../SomeoneHealth/pages/PsychologistDetails/PsychologistDetails';
import SignUp from 'SomeoneHealth/pages/SignUp/SignUp';
import Booking from 'SomeoneHealth/pages/Booking/Booking';
import BookingConfirmation from 'SomeoneHealth/pages/BookingConfirmation/BookingConfirmation';
import UpdateProfile from 'SomeoneHealth/pages/UpdateProfile/UpdateProfile';
import ReferralDetail from 'SomeoneHealth/pages/ReferralDetail/ReferralDetail';
import AppointmentsOverview from 'SomeoneHealth/pages/AppointmentsOverview/AppointmentsOverview';
import SelectAvatar from 'SomeoneHealth/pages/SelectAvatar/SelectAvatar';
import PsychometricList from '../../pages/Psychometric/PsychometricList/PsychometricList';
import Onboarding from '../../pages/OnboardingAssessment/Onboarding/Onboarding';
import PatientCheckIn from '../../pages/PatientCheckIn/PatientCheckIn';
import SomeoneHealthSurveyListWrapper from 'SomeoneHealth/pages/Survey/SomeoneHealthSurveyListWrapper/SomeoneHealthSurveyListWrapper';
import SomeoneHealthSurveyQuestionWrapper from 'SomeoneHealth/pages/Survey/SomeoneHealthSurveyQuestionWrapper/SomeoneHealthSurveyQuestionWrapper';
import ConsentFormSignUp from 'SomeoneHealth/pages/ConsentFormSignUp/ConsentFormSignUp';
import MicroJournalInsights from 'SomeoneHealth/pages/MicroJournalInsights/MicroJournalInsights';
import SignUpWithInvite from 'SomeoneHealth/pages/SignUpWithInvite/SignUpWithInvite';
import SignUpWithInviteWelcome from 'SomeoneHealth/pages/SignUpWithInviteWelcome/SignUpWithInviteWelcome';
import AddPayment from 'SomeoneHealth/pages/AddPayment/AddPayment';
import CompletePage from 'SomeoneHealth/pages/CompletePage/CompletePage';
import PatientAdHocAssessment from '../../pages/PatientAdHocAssessment/PatientAdHocAssessment';
import InvoiceViewPage from '../../pages/InvoiceViewPage/InvoiceViewPage';
import ReportViewPage from '../../pages/ReportViewPage/ReportViewPage';
import ConsentListWrapper from 'SomeoneHealth/pages/ConsentListWrapper/ConsentListWrapper';
import MyPsychologist from 'SomeoneHealth/pages/MyPsychologist/MyPsychologist';
import PsychologistDirectory from 'SomeoneHealth/pages/PsychologistDirectory/PsychologistDirectory';
import GPBookingPage from 'SomeoneHealth/pages/GPBookingPage/GPBookingPage';
import GPBookingSuccessPage from 'SomeoneHealth/pages/GPBookingSuccessPage/GPBookingSuccessPage';
import GPFlowConfirmationPage from 'SomeoneHealth/pages/GPFlowConfirmationPage/GPFlowConfirmationPage';
import GPAddPaymentPage from 'SomeoneHealth/pages/GPAddPaymentPage/GPAddPaymentPage';
import DecoupleGPSignUpPage from 'SomeoneHealth/pages/DecoupleGPFlow/DecoupleGPSignUpPage/DecoupleGPSignUpPage';
import DecoupleGPMedicarePage from 'SomeoneHealth/pages/DecoupleGPFlow/DecoupleGPMedicarePage/DecoupleGPMedicarePage';
import DecoupleGPAddPaymentPage from 'SomeoneHealth/pages/DecoupleGPFlow/DecoupleGPAddPaymentPage/DecoupleGPAddPaymentPage';
import DecoupleGPWelcomePage from 'SomeoneHealth/pages/DecoupleGPFlow/DecoupleGPWelcomePage/DecoupleGPWelcomePage';
import SomeoneHealthActionPage from 'SomeoneHealth/pages/SomeoneHealthActionPage/SomeoneHealthActionPage';
import LetterViewPage from 'pages/LetterViewPage/LetterViewPage';
import { isDevelopmentENV } from 'utils/featureToggle/DevelopmentToggle';

const SomeoneHealthRoutes = () => {
  const {
    HOME,
    PENDING_ACTIONS,
    ONBOARDING,
    PSYCHOMETRIC,
    PRACTITIONER,
    SIGNUP,
    SIGNUP_INVITE,
    SIGNUP_DECOUPLE_GP,
    INVOICE,
    REPORT,
    LETTER,
    AVATAR,
    UPDATE_PROFILE,
    REFERRAL,
    CHECKIN,
    SURVEY,
    CONSENT,
    LOGIN,
    MICRO_JOURNAL_INSIGHTS,
    MY_PSYCHOLOGIST,
    GP
  } = useSomeoneHealthRoutesGenerator();

  return (
    <UserContextProvider>
      <Routes>
        {appRoute({ path: PRACTITIONER.HOME, component: PsychologistLanding, layout: SomeoneHealthWrapper })}
        {appRoute({ path: PRACTITIONER.LISTING, component: PsychologistListing, layout: SomeoneHealthWrapper })}
        {appRoute({ path: PRACTITIONER.DIRECTORY, component: PsychologistDirectory, layout: SomeoneHealthWrapper })}
        {appRoute({ path: SIGNUP.BASE, component: SignUp, layout: SomeoneHealthWrapper })}
        {appRoute({ path: SIGNUP.MEDICARE, component: Booking, layout: SomeoneHealthWrapper })}
        {appRoute({ path: SIGNUP.WELCOME_BOOKING, component: CompletePage, layout: SomeoneHealthWrapper })}
        {appRoute({ path: SIGNUP.CONFIRM_BOOKING, component: BookingConfirmation, layout: SomeoneHealthWrapper })}
        {appRoute({ path: SIGNUP_INVITE.INVITE, component: SignUpWithInvite, layout: SomeoneHealthWrapper })}
        {appRoute({ path: SIGNUP_INVITE.ADD_PAYMENT, component: AddPayment, layout: SomeoneHealthWrapper })}
        {appRoute({
          path: SIGNUP_INVITE.WELCOME_INVITE,
          component: SignUpWithInviteWelcome,
          layout: SomeoneHealthWrapper
        })}
        {appRoute({
          path: PRACTITIONER.LISTING_WITH_FILTER,
          component: PsychologistListing,
          layout: SomeoneHealthWrapper
        })}
        {appRoute({
          path: PRACTITIONER.DIRECTORY_WITH_FILTER,
          component: PsychologistDirectory,
          layout: SomeoneHealthWrapper
        })}
        {appRoute({ path: PSYCHOMETRIC.BASE, component: PsychometricList, layout: SomeoneHealthWrapper })}
        {appRoute({
          path: PSYCHOMETRIC.DETAILS,
          component: PatientAdHocAssessment,
          layout: SomeoneHealthWrapper
        })}
        {appRoute({
          path: PRACTITIONER.DETAILS,
          component: PsychologistDetails,
          layout: SomeoneHealthWrapper
        })}
        {appRoute({
          path: INVOICE.VIEW,
          component: InvoiceViewPage,
          layout: SomeoneHealthWrapper
        })}
        {appRoute({
          path: REPORT.VIEW,
          component: ReportViewPage,
          layout: SomeoneHealthWrapper
        })}
        {appRoute({
          path: LETTER.VIEW,
          component: LetterViewPage,
          layout: SomeoneHealthWrapper
        })}
        {appRoute({
          path: SURVEY.BASE,
          component: SomeoneHealthSurveyListWrapper,
          layout: SomeoneHealthLoginWrapper
        })}
        {appRoute({
          path: SURVEY.DETAILS,
          component: SomeoneHealthSurveyQuestionWrapper,
          layout: SomeoneHealthLoginWrapper
        })}
        {appRoute({ path: SIGNUP_DECOUPLE_GP.BASE, component: DecoupleGPSignUpPage, layout: SomeoneHealthWrapper })}
        {appRoute({
          path: SIGNUP_DECOUPLE_GP.MEDICARE,
          component: DecoupleGPMedicarePage,
          layout: SomeoneHealthWrapper
        })}
        {appRoute({
          path: SIGNUP_DECOUPLE_GP.ADD_PAYMENT,
          component: DecoupleGPAddPaymentPage,
          layout: SomeoneHealthWrapper
        })}
        {appRoute({
          path: SIGNUP_DECOUPLE_GP.WELCOME,
          component: DecoupleGPWelcomePage,
          layout: SomeoneHealthWrapper
        })}

        <Route element={<AuthLayout hideHelpScout />}>
          {appRoute({ path: AVATAR, component: SelectAvatar, layout: SomeoneHealthLoginWrapper })}
          {appRoute({ path: UPDATE_PROFILE, component: UpdateProfile, layout: SomeoneHealthLoginWrapper })}
          {!isDevelopmentENV() &&
            appRoute({ path: MY_PSYCHOLOGIST, component: MyPsychologist, layout: SomeoneHealthLoginWrapper })}
          {appRoute({
            path: LOGIN.APPOINTMENT_OVERVIEW,
            component: AppointmentsOverview,
            layout: SomeoneHealthLoginWrapper
          })}
          {appRoute({ path: REFERRAL, component: ReferralDetail, layout: SomeoneHealthLoginWrapper })}
          {appRoute({ path: ONBOARDING.BASE, component: Onboarding, layout: SomeoneHealthLoginWrapper })}
          {appRoute({ path: CHECKIN, component: PatientCheckIn, layout: SomeoneHealthLoginWrapper })}
          {appRoute({ path: CONSENT.BASE, component: ConsentListWrapper, layout: SomeoneHealthLoginWrapper })}
          {appRoute({ path: CONSENT.DETAILS, component: ConsentFormSignUp, layout: SomeoneHealthLoginWrapper })}
          {appRoute({
            path: MICRO_JOURNAL_INSIGHTS,
            component: MicroJournalInsights,
            layout: SomeoneHealthLoginWrapper
          })}
          {appRoute({ path: GP.BASE, component: GPBookingPage, layout: SomeoneHealthLoginWrapper })}
          {appRoute({ path: GP.CONFIRM_BOOKING, component: GPFlowConfirmationPage, layout: SomeoneHealthLoginWrapper })}
          {appRoute({ path: GP.ADD_PAYMENT, component: GPAddPaymentPage, layout: SomeoneHealthLoginWrapper })}
          {appRoute({ path: GP.COMPLETE, component: GPBookingSuccessPage, layout: SomeoneHealthLoginWrapper })}
          {appRoute({ path: HOME, component: SomeoneHealthHomePage, layout: SomeoneHealthLoginWrapper })}
          {appRoute({ path: PENDING_ACTIONS, component: SomeoneHealthActionPage, layout: SomeoneHealthLoginWrapper })}
        </Route>
      </Routes>
    </UserContextProvider>
  );
};

export default SomeoneHealthRoutes;
