import { MouseEvent, useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';

import styles from './FilterDropdownMultiSelect.module.scss';
import classnames from 'classnames';
import { listInterface } from '../FilterDropdown';

interface FilterDropdownMultiSelectProps {
  id: string;
  listData: listInterface[];
  selectedMultipleValue: listInterface[];
  onChangeValue: (val: listInterface) => void;
  label: string;
  className?: string;
  withOtherSection?: boolean;
  withSearch?: boolean;
}

const FilterDropdownMultiSelect = ({
  id,
  listData,
  selectedMultipleValue,
  onChangeValue,
  label,
  className,
  withOtherSection,
  withSearch
}: FilterDropdownMultiSelectProps) => {
  const listMenuMultiSelectRef = useRef<HTMLDivElement>(null);
  const [showList, setShowList] = useState(false);
  const [showOtherMenu, setShowOtherMenu] = useState(false);
  const [dropdownList, setDropdownList] = useState(listData);
  const [searchValue, setSearchValue] = useState('');

  const handleCloseMenu = () => {
    setShowList(false);
    document.removeEventListener('click', handleCloseMenu);
  };

  const handleSelectButtonClick = () => {
    setShowList(!showList);
  };

  const handleClick = (e: any) => {
    if (listMenuMultiSelectRef.current?.contains(e.target)) {
      return;
    }
    setShowList(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  });

  const handleChangeSearch = useCallback(() => {
    const filterData = listData.filter((obj) => {
      if (showOtherMenu) {
        return obj.inOtherList && obj.label.toLowerCase().includes(searchValue.toLowerCase());
      }
      return !obj.inOtherList && obj.label.toLowerCase().includes(searchValue.toLowerCase());
    });
    setDropdownList(filterData);
  }, [listData, searchValue, showOtherMenu]);

  useLayoutEffect(() => {
    handleChangeSearch();
  }, [showOtherMenu, searchValue, handleChangeSearch]);

  const handleChangeFilter = (val: listInterface) => {
    onChangeValue(val);
    handleCloseMenu();
  };

  const handleRemoveFilter = (e: MouseEvent<HTMLDivElement, MouseEvent>, val: listInterface) => {
    e.preventDefault();
    e.stopPropagation();
    onChangeValue(val);
  };

  const matchedLabel = (value: string) => {
    const matchedIndex = value.toLowerCase().indexOf(searchValue.toLowerCase());
    return (
      <div className={styles.label}>
        {value.substring(0, matchedIndex)}
        <span className={styles.matchedValue}>{value.substring(matchedIndex, matchedIndex + searchValue.length)}</span>
        {value.substring(matchedIndex + searchValue.length)}
      </div>
    );
  };

  return (
    <div ref={listMenuMultiSelectRef} className={classnames(styles.container, className)}>
      <div className={styles.filterTitleWrapper}>
        <div className={classnames(styles.filterTitle, selectedMultipleValue.length > 0 && styles.showTitle)}>
          {label}
        </div>
      </div>
      <div className={classnames(styles.selectButton, showList && styles.openMenu)} onClick={handleSelectButtonClick}>
        <div className={styles.selectedPillWrapper}>
          {selectedMultipleValue.length > 0 ? (
            <>
              {selectedMultipleValue.slice(0, 2).map((obj, index) => (
                <div key={index} className={styles.selectedPill}>
                  <div className={styles.closeBtn} onClick={(e: any) => handleRemoveFilter(e, obj)}>
                    <i className={`material-icons-outlined ${styles.closeIcon}`}>clear</i>
                  </div>
                  <div className={styles.label}>{obj.label}</div>
                </div>
              ))}
              {selectedMultipleValue.length > 2 && (
                <div className={styles.plusLabel}>...+{selectedMultipleValue.length - 2} more topics</div>
              )}
            </>
          ) : (
            label
          )}
        </div>
        <i className={`material-icons-outlined ${styles.arrow}`}>keyboard_arrow_down</i>
      </div>
      <div className={styles.menuWrapper}>
        <div className={showList ? styles.menuBoxShow : styles.menuBoxHide}>
          {withSearch && (
            <div className={styles.searchWrapper}>
              <i className={`material-icons-outlined ${styles.searchIcon}`}>search</i>
              <input
                name={id}
                id={id}
                className={styles.searchInput}
                type={'text'}
                autoComplete={'off'}
                onChange={(e) => setSearchValue(e.target.value)}
              />
            </div>
          )}
          {withOtherSection ? (
            <>
              {searchValue.length > 0 ? (
                <div className={styles.displayMenu}>
                  {dropdownList.length > 0 ? (
                    dropdownList.map((listObj, index) => (
                      <div
                        key={index}
                        className={
                          selectedMultipleValue.some((obj) => obj.id === listObj.id)
                            ? styles.selectedListBox
                            : styles.listBox
                        }
                        onClick={() => handleChangeFilter(listObj)}
                      >
                        {matchedLabel(listObj.label)}
                      </div>
                    ))
                  ) : (
                    <div className={styles.emptyList}>No matching items found</div>
                  )}
                </div>
              ) : (
                <>
                  <div className={showOtherMenu ? styles.hideMenu : styles.displayMenu}>
                    {dropdownList
                      .filter((obj) => !obj.inOtherList)
                      .map((listObj, index) => (
                        <div
                          key={index}
                          className={
                            selectedMultipleValue.some((obj) => obj.id === listObj.id)
                              ? styles.selectedListBox
                              : styles.listBox
                          }
                          onClick={() => handleChangeFilter(listObj)}
                        >
                          {matchedLabel(listObj.label)}
                        </div>
                      ))}
                    {/*<div className={styles.listBox} onClick={() => setShowOtherMenu(true)}>*/}
                    {/*  Something else*/}
                    {/*  <i className={`material-icons-outlined ${styles.rightArrow}`}>arrow_forward_ios</i>*/}
                    {/*</div>*/}
                  </div>
                  <div className={showOtherMenu ? styles.displayMenu : styles.hideMenu}>
                    <div className={styles.backButton} onClick={() => setShowOtherMenu(false)}>
                      <i className={`material-icons-outlined ${styles.leftArrow}`}>arrow_back_ios</i>
                      Back
                    </div>
                    <div className={styles.otherList}>
                      {dropdownList
                        .filter((obj) => obj.inOtherList)
                        .map((listObj, index) => (
                          <div
                            key={index}
                            className={
                              selectedMultipleValue.some((obj) => obj.id === listObj.id)
                                ? styles.selectedListBox
                                : styles.listBox
                            }
                            onClick={() => handleChangeFilter(listObj)}
                          >
                            {matchedLabel(listObj.label)}
                          </div>
                        ))}
                    </div>
                  </div>
                </>
              )}
            </>
          ) : (
            <>
              {dropdownList.map((listObj, index) => (
                <div
                  key={index}
                  className={
                    selectedMultipleValue.some((obj) => obj.id === listObj.id) ? styles.selectedListBox : styles.listBox
                  }
                  onClick={() => handleChangeFilter(listObj)}
                >
                  {matchedLabel(listObj.label)}
                </div>
              ))}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default FilterDropdownMultiSelect;
