import { AppointmentSlot } from 'utils/hooks/appointment';

export interface SurveyResponseInterface {
  id: string;
  response: {
    value: string;
  };
}

export interface SessionActivityInterface {
  id: string;
  name: string;
  description: string;
  link?: string;
  completed: boolean;
  startedAt?: Date;
  completedAt?: Date;
  surveyResponse?: SurveyResponseInterface[];
}

export interface IP6Appointment extends AppointmentSlot {
  duration?: number;
}

export interface ChapterInterface {
  chapterId: number;
  name: string;
  image: string;
  description: string;
  completed: boolean;
  link?: string;
  sessionActivities: {
    pre: SessionActivityInterface[];
    post: SessionActivityInterface[];
  };
  appointment?: IP6Appointment;
}

export interface IP6Interface {
  name: string;
  status: 'active' | 'expired';
  isUnlockAll: boolean;
  chapters: ChapterInterface[];
}

export enum ProgressMenuType {
  Activity = 'activity',
  Progress = 'progress',
  Mood = 'mood',
  SMP = 'smp'
}

export interface ip6QuoteInterface {
  id: string;
  referenceBy: string;
  reference: string;
}
