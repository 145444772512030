import Button from 'components/Button/Button';
import Icon from 'components/Icon/Icon';
import ListItem from 'components/ListItem/ListItem';
import Section from 'components/Section/Section';
import SectionItem from 'components/SectionItem/SectionItem';
import SectionLoader from 'components/SectionLoader/SectionLoader';
import moment from 'moment';
import { SmpStatus } from 'pages/SMP/interfaces/smp';
import { useNavigate } from 'react-router-dom';
import { IS_HELM_APP } from 'utils/hooks/AccountInfo/clientDetails';
import { useSmpList } from 'utils/hooks/smp';

const SMPListPage = () => {
  const navigate = useNavigate();
  const navigateToSmpDetails = (smpId: string) => {
    navigate(`/smp/${smpId}`);
  };

  const { smpList, isSMPListLoading } = useSmpList();

  const smpListSubmitted = smpList.filter((smpObj) => smpObj.status === SmpStatus.submitted);
  const smpListDraft = smpList.filter((smpObj) => smpObj.status === SmpStatus.draft);

  return (
    <Section
      title={`${IS_HELM_APP ? '' : 'Kickstart: '}Self Management Plans`}
      titleRight={<Button onClick={() => navigate('/smp/new')}>Create new plan</Button>}
    >
      {isSMPListLoading ? (
        <SectionLoader />
      ) : (
        <>
          <SectionItem
            disableContentPadding={!!smpListSubmitted?.length}
            disableContentPaddingTop={!smpListSubmitted?.length}
            header="Submitted"
            totalNumber={smpListSubmitted?.length}
          >
            {smpListSubmitted?.map((smp) => (
              <ListItem
                icon={<Icon name="done" />}
                key={smp._id}
                onPrimaryActionClick={() => navigateToSmpDetails(smp._id)}
                primaryActionIconName="visibility"
                primaryText={smp.submittedBy && `Submitted by ${smp.submittedBy}`}
                secondaryText={moment(smp.updatedAt).format('DD MMM YYYY, h:mm a')}
              />
            ))}
          </SectionItem>

          <SectionItem
            disableContentPadding={!!smpListDraft?.length}
            disableContentPaddingTop={!smpListDraft?.length}
            header="Drafts"
            totalNumber={smpListDraft?.length}
          >
            {smpListDraft?.map((smp) => (
              <ListItem
                icon={<Icon name="drafts" />}
                key={smp._id}
                onPrimaryActionClick={() => navigateToSmpDetails(smp._id)}
                primaryActionIconName="edit"
                primaryText={`Last updated by you`}
                secondaryText={moment(smp.updatedAt).format('DD MMM YYYY, h:mm a')}
              />
            ))}
          </SectionItem>
        </>
      )}
    </Section>
  );
};

export default SMPListPage;
