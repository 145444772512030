import classNames from 'classnames';
import Card from 'components/Card/Card';
import styles from './Section.module.scss';

type SectionProps = {
  title?: string;
  children: React.ReactNode;
  maxWidth?: 'sm' | 'md';
  titleRight?: React.ReactNode;
};

const Section = ({ title, children, maxWidth, titleRight }: SectionProps): JSX.Element => {
  return (
    <div
      className={classNames(
        styles.container,
        maxWidth === 'sm' && styles.maxWidthSmall,
        maxWidth === 'md' && styles.maxWidthMedium
      )}
    >
      <div className={styles.titleContainer}>
        <div className={styles.title}>{title}</div>

        <div className={styles.titleRight}>{titleRight}</div>
      </div>

      <Card>{children}</Card>
    </div>
  );
};

export default Section;
