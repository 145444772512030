import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import styles from './SignUp.module.scss';
import { useReserveAppointmentData } from 'CaW/utils/hooks/localData';
import { storeUserSession, UserSessionStorage } from 'CaW/pages/SignUp/helper/userSession';
import { scrollToView } from 'utils/scrollToView';
import { useCaWRoutesGenerator } from 'CaW/utils/Path/CaWRoutesGenerator';
import Loading from 'components/Loading/Loading';
import { useState, ReactNode } from 'react';
import { useFetchReservedAppointmentsWithTimeZone } from 'CaW/utils/hooks/appointment';
import { cawTimeZoneLocalStorage, CAW_TIME_ZONE_LIST } from '../ClinicianListing/ClinicianListing';
import { getAuMobile, getInitialCustomer } from 'CaW/pages/SignUp/components/CaWCreateProfileForm/constants';
import { validatePhoneNumber } from 'pages/SignUp/SignUpForm/components/BasicDetails/validation/BasicDetailsValidation';
import moment from 'moment';
import { postPatientSignupWithoutInvitation } from 'utils/http/PatientProfileService/Patient/patient';
import { notification } from 'antd';
import { CommunicationPreference } from 'interfaces/Clients/clientsRecord';
import CaWTimer from 'CaW/components/CaWTimer/CaWTimer';
import { fetchClaimingReservation } from 'CaW/utils/hooks/useClaimReservationWithoutCheckoutSession';
import TacklitFooter from 'components/TacklitFooter/TacklitFooter';
import CaWHelmetWrapper from '../../components/CaWHelmetWrapper/CaWHelmetWrapper';
import CaWContentLayout from '../../components/CaWContentLayout/CaWContentLayout';
import CaWHeader from '../../components/CaWHeader/CaWHeader';
import CaWCreateProfileForm from './components/CaWCreateProfileForm/CaWCreateProfileForm';
import { useGetEmployerCodesQuery } from 'redux/endpoints/clinicianProfileServices/employerCode';
import { checkEmailAlreadyExisted } from 'utils/http/ClinicianProfileService/Accounts/checkEmailAlreadyExisted';

const SignUp = () => {
  const navigate = useNavigate();
  const clientTimeZone = localStorage.getItem(cawTimeZoneLocalStorage) || CAW_TIME_ZONE_LIST[0].id;
  const { isAuthenticated, isLoading } = useAuth0();
  const { SIGNUP, HOME, PRACTITIONER } = useCaWRoutesGenerator();
  const { accountId, clinicianId, reserveId, appointmentTypeInfo, presetEmployerCode } = useReserveAppointmentData();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [emailErrorMsg, setEmailErrorMsg] = useState<ReactNode>();
  const [mobileNumberErrorMsg, setMobileNumberErrorMsg] = useState<ReactNode>();
  const { fetching, appointments } = useFetchReservedAppointmentsWithTimeZone({
    reserveId: reserveId!,
    accountId: accountId!,
    timeZone: clientTimeZone
  });

  if (!reserveId) {
    navigate(PRACTITIONER.LISTING);
  }

  const { data: employerCodes, isLoading: isEmployerCodesLoading } = useGetEmployerCodesQuery(null, {
    skip: !!presetEmployerCode
  });

  if (isAuthenticated) {
    navigate(HOME);
  }

  const proceedToNextStep = (value: UserSessionStorage, dob?: string) => {
    storeUserSession(value, dob);
    scrollToView('CaWHeader');
    navigate(SIGNUP.EMERGENCY_CONTACT);
  };

  const onSubmitHandle = async (values: ReturnType<typeof getInitialCustomer>) => {
    setIsSubmitting(true);
    const formatMobile = getAuMobile(values.mobileNumber);
    const phoneValidate = await validatePhoneNumber(formatMobile);
    const checkEmailExisted = await checkEmailAlreadyExisted({
      accountId: accountId!,
      email: values.email
    });

    if (phoneValidate.valid) {
      if (checkEmailExisted?.response?.used === false) {
        try {
          const {
            email,
            firstName,
            lastName,
            password,
            receiveSMS,
            postcode,
            employerCode: formEmployerCode,
            issueSource,
            directorate
          } = values;
          const dob = moment(values.dateOfBirth).format('DD/MM/YYYY');
          const communicationPreferenceLogic = receiveSMS
            ? CommunicationPreference.NoPreference
            : CommunicationPreference.Email;

          const employerCode = presetEmployerCode?.toUpperCase() || formEmployerCode;

          const payloadMassage = {
            clinicianId: clinicianId,
            clientRecord: {
              avatar: '',
              mobile: formatMobile,
              email,
              name: firstName,
              password,
              dateOfBirth: dob,
              firstName,
              lastName,
              postcode,
              employerCode,
              issueSource,
              directorate,
              timeZone: clientTimeZone || CAW_TIME_ZONE_LIST[0].id,
              communicationPreference: communicationPreferenceLogic,
              appointmentTypeId: appointmentTypeInfo?.sessionTypeId,
              tag: employerCodes?.find((obj) => obj.code === employerCode)?.name
            }
          };
          const callPatientSignUp = await postPatientSignupWithoutInvitation(accountId || '', payloadMassage);

          const { clientRecord, authToken } = await callPatientSignUp.json();
          await fetchClaimingReservation(reserveId!, accountId!, authToken);
          setIsSubmitting(false);
          proceedToNextStep({ clientRecord, authToken }, dob);
        } catch (ex) {
          setIsSubmitting(false);
          console.error(ex);
          notification.error({
            message: 'Something went wrong while trying to sign up account'
          });
        }
      } else {
        setIsSubmitting(false);
        setEmailErrorMsg(<div>Email address is already used.</div>);
      }
    } else {
      setIsSubmitting(false);
      setMobileNumberErrorMsg(<div>Mobile number not recognized. Please check for typos and try again.</div>);
    }
  };

  return isLoading || fetching || isEmployerCodesLoading ? (
    <div className={styles.loadingContainer}>
      <Loading />
    </div>
  ) : (
    <CaWHelmetWrapper title={'Caraniche at Work - Sign Up'}>
      <CaWContentLayout>
        <CaWHeader withPadding classNames={styles.header} blueMenu />
        <div className={styles.container}>
          <div className={styles.leftContent}>
            <CaWCreateProfileForm
              emailErrorMsg={emailErrorMsg}
              isSubmitting={isSubmitting}
              mobileNumberErrorMsg={mobileNumberErrorMsg}
              onSubmit={onSubmitHandle}
              setEmailErrorMsg={setEmailErrorMsg}
              setMobileNumberErrorMsg={setMobileNumberErrorMsg}
              employerCodes={employerCodes?.map((item) => ({ value: item.code, label: item.code })) || []}
              presetEmployerCode={presetEmployerCode}
            />
          </div>
          <div className={styles.rightContent}>
            <div className={styles.timerContainer}>
              <div className={styles.timerLabel}>
                To confirm these appointments you need to complete registration and payment within 15 minutes
              </div>
              <CaWTimer isReservedAppointmentFetching={fetching} reservedAppointments={appointments} />
            </div>
          </div>
        </div>
      </CaWContentLayout>
      <TacklitFooter />
    </CaWHelmetWrapper>
  );
};

export default SignUp;
