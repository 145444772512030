import styles from './PsychologistBonaFides.module.scss';
import { PractitionerDetailsInterface } from 'interfaces/PublicProfile/Practitioner/practitioner';
import { ClinicianMedicareRole } from '../../../interfaces/Clinician/clinicianDetails';
import ReactTooltip from 'react-tooltip';
import { CHINESE_MANDARIN, FitLanguageList } from 'HealthBright/interfaces/fitFilter';
import { LANGUAGE_COLLECTION } from 'utils/constants/language';

interface PractitionerBonaFidesProps {
  id?: string;
  helmControl: PractitionerDetailsInterface['helmControl'];
  limitDisplayQualifications?: boolean;
  role?: ClinicianMedicareRole;
}

export const MEDICARE_ROLES_OPTIONS = [
  {
    label: 'Clinical Psychologist',
    value: ClinicianMedicareRole.ClinicalPsychologists
  },
  {
    label: 'Registered Psychologist',
    value: ClinicianMedicareRole.RegisteredPsychologists
  },
  {
    label: 'Occupational Therapist',
    value: ClinicianMedicareRole.OccupationalTherapists
  },
  {
    label: 'Social Worker',
    value: ClinicianMedicareRole.SocialWorkers
  },
  {
    label: 'General Practitioner',
    value: ClinicianMedicareRole.GeneralPractitioner
  }
];

export const getRoleLabel = (role: ClinicianMedicareRole) =>
  MEDICARE_ROLES_OPTIONS.find(({ value }) => value === role)?.label;

const PsychologistBonaFides = ({ id, helmControl, limitDisplayQualifications, role }: PractitionerBonaFidesProps) => {
  const languages = helmControl?.bonaFides?.languages?.value
    ?.map((languageObj) => {
      return languageObj === FitLanguageList.zh
        ? CHINESE_MANDARIN
        : LANGUAGE_COLLECTION[languageObj as keyof typeof LANGUAGE_COLLECTION];
    })
    .join(' | ');

  return (
    <div className={styles.container}>
      <div className={styles.label}>BONA FIDES</div>
      <div className={styles.bonaFideDetails}>
        {role && (
          <div className={styles.details}>
            <i className={`material-icons ${styles.icon}`}>supervised_user_circle</i>
            <div>{getRoleLabel(role)}</div>
            <i data-tip data-for={`${id}-role`} className={`material-icons-outlined ${styles.verifiedIcon}`}>
              verified
            </i>
            <ReactTooltip id={`${id}-role`} className={styles.tooltip}>
              Our Psychologists all have current registration with the Psychology Board of Australia. They also undergo
              an interview process to ensure they uphold the highest of standards in ethical care and work in line with
              evidence-based practices to guide your therapy.
            </ReactTooltip>
          </div>
        )}
        {helmControl.bonaFides?.yearsOfExperience?.active && (
          <div className={styles.details}>
            <i className={`material-icons ${styles.icon}`}>work</i>
            <div>{helmControl.bonaFides.yearsOfExperience.value} Years</div>
          </div>
        )}
        {!!helmControl?.bonaFides?.languages?.value?.length && (
          <div className={styles.details}>
            <div className={`material-icons ${styles.icon}`}>language</div>
            <div>{languages}</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PsychologistBonaFides;
