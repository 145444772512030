import styles from './HealthBrightFooter.module.scss';
import HealthBrightContentLayout from '../HealthBrightContentLayout/HealthBrightContentLayout';
import { healthBrightEnvironment } from 'HealthBright/HealthBrightPartner/utils/HealthBrightEnv';

const HealthBrightFooter = () => {
  const URLs = healthBrightEnvironment();

  return (
    <div className={styles.footerContainer}>
      <HealthBrightContentLayout className={styles.container}>
        <section className={styles.content}>
          <div id={styles.aboutUsWrapper} className={styles.textContainer}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
            dolore magna aliqua.
          </div>
          <div className={styles.textContainer}>
            <ul className={styles.list}>
              <li className={styles.text}>
                <a className={styles.textWhite} href={URLs.OurServices}>
                  Our Services
                </a>
              </li>
              <li className={styles.text}>
                <a className={styles.textWhite} href={URLs.About}>
                  About
                </a>
              </li>
              <li className={styles.text}>
                <a className={styles.textWhite} href={URLs.HealthBrightURL}>
                  Treatment Plans
                </a>
              </li>
              <li className={styles.text}>
                <a className={styles.textWhite} href={URLs.HealthBrightURL}>
                  Book and appointment
                </a>
              </li>
            </ul>

            <p className={styles.finalText}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
              dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex
              ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu
              fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt
              mollit anim id est laborum.
            </p>
          </div>
        </section>
      </HealthBrightContentLayout>
    </div>
  );
};

export default HealthBrightFooter;
