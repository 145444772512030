import { listInterface } from 'CaW/components/CaWFilter/components/FilterDropdown/FilterDropdown';
import styles from './ListingFilter.module.scss';
import FilterDropdownWrapper from '../components/FilterDropdownWrapper/FilterDropdownWrapper';
import { CaWMentalHealthList, CAW_MENTAL_HEALTH_LIST_LABELS } from 'CaW/interfaces/mentalHealth';
import CaWContentLayout from '../../CaWContentLayout/CaWContentLayout';

interface ListingFilterProps {
  selectedMentalHealthConcern: CaWMentalHealthList[];
  onChangeMentalHealthConcern: (value: listInterface) => void;
  handleResetValue: () => void;
}

const ListingFilter = ({
  selectedMentalHealthConcern,
  onChangeMentalHealthConcern,
  handleResetValue
}: ListingFilterProps) => {
  const massageMentalHealthConcern = selectedMentalHealthConcern.map((item) => ({
    id: item,
    label: CAW_MENTAL_HEALTH_LIST_LABELS[item]
  }));

  return (
    <CaWContentLayout>
      <div className={styles.container}>
        <div className={styles.filterWrapper}>
          <div className={styles.header}>
            <div className={styles.label}>SHOWING MATCHES FOR</div>
            <div className={styles.headerReset} onClick={handleResetValue}>
              Reset
            </div>
          </div>
          <FilterDropdownWrapper
            classNames={styles.filter}
            mentalHealthConcernValue={massageMentalHealthConcern}
            onChangeMentalHealthConcernValue={onChangeMentalHealthConcern}
          />
          <div className={styles.filterReset} onClick={handleResetValue}>
            Reset
          </div>
        </div>
      </div>
    </CaWContentLayout>
  );
};

export default ListingFilter;
