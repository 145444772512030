import Form from 'antd/lib/form/Form';
import { Formik, FormikErrors } from 'formik';
import { isEmpty, isEqual } from 'lodash';
import { useNavigate } from 'react-router-dom';
import OCD from 'SomeoneHealth/assets/images/OCD.png';
import styles from './PartnerSignUp.module.scss';
import { reservedHBPartnerReferral, useReserveAppointmentData } from 'utils/hooks/EngageReserved/reservedAppointment';
import { scrollToView } from 'utils/scrollToView';
import Loading from 'components/Loading/Loading';
import { useState, ReactNode } from 'react';
import { validatePhoneNumber } from 'pages/SignUp/SignUpForm/components/BasicDetails/validation/BasicDetailsValidation';
import moment from 'moment';
import {
  EmergencyContactDetail,
  KeyClientContact,
  postPatientSignupWithoutInvitation
} from 'utils/http/PatientProfileService/Patient/patient';
import { notification } from 'antd';
import { ClientRecordType, CommunicationPreference } from 'interfaces/Clients/clientsRecord';
import { fetchClaimingReservation } from 'utils/hooks/EngageReserved/useClaimReservationWithoutCheckoutSession';
import ErrorMessageWithHelpLink from 'SomeoneHealth/pages/SignUpWithInvite/components/ErrorMessageWithHelpLink/ErrorMessageWithHelpLink';
import { HEALTH_BRIGHT_TIME_ZONE_LIST, healthBrightTimeZoneLocalStorage } from 'utils/constants/timeZone';
import { useHealthBrightPartnerRoutesGenerator } from 'HealthBright/HealthBrightPartner/utils/Path/HealthBrightPartnerRoutesGenerator';
import HBPartnerCreateProfileForm from './components/HBPartnerCreateProfileForm/HBPartnerCreateProfileForm';
import { PARTNER_CODE_STORAGE } from 'interfaces/PartnerReferral/constants';
import { AppointmentBookingClaimType } from 'interfaces/PublicProfile/Practitioner/practitioner';
import { checkEmailAlreadyExisted } from 'utils/http/ClinicianProfileService/Accounts/checkEmailAlreadyExisted';
import {
  getAuMobile,
  formDataValidationSchema,
  initialClientRecord,
  checkIsYoungPerson,
  checkIsChild,
  PARENT_OR_GUARDIAN_RELATIONSHIP_OPTIONS,
  HB_DEFAULT_EMERGENCY_CONTACT_RELATIONSHIP,
  passwordValidationSchema
} from './constants';
import AgeRestrictionAlert from 'HealthBright/components/AgeRestrictionAlert/AgeRestrictionAlert';
import HBPartnerParentOrGuardianForm from './components/HBPartnerParentOrGuardianForm/HBPartnerParentOrGuardianForm';
import HBPartnerEmergencyContactForm from './components/HBPartnerEmergencyContactForm/HBPartnerEmergencyContactForm';
import HealthBrightHelmetWrapper from 'HealthBright/components/HealthBrightHelmetWrapper/HealthBrightHelmetWrapper';
import HealthBrightContentLayout from 'HealthBright/components/HealthBrightContentLayout/HealthBrightContentLayout';
import HealthBrightHeader from 'HealthBright/components/HealthBrightHeader/HealthBrightHeader';
import { PARTNER_HB_SIGN_UP_USER, UserSessionStorage, storeUserSession } from 'utils/userSession';
import ButtonHB from 'HealthBright/components/ButtonHB/ButtonHB';
import HBPartnerCommunicationPreferencesForm from './components/HBPartnerCommunicationPreferencesForm/HBPartnerCommunicationPreferencesForm';
import HealthBrightTimer from 'HealthBright/components/HealthBrightTimer/HealthBrightTimer';
import { useFetchReservedAppointmentsWithTimeZone } from 'HealthBright/utils/hooks/appointment';
import { getHealthBrightSupportEmail } from 'HealthBright/HealthBrightPartner/utils/getHealthBrightSupportEmail';
import { Address, AddressForm } from 'components/AddressForm/AddressForm';

const PartnerSignUp = () => {
  const navigate = useNavigate();
  const clientTimeZone = localStorage.getItem(healthBrightTimeZoneLocalStorage) || HEALTH_BRIGHT_TIME_ZONE_LIST[0].id;
  const { SIGNUP } = useHealthBrightPartnerRoutesGenerator();
  const { accountId, clinicianId, reserveId, appointmentTypeInfo } =
    useReserveAppointmentData(reservedHBPartnerReferral);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [emailErrorMsg, setEmailErrorMsg] = useState<ReactNode>();
  const [mobileNumberErrorMsg, setMobileNumberErrorMsg] = useState<ReactNode>();
  const [pwScore, setPwScore] = useState<number>(0);
  const [ageConfirm, setAgeConfirm] = useState<boolean>(false);
  const [showPwError, setShowPwError] = useState<boolean>(false);

  const { fetching, appointments } = useFetchReservedAppointmentsWithTimeZone({
    reserveId: reserveId!,
    accountId: accountId!,
    timeZone: clientTimeZone
  });
  const partnerCode = localStorage.getItem(PARTNER_CODE_STORAGE) as AppointmentBookingClaimType | undefined;
  const supportEmail = getHealthBrightSupportEmail(partnerCode);

  const proceedToNextStep = (value: UserSessionStorage, dob: string) => {
    storeUserSession({ storageKey: PARTNER_HB_SIGN_UP_USER, session: value, dateOfBirthValue: dob });
    scrollToView('HealthBrightHeader');
    navigate(SIGNUP.SUMMARY_BOOKING);
  };

  const isButtonEnabled = (isValid: boolean, errors: FormikErrors<typeof initialClientRecord>) => {
    if (!ageConfirm) {
      return false;
    }
    const hasOtherErrorThanPassword = Object.values({ ...errors, password: '' }).reduce(
      (res, item) => res || !!item,
      false
    );
    if (!isValid) {
      if (!showPwError) {
        return !hasOtherErrorThanPassword;
      } else return false;
    }
    return true;
  };

  const onSubmitHandle = async (values: typeof initialClientRecord) => {
    setIsSubmitting(true);
    const formatMobile = values.mobileNumber ? getAuMobile(values.mobileNumber) : '';
    const phoneValidate = values.mobileNumber ? await validatePhoneNumber(formatMobile) : undefined;

    const checkEmailExisted = values.email
      ? await checkEmailAlreadyExisted({
          accountId: accountId!,
          email: values.email
        })
      : undefined;

    let isError = false;
    if (phoneValidate && !phoneValidate.valid) {
      isError = true;
      setMobileNumberErrorMsg(
        <ErrorMessageWithHelpLink
          errorMessage="Mobile number not recognized. Please check for typos and try again."
          email={supportEmail}
        />
      );
    }
    if (checkEmailExisted && checkEmailExisted?.response?.used) {
      isError = true;
      setEmailErrorMsg(<ErrorMessageWithHelpLink errorMessage="Email address is already used." email={supportEmail} />);
    }
    if (isError) {
      setIsSubmitting(false);
      return;
    }

    try {
      const {
        email,
        firstName,
        lastName,
        password,
        receiveSMS,
        receiveEmail,
        guardianFirstName,
        guardianLastName,
        guardianMobileNumber,
        guardianEmail,
        guardianRelationship,
        emergencyContacts,
        saveAsEmergencyContact,
        consentToContact,
        address
      } = values;
      const dob = moment(values.dateOfBirth).format('DD/MM/YYYY');
      const communicationPreferenceLogic =
        receiveEmail && receiveSMS
          ? CommunicationPreference.NoPreference
          : receiveEmail
          ? CommunicationPreference.Email
          : receiveSMS
          ? CommunicationPreference.SMS
          : CommunicationPreference.NONE;

      const isYoungPerson = checkIsYoungPerson(values.dateOfBirth);

      const guardianRoleAsTag = PARENT_OR_GUARDIAN_RELATIONSHIP_OPTIONS.find(
        (item) => item.value === guardianRelationship
      )?.label;

      const keyClientContacts: KeyClientContact[] = emergencyContacts.map((contact: EmergencyContactDetail) => ({
        ...contact,
        tags: [contact.relationship, HB_DEFAULT_EMERGENCY_CONTACT_RELATIONSHIP]
      }));

      const payloadMassage = {
        clinicianId: clinicianId,
        clientRecord: {
          avatar: '',
          mobile: formatMobile,
          email,
          name: firstName,
          dateOfBirth: dob,
          firstName,
          lastName,
          password,
          postcode: '',
          timeZone: clientTimeZone || HEALTH_BRIGHT_TIME_ZONE_LIST[0].id,
          communicationPreference: communicationPreferenceLogic,
          appointmentTypeId: appointmentTypeInfo?.sessionTypeId,
          recordType: isYoungPerson ? ClientRecordType.YoungPerson : ClientRecordType.Adult,
          ...(isYoungPerson && {
            additionalProfiles: [
              {
                firstName: guardianFirstName,
                lastName: guardianLastName,
                mobileNumber: guardianMobileNumber,
                email: guardianEmail,
                role: guardianRelationship,
                isPrimaryContact: consentToContact
              }
            ]
          }),
          ...(isYoungPerson && {
            keyClientContacts: saveAsEmergencyContact
              ? [
                  ...keyClientContacts,
                  {
                    firstName: guardianFirstName,
                    lastName: guardianLastName,
                    mobileNumber: guardianMobileNumber,
                    email: guardianEmail,
                    tags: guardianRoleAsTag
                      ? [guardianRoleAsTag, HB_DEFAULT_EMERGENCY_CONTACT_RELATIONSHIP]
                      : [HB_DEFAULT_EMERGENCY_CONTACT_RELATIONSHIP]
                  }
                ]
              : keyClientContacts
          }),
          ...(address && {
            address
          })
        }
      };
      const callPatientSignUp = await postPatientSignupWithoutInvitation(accountId || '', payloadMassage);

      const { clientRecord, authToken } = await callPatientSignUp.json();
      await fetchClaimingReservation(reserveId!, accountId!, authToken);
      setIsSubmitting(false);
      proceedToNextStep({ clientRecord, authToken }, dob);
    } catch (ex) {
      setIsSubmitting(false);
      console.error(ex);
      notification.error({
        message: 'Something went wrong while trying to sign up account'
      });
    }
  };

  return (
    <HealthBrightHelmetWrapper title={'HealthBright - Sign Up'}>
      <HealthBrightHeader noLogin loginTextClass={styles.loginTextClass} />
      <HealthBrightContentLayout className="health-bright-theme">
        <div className={styles.container}>
          {isSubmitting && (
            <div className={styles.loading}>
              <Loading />
            </div>
          )}
          <Formik
            initialValues={initialClientRecord}
            validationSchema={formDataValidationSchema(13).concat(passwordValidationSchema(pwScore))}
            onSubmit={onSubmitHandle}
          >
            {({ values, isValid, errors, validateForm, setFieldValue }) => (
              <Form noValidate className={styles.formWrapper}>
                {/* Client Profile Form */}
                <div className={styles.row}>
                  {/* Left */}
                  <div className={styles.leftContent}>
                    <div className={styles.header}>
                      <div className={styles.welcome}>
                        Welcome to <span className={styles.highlight}>HealthBright</span>
                      </div>
                      <div className={styles.subHeader}>
                        You are creating a referral to a qualified psychologist via our private access channel. Please
                        complete the following information to book an appointment
                      </div>
                    </div>

                    {checkIsChild(values.dateOfBirth) && (
                      <AgeRestrictionAlert
                        open={checkIsChild(values.dateOfBirth)}
                        onClose={() => setFieldValue('dateOfBirth', '')}
                      />
                    )}
                    <HBPartnerCreateProfileForm
                      password={values.password}
                      showPwError={showPwError}
                      ageConfirm={ageConfirm}
                      setAgeConfirm={setAgeConfirm}
                      setPwScore={setPwScore}
                      emailErrorMsg={emailErrorMsg}
                      mobileNumberErrorMsg={mobileNumberErrorMsg}
                      setEmailErrorMsg={setEmailErrorMsg}
                      setMobileNumberErrorMsg={setMobileNumberErrorMsg}
                    />
                  </div>

                  {/* Right */}
                  <div className={styles.rightContent}>
                    <div className={styles.topNotch}>
                      <img src={OCD} alt="Health Bright top notch" />
                      <div className={styles.topNotchTitle}>Top-notch privacy and security</div>
                      <div className={styles.topNotchDesc}>
                        <b>HealthBright</b> was built to exceed all regulatory requirements for privacy and security.
                        All data is stored within Australia and encrypted both at-rest and in-transit, with strict
                        access controls.
                      </div>
                    </div>
                  </div>
                </div>

                {/* Address Form */}
                <div className={styles.row}>
                  <AddressForm
                    title="Client Address"
                    values={values.address}
                    onAddressAutoComplete={(addressValue: Address) => {
                      setFieldValue('address', addressValue);
                    }}
                    handleChangeValues={(key, value) => {
                      setFieldValue(`address.${key}`, value);
                    }}
                  />
                </div>

                {checkIsYoungPerson(values.dateOfBirth) && (
                  <div className={styles.row}>
                    <div className={styles.leftContent}>
                      {/* Parent or guardian */}
                      <HBPartnerParentOrGuardianForm />

                      {/* Emergency contact */}
                      <HBPartnerEmergencyContactForm />
                    </div>
                  </div>
                )}

                <div className={styles.row}>
                  <div className={styles.leftContent}>
                    {/* Communication Preferences */}
                    <HBPartnerCommunicationPreferencesForm />

                    <ButtonHB
                      className={styles.submitButton}
                      disabled={
                        !values.password ||
                        values.password.length < 2 ||
                        isEqual(values, initialClientRecord) ||
                        !isButtonEnabled(isValid, errors)
                      }
                      onClick={() => {
                        setShowPwError(true);
                        validateForm().then((errors) => {
                          if (isEmpty(errors)) {
                            onSubmitHandle(values);
                          }
                        });
                      }}
                    >
                      Next
                    </ButtonHB>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
        <HealthBrightTimer
          isReservedAppointmentFetching={fetching}
          reservedAppointments={appointments}
          hideTimer
          reversedAppointmentId={reservedHBPartnerReferral}
        />
      </HealthBrightContentLayout>
    </HealthBrightHelmetWrapper>
  );
};

export default PartnerSignUp;
