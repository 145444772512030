import classNames from 'classnames';
import SquareCard from 'components/SquareCard/SquareCard';
import TextArea from 'components/TextArea/TextArea';
import { ChangeEventHandler } from 'react';
import smpArrowRight from 'assets/images/smp/smpArrowRight.png';
import { SMPForm, SMPType } from 'pages/SMP/interfaces/smp';
import styles from './SMPGoalsMethod.module.scss';

type SMPGoalsMethodProps = {
  type?: SMPType;
  methods: SMPForm['methods'];
  onChange: (methods: SMPForm['methods']) => void;
  isEditMode?: boolean;
};

const SMPGoalsMethod = ({ type, methods, onChange, isEditMode }: SMPGoalsMethodProps): JSX.Element => {
  const isSMPTypeIP6 = type === SMPType.IP6;
  const onChangeGenerator =
    (key: keyof SMPForm['methods']): ChangeEventHandler<HTMLTextAreaElement> =>
    (e) =>
      onChange({ ...methods, [key]: e.target.value });

  const labels = isSMPTypeIP6
    ? {
        goals: {
          title: 'I WILL MANAGE MY TRIGGERS & CRAVINGS',
          rowTitle1: 'PEOPLE, PLACES OR THINGS THAT MIGHT TRIGGER ME',
          rowTitle2: 'THINGS I CAN DO TO MANAGE THESE TRIGGERS'
        },
        thoughts: {
          challenge2: 'Some emotions that might challenge me'
        }
      }
    : {
        goals: {
          title: 'I WILL',
          rowTitle1: 'DRAW ON MY STRENGTHS',
          rowTitle2: 'CALL ON MY SUPPORTS'
        },
        thoughts: {
          challenge2: 'Difficult emotions I need to manage'
        }
      };

  return (
    <div className={styles.container}>
      <div className={styles.tableSection}>
        <div className={styles.title}>How I will achieve my goals</div>

        <div className={styles.content}>
          <div className={classNames(styles.tableTitle, isSMPTypeIP6 && styles.tableTitleIP6)}>
            {labels.goals.title}
          </div>
          {!isSMPTypeIP6 && <div className={styles.tableTitle} />}

          <div>{labels.goals.rowTitle1}</div>
          <div>
            {isEditMode ? (
              <TextArea value={methods?.drawStrength} onChange={onChangeGenerator('drawStrength')} />
            ) : (
              <div>{methods?.drawStrength}</div>
            )}
          </div>

          <div>{labels.goals.rowTitle2}</div>
          <div>
            {isEditMode ? (
              <TextArea value={methods?.callSupport} onChange={onChangeGenerator('callSupport')} />
            ) : (
              <div>{methods?.callSupport}</div>
            )}
          </div>
        </div>
      </div>

      <div className={styles.gridSection}>
        <SquareCard
          label="Unhelpful thoughts I will challenge"
          value={methods?.unhelpfulThought}
          onChange={onChangeGenerator('unhelpfulThought')}
          className={styles.unhelpfulThought}
          isEditMode={isEditMode}
          inputClassName={classNames(styles.input, styles.white)}
        >
          <img src={smpArrowRight} alt="right arrow" className={styles.rightArrow} />
        </SquareCard>

        <SquareCard
          value={methods?.helpfulThought}
          label="Some more helpful thoughts are"
          onChange={onChangeGenerator('helpfulThought')}
          className={styles.helpfulThought}
          isEditMode={isEditMode}
          inputClassName={styles.input}
        />

        <SquareCard
          value={methods?.difficultEmotion}
          label={labels.thoughts.challenge2}
          onChange={onChangeGenerator('difficultEmotion')}
          className={styles.difficultEmotion}
          isEditMode={isEditMode}
          inputClassName={classNames(styles.input, styles.white)}
        >
          <img src={smpArrowRight} alt="right arrow" className={styles.rightArrow} />
        </SquareCard>

        <SquareCard
          value={methods?.emotionManagement}
          label="Things I can do to manage them"
          onChange={onChangeGenerator('emotionManagement')}
          className={styles.emotionManagement}
          isEditMode={isEditMode}
          inputClassName={styles.input}
        />
      </div>
    </div>
  );
};

export default SMPGoalsMethod;
