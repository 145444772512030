import Loading from 'components/Loading/Loading';
import { useNavigate } from 'react-router-dom';
import ButtonSH from 'SomeoneHealth/components/ButtonSH/ButtonSH';
import SomeoneHealthContentLayout from 'SomeoneHealth/components/SomeoneHealthContentLayout/SomeoneHealthContentLayout';
import SomeoneHealthHeader from 'SomeoneHealth/components/SomeoneHealthHeader/SomeoneHealthHeader';
import SomeoneHealthHelmetWrapper from 'SomeoneHealth/components/SomeoneHealthHelmetWrapper/SomeoneHealthHelmetWrapper';
import TacklitFooter from 'components/TacklitFooter/TacklitFooter';
import { useSomeoneHealthRoutesGenerator } from 'SomeoneHealth/utils/Path/SomeoneHealthRoutesGenerator';
import { retrieveUserSession } from '../SignUp/helper/userSession';
import styles from './SignUpWithInviteWelcome.module.scss';

const SignUpWithInviteWelcome = () => {
  const signedUpUser = retrieveUserSession();
  const navigate = useNavigate();
  const { AVATAR } = useSomeoneHealthRoutesGenerator();

  const onGoToMyAccount = () => {
    navigate(AVATAR);
  };

  return (
    <SomeoneHealthHelmetWrapper title={'someone.health - welcome'}>
      <div className={styles.pageContainer}>
        <SomeoneHealthHeader pinkLogo noLogin classNames={styles.header} />
        {!signedUpUser && <Loading />}
        <SomeoneHealthContentLayout className={styles.container}>
          <div className={styles.title}>THANK YOU FOR SIGNING UP!</div>
          <div className={styles.content}>
            <b>Thanks {signedUpUser.firstName || signedUpUser.clientRecord?.clientProfiles[0].firstName}</b>
            <br />
            <br />
            You've successfully created your new someone.health profile!
            <br />
            <br />
            All appointments from Wednesday 5 April onwards will be managed here through the someone.health website.
            <br />
            <br />
            You'll receive an email notification with a new video link in your reminder email and SMS prior to your
            appointment.
            <br />
            <br />
            You can now log in to your account using your email address and the password you just created by clicking
            the button below.
            <br />
            <br />
            <div>
              <ButtonSH className={styles.button} onClick={onGoToMyAccount}>
                Go to my account
              </ButtonSH>
            </div>
          </div>
        </SomeoneHealthContentLayout>
        <TacklitFooter />
      </div>
    </SomeoneHealthHelmetWrapper>
  );
};

export default SignUpWithInviteWelcome;
