import styles from './AppointmentsOverview.module.scss';
import AppointmentList from 'components/AppointmentList/AppointmentList';
import PatientContentLayout from 'components/PatientContentLayout/PatientContentLayout';
import { useGetClientProfileDetails } from 'redux/endpoints/clinicianProfileServices/getClientDetails';
import RechargeHeader from 'Recharge/components/RechargeHeader/RechargeHeader';
import RechargeHelmetWrapper from 'Recharge/components/RechargeHelmetWrapper/RechargeHelmetWrapper';
import { RECHARGE_TIME_ZONE_LIST } from 'utils/constants/timeZone';
import classNames from 'classnames';
import { RechargeDefaultTimezone } from 'Recharge/utils/RechargeEnv/RechargeEnv';
import { useRechargeDoDOrNonDoDPathGenerator } from 'Recharge/utils/Path/useRechargeDoDOrNonDoDPathGenerator';

const AppointmentsOverview = () => {
  const { clientProfileData, isClientProfileLoading } = useGetClientProfileDetails();
  const timeZone = clientProfileData?.timeZone || RechargeDefaultTimezone;
  const timeZoneLocation = RECHARGE_TIME_ZONE_LIST.find(({ id }) => id === timeZone)?.label;
  const { PRACTITIONER } = useRechargeDoDOrNonDoDPathGenerator();

  return (
    <RechargeHelmetWrapper title={`Recharge | Appointments`}>
      <PatientContentLayout className={classNames(styles.container, 'recharge-theme')}>
        <div className={styles.contentWrapper}>
          <RechargeHeader withPadding withMenu homeLink whiteFont whiteMenu />
          <div className={styles.content}>
            <div className={styles.titleContainer}>
              <div className={styles.title}>Your Appointment Overview</div>
              {timeZoneLocation && <div className={styles.timeZone}>Time in {timeZoneLocation}</div>}
            </div>
            <AppointmentList
              isPatientProfileLoading={isClientProfileLoading}
              clientTimeZone={clientProfileData?.timeZone}
              className={styles.contentCardShadow}
              newAppointmentPath={PRACTITIONER.DETAILS}
            />
          </div>
        </div>
      </PatientContentLayout>
    </RechargeHelmetWrapper>
  );
};

export default AppointmentsOverview;
