import { useAuth0 } from '@auth0/auth0-react';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import LoadingCircle from 'components/LoadingCircle/LoadingCircle';
import TaskBox from 'components/TaskBox/TaskBox';
import CompletedTaskBox from 'components/CompletedTaskBox/CompletedTaskBox';
import PatientContentLayout from 'components/PatientContentLayout/PatientContentLayout';

import { useGetAllSurveysQuery } from 'redux/endpoints/checkInServices/survey';
import { useGetTasksQuery } from 'redux/endpoints/clinicianProfileServices/client';
import { useFetchPsychometricList } from 'pages/Psychometric/PsychometricList/hooks/getPsychometricList';
import { useGetAccessToken } from 'utils/hooks/token';
import { UserContext } from 'utils/UserContextProvider';

import { useSomeoneHealthRoutesGenerator } from 'SomeoneHealth/utils/Path/SomeoneHealthRoutesGenerator';
import PaymentFailureTaskBox from 'SomeoneHealth/pages/SomeoneHealthHomePage/components/PaymentFailureTaskBox/PaymentFailureTaskBox';
import SomeoneHealthHelmetWrapper from 'SomeoneHealth/components/SomeoneHealthHelmetWrapper/SomeoneHealthHelmetWrapper';
import SomeoneHealthHeader from 'SomeoneHealth/components/SomeoneHealthHeader/SomeoneHealthHeader';

import styles from './SomeoneHealthActionPage.module.scss';

const REFETCH_DATA_INTERVAL_TIME = 15 * 60 * 1000;

const SomeoneHealthActionPage = () => {
  const { user } = useAuth0();
  const clientName = user?.name || '';
  const { token } = useGetAccessToken();
  const { clientProfile } = useContext(UserContext);
  const navigate = useNavigate();
  const [t] = useTranslation();

  const clientRecordId = user?.['https://tacklit.com/clientRecordId'] || '';
  const { data: clientTasks, isLoading: isClientTasksLoading } = useGetTasksQuery(
    { clientRecordId },
    { skip: !clientRecordId, pollingInterval: REFETCH_DATA_INTERVAL_TIME }
  );
  const { psychometricList, isPsychometricListLoading } = useFetchPsychometricList(token);
  const { data: surveyList, isLoading: isSurveyListLoading } = useGetAllSurveysQuery();
  const { CONSENT, SURVEY, ONBOARDING, PSYCHOMETRIC } = useSomeoneHealthRoutesGenerator();

  const isContentLoading = isClientTasksLoading || isPsychometricListLoading || isSurveyListLoading;

  const gpSurvey = surveyList?.find(({ isGPSurvey }) => isGPSurvey);
  const surveys = surveyList?.filter(({ isGPSurvey }) => !isGPSurvey) || [];

  const noGP = clientProfile && !clientProfile.referral;
  const showGPSurvey = noGP && gpSurvey;
  const showGPSurveyCompleted = noGP && clientProfile.gpSurveyCompleted;

  const clientTasksLength = clientTasks
    ? clientTasks.failedPayments.length + clientTasks.consentList.length + clientTasks.assessmentList.length
    : 0;
  const surveyListLength = surveyList ? surveyList.length : 0;
  const psychometricListLength = psychometricList ? psychometricList.length : 0;
  const showGPSurveyLength = showGPSurvey ? 1 : 0;
  const showGPSurveyCompletedLength = showGPSurveyCompleted ? 1 : 0;
  const totalLength =
    clientTasksLength + surveyListLength + psychometricListLength + showGPSurveyLength + showGPSurveyCompletedLength;

  return (
    <SomeoneHealthHelmetWrapper title={`someone.health - Pending actions`}>
      <PatientContentLayout className={styles.container}>
        <div className={styles.contentWrapper}>
          <SomeoneHealthHeader withPadding withMenu homeLink />
          <div className={styles.content}>
            {isContentLoading ? (
              <div className={styles.loading}>
                <LoadingCircle />
              </div>
            ) : (
              <div className={styles.contentBox}>
                <div className={styles.taskListContainer}>
                  <div className={styles.taskListTitle}>
                    <strong className={styles.title}>Here are your actions {clientName}</strong>
                    <div>Please complete the following {totalLength} actions.</div>
                  </div>
                  <div className={styles.taskListBox}>
                    {clientTasks && (
                      <>
                        {clientTasks.failedPayments.map(({ amount, _id }) => (
                          <PaymentFailureTaskBox amount={amount} key={_id} paymentRequestId={_id} />
                        ))}
                        {showGPSurvey && (
                          <TaskBox
                            title={'GP Pre Session Form'}
                            desc="Please complete this form to be able to book in to see one of our GP team"
                            onClick={() => navigate(`${SURVEY.BASE}/${gpSurvey._id}`)}
                          />
                        )}
                        {showGPSurveyCompleted && <CompletedTaskBox />}
                        {clientTasks.consentList.length > 0 && (
                          <TaskBox
                            title={t('label.esign_client_consent_form')}
                            desc={`${clientTasks.consentList.length} actions`}
                            onClick={() => navigate(CONSENT.BASE)}
                          />
                        )}
                        {clientTasks.assessmentList.length > 0 && (
                          <TaskBox
                            title={'Onboarding assessment'}
                            desc={`${clientTasks.assessmentList.length} actions`}
                            onClick={() => navigate(ONBOARDING.BASE)}
                          />
                        )}
                      </>
                    )}
                    {surveyList && surveys.length > 0 && (
                      <TaskBox
                        title={'Surveys'}
                        desc={`${surveyList.length} actions`}
                        onClick={() => navigate(SURVEY.BASE)}
                      />
                    )}
                    {psychometricList.length > 0 && (
                      <TaskBox
                        title={'Psychometrics'}
                        desc={`${psychometricList.length} actions`}
                        onClick={() => navigate(PSYCHOMETRIC.BASE)}
                      />
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </PatientContentLayout>
    </SomeoneHealthHelmetWrapper>
  );
};

export default SomeoneHealthActionPage;
