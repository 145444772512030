export enum ClientRecordType {
  Adult = 'adult',
  Child = 'child',
  Couple = 'couple',
  YoungPerson = 'youngPerson'
}

export enum CommunicationPreference {
  NoPreference = 'noPreference',
  Email = 'email',
  SMS = 'sms',
  NONE = 'none'
}

export interface clientProfilesInterface {
  clientAuth0Id?: string;
  _id: string;
  createdAt: string;
  hashedMobileNumber: string;
  avatar?: string;
  initials: string;
  initialsBackgroundColor: string;
  isEmailThirdParty: boolean;
  isMobileNumberThirdParty: boolean;
  name: string;
  firstName: string;
  lastName: string;
  dateOfBirth?: string;
  onboardingSettings?: {
    facilitationUrl: string;
    firstAssessmentId: string;
    onboardingMethod: string;
  };
  profileType: 'full' | 'recordOnly';
  signupInvitation?: {
    isSent: boolean;
    createdAt: string;
    inviteDate: string;
    sendAt: string;
    updatedAt: string;
    signupDate: string;
  };
  updatedAt: string;
  isPrimaryContact?: boolean;
  role?: string;
}

export interface clientRecordsInterface {
  clientProfiles: clientProfilesInterface[];
  communicationPreference?: CommunicationPreference;
  createdAt: string;
  recordStatus: 'active' | 'closed';
  recordType: ClientRecordType;
  referral: {
    source?: string;
    isReferredByGP?: boolean;
  };
  invoiceSummary?: {
    count: number;
    order: number;
    settings: {
      automatedInvoicing: {
        active: boolean;
        dueAt: number;
        includeAppointments: string;
        issueAt: string;
        note: string;
        sendToClient: boolean;
        type: string;
        sendToOther: any;
      };
      discount?: {
        active: boolean;
        type: 'percent' | 'amount';
        value: number;
      };
    };
  };
  updatedAt: string;
  _id: string;
  isCompleteRecords: boolean;
}
