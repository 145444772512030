import { httpClient } from 'utils/httpClient';
import { BentResponse } from 'bent';

const url = process.env.REACT_APP_CLINICIAN_PROFILE_SERVICE_URL;

export const getIP6Details = (token: string, clientRecordId: string, clientProfileId: string) =>
  httpClient('GET', url!, 200, { Authorization: `Bearer ${token}` })(
    `/client-records/${clientRecordId}/client-profiles/${clientProfileId}/programs/ip6`
  ) as Promise<BentResponse>;

export const putIP6Activities = (token: string, id: string, clientRecordId: string, clientProfileId: string) =>
  httpClient('PUT', url!, [200, 204], { Authorization: `Bearer ${token}` })(
    `/client-records/${clientRecordId}/client-profiles/${clientProfileId}/programs/ip6/activities/${id}:start`
  ) as Promise<BentResponse>;
