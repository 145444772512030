import styles from './PartnerConfirmPage.module.scss';
import {
  reservedHBPartnerReferral,
  useReserveAppointmentData,
  useSetReserveAppointmentData
} from 'utils/hooks/EngageReserved/reservedAppointment';
import { useHealthBrightPartnerRoutesGenerator } from '../../utils/Path/HealthBrightPartnerRoutesGenerator';
import { useNavigate } from 'react-router-dom';
import ButtonHB from 'HealthBright/components/ButtonHB/ButtonHB';
import HealthBrightHeader from 'HealthBright/components/HealthBrightHeader/HealthBrightHeader';
import HealthBrightHelmetWrapper from 'HealthBright/components/HealthBrightHelmetWrapper/HealthBrightHelmetWrapper';
import HealthBrightContentLayout from 'HealthBright/components/HealthBrightContentLayout/HealthBrightContentLayout';
import { HEALTH_BRIGHT_TIME_ZONE_LIST, healthBrightTimeZoneLocalStorage } from '../../../../utils/constants/timeZone';
import Loading from 'components/Loading/Loading';
import { useFetchReservedAppointmentsWithTimeZone } from 'HealthBright/utils/hooks/appointment';
import { useFetchPractitionerDetails } from '../PartnerPsychologistDetails/hooks/getPsychologistDetails';
import { PARTNER_HB_SIGN_UP_USER, retrieveUserSession } from 'utils/userSession';

const PartnerConfirmPage = () => {
  const navigate = useNavigate();
  const user = retrieveUserSession(PARTNER_HB_SIGN_UP_USER)?.clientRecord?.clientProfiles?.[0];
  const clientTimeZone = localStorage.getItem(healthBrightTimeZoneLocalStorage) || HEALTH_BRIGHT_TIME_ZONE_LIST[0].id;
  const { PRACTITIONER } = useHealthBrightPartnerRoutesGenerator();
  const { reserveId, accountId, clinicianId } = useReserveAppointmentData(reservedHBPartnerReferral);
  const { practitionerDetails } = useFetchPractitionerDetails(clinicianId!);
  const { removeReserveAppointmentData } = useSetReserveAppointmentData(reservedHBPartnerReferral);
  const { fetching, appointments } = useFetchReservedAppointmentsWithTimeZone({
    reserveId: reserveId!,
    accountId: accountId!,
    timeZone: clientTimeZone
  });
  const clinicianName = practitionerDetails.name;
  const clinicianAvatar = practitionerDetails.avatar;

  const handleGoToAccount = () => {
    removeReserveAppointmentData();
    navigate(PRACTITIONER.LISTING);
  };

  return (
    <HealthBrightHelmetWrapper title="HealthBright - Confirmed">
      <HealthBrightHeader noLogin />
      <HealthBrightContentLayout className="health-bright-theme">
        {fetching ? (
          <div className={styles.loadingContainer}>
            <Loading />
          </div>
        ) : (
          <div className={styles.box}>
            <div className={styles.avatar}>
              <img src={clinicianAvatar} alt={'Avatar'} />
            </div>
            <div className={styles.content}>
              <div className={styles.badge}>APPOINTMENT CONFIRMED</div>
              <div className={styles.title}>
                Nice work! The appointment for {user?.name} with {clinicianName} has been confirmed.
              </div>
              <div className={styles.desc}>
                <div>
                  Appointment ID is: <strong>{appointments.map((obj) => obj._id).join(', ')}</strong>
                </div>
                <br />
                The client will receive confirmation of their appointment booking by Email and/or SMS shortly
                <br />
                <br />
                They will also receive information on what to expect.
                <br />
                <br />
                You can provide this link should they want to read more immediately: {/* TODO */}
                <a
                  href={'https://someone.health/I-have-been-referred'}
                  className={styles.link}
                  rel="noreferrer"
                  target={'_blank'}
                >
                  someone.health/I-have-been-referred
                </a>
              </div>
            </div>
            <div className={styles.closeButtonWrapper}>
              <ButtonHB fullWidth onClick={handleGoToAccount} className={styles.closeButton}>
                Close this session
              </ButtonHB>
            </div>
          </div>
        )}
      </HealthBrightContentLayout>
    </HealthBrightHelmetWrapper>
  );
};

export default PartnerConfirmPage;
