import classNames from 'classnames';

import styles from './HelmContentLayout.module.scss';

interface HelmContentLayoutProps {
  children?: any;
  className?: string;
}

const HelmContentLayout = ({ children, className }: HelmContentLayoutProps) => {
  return <div className={classNames(styles.contentLayout, className)}>{children}</div>;
};

export default HelmContentLayout;
