import moment from 'moment';
import SelectSlot from '../SelectSlot/SelectSlot';
import styles from './BookingSession.module.scss';
import { AvailabilityAppointmentTypes, DeliveryType, TimeSlotsWithDateInterface } from 'utils/hooks/appointment';
import classNames from 'classnames';
import { PractitionerDetailsInterface } from 'interfaces/PublicProfile/Practitioner/practitioner';
import ToggleSwitchV2 from 'components/ToggleSwitchV2/ToggleSwitchV2';
import { useMemo } from 'react';
import { generateEngageConsultPreferences, getDeliveryTypeLabel } from 'utils/appointment';
import HealthBrightContentLayout from 'HealthBright/components/HealthBrightContentLayout/HealthBrightContentLayout';
import ButtonHB from 'HealthBright/components/ButtonHB/ButtonHB';

interface BookingSessionProps {
  helmDetails: PractitionerDetailsInterface;
  selectedAppointmentType?: AvailabilityAppointmentTypes;
  selectedTimeSlots: TimeSlotsWithDateInterface[];
  onRemoveSelectedSlot: (slot: TimeSlotsWithDateInterface) => void;
  isAvailabilityListLoading: boolean;
  isProcessingReservation: boolean;
  onContinue: () => void;
  isAdvisorySession?: boolean;
  selectedDeliveryPreference: DeliveryType;
  onChangeDeliveryPreference: (value: DeliveryType) => void;
  classname?: string;
}

const TIME_SLOT_DATE_FORMAT = 'DD MMM YYYY';

const BookingSession = ({
  helmDetails,
  selectedAppointmentType,
  selectedTimeSlots,
  onRemoveSelectedSlot,
  isAvailabilityListLoading,
  isProcessingReservation,
  onContinue,
  isAdvisorySession,
  selectedDeliveryPreference,
  onChangeDeliveryPreference,
  classname
}: BookingSessionProps) => {
  const { name, helmControl } = helmDetails;

  const consultPreferences = useMemo(
    () =>
      generateEngageConsultPreferences({
        selectedAppointmentType,
        selectedDeliveryPreference
      }),
    [selectedAppointmentType, selectedDeliveryPreference]
  );

  return (
    <div className={classNames(styles.bookingContainer, isAdvisorySession && styles.isAdvisorySession, classname)}>
      <HealthBrightContentLayout className={styles.contentLayout}>
        <div className={styles.bookingContent}>
          <div className={styles.slotWrapper}>
            {/* Slot */}
            <div className={styles.slotContainer}>
              {selectedTimeSlots.filter((obj) => obj.isConflict).length > 0 && (
                <div className={styles.blocked}>
                  Our counsellors are in high demand. Some times are no longer available. Please select another time.
                </div>
              )}
              <div className={styles.slotWrapper}>
                {selectedTimeSlots.map((timeSlotObj, index) => (
                  <SelectSlot
                    key={index}
                    date={moment(timeSlotObj.date)}
                    time={`${moment(timeSlotObj.startTime, 'hh:mmA').format('hh:mm')} - ${timeSlotObj.endTime}`}
                    onDelete={() => onRemoveSelectedSlot(timeSlotObj)}
                    blockSlot={timeSlotObj.isConflict}
                  />
                ))}
                {Array.from(Array((selectedAppointmentType?.slotCount || 1) - selectedTimeSlots.length)).map(
                  (obj, i) => (
                    <SelectSlot key={i} />
                  )
                )}
              </div>
            </div>
          </div>

          <div className={styles.middleContent}>
            {/* Description */}
            <div className={classNames(styles.bookingDesc)}>
              <div className={styles.bookingTitle}>You are booking</div>
              <div className={styles.bookingDescSummary}>
                {`${selectedAppointmentType?.name} | ${selectedAppointmentType?.duration} minutes ${
                  selectedAppointmentType?.rate !== undefined && selectedAppointmentType?.rate > 0
                    ? `| $${selectedAppointmentType?.rate}`
                    : ''
                }`}
                <span className={styles.bookingOnLabel}>
                  {selectedTimeSlots.length > 0
                    ? ` on ${moment(selectedTimeSlots[0].date).format(TIME_SLOT_DATE_FORMAT)}`
                    : ''}
                </span>
              </div>
              <div className={styles.importantInfoContainer}>
                <div className={styles.importantInfoTitle}>IMPORTANT INFORMATION</div>
                <div className={styles.importantInfoContent}>
                  <div className={styles.cancellation}>
                    You can manage or change appointments in line with our{' '}
                    <a
                      className={styles.link}
                      href="https://someone.health/terms-and-conditions"
                      rel="noreferrer"
                      target="_blank"
                    >
                      cancellation policy
                    </a>
                  </div>
                  {helmControl.servicesUnavailable && helmControl.servicesUnavailable.length > 0 && (
                    <div className={styles.servicesUnavailable}>
                      <div>{`${name} DOES NOT`}</div>
                      <div className={styles.unavailableItems}>
                        {helmControl.servicesUnavailable.map((item, index) => (
                          <div key={index} className={styles.unavailableItem}>
                            <i className={classNames(styles.closeIcon, `material-icons`)}>close</i>
                            {item}
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {/* Consult Preferences */}
            {consultPreferences && consultPreferences.length > 0 && (
              <div className={styles.consultPreferenceWrapper}>
                <div className={styles.consultPreference}>
                  <div className={styles.consultPreferenceLabel}>CONSULT</div>
                  <ToggleSwitchV2
                    id="consultPreference"
                    toggleList={consultPreferences}
                    onChangeStatus={(value) => {
                      onChangeDeliveryPreference(value.id as DeliveryType);
                    }}
                    className={styles.consultPreferenceButton}
                    wrapperClassName={styles[`optionButton${consultPreferences?.length}`]}
                    indicatorClassName={styles.indicator}
                    labelClassName={styles.label}
                    labelActiveClassName={styles.labelActive}
                  />
                  <div className={styles.selectedPreference}>
                    {getDeliveryTypeLabel(
                      selectedDeliveryPreference,
                      selectedAppointmentType?.otherInstructions
                    ).toUpperCase()}{' '}
                    {` preferred`}
                  </div>
                </div>
              </div>
            )}
          </div>

          {/* Next button */}
          <div className={styles.continueBtnWrapper}>
            <ButtonHB
              className={styles.continueBtn}
              onClick={onContinue}
              disabled={
                isAvailabilityListLoading ||
                isProcessingReservation ||
                selectedTimeSlots.length < (selectedAppointmentType?.slotCount || 1)
              }
              icon={'navigate_next'}
              iconPostFix
            >
              Next to complete booking
            </ButtonHB>
            <div className={styles.continueLabel}>Appointment not confirmed until complete</div>
          </div>
        </div>
      </HealthBrightContentLayout>
    </div>
  );
};

export default BookingSession;
