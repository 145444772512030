import { notification } from 'antd';
import CaWContentLayout from 'CaW/components/CaWContentLayout/CaWContentLayout';
import CaWHeader from 'CaW/components/CaWHeader/CaWHeader';
import CaWHelmetWrapper from 'CaW/components/CaWHelmetWrapper/CaWHelmetWrapper';
import CaWTimer from 'CaW/components/CaWTimer/CaWTimer';
import { useFetchReservedAppointmentsWithTimeZone } from 'CaW/utils/hooks/appointment';
import { useReserveAppointmentData } from 'CaW/utils/hooks/localData';
import { useCaWToken } from 'CaW/utils/hooks/useCaWToken';
import { useCaWRoutesGenerator } from 'CaW/utils/Path/CaWRoutesGenerator';
import TacklitFooter from 'components/TacklitFooter/TacklitFooter';
import { HelmBtnStatus } from 'components/v2/Button/Button';
import { validatePhoneNumber } from 'pages/SignUp/SignUpForm/components/BasicDetails/validation/BasicDetailsValidation';
import { ReactNode, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { postKeyContact } from 'utils/http/ClinicianProfileService/ClientRecords/clientRecords';
import { cawTimeZoneLocalStorage, CAW_TIME_ZONE_LIST } from '../ClinicianListing/ClinicianListing';
import { getAuMobile } from '../SignUp/components/CaWCreateProfileForm/constants';
import styles from './CaWEmergencyContact.module.scss';
import CaWEmergencyContactForm from './CaWEmergencyContactForm/CaWEmergencyContactForm';

const CaWEmergencyContact = () => {
  const clientTimeZone = localStorage.getItem(cawTimeZoneLocalStorage) || CAW_TIME_ZONE_LIST[0].id;
  const { SIGNUP } = useCaWRoutesGenerator();
  const navigate = useNavigate();
  const [submitStatus, setSubmitStatus] = useState<HelmBtnStatus>('');
  const [invalidMobileNumber, setInvalidMobileNumber] = useState<ReactNode>();
  const { token } = useCaWToken();

  const { reserveId, accountId } = useReserveAppointmentData();
  const { fetching, appointments } = useFetchReservedAppointmentsWithTimeZone({
    reserveId: reserveId!,
    accountId: accountId!,
    timeZone: clientTimeZone
  });

  const onSubmit = async (values: {
    firstName: string;
    lastName: string;
    mobileNumber?: string;
    relationship: string;
  }) => {
    if (!token) {
      console.error('Token is missing');
      return;
    }

    setSubmitStatus('active');
    const mobileNumber = values.mobileNumber ? getAuMobile(values.mobileNumber) : undefined;
    let mobileNumberValid = true;

    if (mobileNumber) {
      const phoneValidate = await validatePhoneNumber(mobileNumber, true);
      if (!phoneValidate.valid) {
        setInvalidMobileNumber(<div>Mobile number not recognized. Please check for typos and try again.</div>);
        mobileNumberValid = false;
        setSubmitStatus('');
      }
    }
    if (mobileNumberValid) {
      try {
        await postKeyContact(token, { ...values, mobileNumber, tags: [values.relationship, 'Emergency Contact'] });
        setSubmitStatus('finished');
        setTimeout(() => {
          navigate(SIGNUP.CONFIRM_BOOKING);
          setSubmitStatus('');
        }, 1000);
      } catch (e) {
        console.error(e);
        notification.error({ message: 'Something went wrong while trying to add emergency contact' });
        setSubmitStatus('');
      }
    }
  };

  return (
    <>
      <CaWHelmetWrapper title={'Caraniche at Work - Sign Up'}>
        <CaWContentLayout>
          <CaWHeader withPadding classNames={styles.header} blueMenu />
          <div className={styles.container}>
            <div className={styles.header}>
              <div className={styles.primaryHeader}>
                Provide your <span className={styles.highlight}>Emergency Contact</span>
              </div>
            </div>
            <div className={styles.content}>
              <div className={styles.leftContent}>
                <div className={styles.header}>
                  <div className={styles.subHeader}>
                    To provide you with support we need you to provide the detail of one emergency contact.
                    <br />
                    <br />
                    We would only contact this person should we be concerned about your safety. You can add additional
                    contacts, and update information in your client profile
                  </div>
                </div>
                <CaWEmergencyContactForm
                  invalidMobileNumber={invalidMobileNumber}
                  setInvalidMobileNumber={setInvalidMobileNumber}
                  submitStatus={submitStatus}
                  onSubmit={onSubmit}
                />
              </div>
              <div className={styles.rightContent}>
                <div className={styles.timerContainer}>
                  <div className={styles.timerLabel}>
                    To confirm these appointments you need to complete registration and payment within 15 minutes
                  </div>
                  <CaWTimer isReservedAppointmentFetching={fetching} reservedAppointments={appointments} />
                </div>
              </div>
            </div>
          </div>
        </CaWContentLayout>
        <TacklitFooter />
      </CaWHelmetWrapper>
    </>
  );
};

export default CaWEmergencyContact;
