import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';

import { useGetAccessToken } from 'utils/hooks/token';

import LoadingCircle from 'components/LoadingCircle/LoadingCircle';

import TaskBox from 'components/TaskBox/TaskBox';
import { useGetAttachedClinicianDetails } from 'redux/endpoints/clinicianProfileServices/getClientDetails';
import { useRoutesGenerator } from 'utils/Path/RoutesGenerator';
import { useFetchSurveyList } from './hooks/getSurveyList';
import styles from './SurveyList.module.scss';
import classnames from 'classnames';
import { IS_CAW_APP } from '../../../utils/hooks/AccountInfo/clientDetails';

const SurveyList = () => {
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth0();
  const { token, isQueryParamToken, userInfo } = useGetAccessToken(true);
  const { attachedClinicianDetail, isAttachedClinicianDetailLoading } = useGetAttachedClinicianDetails();

  const { surveyList, isSurveyListLoading } = useFetchSurveyList(token);
  const { SURVEY } = useRoutesGenerator();

  const clinicianName = attachedClinicianDetail?.clinician?.name
    ? `${attachedClinicianDetail.clinician?.title} ${attachedClinicianDetail.clinician?.name}`
    : attachedClinicianDetail?.practice?.name || '';

  return (
    <div className={classnames(styles.container, IS_CAW_APP && 'caw-theme')}>
      {isAttachedClinicianDetailLoading || isSurveyListLoading ? (
        <div className={styles.loading}>
          <LoadingCircle />
        </div>
      ) : (
        <div className={styles.contentBox}>
          {!isQueryParamToken && isAuthenticated && (
            <div className={styles.backBtnWrapper}>
              <div className={styles.backBtn} onClick={() => navigate(`/`)}>
                <i className={`material-icons ${styles.icon}`}>keyboard_arrow_left</i>
                Return to home page
              </div>
            </div>
          )}
          {surveyList.length > 0 ? (
            <div className={styles.taskListContainer}>
              <div className={styles.taskListTitle}>
                <div className={styles.heading}>Onwards.</div>
                <strong>{clinicianName}</strong> has asked you to please review and complete the following{' '}
                <strong>{surveyList.length} actions</strong>
                {userInfo.childFirstName && ` for ${userInfo.childFirstName}`}
              </div>
              <div className={styles.taskListBox}>
                {surveyList.map((surveyObj, index) => (
                  <TaskBox
                    key={index}
                    title={surveyObj.title}
                    desc={`${surveyObj.totalQuestions ?? 0} questions`}
                    createdAt={surveyObj.createdAt}
                    onClick={() =>
                      navigate(`${SURVEY.BASE}/${surveyObj._id}${isQueryParamToken ? `?token=${token}` : ''}`)
                    }
                  />
                ))}
              </div>
            </div>
          ) : (
            <div className={styles.taskListContainer}>
              <div className={styles.taskListTitle}>All surveys completed.</div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default SurveyList;
