import styles from './HowToConnect.module.scss';
import { IP6Appointment } from 'helm/interfaces/IP6/ip6';
import { DeliveryType } from 'utils/hooks/appointment';

interface YourProgressContentProps {
  appointmentData: IP6Appointment;
}

const HowToConnect = ({ appointmentData }: YourProgressContentProps) => {
  const generateLabel = () => {
    switch (appointmentData.deliveryType) {
      case DeliveryType.VideoCall:
        return {
          label: appointmentData.videoCallInstructions ? 'Video call link' : 'Video call',
          instructions: appointmentData.videoCallInstructions
        };
      case DeliveryType.PhoneCall:
        return {
          label: 'Phone call',
          instructions: appointmentData.phoneCallInstructions
        };
      case DeliveryType.PhoneCallDialClient:
        return {
          label: 'Phone call',
          instructions: appointmentData.phoneCallDialClientInstructions
        };
      case DeliveryType.FaceToFace:
        return {
          label: appointmentData.faceToFaceLocation ? 'Face to face at' : 'Face to face',
          instructions: appointmentData.faceToFaceLocation
        };
      case DeliveryType.Other:
        if (appointmentData.otherInstructions) {
          return {
            label: appointmentData.faceToFaceLocation ? 'Face to face at' : 'Face to face',
            instructions: appointmentData.faceToFaceLocation
          };
        }
        return {
          label: '',
          instructions: ''
        };
      default:
        return {
          label: '',
          instructions: ''
        };
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.appointmentTitle}>HOW TO CONNECT</div>
      <div className={styles.connectWrapper}>
        <span>{generateLabel().label}</span>{' '}
        <a
          rel={'noopener noreferrer'}
          className={styles.appointmentLink}
          href={generateLabel().instructions}
          target={'_blank'}
        >
          {generateLabel().instructions}
        </a>
      </div>
    </div>
  );
};

export default HowToConnect;
