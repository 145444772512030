import styles from './AppointmentsOverview.module.scss';
import AppointmentList from 'components/AppointmentList/AppointmentList';
import { useGetClientProfileDetails } from 'redux/endpoints/clinicianProfileServices/getClientDetails';
import EaseHelmetWrapper from 'Ease/components/EaseHelmetWrapper/EaseHelmetWrapper';
import EaseContentLayout from 'Ease/components/EaseContentLayout/EaseContentLayout';
import EaseHeader from 'Ease/components/EaseHeader/EaseHeader';
// import { useEaseRoutesGenerator } from 'Ease/utils/Path/EaseRoutesGenerator';

const AppointmentsOverview = () => {
  const { clientProfileData, isClientProfileLoading } = useGetClientProfileDetails();
  // const { BOOKING } = useEaseRoutesGenerator();

  return (
    <EaseHelmetWrapper title={`EASE Wellbeing | Therapy & Counselling Experts in London - Appointments`}>
      <EaseContentLayout className={`${styles.container} ease-theme`}>
        <div className={styles.contentWrapper}>
          <EaseHeader withPadding withMenu homeLink greenMenu />
          <div className={styles.content}>
            <div className={styles.titleContainer}>
              <div className={styles.title}>Your Appointment Overview</div>
            </div>
            <AppointmentList
              isPatientProfileLoading={isClientProfileLoading}
              clientTimeZone={clientProfileData?.timeZone}
              className={styles.contentCardShadow}
              // add this back if need the button
              // newAppointmentPath={BOOKING.BASE}
            />
          </div>
        </div>
      </EaseContentLayout>
    </EaseHelmetWrapper>
  );
};

export default AppointmentsOverview;
