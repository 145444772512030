import classnames from 'classnames';
import { PractitionerDetailsInterface } from 'interfaces/PublicProfile/Practitioner/practitioner';
import { Link } from 'react-router-dom';
import styles from './PsychologistBasicDetails.module.scss';
import { truncateStringByMaxCharacter } from 'utils/general';
import { FitDeliveryList } from 'Select/interfaces/fitFilter';

export const PRONOUN_OPTIONS = {
  heHim: 'He / Him',
  sheHer: 'She / Her',
  theyThem: 'They / Them'
};

const INTRO_MAX_LENGTH = 750;

interface PsychologistBasicDetailsProps {
  className?: string;
  detailPath: string;
  practitionerDetails: PractitionerDetailsInterface;
  selectedValue: string[];
}

const PsychologistBasicDetails = ({
  className,
  detailPath,
  practitionerDetails,
  selectedValue
}: PsychologistBasicDetailsProps) => {
  const getIntroDetails = (value: string) => {
    const shorterIntro = truncateStringByMaxCharacter(value, INTRO_MAX_LENGTH);
    return `${shorterIntro}${
      shorterIntro !== value ? `... <a class='readMore' href='${detailPath}'>read more</a>` : ''
    }`;
  };

  return (
    <div className={classnames(styles.container, className && className)}>
      <div className={styles.topContent}>
        <Link to={detailPath} className={styles.name}>
          {practitionerDetails.name}
        </Link>
        {practitionerDetails.helmControl?.deliveryModes?.includes(FitDeliveryList.faceToFace) && (
          <div
            className={classnames(
              selectedValue?.includes(FitDeliveryList.faceToFace) ? styles.selectedTag : styles.tag
            )}
          >
            <i className={`material-icons ${styles.callIcon}`}>face</i>
            <div className={styles.label}>Face to Face</div>
          </div>
        )}
        {practitionerDetails.helmControl?.deliveryModes?.includes(FitDeliveryList.video) && (
          <div className={classnames(selectedValue?.includes(FitDeliveryList.video) ? styles.selectedTag : styles.tag)}>
            <i className={`material-icons ${styles.callIcon}`}>videocam</i>
            <div className={styles.label}>Video Consults</div>
          </div>
        )}
        {practitionerDetails.helmControl?.deliveryModes?.includes(FitDeliveryList.phone) && (
          <div className={classnames(selectedValue?.includes(FitDeliveryList.phone) ? styles.selectedTag : styles.tag)}>
            <i className={`material-icons ${styles.callIcon}`}>call</i>
            <div className={styles.label}>Phone Consults</div>
          </div>
        )}
      </div>
      {practitionerDetails.pronouns && (
        <div className={styles.middleContent}>
          {PRONOUN_OPTIONS[practitionerDetails.pronouns as keyof typeof PRONOUN_OPTIONS]}
        </div>
      )}
      <div className={styles.aboutMeSection}>
        <div className={styles.aboutMeLabel}>ABOUT ME</div>
        <div
          className={styles.aboutMe}
          dangerouslySetInnerHTML={{ __html: getIntroDetails(practitionerDetails.helmControl.shortDescription) }}
        />
      </div>
    </div>
  );
};

export default PsychologistBasicDetails;
