import { useNavigate, useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useHealthBrightPartnerRoutesGenerator } from '../../utils/Path/HealthBrightPartnerRoutesGenerator';
import { PARTNER_CODE_STORAGE } from 'interfaces/PartnerReferral/constants';
import styles from './AccessTokenAuthWrapper.module.scss';
import { HB_PARTNER_CODE_PARAMS } from 'HealthBright/HealthBrightPartner/pages/TokenAccess/hbPartnerCode';
import TacklitFooter from 'components/TacklitFooter/TacklitFooter';

interface AccessTokenAuthWrapperProps {
  children?: any;
  noFooter?: boolean;
}

const AccessTokenAuthWrapper = ({ children, noFooter }: AccessTokenAuthWrapperProps) => {
  const navigate = useNavigate();
  const { TOKEN } = useHealthBrightPartnerRoutesGenerator();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const accessCode = searchParams.get(HB_PARTNER_CODE_PARAMS);
    if (accessCode) {
      navigate(`${TOKEN.BASE}?${HB_PARTNER_CODE_PARAMS}=${accessCode}`);
      return;
    }
    const getPartnerCode = localStorage.getItem(PARTNER_CODE_STORAGE);
    if (!getPartnerCode) {
      navigate(TOKEN.BASE);
    }
  }, [navigate, TOKEN, searchParams]);

  return (
    <div className={styles.container}>
      {children}
      {!noFooter && <TacklitFooter darkFooter className={styles.footer} linkToTacklit />}
    </div>
  );
};

export default AccessTokenAuthWrapper;
