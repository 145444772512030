export const useHealthBrightPartnerRoutesGenerator = () => {
  const firstPath = window.location.pathname.split('/')[1];
  const initPath = firstPath !== 'p' ? '' : `/${firstPath}`;

  return {
    TOKEN: {
      BASE: `${initPath}/login`
    },
    PRACTITIONER: {
      LISTING: `${initPath}/psychologists`,
      LISTING_WITH_FILTER: `${initPath}/psychologists/:filter`,
      DETAILS: `${initPath}/psychologists-profile/:clinicianId`
    },
    SIGNUP: {
      BASE: `${initPath}/signup`,
      SUMMARY_BOOKING: `${initPath}/summary`,
      CONFIRM_BOOKING: `${initPath}/confirmed`
    }
  };
};
