import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';

import { useGetAccessToken } from 'utils/hooks/token';

import LoadingCircle from 'components/LoadingCircle/LoadingCircle';

import styles from '../../Survey/SurveyList/SurveyList.module.scss';
import TaskBox from 'components/TaskBox/TaskBox';
import { useRoutesGenerator } from 'utils/Path/RoutesGenerator';
import { useGetAttachedClinicianDetails } from 'redux/endpoints/clinicianProfileServices/getClientDetails';
import { useGetTasksQuery } from 'redux/endpoints/clinicianProfileServices/client';

export const OnboardingList = () => {
  const { user, isAuthenticated } = useAuth0();
  const { token, isQueryParamToken, userInfo } = useGetAccessToken(true);
  const navigate = useNavigate();
  const clientRecordId = user?.['https://tacklit.com/clientRecordId'] || '';
  const { attachedClinicianDetail, isAttachedClinicianDetailLoading } = useGetAttachedClinicianDetails();
  const { data: clientTasks, isLoading: isClientTasksLoading } = useGetTasksQuery(
    { clientRecordId },
    { skip: !clientRecordId }
  );
  const { ONBOARDING } = useRoutesGenerator();

  const clinicianName = attachedClinicianDetail?.clinician?.name
    ? `${attachedClinicianDetail.clinician?.title} ${attachedClinicianDetail.clinician?.name}`
    : attachedClinicianDetail?.practice?.name || '';

  return (
    <div className={styles.container}>
      {isAttachedClinicianDetailLoading || isClientTasksLoading ? (
        <div className={styles.loading}>
          <LoadingCircle />
        </div>
      ) : (
        <div className={styles.contentBox}>
          {isAuthenticated && (
            <div className={styles.backBtnWrapper}>
              <div className={styles.backBtn} onClick={() => navigate(`/`)}>
                <i className={`material-icons ${styles.icon}`}>keyboard_arrow_left</i>
                Return to home page
              </div>
            </div>
          )}
          {clientTasks && clientTasks.assessmentList.length > 0 ? (
            <div className={styles.taskListContainer}>
              <div className={styles.taskListTitle}>
                <div className={styles.heading}>Onwards.</div>
                <strong>{clinicianName}</strong> has asked you to please review and complete the following{' '}
                <strong>{clientTasks.assessmentList.length} actions</strong>
                {userInfo.childFirstName && ` for ${userInfo.childFirstName}`}
              </div>
              <div className={styles.taskListBox}>
                {clientTasks.assessmentList.map((surveyObj, index) => (
                  <TaskBox
                    key={index}
                    title={surveyObj.title}
                    desc={`${surveyObj.totalQuestions ?? 0} questions`}
                    createdAt={surveyObj.createdAt}
                    onClick={() =>
                      navigate(
                        `${ONBOARDING.BASE}/${surveyObj._id}/onboard${isQueryParamToken ? `?token=${token}` : ''}`
                      )
                    }
                  />
                ))}
              </div>
            </div>
          ) : (
            <div className={styles.taskListContainer}>
              <div className={styles.taskListTitle}>All onboarding surveys completed.</div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
