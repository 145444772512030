import { listInterface } from 'components/FilterDropdown/FilterDropdown';
import styles from './ListingFilter.module.scss';
import FilterDropdownWrapper from '../components/FilterDropdownWrapper/FilterDropdownWrapper';
import {
  AgeRangeList,
  MentalHealthList,
  NUMBER_AGE_RANGE_LIST_LABELS,
  MENTAL_HEALTH_LIST_LABELS
} from 'SomeoneHealth/interfaces/mentalHealth';
import classNames from 'classnames';
import HealthBrightContentLayout from 'HealthBright/components/HealthBrightContentLayout/HealthBrightContentLayout';

interface ListingFilterProps {
  selectedMentalHealthConcern: MentalHealthList[];
  onChangeMentalHealthConcern: (value: listInterface) => void;
  selectedAgeRange?: AgeRangeList;
  onChangeAgeRange: (value: listInterface | undefined) => void;
  handleResetValue: () => void;
  isLoading?: boolean;
}

const ListingFilter = ({
  selectedMentalHealthConcern,
  onChangeMentalHealthConcern,
  selectedAgeRange,
  onChangeAgeRange,
  handleResetValue,
  isLoading
}: ListingFilterProps) => {
  const massageMentalHealthConcern = selectedMentalHealthConcern.map((item) => {
    return {
      id: item,
      label: MENTAL_HEALTH_LIST_LABELS[item]
    };
  });

  let massageAgeRange = undefined;
  if (selectedAgeRange) {
    massageAgeRange = {
      id: selectedAgeRange,
      label: NUMBER_AGE_RANGE_LIST_LABELS[selectedAgeRange as keyof typeof AgeRangeList]
    };
  }

  return (
    <HealthBrightContentLayout>
      <div className={styles.container}>
        <div className={styles.filterWrapper}>
          <div className={styles.header}>
            <div className={styles.label}>SHOW MATCHES FOR</div>
            <div
              className={classNames(styles.headerReset, isLoading && styles.disabled)}
              onClick={() => {
                !isLoading && handleResetValue();
              }}
            >
              Reset
            </div>
          </div>
          <FilterDropdownWrapper
            classNames={styles.filter}
            mentalHealthConcernValue={massageMentalHealthConcern}
            onChangeMentalHealthConcernValue={onChangeMentalHealthConcern}
            onChangeAgeRangeValue={onChangeAgeRange}
            ageRangeValue={massageAgeRange}
            handleResetValue={handleResetValue}
            isLoading={isLoading}
          />
        </div>
      </div>
    </HealthBrightContentLayout>
  );
};

export default ListingFilter;
