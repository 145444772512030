import { useMemo, MouseEvent as ReactMouseEvent } from 'react';
import parse from 'html-react-parser';

import { Clinician } from 'utils/hooks/clinician';

import { FirstAssessment } from 'components/PatientQuestionForm/AssessmentInterfaces';
import Button from 'components/Button/Button';

import ThankYouImage from 'assets/images/thankyou.png';

import styles from './Gateway.module.scss';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import ClientHeader from 'components/ClientHeader/ClientHeader';
import HelmHeader from 'helm/components/HelmHeader/HelmHeader/HelmHeader';
import PatientContentLayout from 'components/PatientContentLayoutV2/PatientContentLayout';
import {
  IS_CAW_APP,
  IS_EASE_APP,
  IS_HELM_APP,
  IS_RECHARGE_APP,
  IS_SELECT_APP,
  IS_SOMEONE_HEALTH_APP
} from 'utils/hooks/AccountInfo/clientDetails';
import SomeoneHealthHeader from 'SomeoneHealth/components/SomeoneHealthHeader/SomeoneHealthHeader';
import ButtonSH from 'SomeoneHealth/components/ButtonSH/ButtonSH';
import { CLIENT_BRAND, CLIENT_BRAND_LABEL } from 'interfaces/ClientBrand';
import SHThankYouImage from '../../../../assets/images/shtq.png';
import CaWHeader from '../../../../CaW/components/CaWHeader/CaWHeader';
import classnames from 'classnames';
import EaseHeader from 'Ease/components/EaseHeader/EaseHeader';
import ButtonEase from 'Ease/components/ButtonEase/ButtonEase';
import ButtonRecharge from 'Recharge/components/ButtonRecharge/ButtonRecharge';
import RechargeHeader from 'Recharge/components/RechargeHeader/RechargeHeader';
import SelectHeader from 'Select/components/SelectHeader/SelectHeader';
import ButtonSelect from 'Select/components/ButtonSelect/ButtonSelect';

interface GatewayProps {
  state: 'done' | 'saveAndExit' | 'welcomeBack';
  onReturnToCheckIn: () => void;
  assessment?: FirstAssessment;
  clinician?: Clinician;
  completedCheckInStatus?: boolean;
  patientDetails?: { name: string; picture: string };
}

const Gateway = ({
  state,
  onReturnToCheckIn,
  assessment,
  clinician,
  completedCheckInStatus,
  patientDetails
}: GatewayProps) => {
  const { user } = useAuth0();
  const navigate = useNavigate();
  const handleReturnToCheckIn = (e: ReactMouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    onReturnToCheckIn();
  };

  const getDescription = () => {
    if (completedCheckInStatus === false && clinician?.customisations?.checkIn?.firstCheckInDescription) {
      return parse(
        clinician.customisations.checkIn.firstCheckInDescription
          .replace(/\$CLIENT_NAME/g, patientDetails?.name)
          .replace(/\$CLINICIAN_NAME/g, `${clinician.title} ${clinician.name}`)
      );
    } else if (clinician?.customisations?.checkIn?.checkInDescription) {
      return parse(clinician.customisations.checkIn.checkInDescription.replace(/\$CLIENT_NAME/g, patientDetails?.name));
    } else {
      return parse(`<h2><strong>Welcome back.</strong></h2>Hi ${patientDetails?.name}, well done for continuing to log your thoughts in this micro journal. Remember recovery is
      not a straight line, so expect there to be ups and downs along the way.<br /><br /><strong>
        Team ${CLIENT_BRAND_LABEL[CLIENT_BRAND]}</strong>`);
    }
  };

  const totalQuestions = useMemo(() => {
    const {
      checkInQuestions = [],
      clinicalAssessments = [],
      microJournalQuestions = [],
      onboardingQuestions = {
        assessmentField: [] as FirstAssessment['assessment']['onboardingQuestions']['assessmentField']
      }
    } = assessment?.assessment || {};

    const clinicalAssessmentsLength = clinicalAssessments.length;
    const checkInQuestionsLength = checkInQuestions.flatMap((checkInQuestion) =>
      checkInQuestion.sections.flatMap((section) => section.questions)
    ).length;
    const microJournalQuestionsLength = microJournalQuestions.flatMap((microJournalQuestion) =>
      microJournalQuestion.sections.flatMap((section) => section.questions)
    ).length;
    const onboardingQuestionsLength = onboardingQuestions.assessmentField.flatMap((onboardingQuestion) =>
      onboardingQuestion.sections.flatMap((section) => section.questions)
    ).length;

    return clinicalAssessmentsLength + checkInQuestionsLength + microJournalQuestionsLength + onboardingQuestionsLength;
  }, [assessment]);

  const handleBackHomePage = () => {
    navigate('/');
  };

  return (
    <div className={classnames(styles.container, IS_CAW_APP && 'caw-theme')}>
      <div className={styles.contentWrapper}>
        {(state === 'welcomeBack' || state === 'saveAndExit') && (
          <PatientContentLayout className={styles.wrapper}>
            {IS_HELM_APP ? (
              <HelmHeader
                classNames={styles.helmHeader}
                noWrapper
                whiteFont
                logoWithoutText
                whiteLogo
                withMenu
                homeLink
              />
            ) : IS_SOMEONE_HEALTH_APP ? (
              <SomeoneHealthHeader classNames={styles.shHeader} withPadding withMenu homeLink whiteFont />
            ) : IS_CAW_APP ? (
              <CaWHeader classNames={styles.shHeader} withPadding withMenu homeLink whiteFont blueMenu />
            ) : IS_EASE_APP ? (
              <EaseHeader classNames={styles.shHeader} withPadding withMenu homeLink whiteFont greenMenu />
            ) : IS_RECHARGE_APP ? (
              <RechargeHeader classNames={styles.shHeader} withPadding withMenu homeLink whiteFont whiteMenu />
            ) : IS_SELECT_APP ? (
              <SelectHeader className={styles.shHeader} withPadding withMenu homeLink whiteFont />
            ) : (
              <div className={styles.tacklitLogo}>
                <ClientHeader withMenu noPadding />
              </div>
            )}
            <div className={styles.content}>
              <div className={styles.description}>{getDescription()}</div>
            </div>
          </PatientContentLayout>
        )}
        {state === 'done' && (
          <PatientContentLayout className={styles.wrapper}>
            {IS_HELM_APP ? (
              <HelmHeader
                classNames={styles.helmHeader}
                noWrapper
                whiteFont
                logoWithoutText
                whiteLogo
                withMenu
                homeLink
              />
            ) : IS_SOMEONE_HEALTH_APP ? (
              <SomeoneHealthHeader classNames={styles.shHeader} withPadding withMenu homeLink whiteFont />
            ) : IS_CAW_APP ? (
              <CaWHeader classNames={styles.shHeader} withPadding withMenu homeLink whiteFont blueMenu />
            ) : IS_EASE_APP ? (
              <EaseHeader classNames={styles.shHeader} withPadding withMenu homeLink whiteFont greenMenu />
            ) : IS_RECHARGE_APP ? (
              <RechargeHeader classNames={styles.shHeader} withPadding withMenu homeLink whiteFont whiteMenu />
            ) : IS_SELECT_APP ? (
              <SelectHeader className={styles.shHeader} withPadding withMenu homeLink whiteFont />
            ) : (
              <div className={styles.tacklitLogo}>
                <ClientHeader withMenu noPadding />
              </div>
            )}
            <div className={styles.doneContainer}>
              {IS_SOMEONE_HEALTH_APP || IS_CAW_APP ? (
                <>
                  <img className={styles.shThankYouImage} src={SHThankYouImage} alt="Thank you" />
                  <div className={styles.shThankYouText}>Thank you for completing these questions</div>
                  <div className={styles.shThankYouText}>See you next time</div>
                </>
              ) : (
                <>
                  <div className={styles.thankYouText}>Thank you for completing these questions</div>
                  <img className={styles.thankYouImage} src={ThankYouImage} alt="Thank you" />
                  <div className={styles.thankYouText}>See you next time</div>
                </>
              )}
              {user && (
                <div className={styles.homePageBtn}>
                  <Button onClick={handleBackHomePage}>Back to home page</Button>
                </div>
              )}
            </div>
          </PatientContentLayout>
        )}
      </div>
      {(state === 'welcomeBack' || state === 'saveAndExit') && (
        <div className={styles.card}>
          <div className={styles.returnContent}>
            <div className={styles.title}>
              Today's check-in has <strong>{totalQuestions}</strong> questions and should take less than{' '}
              <strong>{(totalQuestions || 0) * 22.5}</strong> seconds to complete
            </div>
            <div className={styles.buttonWrapper}>
              {IS_SOMEONE_HEALTH_APP ? (
                <ButtonSH className={styles.button} onClick={handleReturnToCheckIn}>
                  Let's Begin
                </ButtonSH>
              ) : IS_EASE_APP ? (
                <ButtonEase className={styles.button} onClick={handleReturnToCheckIn}>
                  Let's Begin
                </ButtonEase>
              ) : IS_RECHARGE_APP ? (
                <ButtonRecharge className={styles.button} onClick={handleReturnToCheckIn}>
                  Let's Begin
                </ButtonRecharge>
              ) : IS_SELECT_APP ? (
                <ButtonSelect className={styles.button} onClick={handleReturnToCheckIn}>
                  Let's Begin
                </ButtonSelect>
              ) : (
                <Button className={styles.button} variant="primary" onClick={handleReturnToCheckIn}>
                  Let's Begin
                </Button>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Gateway;
