import { PractitionerDetailsInterface, nextAvailability } from 'interfaces/PublicProfile/Practitioner/practitioner';
import moment from 'moment';
import { FC, useMemo } from 'react';
import { Link, generatePath } from 'react-router-dom';
import PsychologistSpecialisation from '../../PsychologistSpecialisation/PsychologistSpecialisation';
import styles from './PsychologistGridCard.module.scss';
import { useAuth0 } from '@auth0/auth0-react';
import { truncateStringByMaxCharacter } from 'utils/general';
import { getRoleLabel } from 'SomeoneHealth/components/PsychologistBonaFides/PsychologistBonaFides';
import { MentalHealthList } from 'HealthBright/interfaces/mentalHealth';
import {
  getMentalHealthLabels,
  getNextTimeSlot,
  isNoSlotForNewClient
} from 'SomeoneHealth/pages/PsychologistListing/utils/utils';
import PsychologistAvatar from 'HealthBright/components/PsychologistAvatar/PsychologistAvatar';
import CalendarBox from 'HealthBright/components/CalendarBox/CalendarBox';
import ButtonHB from 'HealthBright/components/ButtonHB/ButtonHB';
import { useHealthBrightPartnerRoutesGenerator } from 'HealthBright/HealthBrightPartner/utils/Path/HealthBrightPartnerRoutesGenerator';

const INTRO_MAX_LENGTH = 185;

interface PsychologistGridCardProps {
  practitioner: PractitionerDetailsInterface;
  selectedMentalHealthConcern: MentalHealthList[];
  matchedSpecialisations: string[];
  nextAvailabilityDetails: nextAvailability[];
  handleOpenWaitListModal: () => void;
}

const PsychologistGridCard: FC<PsychologistGridCardProps> = ({
  practitioner,
  selectedMentalHealthConcern,
  matchedSpecialisations,
  nextAvailabilityDetails,
  handleOpenWaitListModal
}) => {
  const { PRACTITIONER } = useHealthBrightPartnerRoutesGenerator();
  const { isAuthenticated } = useAuth0();

  const detailPath = generatePath(PRACTITIONER.DETAILS, { clinicianId: practitioner.slugUrl });

  const nextTimeSlot = useMemo(() => getNextTimeSlot(nextAvailabilityDetails), [nextAvailabilityDetails]);

  const noSlotForNewClient = isNoSlotForNewClient(
    isAuthenticated,
    practitioner.helmControl.shouldUseCaseLoad,
    practitioner.caseLoad.remainingSlots
  );

  const getTruncatedDescription = ({ text, className }: { text: string; className: string }) => {
    const truncatedText = truncateStringByMaxCharacter(text, INTRO_MAX_LENGTH);

    return truncatedText !== text ? (
      <p className={className}>
        {truncatedText}&nbsp;...&nbsp;
        <Link to={detailPath}>Read more</Link>
      </p>
    ) : (
      <p className={className}>{text}</p>
    );
  };

  return (
    <div className={styles.psychologistCardContainer}>
      <div className={styles.infoContainer}>
        <PsychologistAvatar
          className={styles.imageWrapper}
          classNameFrame={styles.borderFrame}
          classNameImage={styles.avatar}
          profileImg={practitioner.avatar}
          detailPath={detailPath}
        />
        <div className={styles.nameContainer}>
          <Link to={detailPath} className={styles.practitionerName}>
            {practitioner.name}
          </Link>
          {practitioner.medicare?.role && (
            <h5 className={styles.registeredPsychologist}>{getRoleLabel(practitioner.medicare.role)}</h5>
          )}
        </div>
        {getTruncatedDescription({
          text: practitioner.helmControl.shortDescription,
          className: styles.shortDescription
        })}
      </div>
      {practitioner.helmControl.primarySpecialisations &&
        practitioner.helmControl.primarySpecialisations.length > 0 && (
          <div className={styles.specialisation}>
            <PsychologistSpecialisation
              label="MAIN AREAS OF PRACTICE"
              specialisationsDetails={getMentalHealthLabels(practitioner.helmControl.primarySpecialisations)}
              selectedValue={getMentalHealthLabels(selectedMentalHealthConcern)}
              matchedSpecialisations={getMentalHealthLabels(matchedSpecialisations)}
              limit={5}
              detailPath={detailPath}
            />
          </div>
        )}
      <div className={styles.bottomContainer}>
        {noSlotForNewClient ? (
          <div className={styles.notAvailableCard}>
            <div className={styles.text}>
              Sorry. I am not available to work with new clients at this time. If you’d like to join my waitlist should
              a spot open up please add your details here.
            </div>
            <ButtonHB
              icon="post_add"
              iconPostFix
              variant="outlined"
              className={styles.joinButton}
              onClick={handleOpenWaitListModal}
            >{`Join ${practitioner.name}'s Waitlist`}</ButtonHB>
          </div>
        ) : nextTimeSlot ? (
          <div className={styles.availableContainer}>
            <span className={styles.nextAvailable}>NEXT AVAILABLE</span>
            <div className={styles.availableCard}>
              <CalendarBox date={moment(nextTimeSlot.date)} />
              <div className={styles.availableDate}>
                {`${moment(nextTimeSlot.date).format(
                  'dddd'
                )}, ${nextTimeSlot.timeSlot.startTime.toLowerCase()} to ${nextTimeSlot.timeSlot.endTime.toLowerCase()}`}
              </div>
            </div>
          </div>
        ) : (
          <div className={styles.notAvailableCard}>
            <div className={styles.text}>
              <div>
                Sorry. My time for <span className={styles.bold}>next 4 weeks</span> is fully booked.
              </div>
            </div>
            <ButtonHB
              icon="post_add"
              iconPostFix
              variant="outlined"
              className={styles.joinButton}
              onClick={handleOpenWaitListModal}
            >{`Join ${practitioner.name}'s Waitlist`}</ButtonHB>
          </div>
        )}
        <ButtonHB icon="navigate_next" iconPostFix to={detailPath} className={styles.seeAll}>
          See all availability for {practitioner.name.split(' ')[0]}
        </ButtonHB>
      </div>
    </div>
  );
};

export default PsychologistGridCard;
