import tacklitLogo from 'assets/images/tacklitLogo.png';
import tacklitLogoWhite from 'assets/images/tacklitLogoWhite.png';
import styles from './TacklitFooter.module.scss';
import classNames from 'classnames';

interface TacklitFooterProps {
  className?: string;
  darkFooter?: boolean;
  linkToTacklit?: boolean;
}

const TacklitFooter = ({ className, darkFooter, linkToTacklit }: TacklitFooterProps) => {
  return (
    <div className={styles.wrapper}>
      <div className={classNames(darkFooter ? styles.darkContainer : styles.container, className)}>
        <img alt={'tacklit logo'} className={styles.tacklitLogo} src={darkFooter ? tacklitLogoWhite : tacklitLogo} />
        <div className={styles.copyrightSymbol}>©</div> Powered by&nbsp;
        {linkToTacklit ? (
          <a href="https://www.tacklit.com" target="_blank" rel="noreferrer">
            Tacklit
          </a>
        ) : (
          'Tacklit'
        )}
      </div>
    </div>
  );
};

export default TacklitFooter;
