import { useEffect, useState } from 'react';

import styles from './AvatarSelector.module.scss';
import classNames from 'classnames';
import Avataaars from 'avataaars';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRedoAlt } from '@fortawesome/free-solid-svg-icons';
import Button from 'components/Button/Button';
import {
  accessoriesType,
  clotheColor,
  clotheType,
  eyebrowType,
  eyeType,
  facialHairColor,
  facialHairType,
  graphicType,
  hairColor,
  mouthType,
  skinColor,
  topType
} from './constants/options';

interface Avatar {
  topType: any;
  accessoriesType: string;
  facialHairType: string;
  clotheType: string;
  clotheColor: string;
  eyeType: string;
  eyebrowType: string;
  mouthType: string;
  skinColor: any;
  hairColor: string;
  facialHairColor: string;
  graphicType: string;
}

interface AvatarSelectorProps {
  onChangeAvatar: (val: string) => void;
}

const AvatarSelector = ({ onChangeAvatar }: AvatarSelectorProps) => {
  const randomiseAvatars = (moreLikeThisIndex?: number): Avatar[] => {
    const generatedAvatars = [];
    let likeThisSkinColor;
    let likeThisTopType;
    if (typeof moreLikeThisIndex !== 'undefined' && moreLikeThisIndex >= 0) {
      likeThisSkinColor = avatars[moreLikeThisIndex].skinColor;
      likeThisTopType = avatars[moreLikeThisIndex].topType;
    }

    for (let i = 0; i < 6; i = i + 1) {
      generatedAvatars.push({
        topType: likeThisTopType ?? topType[Math.floor(Math.random() * topType.length)],
        accessoriesType: accessoriesType[Math.floor(Math.random() * accessoriesType.length)],
        facialHairType: facialHairType[Math.floor(Math.random() * facialHairType.length)],
        clotheType: clotheType[Math.floor(Math.random() * clotheType.length)],
        clotheColor: clotheColor[Math.floor(Math.random() * clotheColor.length)],
        eyeType: eyeType[Math.floor(Math.random() * eyeType.length)],
        eyebrowType: eyebrowType[Math.floor(Math.random() * eyebrowType.length)],
        mouthType: mouthType[Math.floor(Math.random() * mouthType.length)],
        skinColor: likeThisSkinColor ?? skinColor[Math.floor(Math.random() * skinColor.length)],
        hairColor: hairColor[Math.floor(Math.random() * hairColor.length)],
        facialHairColor: facialHairColor[Math.floor(Math.random() * facialHairColor.length)],
        graphicType: graphicType[Math.floor(Math.random() * graphicType.length)]
      });
    }
    return generatedAvatars;
  };

  const defaultAvatars = randomiseAvatars();
  const [avatars, setAvatars] = useState(defaultAvatars);
  const [selectedAvatar, setSelectedAvatar] = useState(0);

  useEffect(() => {
    selectAvatar(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [avatars]);

  const selectAvatar = (index: number) => {
    setSelectedAvatar(index);

    const svgNode = document.getElementById(`avatar-${index}`)?.children[0];
    const canvas = document.getElementById('canvas') as HTMLCanvasElement;
    const ctx = canvas?.getContext('2d')!;
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    const anyWindow = window as any;
    const DOMURL = anyWindow.URL || anyWindow.webkitURL || window;

    const data = svgNode?.outerHTML as string;
    const img = new Image();
    const svg = new Blob([data], { type: 'image/svg+xml' });
    const url = DOMURL.createObjectURL(svg);

    img.onload = () => {
      ctx.save();
      ctx.scale(2, 2);
      ctx.drawImage(img, 0, 0);
      ctx.restore();
      DOMURL.revokeObjectURL(url);
      onChangeAvatar(ctx.canvas.toDataURL());
    };
    img.src = url;
  };

  return (
    <div className={styles.container}>
      <div className={styles.avatarsContainer}>
        {avatars.map((avatar, index) => (
          <div className={styles.avatarWrapper} key={`avatar-${index}`}>
            <div className={styles.avatarContainer}>
              <div
                className={classNames(styles.avatar, selectedAvatar === index && styles.selected)}
                onClick={() => {
                  selectAvatar(index);
                }}
                id={`avatar-${index}`}
              >
                <Avataaars
                  avatarStyle={'Circle'}
                  topType={avatar.topType}
                  accessoriesType={avatar.accessoriesType}
                  facialHairType={avatar.facialHairType}
                  clotheType={avatar.clotheType}
                  clotheColor={avatar.clotheColor}
                  eyeType={avatar.eyeType}
                  eyebrowType={avatar.eyebrowType}
                  mouthType={avatar.mouthType}
                  skinColor={avatar.skinColor}
                  hairColor={avatar.hairColor}
                  facialHairColor={avatar.facialHairColor}
                  graphicType={avatar.graphicType}
                />
              </div>
              <div
                className={styles.moreLikeThis}
                onClick={() => {
                  setAvatars(randomiseAvatars(index));
                  selectAvatar(index);
                }}
              >
                <FontAwesomeIcon icon={faRedoAlt} className={styles.redoIcon} />
                <div className={styles.redoLabel}>More like this</div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <canvas id="canvas" style={{ display: 'none' }} width="528" height="560" />
      <div className={styles.avatarGenerateMore}>
        <Button
          className={styles.moreAvatarButton}
          type="button"
          variant="secondary"
          onClick={() => {
            setAvatars(randomiseAvatars());
          }}
        >
          <FontAwesomeIcon icon={faRedoAlt} /> More avatar options
        </Button>
      </div>
    </div>
  );
};

export default AvatarSelector;
