import { useLogout } from 'utils/hooks/logout';

import { useAuth0 } from '@auth0/auth0-react';
import classNames from 'classnames';
import ClientProfileAvatar from 'components/ClientProfileAvatar/ClientProfileAvatar';
import { useGetClientProfileDetails } from 'redux/endpoints/clinicianProfileServices/getClientDetails';
import queryString from 'query-string';
import { useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useFetchAvatar } from 'utils/hooks/getAvatarFromToken';
import { useRoutesGenerator } from 'utils/Path/RoutesGenerator';
import HeaderMenu from '../../helm/components/HelmHeader/HelmFilterHeader/components/HeaderMenu/HeaderMenu';
import { useFetchPatientDetails } from '../../pages/PatientCheckIn/PatientCheckIn';
import styles from './ClientHeader.module.scss';
import { useGetAccessToken } from 'utils/hooks/token';
import { Skeleton } from 'antd';
import { useCustomLogo } from '../../utils/hooks/useCustomLogo';

interface ClientHeaderProps {
  withMenu?: boolean;
  noPadding?: boolean;
  darkLogo?: boolean;
}

const ClientHeader = ({ withMenu, noPadding, darkLogo }: ClientHeaderProps) => {
  const location = useLocation();
  const { user, isAuthenticated } = useAuth0();
  const { initialsName } = useFetchAvatar();
  const { logout } = useLogout();
  const { name }: { name?: string } = queryString.parse(decodeURIComponent(location.search));
  const { patientDetails } = useFetchPatientDetails(name);
  const { clientProfileData } = useGetClientProfileDetails();
  const { isQueryParamToken } = useGetAccessToken(true);
  const { APPOINTMENT_OVERVIEW, UPDATE_PROFILE, SMP } = useRoutesGenerator();

  const {
    darkLogo: darkCustomLogo,
    lightLogo: lightCustomLogo,
    logoAlt,
    isCustomLogo,
    isLogoLoading
  } = useCustomLogo();

  const menuItems = useMemo(() => {
    const defaultMenuItems = [
      { name: 'Manage my appointments', url: APPOINTMENT_OVERVIEW, isExternal: false },
      { name: 'Manage my profile', url: UPDATE_PROFILE, isExternal: false }
    ];

    if (clientProfileData?.isAccountSmpEnabled) {
      return [...defaultMenuItems, { name: 'My Self Management Plan', url: SMP.LIST, isExternal: false }];
    }

    return defaultMenuItems;
  }, [APPOINTMENT_OVERVIEW, SMP.LIST, UPDATE_PROFILE, clientProfileData?.isAccountSmpEnabled]);

  return (
    <div className={classNames(styles.container, noPadding && styles.noPadding)}>
      <div>
        {isLogoLoading ? (
          <div className={styles.logoLoading}>
            <Skeleton.Input active className={styles.logoLoadingImg} />
          </div>
        ) : (
          <Link to={'/'}>
            <img
              className={isCustomLogo ? styles.customLogoImage : styles.logoImage}
              src={darkLogo ? darkCustomLogo : lightCustomLogo}
              alt={logoAlt}
            />
          </Link>
        )}
      </div>
      <div className={styles.profileWrapper}>
        <div className={styles.profileBox}>
          <div className={styles.profile}>
            <ClientProfileAvatar
              avatarUrl={user?.picture || patientDetails?.picture}
              initialsName={initialsName}
              tokenUser={!!initialsName}
            />
          </div>
          {!isQueryParamToken && withMenu && isAuthenticated && (
            <div className={styles.menuWrapper}>
              <HeaderMenu menuList={menuItems}>
                <i className={`material-icons ${styles.menuIcon}`}>menu</i>
              </HeaderMenu>
            </div>
          )}
        </div>
        {!isQueryParamToken && isAuthenticated && !withMenu && (
          <div className={styles.logOutBtn} onClick={logout}>
            Log out <i className={`material-icons ${styles.icon}`}>exit_to_app</i>
          </div>
        )}
      </div>
    </div>
  );
};

export default ClientHeader;
