import { PractitionerDetailsInterface } from 'interfaces/PublicProfile/Practitioner/practitioner';
import styles from './PsychologistCard.module.scss';
import { generatePath } from 'react-router-dom';
import { useSelectRoutesGenerator } from 'Select/utils/path/SelectRoutesGenerator';
import { MENTAL_HEALTH_LIST_LABELS, MentalHealthList } from 'Select/interfaces/mentalHealth';
import SelectContentLayout from 'Select/components/SelectContentLayout/SelectContentLayout';
import PsychologistBasicDetails from './components/PsychologistBasicDetails/PsychologistBasicDetails';
import { PsychologistFitFilter } from '../../PsychologistListing';
import PsychologistSpecialisation from './components/PsychologistSpecialisation/PsychologistSpecialisation';
import PsychologistAvailabilityCalendar from './components/PsychologistAvailabilityCalendar/PsychologistAvailabilityCalendar';
import PsychologistAvatar from 'Select/components/PsychologistAvatar/PsychologistAvatar';
import PsychologistBonaFides from 'Select/components/PsychologistBonaFides/PsychologistBonaFides';

interface PsychologistCardProps {
  psychologist: PractitionerDetailsInterface;
  matchedSpecialisations: string[];
  selectedFitFilter: PsychologistFitFilter;
  selectedMentalHealthConcern: MentalHealthList[];
  isNewClient: boolean;
  isOddItem: boolean;
  isReversedBackground: boolean;
  onClickJoinWaitlist: () => void;
}

const PsychologistCard = ({
  psychologist,
  matchedSpecialisations,
  selectedFitFilter,
  selectedMentalHealthConcern,
  isNewClient,
  isOddItem,
  isReversedBackground,
  onClickJoinWaitlist
}: PsychologistCardProps) => {
  const { PRACTITIONER } = useSelectRoutesGenerator();

  const detailsPagePath = generatePath(PRACTITIONER.DETAILS, {
    clinicianId: psychologist.slugUrl
  });
  const detailPath = `${detailsPagePath}`;
  const detailPathWithTimeSlot = `${detailsPagePath}`;
  const matchedSecondarySpecialisations =
    matchedSpecialisations.length > 0 &&
    psychologist.helmControl.secondarySpecialisations &&
    psychologist.helmControl.secondarySpecialisations.length > 0
      ? psychologist.helmControl.secondarySpecialisations
          .filter((item) => matchedSpecialisations.includes(item))
          .map((item) => MENTAL_HEALTH_LIST_LABELS[item as MentalHealthList])
      : [];

  return (
    <div className={isReversedBackground ? styles.contentWrapperRs : styles.contentWrapper}>
      <SelectContentLayout>
        <div className={styles.content}>
          <PsychologistBasicDetails
            practitionerDetails={psychologist}
            detailPath={detailPath}
            className={styles.mobileDetails}
            selectedValue={[selectedFitFilter.deliveryMode || '']}
          />
          <div className={styles.profileWrapper}>
            <PsychologistAvatar
              className={styles.profile}
              classNameImage={styles.profileImg}
              profileImg={psychologist.avatar}
              detailPath={detailPath}
            />
            <PsychologistBonaFides limitDisplayQualifications bonaFidesDetails={psychologist.helmControl.bonaFides} />
          </div>
          <div className={styles.detailsContainer}>
            <PsychologistBasicDetails
              practitionerDetails={psychologist}
              detailPath={detailPath}
              className={styles.details}
              selectedValue={[selectedFitFilter.deliveryMode || '']}
            />
            {psychologist.helmControl.primarySpecialisations &&
              psychologist.helmControl.primarySpecialisations.length > 0 && (
                <PsychologistSpecialisation
                  label={'MAIN AREAS OF PRACTICE'}
                  specialisationsDetails={psychologist.helmControl.primarySpecialisations.map(
                    (item) => MENTAL_HEALTH_LIST_LABELS[item as MentalHealthList]
                  )}
                  selectedValue={selectedMentalHealthConcern.map(
                    (item) => MENTAL_HEALTH_LIST_LABELS[item as MentalHealthList]
                  )}
                  limit={5}
                  matchedSpecialisations={matchedSpecialisations.map(
                    (item) => MENTAL_HEALTH_LIST_LABELS[item as MentalHealthList]
                  )}
                  detailPath={detailPath}
                />
              )}

            {/*  Show this only if any in secondary specialisations matched with selected filters
             *   and show only matched value
             */}
            {matchedSecondarySpecialisations.length > 0 && (
              <PsychologistSpecialisation
                label={'I CAN ALSO HELP WITH'}
                specialisationsDetails={matchedSecondarySpecialisations}
                badgeClassName={styles.highlightSecondarySpecs}
              />
            )}
            <PsychologistAvailabilityCalendar
              practitionerDetails={psychologist}
              detailPath={detailPathWithTimeSlot}
              nextAvailabilityDetails={psychologist.nextAvailabilities?.filter((item) =>
                isNewClient
                  ? item.appointmentTypeBookingRules?.new.available
                  : item.appointmentTypeBookingRules?.existing.available
              )}
              isDarkerBackground={isOddItem}
              onShowWaitlistForm={onClickJoinWaitlist}
            />
          </div>
        </div>
      </SelectContentLayout>
    </div>
  );
};

export default PsychologistCard;
