import { useAppDispatch, useAppSelector } from 'redux/hooks';
import styles from './SelectedSlotDetails.module.scss';
import {
  selectSelectedDeliveryType,
  selectSelectedGPAppointmentType,
  selectSelectedTimeSlot,
  setSelectedDeliveryType,
  setSelectedTimeSlot
} from 'redux/globalStore/gpBookingSlice';
import SelectSlot from 'SomeoneHealth/pages/PsychologistDetails/components/PsychologistTimeSlot/components/SelectSlot/SelectSlot';
import moment from 'moment';
import ButtonSH, { ButtonStatusType } from 'SomeoneHealth/components/ButtonSH/ButtonSH';
import classNames from 'classnames';
import { DeliveryType } from 'utils/hooks/appointment';
import ToggleSwitchV2 from 'components/ToggleSwitchV2/ToggleSwitchV2';
import { toWord } from 'utils/generateCamelCase';
import { useGetAttachedClinicianDetails } from 'redux/endpoints/clinicianProfileServices/getClientDetails';
import { postReservedAppointment } from 'utils/http/appointment';
import { useTimeZone } from 'utils/hooks/useTimeZone';
import { SOMEONE_HEALTH_TIME_ZONE_LIST, someoneHealthTimeZoneLocalStorage } from 'utils/constants/timeZone';
import { massageTimeSlotReverse } from 'SomeoneHealth/pages/PsychologistListing/hooks/getPsychologistList';
import { notification } from 'antd';
import { useSetReserveGPAppointmentData } from 'utils/hooks/EngageReserved/reservedAppointment';
import { useNavigate } from 'react-router-dom';
import { useSomeoneHealthRoutesGenerator } from 'SomeoneHealth/utils/Path/SomeoneHealthRoutesGenerator';
import { useState } from 'react';
import { scheduleServicesApiSlice } from 'redux/services/scheduleServicesApiSlice';
import { scrollToView } from 'utils/scrollToView';

const DeliveryIconMapping: Record<DeliveryType, string> = {
  [DeliveryType.FaceToFace]: 'face',
  [DeliveryType.PhoneCall]: 'phone_forwarded',
  [DeliveryType.PhoneCallDialClient]: 'phone_forwarded',
  [DeliveryType.VideoCall]: 'video_camera_front',
  [DeliveryType.Other]: 'connect_without_contact'
};

const SelectedSlotDetails = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { GP } = useSomeoneHealthRoutesGenerator();

  const [submitButtonStatus, setSubmitButtonStatus] = useState<ButtonStatusType>('');

  const { setReserveGPAppointmentData } = useSetReserveGPAppointmentData();
  const { attachedClinicianDetail } = useGetAttachedClinicianDetails();
  const selectedTimeSlot = useAppSelector(selectSelectedTimeSlot);
  const selectedGPAppointmentType = useAppSelector(selectSelectedGPAppointmentType);
  const selectedDeliveryType = useAppSelector(selectSelectedDeliveryType);

  const clientTimeZone = useTimeZone(SOMEONE_HEALTH_TIME_ZONE_LIST, someoneHealthTimeZoneLocalStorage);

  const onSubmit = async () => {
    if (attachedClinicianDetail?.accountId && selectedGPAppointmentType?._id && selectedTimeSlot) {
      setSubmitButtonStatus('active');
      const payload = {
        isNewClient: false,
        clinicianId: selectedTimeSlot.clinicianId,
        appointmentTypeId: selectedGPAppointmentType._id,
        deliveryType: selectedDeliveryType,
        slots: [massageTimeSlotReverse(selectedTimeSlot, clientTimeZone)]
      };

      try {
        const response = await postReservedAppointment(attachedClinicianDetail?.accountId || '', payload);
        if (response.statusCode === 200) {
          const responseDate: { clinicianId: string; reserveId: string } = await response.json();
          setReserveGPAppointmentData({
            reserveId: responseDate.reserveId,
            clinicianId: responseDate.clinicianId,
            accountId: attachedClinicianDetail.accountId
          });
          setSubmitButtonStatus('finished');
          scrollToView('SomeoneHealthHeader');
          navigate(GP.CONFIRM_BOOKING);
        } else if (response.statusCode === 409) {
          notification.info({
            message: 'You selected slot is reserved by someone else, please select other',
            duration: 2,
            closeIcon: <span className="success">OK</span>
          });
          dispatch(setSelectedTimeSlot(undefined));
          dispatch(scheduleServicesApiSlice.util.invalidateTags(['AvailabilityAppointmentsByAppointmentTypeId']));
        }
      } catch (ex) {
        notification.error({
          message: 'GP appointment fails to booked',
          duration: 2,
          closeIcon: <span className="success">OK</span>
        });
      } finally {
        setTimeout(() => {
          setSubmitButtonStatus('');
        }, 1000);
      }
    }
  };

  const consultPreferences = selectedGPAppointmentType?.deliveryOptions
    .map((item: DeliveryType, index) => ({
      sequenceId: item === DeliveryType.VideoCall ? 1 : item === DeliveryType.PhoneCall ? 2 : index + 3,
      id: item,
      label: DeliveryIconMapping[item],
      isActive: selectedDeliveryType === item,
      isIcon: true
    }))
    .sort((a, b) => a.sequenceId - b.sequenceId);

  return (
    <div className={styles.container}>
      {/* Slot */}
      <div className={styles.slotWrapper}>
        <SelectSlot
          date={selectedTimeSlot?.date ? moment(selectedTimeSlot.date) : undefined}
          time={`${moment(selectedTimeSlot?.startTime, 'hh:mmA').format('hh:mm')} - ${selectedTimeSlot?.endTime}`}
          onDelete={() => dispatch(setSelectedTimeSlot(undefined))}
          blockSlot={selectedTimeSlot?.isConflict}
        />
      </div>

      <div className={styles.middleContent}>
        {/* Description */}
        <div className={classNames(styles.bookingDesc)}>
          <div className={styles.bookingTitle}>You are booking</div>
          <div className={styles.bookingDescSummary}>
            {selectedGPAppointmentType?.name} | {selectedGPAppointmentType?.duration} minutes
            <span className={styles.bookingOnLabel}>
              {selectedTimeSlot ? ` on ${moment(selectedTimeSlot.date).format('DD MMM YYYY')}` : ''}
            </span>
          </div>
          <div className={styles.importantInfoContainer}>
            <div className={styles.importantInfoTitle}>IMPORTANT INFORMATION</div>
            <div className={styles.importantInfoContent}>
              <div className={styles.cancellation}>
                You can manage or change appointments in line with our{' '}
                <a
                  className={styles.link}
                  href="https://someone.health/terms-and-conditions"
                  rel="noreferrer"
                  target="_blank"
                >
                  cancellation policy
                </a>
              </div>
            </div>
          </div>
        </div>
        {/* Consult Preferences */}
        {consultPreferences && consultPreferences.length > 0 && (
          <div className={styles.consultPreferenceWrapper}>
            <div className={styles.consultPreference}>
              <div className={styles.consultPreferenceLabel}>CONSULT PREFERENCE</div>
              <ToggleSwitchV2
                id="consultPreference"
                toggleList={consultPreferences}
                onChangeStatus={(value) => {
                  dispatch(setSelectedDeliveryType(value.id));
                }}
                className={styles.consultPreferenceButton}
                wrapperClassName={styles[`optionButton${consultPreferences?.length}`]}
                indicatorClassName={styles.indicator}
                labelClassName={styles.label}
                labelActiveClassName={styles.labelActive}
              />
              <div className={styles.selectedPreference}>
                <b>{toWord(selectedDeliveryType).toUpperCase()}</b> {` preferred`}
              </div>
            </div>
          </div>
        )}
      </div>

      {/* Next button */}
      <div className={styles.continueBtnWrapper}>
        <ButtonSH
          status={submitButtonStatus}
          className={styles.continueBtn}
          onClick={onSubmit}
          disabled={!selectedTimeSlot}
          icon={'navigate_next'}
          iconPostFix
        >
          Next to complete booking
        </ButtonSH>
        <div className={styles.continueLabel}>Appointment not confirmed until complete</div>
      </div>
    </div>
  );
};

export default SelectedSlotDetails;
