import { Link } from 'react-router-dom';
import { PractitionerDetailsInterface } from 'interfaces/PublicProfile/Practitioner/practitioner';

import styles from './PsychologistDetailsHeader.module.scss';
import HealthBrightHeader from 'HealthBright/components/HealthBrightHeader/HealthBrightHeader';
import HealthBrightContentLayout from 'HealthBright/components/HealthBrightContentLayout/HealthBrightContentLayout';

interface PractitionerDetailsHeaderProps {
  practitionerDetails: PractitionerDetailsInterface;
  listingLink: string;
  noLogin?: boolean;
}

const PsychologistDetailsHeader = ({ practitionerDetails, listingLink, noLogin }: PractitionerDetailsHeaderProps) => {
  return (
    <div className={styles.container}>
      <HealthBrightHeader withPadding noWrapper noLogin={noLogin} />
      <HealthBrightContentLayout className={styles.wrapper}>
        <div className={styles.overlay}>
          <div className={styles.detailsWrapper}>
            <div className={styles.name}>{practitionerDetails.name}</div>
            <div className={styles.actionWrapper}>
              <Link to={listingLink} className={styles.otherPractitioner}>
                <i className={`material-icons ${styles.icon}`}>arrow_back</i>
                <div className={styles.label}>SEE OTHER PSYCHOLOGISTS</div>
              </Link>
            </div>
          </div>
        </div>
        <div className={styles.content}>
          <div className={styles.middleWrapper}>
            <div className={styles.headerImageContainer}>
              {practitionerDetails.helmControl?.headerPhoto && (
                <div className={styles.headerImgWrapper}>
                  <div className={styles.headerImageBgWrapper}>
                    <div className={styles.headerImgBackground} />
                  </div>
                  <div className={styles.headerImgMask}>
                    <img
                      alt={'heading_photo'}
                      className={styles.headerImage}
                      src={practitionerDetails.helmControl.headerPhoto}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </HealthBrightContentLayout>
    </div>
  );
};

export default PsychologistDetailsHeader;
