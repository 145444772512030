import { generatePath } from 'react-router-dom';
import { newHelmUpdate } from 'utils/featureToggle/featureToggle';

export const getHelmPractitionerDetailsPath = ({
  clinicianId,
  counsellorsCalendarDetailsPath,
  practitionerDetailsPath
}: {
  clinicianId: string;
  counsellorsCalendarDetailsPath: string;
  practitionerDetailsPath: string;
}) =>
  newHelmUpdate
    ? generatePath(counsellorsCalendarDetailsPath, {
        clinicianId
      })
    : generatePath(practitionerDetailsPath, {
        clinicianId
      });
